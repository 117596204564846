import React from "react";

// Assets
import header from "../../../assets/bs/propos-header.png";

const Header = () => {
	return (
		<div className="header">
			<div className="header__container">
				<div className="header__content">
					<p className="header__content--section">À propos</p>
					<h1 className="header__content--title">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit
					</h1>
				</div>
				<div className="header__image">
					<img src={header} alt="" />
				</div>
			</div>
		</div>
	);
};

export default Header;
