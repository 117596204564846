import React from "react";
import { useHistory } from "react-router-dom";
// Material UI Core
import { Grid } from "@material-ui/core";
// Redux
import { useDispatch } from "react-redux";
import { addSubscription } from "../../../store/reducers/checkout";
// DB
import { getRequest, postRequest } from "../../../actions/requests";
import { subscriptionsRoot } from "../../../actions/subscriptionsRoute";
// assets
import Promo from "../../../assets/akt/bf-ete2022.jpg";
import PromoMobile from "../../../assets/akt/bf-ete2022.jpg";
import Jimmy from "../../../assets/akt/jimmy.png";

function Products() {
    const history = useHistory();
    // Redux
    const dispatch = useDispatch();

    const YEARLYSOLO = "TdWWNjaYrD2Rhc95tkFv";
    const YEARLYDUO = "b0ohOyDVJkmxsCaVVWQO";
    const MONTHLY = "9RPkVos0gMqxQgxRx2C7";

    return (
        <>
        {/* FEATURED ITEM */}

        {/* <Grid item xs={12} className="promo__desktop">
                <img src={Promo} alt="" />
            </Grid>

            <Grid item xs={12} className="promo__mobile">
                <img src={PromoMobile} alt="" />
                <br />
                <br />
                <br />
                <br />
                <br />
            </Grid> */}


        <div className="products" id="produits">
            <div className="products__container">
                <h3 className="products__title">CHOISISSEZ LE PLAN POUR VOUS!</h3>
                <Grid container spacing={3} className="products__grid">
                    <img src={Jimmy} className="products__jimmy" />
                    <Grid item lg={4} >

                    </Grid>
                    <Grid item md={12} lg={4}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                const subPayload = await getRequest(`${subscriptionsRoot}/${YEARLYSOLO}`);
                                const subscription = subPayload.data;
                                dispatch(addSubscription({ ...subscription, selectedQuantity: 1 }));
                                history.push(`/checkout`);
                            }}
                        >
                            <div className="products__top akt-dark-teal">
                                <span className="products__top__text">
                                    Le prix affiché inclut <br /> la promotion en cours
                                <span className="products__top__text__disclaimer">si applicable</span>
                                </span>
                            </div>

                            <div className="products__name">
                                <span>
                                    Forfait SOLO <br />
                                    annuel Aktivation
                                </span>
                            </div>

                            <div className="products__promo akt-dark-teal">
                                <span className="products__promo__strike strike-red">Prix régulier 249,99$</span>
                                <span className="products__promo__price">224,99$</span>
                                <span className="products__promo__title">PRIX PROMO</span>
                            </div>

                            <div className="products__pricing">
                                <span className="products__price">224,99$</span>
                                <span className="products__price__info">+ taxes | par an</span>
                                <span className="products__price__monthly">(Équivaut à 18,75$ / mois)</span>
                                {/* <span className="products__price__monthly">Prix régulier 199,99$</span> */}
                            </div>

                            <div className="products__items">
                                <span className="products__feature">14 JOURS D'ESSAI GRATUIT</span>
                                <span className="products__feature">Plus de 100 entraînements</span>
                                <span className="products__feature">Accès en ligne 24h/24h</span>
                                <span className="products__feature">Entraînez vous n'importe où et n'importe quand! </span>
                                <span className="products__feature">3 à 4 nouveaux programmes par année GARANTI!</span>
                                <span className="products__feature">Aucun besoin de lecteur DVD</span>
                                <span className="products__feature">Plateforme 100% Québécoise</span>
                                <span className="products__feature">Entraînements de Qualité supérieure!</span>
                                <span className="products__feature">Entraîneurs reconnus</span>
                                <span className="products__feature">Résultats efficaces</span>
                            </div>

                            <div className="products__cta akt-dark-teal">
                                <span>Je m'abonne au Forfait SOLO annuel Aktivation</span>
                            </div>
                        </div>
                    </Grid>

                    {/* <Grid item md={12} lg={4}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                const subPayload = await getRequest(`${subscriptionsRoot}/${YEARLYDUO}`);
                                const subscription = subPayload.data;
                                dispatch(addSubscription({ ...subscription, selectedQuantity: 1 }));
                                history.push(`/checkout`);
                            }}
                        >
                            <div className="products__top akt-light-blue">
                                <span className="products__top__text">
                                    Le prix affiché inclut <br /> la promotion en cours
                                    <span className="products__top__text__disclaimer">si applicable</span>
                                </span>
                            </div>

                            <div className="products__name">
                                <span>
                                    Forfait DUO <br />
                                    annuel Aktivation
                                </span>
                            </div>

                            <div className="products__promo akt-light-blue">
                            <span className="products__promo__strike strike-red">Prix régulier 349,99$</span>
                                <span className="products__promo__price">299,99$</span>
                                <span className="products__promo__title">PRIX PROMO</span>
                            </div>

                            <div className="products__pricing">
                                <span className="products__price">299,99$</span>
                                <span className="products__price__info">+ taxes | par an</span>
                                <span className="products__price__monthly">(Équivaut à 12,49$ / personne / mois)</span>
                            </div>

                            <div className="products__items">
                                <span className="products__feature">14 JOURS D'ESSAI GRATUIT</span>
                                <span className="products__feature">2 utilisateurs</span>
                                <span className="products__feature">Plus de 100 entraînements</span>
                                <span className="products__feature">Accès en ligne 24h/24h</span>
                                <span className="products__feature">Entraînez vous n'importe où et n'importe quand! </span>
                                <span className="products__feature">3 à 4 nouveaux programmes par année GARANTI!</span>
                                <span className="products__feature">Aucun besoin de lecteur DVD</span>
                                <span className="products__feature">Plateforme 100% Québécoise</span>
                                <span className="products__feature">Entraînements de Qualité supérieure!</span>
                                <span className="products__feature">Entraîneurs reconnus</span>
                                <span className="products__feature">Résultats efficaces</span>
                            </div>

                            <div className="products__cta akt-light-blue">
                                <span>Je m'abonne au Forfait DUO annuel Aktivation</span>
                            </div>
                        </div>
                    </Grid> */}

                    <Grid item md={12} lg={4}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                const subPayload = await getRequest(`${subscriptionsRoot}/${MONTHLY}`);
                                const subscription = subPayload.data;
                                dispatch(addSubscription({ ...subscription, selectedQuantity: 1 }));
                                history.push(`/checkout`);
                            }}
                        >
                            <div className="products__top akt-light-red">
                                <span className="products__top__text">
                                    Le prix affiché inclut <br /> la promotion en cours
                                    <span className="products__top__text__disclaimer">si applicable</span>
                                </span>
                            </div>

                            <div className="products__name">
                                <span>
                                    Forfait Mensuel <br />
                                    Aktivation
                                </span>
                            </div>

                            <div className="products__promo akt-light-red">
                                <span className="products__promo__strike strike-black">Prix régulier 29,99$</span>
                                <span className="products__promo__price">19,99$</span>
                                <span className="products__promo__title">PRIX PROMO</span>
                            </div>

                            <div className="products__pricing">
                                <span className="products__price">19,99$</span>
                                <span className="products__price__info">+ taxes | par mois</span>
                                {/* <span className="products__price__monthly">
                                    Prix régulier <span className="red">29,99$</span>
                                </span> */}
                            </div>

                            <div className="products__items">
                                <span className="products__feature">14 JOURS D'ESSAI GRATUIT</span>
                                <span className="products__feature">Plus de 100 entraînements</span>
                                <span className="products__feature">Accès en ligne 24h/24h</span>
                                <span className="products__feature">Entraînez vous n'importe où et n'importe quand! </span>
                                <span className="products__feature">3 à 4 nouveaux programmes par année GARANTI!</span>
                                <span className="products__feature">Aucun besoin de lecteur DVD</span>
                                <span className="products__feature">Plateforme 100% Québécoise</span>
                                <span className="products__feature">Entraînements de Qualité supérieure!</span>
                                <span className="products__feature">Entraîneurs reconnus</span>
                                <span className="products__feature">Résultats efficaces</span>
                            </div>

                            <div className="products__cta akt-light-red">
                                <span>Je m'abonne au Forfait Mensuel Aktivation</span>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
        </>
    );
}

export default Products;
