import React from "react";
import { useHistory } from "react-router-dom";
// Assets
import Logo from "../../../assets/mcf/js-signature-blue.png";

function Nav() {
    const history = useHistory();

    const closeMenu = () => (document.getElementById("menu_toggler").checked = false);

    return (
        <>
            <div className="nav">
                <div className="nav__top">
                    <span>
                        SUPPORTEZ L’ÉCONOMIE QUÉBÉCOISE - MÉLANGE COUPE FAIM 2.0 EST{" "}
                        <b>
                            <u>100% QUÉBÉCOIS</u>
                        </b>
                    </span>
                </div>
                <div className="nav__main">
                    <div className="nav__logo clickable__tag" onClick={() => {history.push("/"); window.scrollTo(0, 0);}}>
                        <img src={Logo} alt="logo" />
                    </div>
                    <div className="nav__nav">
                        {/* <span>AMBASSADEUR</span> */}
                        <a href="https://www.jimmysevigny.com/blogue/alimentation/" target="_blank">
                        <span className="clickable__tag">
                            RECETTES
                        </span>
                        </a>
                        {/* <span className="clickable__tag" onClick={() => history.push("/detaillants")}>
                            POINTS DE VENTE
                        </span> */}
                        <span className="clickable__tag" onClick={() => history.push("/ambassadeurs")}>
                            AMBASSADEURS
                        </span>
                        <a href="./#faq">
                            <span>VOUS AVEZ DES QUESTIONS?</span>
                        </a>
                        <div className="nav__cta">
                            <button className="nav__cta1" onClick={() => history.push("/portail")}>ESPACE CLIENT</button>
                            <a href="./#produits">
                                <button className="nav__cta2">JE COMMANDE</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="nav__mobile">
                <div className="nav__mobile__logo" className="clickable__tag" onClick={() => {history.push("/"); window.scrollTo(0, 0);}}>
                    <img src={Logo} alt="logo" />
                </div>
                <div className="menu-wrap">
                    <input id="menu_toggler" type="checkbox" className="toggler" />
                    <div className="hamburger">
                        <div></div>
                    </div>
                    <div className="menu">
                        <div>
                            <div>
                                <ul>
                                    <li>
                                    <a href="https://www.jimmysevigny.com/blogue/alimentation/" target="_blank">
                                        <span className="clickable__tag">
                                            RECETTES
                                        </span>
                                    </a>
                                    </li>
                                    {/* <li>
                                        <span className="clickable__tag" onClick={() => history.push("/detaillants")}>
                                            POINTS DE VENTE
                                        </span>
                                    </li> */}
                                    <li>
                                        <span className="clickable__tag" onClick={() => history.push("/ambassadeurs")}>
                                            AMBASSADEURS
                                        </span>
                                    </li>
                                    <li>
                                        <a href="./#faq" onClick={() => closeMenu()}>
                                            VOUS AVEZ DES QUESTIONS?
                                        </a>
                                    </li>
                                    <div className="nav__cta">
                                        <button className="nav__cta1 clickable__tag" onClick={() => history.push("/portail")}>
                                            ESPACE CLIENT
                                        </button>
                                        <a href="./#produits">
                                            <button className="nav__cta2 clickable__tag" onClick={() => closeMenu()}>
                                                JE COMMANDE
                                            </button>
                                        </a>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Nav;
