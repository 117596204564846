import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import Grid from '@material-ui/core/Grid';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import BG from '../../../assets/gc/faq-bg.png';

function Faq() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div className="faq" id="faq">
            <div className="faq__container">
                <Grid container>

                    <Grid item sm={12} md={8}>

                        <h3 className="faq__title">La réponse à vos questions</h3>

                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Qu’est-ce que Go Choko! ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Il s’agit de collations prêtes-à-manger faites à base de perles de protéines qui sont recouvertes de VRAI chocolat. Ces collations ont pris plusieurs années à développer en collaboration avec des nutritionnistes, des chercheurs ainsi que des chocolatiers.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Combien de portion se retrouvent dans un de vos produit ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>1 sac de Go Choko ! contient 50gr de délicieuses perles. Il est possible de le consommer en 1 seule ou plusieurs portions.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>De quelle façon sont livrés les produits ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>En colis standard par Postes Canada</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Renouvellement de l’abonnement obligatoire ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Aucunement. Vous pouvez annuler en tout temps.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Comment modifier mes informations personnelles, renouvellement etc?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Il suffit de vous connecter via votre profil afin de modifier facilement toutes vos informations.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Quelle est la durée de conservation de Go Choko! ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>La date d’expiration affichée est de 24 mois après sa création.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Quel est le contenu en protéines d’un sachet ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Chaque sachet de Go Choko! contient entre 13 et 17 grammes de protéines.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Est-ce que les enfants peuvent consommer Go Choko! ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Aucun problème! Toutefois, veuillez vous assurer que votre enfant n’est pas allergique à l’un ou l’autre des ingrédients contenus dans nos sachets.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Est-ce que votre produit est certifié sans arachides ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Oui.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Est-ce que le produit contient du gluten ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Non.</Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item md={4}>
                        <img src={BG} className="faq__bg" />
                    </Grid>

                </Grid>
            </div>
        </div>
    );
}

export default Faq;
