import React from "react";

// React-router-dom
import { Link } from "react-router-dom";

// Assets
import box from "../../../assets/bs/order-box.png";
import plates from "../../../assets/bs/order-plates.png";

const OrderHome = () => {
    const Item = ({ image, imageAlt, title, top, price, color }) => {
        return (
            <div className={`orderHome__item orderHome__item--${color}`}>
                <img src={image} alt={imageAlt} className="item__image" />
                <p className={`item__top item__top--${color}`}>{top}</p>
                <h3 className="item__title">{title}</h3>
                <p className="item__price">
                    À partir de <b>{price}$</b>
                </p>
                <div className="item__bottom">
                    <Link to="/order" className={`button button__cta button__cta--${color}`}>Débuter ma commande</Link>
                    <Link to={"/menus"} className="item__bottom--menus">
                        Voir les menus
                    </Link>
                </div>
                <p className="item__footer">*Abonnement non requis</p>
            </div>
        );
    };

    return (
        <div className="orderHome">
            <Item
                color="green"
                image={box}
                imageAlt="boite-decouverte"
                title="Envie de découverte?
Essayez nos boîtes pré-faites, vous serez conquis.*"
                top="boîte découverte"
                price={124.99}
            />
            <Item
                color="orange"
                image={plates}
                imageAlt="boîte-sur-mesure"
                title="Créez votre boîte 
        et commandez-la une ou cent fois*"
                top="boîte sur-mesure"
                price={124.99}
            />
        </div>
    );
};

export default OrderHome;
