import React from "react";

// React-router-dom
import { Link } from "react-router-dom";

// Assets
import box from "../../../assets/bs/how-box.png";
import coffee from "../../../assets/bs/coffee.png";
import truck from "../../../assets/bs/truck.png";
import squares from "../../../assets/bs/how-squares.png";

const OrderOnline = () => {
    const Step = ({ number, icon, iconAlt, title, text }) => {
        return (
            <div className="orderOnline__step">
                <div className="step__number button button__regular">{number}</div>
                <img src={icon} alt={iconAlt} />
                <h6 className="step__title">{title}</h6>
                {text && <p className="step__text">{text}</p>}
            </div>
        );
    };

    return (
        <div className="orderOnline">
            <div className="orderOnline__container">
                <h2 className="orderOnline__title">Commandez en ligne</h2>
                <div className="orderOnline__steps">
                    <Step number={1} icon={box} iconAlt="boîte" title="Choisissez une boîte repas ou créez la vôtre" />
                    <Step number={2} icon={squares} iconAlt="des carrés" title="Choisissez votre type d'abonnement" />
                    <Step number={3} icon={truck} iconAlt="un camion" title="Recevez votre boîte directement chez vous" />
                    <Step number={4} icon={coffee} iconAlt="un café chaud" title="Réchauffez et savourez" />
                </div>
                <Link to="/order" className="button button__cta button__cta--orange">Débuter une commande</Link>
            </div>
        </div>
    );
};

export default OrderOnline;
