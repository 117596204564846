import React from 'react'

// Assets
import fruits from "../../../assets/bs/advantages-fruits.png";
import plate from "../../../assets/bs/advantages-plate.png";
import box from "../../../assets/bs/advantages-box.png";

const Advantages = () => {

  const Item = ({title, image, text}) => {
    return (
      <div className="advantages__item">
        <img src={image} alt="" />
        <div className="item__content">
          <h5 className="item__content--title">{title}</h5>
          <p className="item__content--text">{text}</p>
        </div>
      </div>
    )
  }

  return (
    <div className='advantages'>
      <Item 
        title="Santé"
        image={fruits}
        text="Des recettes élaborées par notre nutritionniste"
      />
      <Item 
        title='Rapide'
        image={plate}
        text="Prêts en 2min, à la maison ou au bureau"
      />
      <Item 
        title='Pratique'
        image={box}
        text="Livraison gratuite partout au Québec"
      />
    </div>
  )
}

export default Advantages