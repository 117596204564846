// https://stackoverflow.com/questions/365826/calculate-distance-between-2-gps-coordinates

function degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180;
}

export function distanceInKmBetweenEarthCoordinates(lat1, lng1, lat2, lng2) {
    var earthRadiusKm = 6371;

    var dLat = degreesToRadians(lat2 - lat1);
    var dLon = degreesToRadians(lng2 - lng1);

    lat1 = degreesToRadians(lat1);
    lat2 = degreesToRadians(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c;
}
