import React from "react";
import { useHistory } from "react-router-dom";

// style
import style from "./Product.module.scss";

import { getItemPrice } from "../../../../../utils/price";

const Product = ({ value }) => {
    const h = useHistory();

    const handleClick = () => {
        h.push(`/portail/boutique/${value.id}`);
    };

    return (
        <div className={style.container} onClick={handleClick}>
            <div className={style.img__container}>
                <img src={value.imageURLs[0]} alt={value.name} />
            </div>
            <div className={style.info}>
                <span className={style.name}>{value.name}</span>
                <span className={style.price}>{`${getItemPrice(value)} $`}</span>
            </div>
        </div>
    );
};

export default Product;
