import { apiCallBegan } from "../apiCalls";
import { loadingStart, loadingEnd, addSlice } from "../reducers/system";
// utilitary functions for redux store

export const dispatchActions = (dispatch, actions, payload = null) => {
    if (!Array.isArray(actions)) actions = [actions];
    actions.forEach(a => {
        if (typeof a === "string") dispatch({ type: a, payload });
        else {
            if (a.type) dispatch(a);
            else a.call(null);
        }
    });
};

/** A wrapper function used for any loading action. It contains all the usually properties need for
 * an action of he type "apiCallBegan". We can use this function to automate the concept of "loadingState".
 * @param sliceName string  the name of the slice that is being loaded
 * @param dispatch function the dispatch function used by redux
 * @param method string the http method, default is GET
 * @param data object data to be sent
 * @param onStart {array || string} a single action or an array of actions to be done before the api call
 * @param onSuccess {array || string} a single action or an array of actions to be done on a successsful api call
 * @param onError {array || string} a single action or an array of actions to be done on an unsuccesssful api call
 * @param onFinish {array || string} a single action or an array of actions to be done when the api call is finished
 *
 * @throws Error
 */
export const loadingAction = (
    { headers, sliceName, dispatch, url, method, data, onStart, onSuccess, onError, onFinish } = {
        method: "get",
        onStart: [],
        onSuccess: [],
        onError: [],
        onFinish: [],
    }
) => {
    // Error checking
    if (sliceName === "" || sliceName === null || sliceName === undefined) throw new Error("sliceName must be defined");
    if (!dispatch) throw new Error("dispatch must be defined");
    if (typeof dispatch !== "function") throw new Error("dispatch must be a function");
    if (method === "post" && (data === null || data === undefined)) throw new Error("Please supply data for a POST request");

    // Normalize all actions
    if (!Array.isArray(onStart)) {
        if (typeof onStart === "string") onStart = [onStart];
        else if (!onStart) onStart = [];
    }
    if (!Array.isArray(onSuccess)) {
        if (typeof onSuccess === "string") onSuccess = [onSuccess];
        else if (!onSuccess) onSuccess = [];
    }
    if (!Array.isArray(onError)) {
        if (typeof onError === "string") onError = [onError];
        else if (!onError) onError = [];
    }
    if (!Array.isArray(onFinish)) {
        if (typeof onFinish === "string") onFinish = [onFinish];
        else if (!onFinish) onFinish = [];
    }

    // set the loading state action, they will be triggered in the api middleware
    onStart.push(loadingStart.type, addSlice(sliceName).type);

    // TODO: on error, add a snackbar state the error

    // Ending the loading state
    onFinish.push(loadingEnd.type);

    dispatch(apiCallBegan({ headers, url, method, data, onStart, onSuccess, onError, onFinish }));
};
