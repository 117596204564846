import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const TableLoadingSkeleton = ({ numRows = 1, colSpan = 1, animation = "wave" }) => {
    const final = [];

    for (let i = 0; i < numRows; i++) {
        final.push(
            <TableRow key={i} hover>
                <TableCell colSpan={colSpan} align="center">
                    <Skeleton height={50} animation={animation} />
                </TableCell>
            </TableRow>
        );
    }

    return <>{final}</>;
};

export default TableLoadingSkeleton;
