import React, { useState, useEffect } from "react";
import _ from "lodash";
// Custom components
import Table from "../../shared/utils/table";
// Utils
import { settings } from "../../../settings";
import { getItemPrice } from "../../../utils/price";
// Firebase
import { getRequest } from "../../../actions/requests";
import { productsRoot } from "../../../actions/productsRoute";
import { subscriptionsRoot } from "../../../actions/subscriptionsRoute";

const TrialBillTableWrapper = ({ data, loading, market, brand, order, setLoading }) => {
    // Table
    const [sortColumn, setSortColumn] = useState({ path: "orderNumber", order: "asc" });
    const [selectedPage, setSelectedPage] = useState(0);
    const [items, setItems] = useState([]);

    const sortedData = _.orderBy(items, [sortColumn.path], [sortColumn.order]);
    const totalCount = sortedData.length;

    const columns = [
        {
            path: "name",
            label: "Article",
            asyncBody: async item => {
                try {
                    let itemDescription = "";
                    const itemObj = data.find(d => d.id === item.id);

                    let dataItem;
                    if (item.rate) {
                        dataItem = (await getRequest(`subscriptions/${item.id}`)).data;
                    } else {
                        dataItem = (await getRequest(`products/${item.id}`)).data;
                    }

                    if (dataItem.rate) {
                        itemDescription = "(Abonnement)";
                    } else if (itemObj && itemObj.isAddOnTrial) {
                        itemDescription = "(le produit vous sera envoyé après votre essai gratuit si vous poursuivez votre abonnement)";
                    }

                    return `${dataItem.name} ${itemDescription}`;
                } catch (e) {
                    console.error(e);
                }
            },
        },
        { path: "sku", label: "Sku" },
        {
            path: "status",
            label: "Statut",
            body: item => {
                const dataItem = data.find(d => d.id === item.id);
                return dataItem ? dataItem.status : "";
            },
        },
        {
            path: "selectedQuantity",
            label: "Quantité",
            body: item => (item.selectedQuantity ? item.selectedQuantity : 1),
        },
        {
            path: "price",
            label: "Prix",
            body: item => "0.00 $",
        },
        {
            path: "subTotal",
            label: "Sous-total",
            body: item => "0.00 $",
        },
    ];

    const handleSort = sortColumn => {
        setSortColumn(sortColumn);
    };

    const handlePageChange = (_, page) => {
        setSelectedPage(page);
    };

    const handleRowClick = order => {};

    const fetchItems = async () => {
        try {
            setLoading(true);
            const promises = data.map(async d => {
                if (d.isSubscription) {
                    const subPayload = await getRequest(`${subscriptionsRoot}/${d.id}`);
                    const sub = subPayload.data;
                    sub.selectedQuantity = d.selectedQuantity;
                    if (d.hasOwnProperty("price")) {
                        sub.price = d.price;
                    }
                    return sub;
                } else {
                    const productPayload = await getRequest(`${productsRoot}/${d.id}`);
                    const product = productPayload.data;
                    product.selectedQuantity = d.selectedQuantity;
                    if (d.hasOwnProperty("price")) {
                        product.price = d.price;
                    }
                    return product;
                }
            });
            setItems(await Promise.all(promises));
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (data && data.length > 0) fetchItems();
    }, [data]);

    return (
        <Table
            columns={columns}
            sortColumn={sortColumn}
            data={sortedData}
            rowsPerPage={settings.page.rowsPerPage}
            selectedPage={selectedPage}
            totalCount={totalCount}
            loading={loading}
            sortable={false}
            paginated={false}
            rowClickable={false}
            onSort={handleSort}
            onPageChange={handlePageChange}
            onRowClick={handleRowClick}
        />
    );
};

export default TrialBillTableWrapper;
