import React from "react";
import { Grid } from "@material-ui/core";

import Intro1 from "../../../assets/gc/intro-1.png";
import Intro2 from "../../../assets/gc/intro-2.png";
import Intro3 from "../../../assets/gc/intro-3.png";
import Intro4 from "../../../assets/gc/intro-4.png";

function Intro() {
    return (
        <div className="intro">
            <div className="intro__container">
                <div className="intro__item">
                    <div className="intro__top">
                        <img src={Intro1} alt="" />
                    </div>
                    <div className="intro__bottom">
                        <h6>Valeur élevée <br/> en protéines</h6>
                        <span>Chaque sachet Go Choko! contient jusqu’à 17 grammes de protéines</span>
                    </div>
                </div>
                <div className="intro__item">
                    <div className="intro__top">
                        <img src={Intro2} alt="" />
                    </div>
                    <div className="intro__bottom">
                        <h6>Collation <br/> “on-the-go”</h6>
                        <span>Lors de vos loisirs, au travail ou tout simplement à la maison, Go Choko! vous suit n’importe où</span>
                    </div>
                </div>
                <div className="intro__item">
                    <div className="intro__top">
                        <img src={Intro3} alt="" />
                    </div>
                    <div className="intro__bottom">
                        <h6>Fabriqué <br/> au Québec</h6>
                        <span>Un produit 100% québécois développé en collaboration avec des chocolatiers d’ici!</span>
                    </div>
                </div>
                <div className="intro__item">
                    <div className="intro__top">
                        <img src={Intro4} alt="" />
                    </div>
                    <div className="intro__bottom">
                        <h6>Livraison gratuite <br/> en tout temps</h6>
                        <span>​Nous sommes fiers d’offrir la livraison gratuite partout au Canada</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Intro;
