import React from "react";
import { Grid } from "@material-ui/core";
import Test1 from "../../../assets/mcf/testimonial1.jpg";
import Test2 from "../../../assets/mcf/testimonial2.png";
import Test3 from "../../../assets/mcf/testimonial3.jpg";

function Testimonials() {
    return (
        <div className="testimonials">
            <div className="testimonials__container">
                <h3 className="testimonials__title">
                    Découvrez les témoignages <br /> des consommateurs
                </h3>
                <Grid container spacing={3}>
                    <Grid item md={12} lg={4}>
                        <div className="testimonials__item">
                            <div className="testimonials__top">
                                <span>
                                    Délicieux! Petits fruits et yogourt grec ça fait un bel ajout et c'est consistant. Je vais changer mon abonnement
                                    passer du mois aux deux semaines! Je vous le recommande
                                </span>
                                <img src={Test1} alt="" />
                            </div>
                            <div className="testimonials__bottom">
                                <span>Chantale C.</span>
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={12} lg={4}>
                        <div className="testimonials__item">
                            <div className="testimonials__top">
                                <span>
                                    41 ans, monoparentale, pas le temps... Oui, j'ai toutes les excuses du monde 😉 <br /> Saines habitudes
                                    alimentaires, DVD Intensité 5.25 et Mélange Coupe Faim 2.0 sont mes alliés dans mon mode de vie actif et santé.{" "}
                                    <br />
                                    Oui, 5 ans plus tard, c'est encore possible 💪🏻
                                </span>
                                <img src={Test2} alt="" />
                            </div>
                            <div className="testimonials__bottom">
                                <span>Véronique R.</span>
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={12} lg={4}>
                        <div className="testimonials__item">
                            <div className="testimonials__top">
                                <span>
                                    Un p'tit goût de cabane à sucre pour déjeuner, en encourageant des entreprises locales, ça commence bien la
                                    journée 🥰
                                </span>
                                <img src={Test3} alt="" />
                            </div>
                            <div className="testimonials__bottom">
                                <span>Mélanie M.</span>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Testimonials;
