import React, { useEffect, useCallback, useState } from "react";
import _ from "lodash";
import { GoogleMap, OverlayView, Marker } from "@react-google-maps/api";
// Material UI Core
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MapIcon from "@material-ui/icons/Map";
// Utils
import { GetNavbar, getLandingPageInfo, getMarkerIcon, GetHelmet } from "../../utils/config";
import { distanceInKmBetweenEarthCoordinates } from "../../utils/distance";
// Custom components
import SearchBar from "./utils/searchBar";
// Assets
import eggMarker from "../../assets/shared/eggMarker.png";
// Redux
import { useDispatch } from "react-redux";
import { snackbarEnqueuedAction } from "../../store/reducers/snackbars";
// Firebase
import { getRequest } from "../../actions/requests";

/**
 * Map overlay panel position based on selected marker
 * @param {number} width
 * @param {number} height
 */
const centerOverlayView = (width, height) => ({
    x: -(width / 2),
    y: -(height + 40),
});

const Retailers = () => {
    const { brand } = getLandingPageInfo();
    // Redux
    const dispatch = useDispatch();
    const [selectedStore, setSelectedStore] = useState(null);
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    // Stores
    const [stores, setStores] = useState([]);
    const [filteredStores, setFilteredStores] = useState([]);
    // Map overlay panel
    const [overlayPane, setOverlayPane] = useState(OverlayView.OVERLAY_MOUSE_TARGET);
    const [currentPosition, setCurrentPosition] = useState({ lat: 45.508843, lng: -73.631151 });
    const [map, setMap] = useState(null);

    const fetchStores = async () => {
        const storesPayload = await getRequest(`stores?noLimit=true&brands:array-contains-any=${JSON.stringify([brand])}`);

        setStores(filterStoreByDistance(storesPayload.data));
    };

    const filterStoreByDistance = unfilteredStores => {
        let filteredStores = [];

        // Sort by distance
        filteredStores = unfilteredStores.sort((a, b) => {
            return (
                distanceInKmBetweenEarthCoordinates(currentPosition.lat, currentPosition.lng, a.position.lat, a.position.lng) -
                distanceInKmBetweenEarthCoordinates(currentPosition.lat, currentPosition.lng, b.position.lat, b.position.lng)
            );
        });
        return filteredStores;
    };

    /**
     * Gets the user's current position
     */
    const getCurrentPosition = () => {
        navigator.geolocation.getCurrentPosition(handleGeolocationSuccess, handleGeolocationError);
    };

    const handleGeolocationSuccess = pos => {
        setCurrentPosition({ lat: pos.coords.latitude, lng: pos.coords.longitude });
    };

    // useEffect(() => {
    //     if (stores.length > 0 && !_.isEmpty(currentPosition)) {
    //         filterStoresByDistance(stores, currentPosition);
    //     }
    // }, [stores, currentPosition]);

    /**
     * Displays geolocation error
     * @param {Error} err
     */
    const handleGeolocationError = err => {
        let errorMessage = "";
        switch (err.code) {
            case err.PERMISSION_DENIED:
                errorMessage = "Vous n'avez pas permis au navigateur de détecter votre position.";
                break;
            case err.POSITION_UNAVAILABLE:
                errorMessage = "L'information de position n'est pas disponible.";
                break;
            case err.TIMEOUT:
                errorMessage = "La requête pour obtenir votre position a échouée.";
                break;
            case err.UNKNOWN_ERROR:
                errorMessage = "Un erreur inconnu est survenu.";
                break;
            default:
                break;
        }
        dispatch(
            snackbarEnqueuedAction({
                message: errorMessage + " Position par défaut.",
                options: {
                    variant: "error",
                },
            })
        );
        setCurrentPosition({ lat: 45.508843, lng: -73.631151 });
    };

    // const filterStoresByDistance = (stores, currentPosition) => {
    //     let storesInRadius = [];
    //     stores.forEach(s => {
    //         const distanceInKm = distanceInKmBetweenEarthCoordinates(currentPosition.lat, currentPosition.lng, s.position.lat, s.position.lng);
    //         if (distanceInKm <= MAX_RADIUS / 100) {
    //             storesInRadius.push(s);
    //         }
    //     });
    //     setFilteredStores(storesInRadius);
    // };

    /**
     * Opens or closes the map's overlay panel
     */
    const handleMarkerClick = useCallback(
        store => {
            if (!isOverlayOpen) {
                setSelectedStore(store);
                setCurrentPosition(store.position);
            } else {
                setSelectedStore(null);
            }
            setIsOverlayOpen(!isOverlayOpen);
        },
        [isOverlayOpen]
    );

    const coordinatesInBounds = (lat, lng) => {
        if (!_.isEmpty(map)) {
            const bounds = map.getBounds();

            if (bounds) {
                const topRight = map.getBounds().getNorthEast();
                const bottomLeft = map.getBounds().getSouthWest();
                let isLongInRange = false;
                let isLatiInRange = false;

                if (bottomLeft.lng() < topRight.lng() && bottomLeft.lat() < topRight.lat()) {
                    isLongInRange = lng >= bottomLeft.lng() && lng <= topRight.lng();
                    isLatiInRange = lat >= bottomLeft.lat() && lat <= topRight.lat();

                    return isLongInRange && isLatiInRange;
                }
            }
        }
    };

    const handleBoundsChanged = () => {
        fetchStoreInBounds();
    };

    const fetchStoreInBounds = () => {
        if (map) {
            const storesInBounds = [];
            if (stores && stores.length > 0) {
                for (const s of stores) {
                    if (coordinatesInBounds(s.position.lat, s.position.lng)) storesInBounds.push(s);
                }
            }

            setFilteredStores(filterStoreByDistance(storesInBounds));
        }
    };

    // Only once
    useEffect(() => {
        fetchStores();
        getCurrentPosition();
    }, []);

    // Fetch store in bound when page load
    useEffect(() => {
        if (stores.length > 0 && !_.isEmpty(map)) {
            fetchStoreInBounds();
        }
    }, [stores]);

    return (
        <>
            <GetNavbar />
            <GetHelmet />
            <Grid container className={brand}>
                <Grid item xs={12} md={2}>
                    <div>
                        <div className="storelocator__list">
                            <h1 className="storelocator__title">Points de vente</h1>
                            {filteredStores.map(store => {
                                return (
                                    <div
                                        key={store.id}
                                        className={`storelocator__list__item ${store === selectedStore ? "selected" : ""}`}
                                        onClick={() => {
                                            setSelectedStore(store);
                                            setIsOverlayOpen(!isOverlayOpen);
                                            setCurrentPosition({ lat: store.position.lat, lng: store.position.lng });
                                        }}
                                    >
                                        <span className="storelocator__list__name">{store.name}</span>
                                        <span className="storelocator__list__city">{store.coordinates.city}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={10}>
                    <div className="map">
                        <div className="map-container">
                            <GoogleMap
                                id="storelocator__map"
                                zoom={10}
                                center={currentPosition}
                                onLoad={map => {
                                    setMap(map);
                                    fetchStoreInBounds(map);
                                }}
                                onBoundsChanged={handleBoundsChanged}
                            >
                                <SearchBar setCurrentPosition={setCurrentPosition} />
                                <Marker
                                    icon={eggMarker}
                                    position={{ lat: 39.06339009204529, lng: 125.78909814006333 }}
                                    onClick={() => window.open("https://www.youtube.com/watch?v=dQw4w9WgXcQ", "_blank")}
                                />
                                <Marker position={currentPosition} />
                                {stores.map(store => {
                                    const lat = store.position.lat;
                                    const lng = store.position.lng;
                                    return (
                                        <Marker
                                            icon={getMarkerIcon()}
                                            key={store.id}
                                            position={{ lat, lng }}
                                            onClick={() => handleMarkerClick(store)}
                                        />
                                    );
                                })}
                                {isOverlayOpen && selectedStore && (
                                    <OverlayView
                                        position={selectedStore.position}
                                        mapPaneName={overlayPane}
                                        getPixelPositionOffset={centerOverlayView}
                                    >
                                        <div className="storelocator__map__box">
                                            <div className="clickable__tag" onClick={() => handleMarkerClick()}>
                                                <Typography className="element__centered" variant="h5" style={{ marginBottom: 10 }}>
                                                    {selectedStore.name}
                                                </Typography>
                                                <Typography className="element__centered storelocator__address" variant="body1">
                                                    {selectedStore.coordinates.appartment && selectedStore.coordinates.appartment + " -"}
                                                    {selectedStore.coordinates.streetNumber} {selectedStore.coordinates.street}
                                                </Typography>
                                                <Typography
                                                    className="element__centered storelocator__address"
                                                    variant="body1"
                                                    style={{ marginBottom: 10 }}
                                                >
                                                    {selectedStore.coordinates.city}, {selectedStore.coordinates.province},{" "}
                                                    {selectedStore.coordinates.postalCode}
                                                </Typography>
                                            </div>
                                            <a
                                                className="element__centered"
                                                target="_blank"
                                                href={`https://www.google.ca/maps/dir/${
                                                    currentPosition ? currentPosition.lat + "," + currentPosition.lng : ""
                                                }/${selectedStore.position.lat},${selectedStore.position.lng}`}
                                            >
                                                <MapIcon className="storelocator__icon" />
                                            </a>
                                        </div>
                                    </OverlayView>
                                )}
                            </GoogleMap>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default Retailers;
