import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CryptoJS from "crypto-js";

// Material UI Core
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// Custom Components
import LogoAKT from "../../../assets/portal/logo-aktivation.png";
import LogoGM from "../../../assets/gm/gm-logo-color.png";
import LogoGC from "../../../assets/gc/gc-login.png";
import LogoMCF from "../../../assets/mcf/js-signature-blue.png";

// Custom Components
import { getLandingPageInfo } from "../../../utils/config";
import { GetHelmet } from "../../../utils/config";
import Loading from "../../shared/loading";

// Utils
import { settings } from "../../../settings";

// api requests
import { getRequest } from "../../../actions/requests";

// Hooks
import { useForgotPassword } from "../../../hooks/useForgotPassword";

// validation
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// yup validation
const requiredMessage = "Ce champ est requis";

const schema = yup.object({
    password: yup.string().min(6).required(requiredMessage),
    passwordConfirm: yup
        .string()
        .min(6)
        .oneOf([yup.ref("password")], "Les mots de passe doivent être identiques")
        .required(requiredMessage),
});

export const ChangePassword = () => {
    const hist = useHistory();
    const { email } = useForgotPassword();
    const { enqueueSnackbar } = useSnackbar();
    const { brand, brandAcronym } = getLandingPageInfo();

    // States
    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(schema) });

    const onSubmit = async data => {
        try {
            setLoading(true);
            await getRequest("users/changePassword", {
                email,
                newPassword: CryptoJS.AES.encrypt(data.password, settings.encryption.secret).toString(),
            });
            enqueueSnackbar("Mot de passe modifié", { variant: "success" });
            hist.push("/portail/login");
        } catch (e) {
            if (e.response && e.response.data) {
                enqueueSnackbar(e.response.data, { variant: "error" });
            } else console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const properLogo = () => {
        switch (brandAcronym) {
            case "MCF":
                return LogoMCF;
            case "GM":
                return LogoGM;
            case "AKT":
                return LogoAKT;
            case "GC":
                return LogoGC;
            default:
                return LogoGC;
        }
    };

    if (!email) return <Redirect to="/portail/login" />;
    else
        return (
            <>
                <Loading loading={loading} />

                <div className={`login__page ${brand}`}>
                    <GetHelmet />
                    <Card className="login__container">
                        <CardContent>
                            <img
                                onClick={() => hist.push("/")}
                                src={properLogo()}
                                alt="Leadhouse Logo"
                                className="login__logo"
                            />

                            <div className="element__centered" style={{ fontFamily: "Roboto" }}>
                                <h1>Changement mot de passe</h1>
                            </div>
                            <div className="element__centered" style={{ fontFamily: "Roboto" }}>
                                <h4>
                                    <p>Entrez votre nouveau mot de passe et confirmez le.</p>
                                </h4>
                            </div>

                            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                <div className="login__actions">
                                    <TextField
                                        helperText={errors.password?.message}
                                        error={!!errors.password?.message}
                                        label="Nouveau mot de passe"
                                        type="password"
                                        name="password"
                                        inputRef={register}
                                        fullWidth
                                        required
                                    />
                                </div>
                                <div className="login__actions">
                                    <TextField
                                        helperText={errors.passwordConfirm?.message}
                                        error={!!errors.passwordConfirm?.message}
                                        label="Nouveau mot de passe"
                                        type="password"
                                        name="passwordConfirm"
                                        inputRef={register}
                                        fullWidth
                                        required
                                    />
                                </div>
                                <div className="login__actions">
                                    <Button className={`login__cta ${brand}`} type="submit" variant="contained">
                                        Confirmer
                                    </Button>
                                    <Button className={`login__cta ${brand}`} variant="contained" onClick={() => hist.push("/portail/login")}>
                                        Retour
                                    </Button>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </>
        );
};

export default ChangePassword;
