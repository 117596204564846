import React from "react";
import Grid from '@material-ui/core/Grid';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

function Stats() {
    return (
        <div className="stats">
           <Grid container>
                <Grid item xs={12} md={3}>
                    <div className="stats__item">
                        <VisibilitySensor partialVisibility offset={{ bottom: 1 }}>
                            {({ isVisible }) => (
                            <div>
                                {isVisible ? <CountUp end={1480000} duration={3} className="stats__number" /> : "0"}
                            </div>
                            )}
                        </VisibilitySensor>
                        <span className="stats__description">Nombre de grammes de <br/>proteines consommées</span>
                    </div>
                </Grid>

                <Grid item xs={12} md={3}>
                    <div className="stats__item">
                        <VisibilitySensor partialVisibility offset={{ bottom: 1 }}>
                            {({ isVisible }) => (
                            <div>
                                {isVisible ? <CountUp end={101000} duration={3} className="stats__number" /> : <span className="stats__number">0</span>}
                            </div>
                            )}
                        </VisibilitySensor>
                        <span className="stats__description">Nombre de <br/>sachets vendus</span>
                    </div>
                </Grid>

                <Grid item xs={12} md={3}>
                    <div className="stats__item">
                        <VisibilitySensor partialVisibility offset={{ bottom: 1 }}>
                            {({ isVisible }) => (
                            <div>
                                {isVisible ? <CountUp end={209} duration={3} className="stats__number" /> : <span className="stats__number">0</span>}
                            </div>
                            )}
                        </VisibilitySensor>
                        <span className="stats__description">Nombre de points de <br/>vente au Québec</span>
                    </div>
                </Grid>

                <Grid item xs={12} md={3}>
                    <div className="stats__item">
                        <VisibilitySensor partialVisibility offset={{ bottom: 1 }}>
                            {({ isVisible }) => (
                            <div>
                                {isVisible ? <CountUp end={16480} duration={3} className="stats__number" /> : <span className="stats__number">0</span>}
                            </div>
                            )}
                        </VisibilitySensor>
                        <span className="stats__description">Nombre de <br/>clients satisfaits</span>
                    </div>
                </Grid>
           </Grid>
        </div>
    );
}

export default Stats;