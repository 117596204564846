import { postRequest } from "../../../actions/requests";

/**
 * generate the metadata needs for jwplayer to create a playlist.
 * We first add the teaser if there is one and then for each item in our workout, we add only those
 * containing a video
 **/
export const generatePlaylist = (item, title = "", description = "") => {
    const playlistMetadata = { title, description };
    const playlist = [];

    if (item.fundamental)
        playlist.push({ file: item.fundamental, image: item.thumbnail, title: item.title + " fundamental", description: item.description });
    if (item.hasOwnProperty("difficulty")) playlist.push({ file: item.url, image: item.thumbnail, title: item.title, description: item.description });

    playlistMetadata.playlist = playlist;

    return playlistMetadata;
};

/*
 * Convert a base 64 file to a blob
 * */
export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

/*
 * Fetches a file from the given url and generates a blob file
 * */
export const downloadFile = async (url, filename = "") => {
    try {
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        link.click();
        link.remove();
    } catch (e) {
        console.error(e);
    }
};

const displayItem = item => item.isFile || (item.release && new Date(item.release._value) <= new Date().getTime());

export const getItemsFromPhase = (phaseId, items) => {
    if (!Array.isArray(items) || items.length === 0) return null;
    return items
        .reduce((acc, value, index) => {
            if (value.phase === phaseId) {
                acc.push({ ...value, index });
            }
            return acc;
        }, [])
        .filter(displayItem);
};

export const getItemsOutsidePhase = items => {
    if (!Array.isArray(items) || items.length === 0) return [];
    return items
        .reduce((acc, value, index) => {
            if (value.phase === null || value.phase === undefined) {
                acc.push({ ...value, index });
            }
            return acc;
        }, [])
        .filter(displayItem);
};

export const doesPhaseHasDocument = items => {
    for (let item of items) {
        if (item && !item.hasOwnProperty("difficulty")) return true;
    }

    return false;
};

/*
 * Checks if an item is releases or not. A document does not have a release date, therefore it should always show
 * */
export const isItemReleased = item => {
    if (!item.hasOwnProperty("release")) return true;

    return new Date(item.release._value) > Date.now();
};

/*
 * toggle a video between favorite or not for the given user
 **/
export const onToggleFavorite = (user, workoutId, video) => {
    let favs = {};
    if (user.favoriteVideos) {
        favs = JSON.parse(JSON.stringify(user.favoriteVideos));
        if (!favs[workoutId]) favs[workoutId] = [];
    } else {
        favs[workoutId] = [];
    }

    const isFavorite = Object.values(favs[workoutId]).findIndex(v => v === video.id) > -1;

    if (isFavorite) favs[workoutId] = favs[workoutId].filter(v => v !== video.id);
    else favs[workoutId].push(video.id);

    return { isFavorite: !isFavorite, favs };
};
