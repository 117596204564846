import React, { useState } from "react";
// Material UI Core
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
// Custom Components
import { settings } from "../../../settings";
import MobileNav from "./navigation/mobileNav";
import TopNav from "./navigation/topNav";
import { Fab } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    drawerPaper: {
        width: settings.page.mobileDrawerWidth,
        backgroundColor: "#007faa",
        color: "white",
    },
}));

/**
 * Displays this drawer if screen width <= 960
 */
const MobileMenu = () => {
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <>
            <CssBaseline />
            <Fab
                className="nav__mobile__fab"
                children={
                    <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle}>
                        <MenuIcon />
                    </IconButton>
                }
            />
            <Hidden smUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{ paper: classes.drawerPaper }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <MobileNav isMobile={true} />
                </Drawer>
            </Hidden>
        </>
    );
};

export default MobileMenu;
