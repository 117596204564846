import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";

// Mui
import Button from "@material-ui/core/Button";

// Embed
import Embed from "react-embed";

// redux
import { useDispatch, useSelector } from "react-redux";
import { loadById, getProductById, isLoading, fetchPage } from "../../.../../../../store/reducers/products";

// Custom components
import Skus from "./Skus";
import QuantityControls from "../Cart/QuantityControls";
import ProductCard from "../Products/Product";

// utils
import { parseDraftToHtml } from "../../../../utils/parseData";
import { getItemPrice } from "../../../../utils/price";

// hooks
import useCart from "../../../../hooks/useCart";

// style
import style from "./Product.module.scss";

const Product = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const product = useSelector(getProductById(id));
    const loading = useSelector(isLoading);
    const products = useSelector(state => state.products.list);

    const [selectedSku, setSelectedSku] = useState(null);
    const [relatedItems, setRelatedItems] = useState([]);
    const [showcaseImgSrc, setshowcaseImgSrc] = useState("");

    const hasSkus = product?.skus?.length > 0;

    const { items, changeQuantity } = useCart();
    const cartItem = items.find(({ item }) => item.id === product.id);

    const notFound = !loading && !product;
    const notReady = !product;

    useEffect(() => {
        if (!product) dispatch(loadById(id));
        dispatch(fetchPage());
    }, []);

    // set related items. Don't fetch data if item already loaded
    useEffect(() => {
        if (!Array.isArray(product?.relatedItems) || (Array.isArray(product?.relatedItems) && !product.relatedItems.length)) {
            setRelatedItems([]);
            return;
        }
        if (!products.length) return;

        for (const relatedItemId of product.relatedItems) {
            const isIncluded = relatedItems.some(ri => ri.id === relatedItemId);
            if (isIncluded) continue;

            const itemFound = products.find(p => p.id === relatedItemId);
            if (!itemFound) dispatch(loadById(id));
            else setRelatedItems(relatedItems => [...relatedItems, itemFound]);
        }
    }, [product, products]);

    if (notFound) return <>Oops... Produits introuvables</>;
    if (notReady) return null;

    return (
        <div className={style.container}>
            <div className={style.content}>
                <div className={style.images}>
                    <img className={style.main} src={showcaseImgSrc || product.imageURLs[0]} alt={product.name} />
                    {product.imageURLs.length > 1 && (
                        <div className={style.rest}>
                            {product.imageURLs.slice(1).map(src => (
                                <img src={src} key={src} />
                            ))}
                        </div>
                    )}
                </div>
                <div className={style.info}>
                    <h2 className={style.name}>{product.name}</h2>
                    <p className={style.description}>{parseDraftToHtml(product.description)}</p>
                    {hasSkus && (
                        <div className={style.skus}>
                            <Skus
                                skus={product.skus}
                                imgProp={product.skuImgs.associatedProp}
                                onSkuSelected={sku => {
                                    setSelectedSku(sku);
                                }}
                                onImgChange={src => {
                                    setshowcaseImgSrc(src);
                                }}
                            />
                        </div>
                    )}
                    <div className={style.quantity}>
                        <QuantityControls item={product} quantity={cartItem?.count ?? 0} />
                    </div>
                    <h1 className={style.price}>{getItemPrice(product)} $</h1>
                    {parseInt(selectedSku?.stock.value) <= 0 && (
                        <h2 className={style.out__of__stock}>
                            <em>Rupture d'inventaire</em>
                        </h2>
                    )}
                    <Button
                        disabled={(hasSkus && !selectedSku) || parseInt(selectedSku?.stock.value) <= 0}
                        className={style.add__to__cart}
                        onClick={() => changeQuantity({ ...product, selectedSku: selectedSku?.sku.value }, 1)}
                    >
                        Ajouter au panier
                    </Button>
                    {product.secondaryDescription && (
                        <div className={style.secondary__description}>
                            <p>{parseDraftToHtml(product.secondaryDescription)}</p>
                        </div>
                    )}
                    {product.videoUrl && (
                        <div className={style.video__preview}>
                            <Embed url={product.videoUrl} />
                        </div>
                    )}
                </div>
            </div>
            {relatedItems.length > 0 && (
                <div className={style.similar__products}>
                    <h1>Produits Similaires</h1>
                    <hr />
                    <div className={style.products}>
                        {relatedItems.map(ri => (
                            <ProductCard key={nanoid()} value={ri} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Product;
