import React from "react";
import { useHistory } from "react-router-dom";
// Redux
import { useDispatch } from "react-redux";
import { addProduct } from "../../../store/reducers/checkout";
// DB
import { getRequest } from "../../../actions/requests";
import { productsRoot } from "../../../actions/productsRoute";
import Image from "../../../assets/gk/gokrisp-features.jpg";
import ImageMobile from "../../../assets/gk/gokrisp-features-mobile.jpg";

function Features() {
    const history = useHistory();
    const dispatch = useDispatch();

    const SAMPLE_PACK = "t4AnYPTEMFSaHI9f6qrO";

    return (
        <div className="features">
          <img src={Image} className="features__pc" />
          <img src={ImageMobile} className="features__mobile"/>
          <button
            onClick={async () => {
              const payload = await getRequest(`${productsRoot}/${SAMPLE_PACK}`, {});
              dispatch(addProduct({ ...payload.data, selectedQuantity: 1, status: "À livrer" }));
              history.push(`/checkout`);
          }}
          >Essayer un “sample pack”  -  14,96$</button>
        </div>
    );
}

export default Features;
