import React from "react";

// Assets
import fire from "../../../assets/bs/fire.png";
import heart from "../../../assets/bs/heart.png";
import lightning from "../../../assets/bs/lightning.png";

const Subscription = () => {
    const Card = ({ top, frequence }) => {
        return (
            <div className="subscription__card">
                {top === "economique" && (
                    <>
                        <span className="subscription__card--top subscription__card--economique">
                            <img src={fire} alt="" />
                            Plus économique
                        </span>
                    </>
                )}
                {top === "populaire" && (
                    <>
                        <span className="subscription__card--top subscription__card--populaire">
                            <img src={heart} alt="" />
                            Plus populaire
                        </span>
                    </>
                )}
                {top === "decouverte" && (
                    <>
                        <span className="subscription__card--top subscription__card--decouverte">
                            <img src={lightning} alt="" />
                            Découverte
                        </span>
                    </>
                )}
                {frequence === "weekly" && (
                    <>
                        <h6 className="subscription__card--title">
                            Forfait
                            <br />
                            hebdomadaire
                        </h6>
                        <p className="subscription__card--text">Recevez votre boîte à chaque semaine</p>
                        <div className="subscription__card--prices">
                            <p className="new-price">12,00$</p> <p className="old-price">15,00$</p>
                        </div>
                    </>
                )}
                {frequence === "biweekly" && (
                    <>
                        <h6 className="subscription__card--title">
                            Forfait
                            <br />
                            bimensuel
                        </h6>
                        <p className="subscription__card--text">Recevez votre boîte à chaque 2 semaines</p>
                        <div className="subscription__card--prices">
                            <p className="new-price">13,00$</p> <p className="old-price">15,00$</p>
                        </div>
                    </>
                )}
                {frequence === "monthly" && (
                    <>
                        <h6 className="subscription__card--title">
                            Forfait
                            <br />
                            mensuel
                        </h6>
                        <p className="subscription__card--text">Recevez votre boîte à chaque mois</p>
                        <div className="subscription__card--prices">
                            <p className="new-price">14,00$</p> <p className="old-price">15,00$</p>
                        </div>
                    </>
                )}
                {frequence === "once" && (
                    <>
                        <h6 className="subscription__card--title">
                            Commande
                            <br />
                            unique
                        </h6>
                        <p className="subscription__card--text">Recevez votre boîte une seule fois</p>
                        <div className="subscription__card--prices">
                            <p className="new-price">15,00$</p>
                        </div>
                    </>
                )}

                <span className="subscription__card--per">Par repas</span>
            </div>
        );
    };

    return (
        <div className="subscription">
            <h3 className="subscription__title">Sélectionnez un abonnement</h3>
            <div className="subscription__content">
                <Card top="economique" frequence="weekly" />
                <Card top="populaire" frequence="biweekly" />
                <Card top="populaire" frequence="monthly" />
                <Card top="decouverte" frequence="once" />
            </div>
        </div>
    );
};

export default Subscription;
