import React from "react";

// React-router-dom
import { Link } from "react-router-dom";

// Assets
import jimmy from "../../../assets/bs/jimmy.png";

const Header = () => {
    return (
        <header className="header">
            <h1 className="header__title">
                Des repas goûteux,
                <br /> sains et équilibrés
            </h1>
            <div className="header__subtitle--concept">
                <h4>Concept développé par</h4>
                <div className="header__subtitle--jimmy">
                    <img src={jimmy} alt="Jimmy Sévigny" />
                    <h4>Jimmy Sévigny</h4>
                </div>
            </div>
            <Link to="/menus" className="button button__cta button__cta--orange">
                Voir les menus
            </Link>
        </header>
    );
};

export default Header;
