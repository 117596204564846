const serialize = value => {
    let serialized_value = value;
    if (value === null) return null;

    //  Convert functions into string
    if (value instanceof Function)
        serialized_value = {
            _type: "Function",
            _value: value.toString(),
        };

    //  Convert dates into serialized timestamps
    if (value instanceof Date)
        serialized_value = {
            _type: "Date",
            _value: value.getTime(),
        };

    //  Convert Moment objects into serialized timestamps
    //  You'll get back a Javascript date from the selector
    if (value && value._isAMomentObject)
        serialized_value = {
            _type: "Date",
            _value: value._d.getTime(),
        };

    return serialized_value;
};

// Deserialize a value
export const deserialize = value => {
    if (typeof value === "undefinded" || value === null || typeof value !== "object" || !value.hasOwnProperty("_type")) return value;

    switch (value._type) {
        case "Function":
            return new Function(value._value);
        case "Date":
            return new Date(value._value);
    }
};

// Apply the "apply" function to all nodes in a JSON structure
export const jsonWalk = (source, apply) => {
    if (typeof apply !== "function") throw new Error('"apply" must be a function');

    if (Array.isArray(source)) source.forEach(node => (node = jsonWalk(node, apply)));
    else if (source !== null && typeof source === "object")
        for (const key of Object.keys(source)) {
            let node = source[key];

            if (node !== null && (typeof node === "object" || Array.isArray(node))) node = jsonWalk(node, apply);
            else node = apply(node);

            source[key] = node;
        }
    else source = apply(source);

    return source;
};

const serializer = serializeActions => store => next => action => {
    if (serializeActions.includes(action.type)) {
        for (const key of Object.keys(action.payload)) {
            action.payload[key] = jsonWalk(action.payload[key], serialize);
        }
    }

    return next(action);
};

export default serializer;
