import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { updatePromocode } from "../store/reducers/orders";
import { getRequest } from "../actions/requests";

const useWithTriggerProduct = order => {
    const dispatch = useDispatch();
    const updateOrderWithPromocode = order => dispatch(updatePromocode(order.id, order));

    const prevOrder = useRef();
    useEffect(() => {
        if (!order) return;

        if (prevOrder.current) return;

        (async () => {
            try {
                for (const itemId of Array.from(new Set(order.items.map(i => i.id)))) {
                    const promo = (await getRequest(`promocodes/?triggerProduct=${itemId}`)).data[0];
                    if (promo) updateOrderWithPromocode({ ...order, promoCode: promo.promoCode });
                }
                prevOrder.current = order;
            } catch (e) {
                console.error(e);
            }
        })();
    }, [order, prevOrder.current]);
};

export default useWithTriggerProduct;
