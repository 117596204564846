import React from "react";

// React-router-dom
import { Link } from "react-router-dom";

const Banner = () => {

  const Item = ({color, section, title, link}) => {
    return (
      <div className={`item item__${color}`}>
        <p className={`item__section item__section--${color}`}>{section}</p>
				<h4 className="item__title">
					{title}
				</h4>
				<Link to={`/${link}`} className={`button button__cta button__cta--${color}`}>
					En savoir plus
				</Link>
      </div>
    )
  }

	return (
		<div className="banner">
			<div className="banner__container">
        <Item 
          color="orange"
          title="Des plats rigoureusement
          élaborés par notre nutritionniste"
          section="notre engagement"
          link="commitment"
        />
        <Item 
          color="green"
          title="Vous avez des questions? C'est par ici!"
          section="foire aux questions"
          link="faq"
        />
      </div>
		</div>
	);
};

export default Banner;
