import React from "react";
import Accordion from "@material-ui/core/Accordion";
import Grid from "@material-ui/core/Grid";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";

function Faq() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className="faq" id="faq">
      <h3 className="faq__title no-margin-top">La réponse à vos questions</h3>
      <div className="faq__container">
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  Pourquoi devrais-je me procurer le Mélange Coupe Faim 2.0?{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Car cela fait maintenant 3 ans que je travaille sur
                  l'élaboration de ce mélange. J'ai essayé plus de 300
                  combinaisons d'ingrédients afin d'arriver à celle-ci. Selon
                  moi et selon l'avis de milliers de personnes qui ont testé le
                  mélange, cette combinaison est celle qui a le plus grand
                  impact sur la diminution de l'appétit et l'augmentation de la
                  satiété.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item sm={12} md={6}>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  Pourquoi devrais-je prendre une formule qui inclut un
                  renouvellement automatique?{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Car la vie va tellement vite! Si vous n'avez pas le temps ou
                  que vous oubliez de passer votre commande, notre système s'en
                  chargera pour vous. En plus de sauver de l'argent, vous ne
                  serez plus jamais à court de Mélange Coupe Faim 2.0!
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item sm={12} md={6}>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>Comment utilise-t-on le MCF 2.0?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Le sachet du Mélange Coupe Faim 2.0 contient 225 grammes. La
                  portion suggérée est de 18 g ou si vous préférez 2 c. à soupe.
                  Vous pouvez prendre jusqu'à 2 portions du MCF 2.0 par jour, au
                  besoin. Il peut même être utilisé le soir puisqu'il ne
                  contient aucun stimulant. Selon vos fringales, vous pouvez
                  aussi le diviser en 4 portions de 1 c. à soupe à la fois. Nous
                  vous conseillons de cibler vos fringales et d’utiliser le
                  Mélange Coupe Faim 2.0 dans ces moments. Normalement l'effet
                  est quasi immédiat (15 minutes) car les fibres gonflent
                  rapidement dans l'estomac lorsqu'il y a suffisamment d'eau. Il
                  est donc fortement conseillé de boire de l’eau tout au long de
                  la journée lorsque vous consommez votre mélange pour une
                  meilleure efficacité avec les fibres (entre 1,5 et 2 litres
                  par jour) <br />
                  Le Mélange Coupe Faim 2.0 est faible en calories (35 cal. par
                  c.à.s.) il a donc peu d’impact sur votre apport calorique
                  quotidien. Cependant, il peut devenir un excellent allié pour
                  contrer les fringales. <br />
                  Il est important de noter que le Mélange Coupe Faim 2.0 n’est
                  pas un remplacement de repas. Il se prend en complément avec
                  autre chose. Il suffit de le mélanger à votre yogourt,
                  smoothie, gruau, sur une salade, libre à votre imagination !
                  On a aussi plusieurs bonnes{" "}
                  <a
                    href="https://www.jimmysevigny.com/blogue/alimentation/"
                    target="_blank"
                  >
                    recettes
                  </a>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item sm={12} md={6}>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>Combien de temps dure un sachet?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  La durée d’un sachet est variable. Une pleine portion est de 2
                  cuillères à soupe (30ml) mais plusieurs personnes nous disent
                  ressentir pleinement les effets en ne consommant que 15 ml par
                  jour. Un sac contient donc de 12 à 25 portions.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item sm={12} md={6}>
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  Je mange toujours mes émotions ! Est-ce que le Mélange va
                  régler ce comportement?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Non. L'action du Mélange Coupe Faim 2.0 est de jouer sur votre
                  appétit et non sur vos émotions.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item sm={12} md={6}>
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  De quelle façon sont livrés les produits?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>En colis standard par Postes Canada</Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item sm={12} md={6}>
            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  Renouvellement de l’abonnement obligatoire?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Aucunement. Vous pouvez annuler en tout temps.{" "}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item sm={12} md={6}>
            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  Comment modifier mes informations personnelles, renouvellement
                  etc?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Il suffit de vous connecter via votre profil afin de modifier
                  facilement toutes vos informations.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item sm={12} md={6}>
            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>Durée de vie du produit :</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  La durée de vie du produit est de 90 jours lorsque conservé à
                  température pièce (avant ouverture) et jusqu’à 6 mois une fois
                  ouvert lorsque conservé au réfrigérateur ou au congélateur.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item sm={12} md={6}>
            <Accordion
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  Est-ce qu’on peut prendre le MCF si on suit le régime Kéto?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Les gens qui adoptent ce style alimentaire peuvent consommer
                  le Mélange Coupe Faim 2.0
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item sm={12} md={6}>
            <Accordion
              expanded={expanded === "panel11"}
              onChange={handleChange("panel11")}
            >
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>Noix , arachides et gluten</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Le Mélange Coupe Faim 2.0 ne contient ni arachides, ni noix.
                  Cependant on ne peut garantir qu’il n’est été en contact avec
                  noix/arachides dans l’environnement dans lequel il a été
                  assemblé. Dans le cas d’allergies sévères, il est donc suggéré
                  de s’abstenir ! <br />
                  Le produit ne contient pas de gluten.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Faq;
