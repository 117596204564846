import React from "react";

// Custom Components
import Nav from "./shared/nav";
import Header from "./home/header";
import Advantages from "./shared/advantages";
import OrderHome from "./home/orderHome";
import How from "./home/how";
import Testimonials from "./shared/testimonials";
import Engagement from "./shared/engagement";
import About from "./home/about";
import Newsletter from "./shared/newsletter";
import Footer from "./shared/footer";

function Home() {
    return (
        <div className="bs">
            <div className="home">
                <Nav />
                <Header />
                <Advantages />
                <OrderHome />
                <How />
                <Testimonials />
                <Engagement />
                <About />
                <Newsletter />
                <Footer />
            </div>
        </div>
    );
}

export default Home;
