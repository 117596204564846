import React from "react";

// Assets
import patate from "../../../assets/bs/epatante-patate.png";

const Partners = () => {
    return (
        <div className="partners">
            <h3 className="partners__title">Nos partenaires</h3>
            <div className="partners__content">
                <img src={patate} alt="" />
                <img src={patate} alt="" />
                <img src={patate} alt="" />
                <img src={patate} alt="" />
            </div>
        </div>
    );
};

export default Partners;
