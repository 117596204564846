import React from "react";
import { useHistory } from "react-router-dom";
import StoreIcon from "@material-ui/icons/Store";

function ContinueShopping({ brand }) {
    const history = useHistory();
    return (
        <div className={`continue__shop ${brand} clickable__tag`} onClick={() => history.push("./#produits")}>
            <div className="continue__shop__container">
                <StoreIcon />
                {/* <span className="continue__shop__text">continuer mes achats</span> */}
            </div>
        </div>
    );
}

export default ContinueShopping;
