import React from "react";
import Nutrition from "../../../assets/mcf/nutrition.jpg";

function Ingrediants() {
    return (
        <div className="ingred">
            <img src={Nutrition} className="ingred__img" alt="" />
            <div className="ingred__container">
                <h3 className="ingred__title">1 mélange de 7 ingrédients</h3>
                <span className="ingred__text">
                    Le Mélange Coupe Faim 2.0 n'est pas un supplément mais bel <br />
                    et bien une combinaison de 7 ingrédients qui en fait un aliment gourmet!
                </span>
                <div className="ingred__ingred">
                    <span>- Graines de Chia</span>
                    <span>- Poudre de graines de Chia</span>
                    <span>- Poudre de Psyllium</span>
                    <span>- Enveloppes de Psyllium</span>
                    <span>- Protéines de graines de citrouille</span>
                    <span>- Graines de Lin</span>
                    <span>- Graines de Lin moulues</span>
                </div>
            </div>
        </div>
    );
}

export default Ingrediants;
