import React from "react";
import { Grid } from "@material-ui/core";

import Left from "../../../assets/gk/gokrisp-newsletter.jpg";

function Newsletter() {
    return (
        <div className="newsletter">
            <Grid container>
              <Grid item sm={12} md={6}>
                    <img src={Left} className="newsletter__left" alt="" />
                </Grid>
                <Grid item sm={12} md={6}>
                    <div className="newsletter__container">
                        <span>
                            <span className="newsletter__1">Infolettre</span>
                            <span className="newsletter__2">Restez à l’affût des promotions Go Krisp!</span>
                            <span className="newsletter__3">Inscrivez-vous à notre liste d’envoi et profitez de nos nombreuses promotions.</span>
                            <div class="klaviyo-form-WZgBwQ"></div>
                        </span>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Newsletter;