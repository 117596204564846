import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import Grid from '@material-ui/core/Grid';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import Bottle from '../../../assets/gm/bottle.jpg';

function Faq() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div className="faq" id="faq">
            <h3 className="faq__title">La réponse à vos questions</h3>
            <div className="faq__container">
                <Grid container>
                    <Grid item sm={12} md={9}>
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={6}>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography>Comment utilise-t-on Go Matcha! ?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>Pour le Go Matcha! aromatisé, vous n’avez qu’à prendre le contenu d’un sachet et le verser dans une bouteille pouvant contenir de 300 à 600 ml d’eau, agitez et savourez! <br/>Pour le Go Matcha! original, le sachet est de 100 grammes. Une portion régulière est 1 c à thé. Cette portion peut être diluée dans 250 à 1000 ml d’eau. Vous pouvez prendre jusqu'à 3 portions de thé par jour. Nous suggérons de le prendre au début de votre journée pour en retirer tous les bienfaits, et comme il contient de la théine il est à éviter 4 heures avant d’aller se coucher.  Si vous avez besoin d’idées, essayer l’une de nos délicieuses recettes.</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item sm={12} md={6}>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography>Combien de portion se retrouvent dans un de vos produit?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Pour le Go Matcha! aromatisé, vous avez 18 sachets d’une portion individuelle tandis que pour le Go Matcha! original, vous avez 20 portions par sachet</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item sm={12} md={6}>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography>De quelle façon sont livrés les produits?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>En colis standard par Postes Canada</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item sm={12} md={6}>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography>Renouvellement de l’abonnement obligatoire?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Aucunement. Vous pouvez annuler en tout temps après le 1er renouvellement.  </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item sm={12} md={6}>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography>Comment modifier mes informations personnelles, renouvellement etc?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Il suffit de vous connecter via votre profil afin de modifier facilement toutes vos informations. </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item sm={12} md={6}>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography>Quelle est la durée de conservation de votre thé? </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Pour le Go Matcha Original, si conservé au frais, jusqu’à 12 mois. Pour le Go Matcha aromatisé, la date d’expiration affiché est de 24 mois après sa création.</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item sm={12} md={6}>
                            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography>Est-ce qu’on peut prendre le thé vert si on suit le régime Kéto?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Les gens qui ont ce style de vie peuvent consommer Go Match </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item sm={12} md={6}>
                            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography>Est-ce que les enfants peuvent consommer le thé matcha?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Ce produit contient de la théine qui est très similaire à la caféine donc il n’est pas recommandé d’en servir aux enfants. </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item sm={12} md={6}>
                            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography>Quelle est la provenance du thé matcha et quel est son grade?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Notre matcha provient du Japon et il est biologique. Il est ensaché en périphérie de Montréal. Son grade de qualité se situe entre premium et cérémonie et nous sommes vraiment fier de pouvoir affirmer que pour cette qualité de matcha, nous sommes les moins dispendieux au Canada. </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item sm={12} md={6}>
                            <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography>Noix et arachides dans le produit</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Le Go Matcha! aromatisé est certifié sans arachides. Pour le Go Matcha! original, bien que l’usine qui l’ensache ne traite aucun produit à base d’arachides, nous ne pouvons le garantir à 100% car cette dernière n’a pas de certification à ce sujet.</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item sm={12} md={6}>
                            <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography>Est-ce que le produit contient du gluten?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Le thé matcha ne contient pas de gluten.</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item sm={12} md={6}>
                            <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography>Est-ce qu’on peut préparer le thé matcha à l’avance?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Aucun problème. Il faut seulement s'assurer de bien mélanger avant de boire car le thé Matcha fait des dépôts.</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                           
                        </Grid>
                    </Grid> 
                    <Grid item sm={12} md={3} style={{ textAlign: 'center' }} >
                        <img src={Bottle} />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Faq;
