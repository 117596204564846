import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// draft js
import draftToHtml from "draftjs-to-html";
import parse from "html-react-parser";

// Mui
import Grid from "@material-ui/core/Grid";

// Redux
import useSlice from "../../../hooks/useSlice";

// System wide state from redux

// Custom Components
import { GetPortalHelmet } from "../../../utils/config";
import { getSplashScreenImage } from "../../../utils/config";
import SplashScreen from "../../shared/utils/splashScreen";

// Assets
import FeaturedBanner from "../../../assets/akt/ko30-banniere.jpg";

const Videos = () => {
    const hist = useHistory();

    const workoutSlice = useSlice({ sliceName: "workouts" });
    const workouts = workoutSlice.selectors.all;

    useEffect(() => {
        workoutSlice.actionCreators.fetchFn({ refresh: true });
    }, []);

    if (!workouts) return <SplashScreen image={getSplashScreenImage()} />;

    return (
        <>
            <GetPortalHelmet title="Vidéos" />
            <div
                className="workouts__banner clickable__tag"
                onClick={() => {
                    hist.push(`/portail/video/adkNeWUv5vO7sqFUAFVI`);
                }}
            >
                <img src={FeaturedBanner} alt="workouts banner" />
            </div>
            <h1 className="workouts__header">Programmes</h1>
            <Grid container>
                {workouts
                    .filter(w => {
                        const release = new Date(w.release._value);
                        return Date.now() - release > 0;
                    })
                    .map((w, i) => {
                        return (
                            <Grid xs={12} sm={6} md={4} className="workouts__thumbnail" item key={i}>
                                <img
                                    onClick={() => {
                                        hist.push(`/portail/video/${w.id}`);
                                    }}
                                    src={w.profileImage}
                                    alt="workout"
                                />
                                <h1 className="workouts__title">{w.title}</h1>
                                <p className="workouts__description">{w.shortDescription}</p>
                            </Grid>
                        );
                    })}
            </Grid>
        </>
    );
};

export default Videos;
