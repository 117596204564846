import React from "react";

// Components
import Product from "../shared/product";
import ProductCta from "../shared/productCta";

// Assets
import decouverte from "../../../assets/bs/menus/boite-decouverte.png";
import lasagne from "../../../assets/bs/menus/lasagne.png";
import jerk from "../../../assets/bs/menus/poulet-jerk.png";
import asiatique from "../../../assets/bs/menus/boite-asiatique.png";
import curry from "../../../assets/bs/menus/curry-vegetarien.png";
import bourguignon from "../../../assets/bs/menus/boeuf-bourguignon.png";
import chili from "../../../assets/bs/menus/chili-vegetarien.png";
import barre from "../../../assets/bs/menus/barre.png";
import boule from "../../../assets/bs/menus/boule.png";
import chips from "../../../assets/bs/menus/chips.png";
import matchaframboise from "../../../assets/bs/menus/matcha-framboise.png";
import matchapeche from "../../../assets/bs/menus/matcha-peche.png";
import matchacanneberge from "../../../assets/bs/menus/matcha-canneberge.png";

const Products = () => {
	return (
		<div className="products">
			<div className="products__container">
				<Product
					image={decouverte}
					title="Boîte Végétarienne"
					subtitle="Sans lactose, Gourmand"
					type="decouverte"
					/>
				<Product
					image={decouverte}
					title="Boîte BBQ"
					subtitle="Sans lactose, Gourmand"
					type="decouverte"
					/>
				<Product
					image={decouverte}
					title="Boîte Découverte"
					subtitle="Sans lactose, Gourmand"
					type="decouverte"
					/>
				<Product
					image={jerk}
					title="Poulet Jerk"
					subtitle="Sans lactose, Gourmand"
					/>
				<Product
					image={lasagne}
					title="Lasagne"
					subtitle="Sans lactose, Gourmand"
					/>
				<Product
					image={decouverte}
					title="Boîte Mexicaine"
					subtitle="Sans lactose, Gourmand"
					type="decouverte"
					/>
				<Product
					image={decouverte}
					title="Boîte Italienne"
					subtitle="Sans lactose, Gourmand"
					type="decouverte"
				/>
				<ProductCta decouverte />
				<Product
					image={asiatique}
					title="Boîte Asiatique"
					subtitle="Sans lactose, Gourmand"
				/>
				<Product
					image={curry}
					title="Curry Végétarien"
					subtitle="Sans lactose, Gourmand"
				/>
				<Product
					image={bourguignon}
					title="Boeuf Bourguignon"
					subtitle="Sans lactose, Gourmand"
				/>
				<ProductCta decouverte={false} />
				<Product
					image={chili}
					title="Chili Végétarien"
					subtitle="Sans lactose, Gourmand"
				/>
				<Product
					image={barre}
					title="Barre d'énergie"
					subtitle="Sans lactose, Gourmand"
				/>
				<Product
					image={boule}
					title="Boule d'énergie"
					subtitle="Sans lactose, Gourmand"
				/>
				<Product
					image={matchapeche}
					title="Go Matcha - Pêche"
					subtitle="Sans lactose, Gourmand"
					type="snacks"
				/>
				<Product
					image={matchaframboise}
					title="Go Matcha - Framboise"
					subtitle="Sans lactose, Gourmand"
					type="snacks"
				/>
				<Product
					image={chips}
					title="Chips santé"
					subtitle="Sans lactose, Gourmand"
				/>
				<Product
					image={matchacanneberge}
					title="Go Matcha - Canneberge"
					subtitle="Sans lactose, Gourmand"
					type="snacks"
				/>
			</div>
		</div>
	);
};

export default Products;
