import React, { useEffect, useState } from "react";

// React-router-dom
import { Link, NavLink } from "react-router-dom";

// Components
import Promo from "./promo";

// React-icons
import { BsBoxSeam } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";

// Assets
import logo from "../../../assets/bs/logo.png";
import menu from "../../../assets/bs/menu.png";
import close from "../../../assets/bs/menu-close.png";
import linkedin from "../../../assets/bs/linkedin.png";
import facebook from "../../../assets/bs/facebook.png";
import instagram from "../../../assets/bs/instagram.png";
import youtube from "../../../assets/bs/youtube.png";

const Nav = () => {
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    let activeStyle = {
        textDecoration: "underline",
    };

    let activeClassName = "underline";

    const HamburgerMenu = () => {
        return (
            <div className={open ? "nav__left--links open" : "nav__left--links"}>
                <Link to="/menus">
                    <span className="link">Menus</span>
                </Link>
                <Link to="/how">
                    <span className="link">Comment ça marche ?</span>
                </Link>
                <Link to="/commitment">
                    <span className="link">Notre engagement</span>
                </Link>
                <Link to="/about">
                    <span className="link">À propos</span>
                </Link>
                <Link to="/faq">
                    <span className="link">FAQ</span>
                </Link>
                <div className="nav__right--buttons">
                    <Link to="/order" className="button button__cta button__cta--orange">Débuter ma commande</Link>
                    <div className="nav__buttons--list">
                        <button className="button button__regular">
                            <AiOutlineUser /> Profil
                        </button>
                        <button className="button button__regular">
                            <BsBoxSeam /> Panier
                        </button>
                    </div>
                </div>
                <div className="nav__bar"></div>
                <div className="nav__buttons--list">
                    <button className="button button__regular button__regular--socials">
                        <img src={linkedin} alt="" />
                    </button>
                    <button className="button button__regular button__regular--socials">
                        <img src={facebook} alt="" />
                    </button>
                    <button className="button button__regular button__regular--socials">
                        <img src={instagram} alt="" />
                    </button>
                    <button className="button button__regular button__regular--socials">
                        <img src={youtube} alt="" />
                    </button>
                </div>
                <span className="copyright">
                    © 2022 <b>Boîte Santé</b>
                </span>
            </div>
        );
    };

    const handleSize = () => {
        setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleSize);

    const handleSetOpen = () => {
        setOpen(!open);
    };

    return (
        <>
            {screenSize && screenSize > 1280 ? (
                <nav className="nav">
                    <div className="nav__container">
                        <div className="nav__container--content">
                            <div className="nav__left">
                                <Link to="/">
                                    <img className="nav__left--logo" src={logo} alt="Boîte Santé Logo" />
                                </Link>
                                <div className="nav__left--links">
                                    <NavLink to="/menus">
                                        <span className="link">Menus</span>
                                    </NavLink>
                                    <NavLink to="/how">
                                        <span className="link">Comment ça marche?</span>
                                    </NavLink>
                                    <NavLink to="/commitment">
                                        <span className="link">Notre engagement</span>
                                    </NavLink>
                                    <NavLink to="/about">
                                        <span className="link">À propos</span>
                                    </NavLink>
                                    <NavLink to="/faq">
                                        <span className="link">FAQ</span>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="nav__right">
                                <AiOutlineUser />
                                <BsBoxSeam />
                                <Link to="/order" className="button button__cta button__cta--orange">Débuter ma commande</Link>
                            </div>
                        </div>
                        <div className="footer__bar"></div>
                    </div>
                    <Promo />
                </nav>
            ) : (
                <nav className="nav">
                    <div className="nav__container">
                        <div className="nav__container--content">
                            <div className="nav__left">
                                <Link to="/">
                                    <img className="nav__left--logo" src={logo} alt="Boîte Santé Logo" />
                                </Link>
                            </div>
                            <div className="nav__right">
                                <AiOutlineUser />
                                <BsBoxSeam />
                                {!open ? (
                                    <img
                                        src={menu}
                                        alt="hamburger menu"
                                        onClick={() => {
                                            handleSetOpen();
                                        }}
                                    />
                                ) : (
                                    <img
                                        src={close}
                                        alt="hamburger menu"
                                        onClick={() => {
                                            handleSetOpen();
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <Promo />
                    <HamburgerMenu />
                </nav>
            )}
        </>
    );
};

export default Nav;
