import React from "react";
import Image from "../../../assets/mcf/clients-video.png";

function Clients() {
  return (
    <div className="clients">
      <h3 className="clients__title1 no-margin-top">
        Vendu à plus de 100 000 exemplaires
      </h3>
      <h3 className="clients__title2">Voici leurs témoignages...</h3>
      <div className="clients__container">
        {/* <div className="clients__bubble">
                    <div className="clients__top">
                        <p>Super produit, tellement efficace :) j'adore le prendre en collation d'après-midi, ça enlève mes rages de bouffe le soir</p>
                    </div>
                    <div className="clients__bottom">
                        <span>- Mélanie -</span>
                    </div>
                </div>

                <div className="clients__bubble clients__middle">
                    <div className="clients__top">
                        <p>Un gros MERCI Jimmy pour ton mélange coupe faim WOW super efficace et m'aide à me rendre au dîner sans avoir trop faim. En tout cas je fait attention depuis novembre 2018 et j'ai 18 livres de perdu alors on continue</p>
                    </div>
                    <div className="clients__bottom">
                        <span>- Nathalie -</span>
                    </div>
                </div> */}

        <div>
          <iframe
            width="733"
            height="400"
            src="https://cdn.jwplayer.com/players/VujaVUhx-hDyhcbyt.html"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Clients;
