import React from "react";

// Assets
import box from "../../../assets/bs/menus/boite-decouverte.png";
import eye from "../../../assets/bs/eye.png";

const productOrder = ({ create, price, image }) => {
    return create ? (
        <div className="productOrder create">
            <img src={image} alt="" className="productOrder__background" />
            <div className="productOrder__eye">
                <img src={eye} alt="" className="watch" />
            </div>
            {price && (
                <div className="productOrder__price">
                    <p className="price">{price}$</p>
                </div>
            )}

            <div className="productOrder__bottom--container">
                <div className="productOrder__bottom">
                    <p className="productOrder__bottom--less">-</p>
                    <p className="productOrder__bottom--number">0</p>
                    <p className="productOrder__bottom--more">+</p>
                </div>
            </div>
            <div className="productOrder__container">
                <h5 className="productOrder__title">
                    Boîte pas italienne
                    <span className="productOrder__desc">Sans lactose, Gourmand</span>
                </h5>
            </div>
        </div>
    ) : (
        <div className="productOrder try">
            <div className="productOrder__bottom--container">
                <div className="productOrder__bottom">
                    <p className="productOrder__bottom--less">-</p>
                    <p className="productOrder__bottom--number">0</p>
                    <p className="productOrder__bottom--more">+</p>
                </div>
            </div>
            <div className="productOrder__container">
                <img src={box} alt="" className="productOrder__image" />
                <h5 className="productOrder__title">Boîte italienne</h5>
            </div>
        </div>
    );
};

export default productOrder;
