import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
// Material UI Core
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
// Custom Components
import Info from "./tabs/info";
import Orders from "./tabs/orders";
import TrialOrders from "./tabs/trialOrders";
import Subscriptions from "./tabs/subscriptions";
import { GetPortalHelmet } from "../../../utils/config";
// Custom Components
import { UserContext } from "../../../reducer/userContext";
import { getRequest } from "../../../actions/requests";
// Redux
import { useDispatch } from "react-redux";
import { updateCurrentUser } from "../../../store/reducers/users";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        "aria-controls": `nav-tabpanel-${index}`,
    };
}
function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={event => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const Profile = () => {
    // Redux
    const dispatch = useDispatch();

    const { user } = useContext(UserContext);
    const [value, setValue] = useState(0);

    useEffect(() => {
        const fetchUser = async () => {
            // Update user
            const userPayload = await getRequest(`users/${user.id}`);

            if (user.hasVideoAccess !== userPayload.data.hasVideoAccess) 
                dispatch(updateCurrentUser(userPayload.data));
            
        };

        fetchUser();
    }, []);

    return (
        <div className="portal__container">
            <GetPortalHelmet title="Profil" />
            <AppBar position="static">
                <div className="tab__background__color">
                    <Tabs variant="fullWidth" value={value} onChange={(e, newValue) => setValue(newValue)}>
                        <LinkTab label="Profil" href="/profile" {...a11yProps(0)} />
                        <LinkTab label="Commandes" href="/orders" {...a11yProps(1)} />
                        <LinkTab label="Essais" href="/trialOrders" {...a11yProps(2)} />
                        <LinkTab label="Abonnements" href="/subscriptions" {...a11yProps(3)} />
                        {/* <LinkTab label="Progression" href="/progression" {...a11yProps(4)} /> */}
                    </Tabs>
                </div>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Info currentUser={user} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Orders displayedUser={user} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <TrialOrders displayedUser={user} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Subscriptions displayedUser={user} />
            </TabPanel>
            {/* <TabPanel value={value} index={3}>
                <Progression displayedUser={displayedUser}/>
            </TabPanel> */}
        </div>
    );
};

export default Profile;
