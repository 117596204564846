import React from "react";
import propTypes from "prop-types";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
// Material UI Core
import { Table as MuiTable } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";

/**
 * Renders Custom Table with default style
 * @param {Object} columns
 * @param {Object} sortColumn
 * @param {Array<Object>} data
 * @param {Number} rowsPerPage
 * @param {Number} selectedPage
 * @param {Number} totalCount
 * @param {Boolean} loading
 * @param {Boolean} paginated
 * @param {Boolean} sortable
 * @param {Boolean} rowClickable
 * @param {Function} onSort
 * @param {Function} onPageClick
 * @param {Function} onRowClick
 */
const Table = ({
    columns,
    sortColumn,
    data,
    rowsPerPage,
    selectedPage,
    totalCount,
    loading,
    paginated = true,
    sortable = true,
    rowClickable = true,
    onSort,
    onPageChange,
    onRowClick,
}) => {
    return (
        <TableContainer className="table__container">
            <MuiTable>
                <TableHeader
                    columns={columns}
                    sortColumn={sortColumn}
                    rowsPerPage={rowsPerPage}
                    selectedPage={selectedPage}
                    totalCount={totalCount}
                    paginated={paginated}
                    sortable={sortable}
                    onSort={onSort}
                    onPageChange={onPageChange}
                />
                <TableBody
                    data={data}
                    columns={columns}
                    rowsPerPage={rowsPerPage}
                    selectedPage={selectedPage}
                    loading={loading}
                    paginated={paginated}
                    rowClickable={rowClickable}
                    onRowClick={onRowClick}
                />
            </MuiTable>
        </TableContainer>
    );
};

Table.propTypes = {
    columns: propTypes.array.isRequired,
    sortColumn: propTypes.object.isRequired,
    data: propTypes.array.isRequired,
    rowsPerPage: propTypes.number.isRequired,
    selectedPage: propTypes.number.isRequired,
    totalCount: propTypes.number.isRequired,
    loading: propTypes.bool.isRequired,
    paginated: propTypes.bool,
    sortable: propTypes.bool,
    rowClickable: propTypes.bool,
    onSort: propTypes.func.isRequired,
    onPageChange: propTypes.func.isRequired,
    onRowClick: propTypes.func.isRequired,
};

export default Table;
