import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "../apiCalls";
import { settings } from "../../settings";

// each action in reducers object has a state and action params
const slice = createSlice({
    name: "recipes",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        categories: {
            list: [],
            lastFetch: null,
        },
    },
    reducers: {
        received: (recipes, action) => {
            recipes.list = action.payload;
            recipes.loading = false;
            recipes.lastFetch = Date.now();
        },
        requested: (recipes, action) => {
            recipes.loading = true;
        },
        requestedFailed: (recipes, action) => {
            recipes.loading = false;
        },
        removed: (recipes, action) => {
            const newList = recipes.list.filter(recipes => recipes.id !== action.payload.id);
            recipes.list = newList;
        },
        added: (recipes, action) => {
            recipes.list.push(action.payload.recipe);
        },
        updated: (recipes, action) => {
            const { id, updatedFields } = action.payload;

            const i = recipes.list.findIndex(r => r.id === id);
            const tempRecipes = {
                ...recipes.list[i],
                ...updatedFields,
            };
            recipes.list[i] = tempRecipes;
        },
        categoriesReceived: (recipes, action) => {
            recipes.categories.list = action.payload;
            recipes.loading = false;
            recipes.categories.lastFetch = Date.now();
        },
        categoriesRequested: (recipes, action) => {
            recipes.loading = true;
        },
        categoryAdded: (recipes, action) => {
            recipes.categories.list.push(action.payload.newCategory);
        },
        categoryRemoved: (recipes, action) => {
            const i = recipes.categories.list.findIndex(category => category === action.payload.categoryName);
            if (i !== -1) recipes.categories.list.splice(i, 1);
        },
    },
});

const {
    added,
    updated,
    requested,
    received,
    requestedFailed,
    removed,
    categoriesReceived,
    categoriesRequested,
    categoriesRequestedFailed,
    categoryAdded,
    categoryRemoved,
} = slice.actions;
export default slice.reducer;

// Action creators
export const loadRecipes = () => (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: `${settings.urls.recipes}/all`,
            onStart: requested.type,
            onSuccess: received.type,
            onError: requestedFailed.type,
        })
    );
};

export const loadRecipesByBrand = brand => (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: `${settings.urls.recipes}/getByBrand/${brand}`,
            onStart: requested.type,
            onSuccess: received.type,
            onError: requestedFailed.type,
        })
    );
};

export const loadRecipeById = id => (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: `${settings.urls.recipes}/${id}`,
            onStart: requested.type,
            onSuccess: received.type,
            onError: requestedFailed.type,
        })
    );
};

export const loadRecipeCategories = () => (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: `${settings.urls.recipes}/categories/all`,
            onStart: categoriesRequested.type,
            onSuccess: categoriesReceived.type,
            onError: requestedFailed.type,
        })
    );
};

export const addRecipe = recipe =>
    apiCallBegan({
        url: `${settings.urls.recipes}/create`,
        method: "post",
        data: recipe,
        onSuccess: added.type,
        onError: requestedFailed.type,
    });

export const updateRecipe = data =>
    apiCallBegan({
        url: `${settings.urls.recipes}/update`,
        method: "post",
        data,
        onSuccess: updated.type,
        onError: requestedFailed.type,
    });

export const deleteRecipe = recipeId =>
    apiCallBegan({
        url: `${settings.urls.recipes}/delete`,
        method: "post",
        data: { id: recipeId },
        onSuccess: removed.type,
        onError: requestedFailed.type,
    });

export const addCategory = category =>
    apiCallBegan({
        url: `${settings.urls.recipes}/categories/create`,
        method: "post",
        data: { newCategory: category },
        onSuccess: categoryAdded.type,
        onError: requestedFailed.type,
    });

export const removeCategory = categoryName =>
    apiCallBegan({
        url: `${settings.urls.recipes}/categories/delete`,
        method: "post",
        data: { categoryName },
        onSuccess: categoryRemoved.type,
        onError: requestedFailed.type,
    });

// Selectors
export const getAllRecipes = createSelector(
    state => state.recipes.list,
    list => list
);

export const getAllCategories = createSelector(
    state => state.recipes.categories.list,
    list => list
);

export const getRecipeById = id =>
    createSelector(
        state => (state.recipes.list.length > 0 ? state.recipes.list.find(recipe => recipe.id === id) : undefined),
        recipe => recipe
    );

export const getLoading = createSelector(
    state => state.recipes.loading,
    loading => loading
);
