import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

// Material UI Core
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

// Icons
import { MdLogout } from "react-icons/md";

// Custom Components
import { logout } from "../../../../utils/auth";

const BottomNav = () => {
    const history = useHistory();

    const handleLogoutSuccess = () => {
        return history.push("/");
    };

    return (
        <List className="nav__link__container">
            <div className="nav__link__item">
                <ListItem button className="nav__listItem" onClick={() => logout(handleLogoutSuccess())}>
                    <ListItemIcon>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <MdLogout />
                            <span style={{ color: "black", fontSize: "0.8rem" }}>Déconnexion</span>
                        </div>
                    </ListItemIcon>
                </ListItem>
            </div>
        </List>
    );
};

export default BottomNav;
