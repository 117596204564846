import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import draftToHtml from "draftjs-to-html";
import { useParams } from "react-router-dom";
import placeholderImage from "../../assets/shared/placeholder.jpg";

// Utils
import { settings } from "../../settings";

// Custom Components
import { GetHelmet, GetNavbar } from "../../utils/config";
import { getLandingPageInfo } from "../../utils/config";

// DB
import { getRequest } from "../../actions/requests";
import { rootRecipesRoute } from "../../actions/recipesRoute";

// Asset
import preparation from "../../assets/portal/preparation.svg";
import cuisson from "../../assets/portal/cuisson.svg";
import portion from "../../assets/portal/portion.svg";

/**
 * Displays the recipe in a bigger and cleaner format
 * @returns JSX component
 */
const Recipe = () => {
    const { id } = useParams();
    const { brand } = getLandingPageInfo();

    const [recipe, setRecipe] = useState(null);
    const [ingredients, setIngredients] = useState([]);
    const [imageURLs, setImageURLs] = useState([]);
    const [descriptionMarkup, setDescriptionMarkup] = useState([]);
    const [steps, setSteps] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    const MobileNutritionFact = ({ title, value, adornment, icon }) => {
        return (
            <Grid item xs={4}>
                {icon && (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: 20, opacity: 1 }}>
                        <img src={icon} style={{ color: "#035164", height: 24, width: 24 }} alt="" />
                    </div>
                )}

                <div style={{ textAlign: "center", fontSize: 18 }}>{title}</div>
                <hr
                    style={{
                        height: 1,
                        borderWidth: 0,
                        borderRadius: 20,
                        color: "#035164",
                        // backgroundColor: "#035164",
                        width: 35,
                    }}
                    className={`recipe__nutrition__values ${brand}`}
                />
                <div style={{ textAlign: "center", fontWeight: "bold", paddingBottom: 40, fontSize: 18 }}>
                    {value} {adornment}
                </div>
            </Grid>
        );
    };

    const fetchRecipe = () => {
        getRequest(`${rootRecipesRoute}/${id}`)
            .then(recipePayload => {
                setRecipe(recipePayload.data);
            })
            .catch(err => console.error(err));
    };

    const parseDescription = () => {
        const description = JSON.parse(recipe.description);
        const descriptionBlocks = [];
        description.blocks.forEach(element => {
            if (element.type === "unordered-list-item" || element.type === "ordered-list-item") {
                element.type = "unstyled";
                setSteps(prev => [...prev, { blocks: [{ ...element }], entityMap: [] }]);
            } else {
                descriptionBlocks.push({ ...element });
            }
        });
        setDescriptionMarkup({ ...descriptionMarkup, blocks: descriptionBlocks, entityMap: [] });
    };

    const parseIngredients = () => {
        const rawIngredients = JSON.parse(recipe.ingredients);
        rawIngredients.blocks.forEach(element => {
            element.type = "unstyled";
            setIngredients(prev => [...prev, { blocks: [{ ...element }], entityMap: [] }]);
        });
    };

    useEffect(() => {
        fetchRecipe(id);
    }, []);

    useEffect(() => {
        if (recipe) {
            setImageURLs(recipe.imageURLs);
            parseIngredients();
            parseDescription();
        }
    }, [recipe]);

    return (
        <>
            <GetHelmet />
            <GetNavbar />

            {recipe && (
                <div className={`recipe ${brand}`}>
                    {/* <img className="recipe__image" src={imageURLs ? imageURLs[0] : placeholderImage} alt="image recette" /> */}
                    <div className="recipe__image" style={{ backgroundImage: `url(${imageURLs ? imageURLs[0] : placeholderImage})` }}>
                        {/* <h3 className="recipe__title">{recipe.title}</h3> */}
                    </div>
                    <Grid
                        container
                        className="recipe__info"
                        style={width >= settings.page.maxScreenWidth ? { paddingLeft: 100, paddingRight: 100 } : {}}
                    >
                        <Grid item xs={12} md={3} className="recipe__section__grid recipe__ingredients">
                            <div className="element__centered" style={width < settings.page.maxScreenWidth ? { paddingBottom: 40 } : {}}>
                                <div className="recipe__ingredients__container">
                                    <div
                                        className={`item recipe__ingredients__bar ${brand}`}
                                        style={{ paddingLeft: 40, paddingRight: 40, paddingBottom: 20, color: "white" }}
                                    >
                                        <h3 className={`recipe__section__title ${brand}`} style={{ color: "white" }}>
                                            Ingrédients
                                        </h3>

                                        <div>
                                            {ingredients &&
                                                ingredients.map((ingredient, index) => (
                                                    <div key={index}>
                                                        <FormControlLabel
                                                            control={<Checkbox color="primary" />}
                                                            label={parse(draftToHtml(ingredient))}
                                                        />
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={9} className="recipe__section__grid recipe__prep">
                            <div>
                                <div
                                    style={
                                        width >= settings.page.maxScreenWidth
                                            ? { fontSize: 40, fontWeight: "bold", paddingLeft: 40 }
                                            : { fontSize: 40, fontWeight: "bold" }
                                    }
                                >
                                    {recipe.title}
                                </div>

                                <div style={{ paddingTop: 40 }}>
                                    <div style={{ paddingTop: 40, marginLeft: 40 }} className={`recipe__colors ${brand}`}>
                                        <Grid item container>
                                            <MobileNutritionFact
                                                title="Préparation"
                                                value={recipe.prepTime}
                                                adornment={recipe.prepTime > 1 ? "mins" : "min"}
                                                icon={preparation}
                                            />
                                            <MobileNutritionFact
                                                title="Cuisson"
                                                value={recipe.cookingTime}
                                                adornment={recipe.cookingTime > 1 ? "mins" : "min"}
                                                icon={cuisson}
                                            />
                                            <MobileNutritionFact title="Portions" value={recipe.portion} adornment="" icon={portion} />
                                        </Grid>
                                    </div>
                                </div>

                                <div style={width >= settings.page.maxScreenWidth ? { paddingLeft: 40 } : {}}>
                                    {descriptionMarkup && descriptionMarkup.blocks && descriptionMarkup.blocks.length > 0 && (
                                        <h3 className={`recipe__section__title ${brand}`}>Description</h3>
                                    )}
                                    {descriptionMarkup && <div>{parse(draftToHtml(descriptionMarkup))}</div>}
                                    {steps && steps.length > 0 && <h3 className={`recipe__section__title ${brand}`}>Préparation</h3>}
                                    {steps &&
                                        steps.map((step, i) => {
                                            const counter = i + 1;
                                            const stepElement = parse(draftToHtml(step));
                                            return (
                                                <React.Fragment key={i}>
                                                    <div className="recipe__step">
                                                        <span className={`recipe__step__number ${brand}`}>{counter}</span>
                                                        <span className="recipe__step__text">{stepElement}</span>
                                                    </div>
                                                    <br />
                                                </React.Fragment>
                                            );
                                        })}
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    {/* Desktop footer */}
                    {width >= settings.page.maxScreenWidth && (
                        <div className={`recipe__quickinfo ${brand}`}>
                            {/* {recipe.prepTime > 0 && (
                                <span>
                                    <b>Prép.:</b> {recipe.prepTime} min{recipe.prepTime > 1 ? "s" : ""}
                                </span>
                            )}
                            {recipe.cookingTime > 0 && (
                                <span>
                                    <b>Cuisson:</b> {recipe.cookingTime} min{recipe.cookingTime > 1 ? "s" : ""}
                                </span>
                            )}
                            {recipe.portion && (
                                <span>
                                    <b>Portions:</b> {recipe.portion}
                                </span>
                            )} */}
                            {recipe.calories && (
                                <span>
                                    <b>Calories:</b> {recipe.calories}
                                </span>
                            )}
                            {recipe.proteins && (
                                <span>
                                    <b>Protéines:</b> {recipe.proteins} g
                                </span>
                            )}
                            {recipe.carbohydrates && (
                                <span>
                                    <b>Glucides:</b> {recipe.carbohydrates} g
                                </span>
                            )}
                            {recipe.totalFat && (
                                <span>
                                    <b>Gras Totaux:</b> {recipe.totalFat} g
                                </span>
                            )}
                            {recipe.saturedFat && (
                                <span>
                                    <b>Gras Saturés:</b> {recipe.saturedFat} g
                                </span>
                            )}
                            {recipe.transFat && (
                                <span>
                                    <b>Gras Trans:</b> {recipe.transFat} g
                                </span>
                            )}
                            {recipe.sugar && (
                                <span>
                                    <b>Sucre:</b> {recipe.sugar} g
                                </span>
                            )}
                            {recipe.fibers && (
                                <span>
                                    <b>Fibres:</b> {recipe.fibers} g
                                </span>
                            )}
                            {recipe.sodium && (
                                <span>
                                    <b>Sodium:</b> {recipe.sodium} mg
                                </span>
                            )}
                        </div>
                    )}

                    {/* Mobile footer */}
                    {width < settings.page.maxScreenWidth && (
                        <>
                            <h3 className={`recipe__section__title ${brand}`} style={{ textAlign: "center", marginTop: 40, paddingBottom: 20 }}>
                                Valeurs Nutritives
                            </h3>

                            <Grid item container>
                                <MobileNutritionFact title="Calories" value={recipe.calories} adornment="" />
                                <MobileNutritionFact title="Protéines" value={recipe.proteins} adornment="g" />
                                <MobileNutritionFact title="Glucides" value={recipe.carbohydrates} adornment="g" />
                                <MobileNutritionFact title="Gras Totaux" value={recipe.totalFat} adornment="g" />
                                <MobileNutritionFact title="Gras Saturés" value={recipe.saturedFat} adornment="g" />
                                <MobileNutritionFact title="Gras Trans" value={recipe.transFat} adornment="g" />
                                <MobileNutritionFact title="Sucre" value={recipe.sugar} adornment="g" />
                                <MobileNutritionFact title="Fibres" value={recipe.fibers} adornment="g" />
                                <MobileNutritionFact title="Sodium" value={recipe.sodium} adornment="mg" />
                            </Grid>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default Recipe;
