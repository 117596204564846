import React from "react";
import { useHistory } from "react-router-dom";

function Connect() {
    const history = useHistory();
    return (
        <div className="connect">
            <div className="connect__container">
                <h3 className="connect__title">CONNECTEZ-VOUS À VOTRE COMPTE!</h3>
                <button className="connect__cta" onClick={() => history.push("/portail")}>
                    CONNEXION
                </button>
            </div>
        </div>
    );
}

export default Connect;
