import React from "react";

// Components
import Nav from "./shared/nav";
import Header from "./how/header";
import Advantages from "./shared/advantages";
import Discover from "./shared/discover";
import OrderOnline from "./how/orderOnline";
import Customize from "./how/customize";
import Subscription from "./how/subscription";
import Banner from "./shared/banner";
import Newsletter from "./shared/newsletter";
import Footer from "./shared/footer";

const how = () => {
    return (
        <div className="bs">
            <div className="how">
                <Nav />
                <Header />
                <Advantages />
                <Discover />
                <OrderOnline />
                <Customize />
                <Subscription />
                <Banner />
                <Newsletter />
                <Footer />
            </div>
        </div>
    );
};

export default how;
