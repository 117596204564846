import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import Grid from '@material-ui/core/Grid';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import BG from '../../../assets/akt/faq-bg.png';

function Faq() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div className="faq">
            <div className="faq__container">
                <Grid container>

                    <Grid item xs={12} md={8}>
                        <h3 className="faq__title">La réponse à vos questions</h3>

                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Est-ce que tous les anciens programmes (DVD) de Jimmy se retrouvent sur la plateforme? </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Tous les coffrets DVD se retrouvent sur la plateforme</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Mis-à-part Jimmy, est-ce qu’il y a d’autres entraîneurs? </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Absolument! En plus de Jimmy, vous retrouverez Joanie, Coach Sarah, Julie, Sophie, Christian ainsi que plusieurs autres à venir.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>À quelle fréquence offrez-vous du nouveau contenu?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Nous garantissons au minimum 3 à 4 nouveaux programmes par année</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Ai-je besoin d’être en « super forme » pour m’entraîner avec vous?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Aucunement. Dans la plupart de nos programmes, nous offrons 3 niveaux de difficulté en simultané soit débutant, intermédiaire et avancé.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Ai-je besoin d’une connexion internet pour utiliser AKTIVATION? </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Oui. Toutefois, il est possible de télécharger vos entraînements dans votre application pour une durée déterminée. Cela vous permet de vous entraîner lorsque vous n’avez pas de connexion internet.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Avez-vous une application ?  </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Oui. Vous avez accès à notre application AKTIVATION via le APPLE STORE & GOOGLE PLAY. </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Avez-vous un groupe de soutien / motivation ? </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Oui! Joignez-vous à nous en cliquant <a href="https://www.facebook.com/groups/1476658622351162/">ICI</a></Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Si je désire modifier mon profil et/ou mon abonnement, puis-je le faire moi-même?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Oui. Pour le profil, aussitôt que vous êtes connecté à AKTIVATION, vous avez accès à toutes vos informations. Pour ce qui est de votre abonnement, vous avez la possibilité d’annuler le renouvellement automatique vous-même. Simplement en cliquant sur le X rouge au bout de votre abonnement. Si toutefois, vous avez besoin d’assistance supplémentaire, notre équipe pourra vous aider.  </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Quel matériel d'entraînement ai-je besoin?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Vous retrouverez certains programmes d'entraînement qui ne nécessitent aucun matériel, mais dans la majorité des cas, vous aurez besoin d'au minimum une paire d'haltères et un tapis d'entraînement. Tous les autres accessoires (par exemple : corde à sauter, élastique, step, etc.) sont optionnels.  </Typography>
                            </AccordionDetails>
                        </Accordion>

                    </Grid>

                    <Grid item md={4}>
                        <img src={BG} className="faq__bg" />
                    </Grid>


                </Grid>
            </div>
        </div>
    );
}

export default Faq;
