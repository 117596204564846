import { useDispatch, useSelector } from "react-redux";

// redux
import { getCheckoutDetails, getProducts, getSubscriptions, changeItemQuantity, addItem } from "../store/reducers/checkout";

// utils
import { orderItemsComparator } from "../utils/order";
import { alphabeticalSort, uniqWithCount } from "../utils/array";

export default function useCart() {
    const dispatch = useDispatch();

    const details = useSelector(getCheckoutDetails);
    const products = useSelector(getProducts);
    const subscriptions = useSelector(getSubscriptions);
    const itemCount = products.length + subscriptions.length;

    const changeQuantity = (item, quantity) => {
        dispatch(changeItemQuantity(item, quantity));
    };

    const addToCart = (item, quantity = 1, addTo = "products") => {
        dispatch(addItem({ item, quantity, addTo }));
    };

    return {
        details,
        items: uniqWithCount([...products, ...subscriptions], orderItemsComparator)
            .map(({ value, count }) => ({ item: value, count }))
            .sort(alphabeticalSort("item.name")),
        itemCount,
        addToCart,
        changeQuantity,
    };
}
