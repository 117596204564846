import React, { useState } from "react";

// Mui
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

// Assets
import bs from "../../../assets/bs/logo-icon.png";
import x from "../../../assets/bs/menu-close.png";
import box from "../../../assets/bs/how-box.png";
import forkknife from "../../../assets/bs/how-forkknife.png";
import palet from "../../../assets/bs/how-palet.png";
import squares from "../../../assets/bs/how-squares.png";

const HowOrder = () => {
    const [open, setOpen] = useState(true);

    const Step = ({ number, icon, iconAlt, title, text }) => {
        return (
            <div className="how__step">
                <div className="step__number button button__regular">{number}</div>
                <img src={icon} alt={iconAlt} />
                <h6 className="step__title">{title}</h6>
                <p className="step__text">{text}</p>
            </div>
        );
    };

    const handleOpen = () => {
        setOpen(!open);
    };

    return (
        <div className="howOrder">
            <Accordion className="questions__accordion">
                <AccordionSummary
                    className="howOrder__title"
                    expandIcon={
                        <div>
                            <img src={x} alt="" />
                        </div>
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <img src={bs} className="howOrder__title--logo" alt="" />
                    <p className="howOrder__title--text">Comment ça marche?</p>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="how__steps">
                        <Step
                            number={1}
                            icon={box}
                            iconAlt="boîte"
                            title="Choisissez un format de boîte"
                            text="In nunc ac convallis adipiscing ac eget."
                        />
                        <Step
                            number={2}
                            icon={forkknife}
                            iconAlt="un couteau et une fourchette"
                            title="Sélectionnez les plats"
                            text="Commodo fermentum velit eget quis condimentum."
                        />
                        <Step
                            number={3}
                            icon={palet}
                            iconAlt="une palette de couleur"
                            title="Ajoutez des à-côtés"
                            text="Imperdiet imperdiet nec diam ultricies nullam hac."
                        />
                        <Step
                            number={4}
                            icon={squares}
                            iconAlt="des carrés"
                            title="Choisissez un forfait"
                            text="Risus integer quis adipiscing consectetur ut."
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
            <div className="footer__bar"></div>
        </div>
    );
};

export default HowOrder;
