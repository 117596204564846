import React from "react";

// React-router-dom
import { Link } from "react-router-dom";

// Assets
import logo from "../../../assets/bs/logo-icon.png";
import box from "../../../assets/bs/how-box.png";
import forkknife from "../../../assets/bs/how-forkknife.png";
import palet from "../../../assets/bs/how-palet.png";
import squares from "../../../assets/bs/how-squares.png";

const How = () => {

	const Step = ({
		number,
		icon,
		iconAlt,
		title,
		text,
	}) => {
		return (
			<div className="how__step">
				<div className="step__number button button__regular">{number}</div>
				<img src={icon} alt={iconAlt} />
				<h6 className="step__title">{title}</h6>
				<p className="step__text">{text}</p>
			</div>
		);
	};

	return (
		<div className="how">
			<div className="how__container">
				<img src={logo} alt="Boîte Santé Logo" />
				<h2 className="how__title">Comment ça marche ?</h2>
				<div className="how__steps">
					<Step
						number={1}
						icon={box}
						iconAlt="boîte"
						title="Choisissez un format de boîte"
						text="In nunc ac convallis adipiscing ac eget."
					/>
					<Step
						number={2}
						icon={forkknife}
						iconAlt="un couteau et une fourchette"
						title="Sélectionnez les plats"
						text="Commodo fermentum velit eget quis condimentum."
					/>
					<Step
						number={3}
						icon={palet}
						iconAlt="une palette de couleur"
						title="Ajoutez des à-côtés"
						text="Imperdiet imperdiet nec diam ultricies nullam hac."
					/>
					<Step
						number={4}
						icon={squares}
						iconAlt="des carrés"
						title="Choisissez un forfait"
						text="Risus integer quis adipiscing consectetur ut."
					/>
				</div>
				<Link to="/how" className="button button__cta button__cta--orange">
					En savoir plus
				</Link>
			</div>
		</div>
	);
};

export default How;
