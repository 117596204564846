import React from "react";

// Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Assets
import box from "../../../assets/bs/testimonials-heartbox.png";

// React-icons
import { AiFillStar } from "react-icons/ai";

const Testimonials = () => {
    const settings = {
        dots: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: () => (
            <div className="ft-slick__dots--custom">
                <div className="current" />
            </div>
        ),
    };
    const addDecimal = number => {
        return (Math.round(number * 100) / 100).toFixed(1);
    };

    const Testimonial = ({ stars, text, name }) => {
        return (
            <div className="testimonial">
                <div className="testimonial__stars">
                    <div className="testimonial__stars--number">
                        <p>{stars}</p>
                    </div>
                    <div className="testimonial__stars--note">
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                    </div>
                </div>
                <p className="testimonial__text">{text}</p>
                <p className="testimonial__name">{name}</p>
            </div>
        );
    };

    return (
        <div className="testimonials">
            <div className="testimonials__container">
                <img src={box} alt="Une boîte avec un coeur" />
                <h2 className="testimonials__title">Ils ont testé et aimé leur boîte</h2>
                <div className="testimonials__carousel">
                    <Slider {...settings} arrows autoplay>
                        <Testimonial
                            stars={addDecimal(5)}
                            text="Les ingrédients sont toujours de qualité. Je fais des économies et je gagne du temps car je fais beaucoup moins souvent les courses. Le mieux, ce sont les fiches recette grâce auxquelles mon fils apprend à cuisiner. Le tout lors d'un super moment ensemble en cuisine !"
                            name="Alex E."
                        />
                        <Testimonial
                            stars={addDecimal(5)}
                            text="Les ingrédients sont toujours de qualité. Je fais des économies et je gagne du temps car je fais beaucoup moins souvent les courses. Le mieux, ce sont les fiches recette grâce auxquelles mon fils apprend à cuisiner. Le tout lors d'un super moment ensemble en cuisine !"
                            name="Keven E."
                        />
                        <Testimonial
                            stars={addDecimal(5)}
                            text="Les ingrédients sont toujours de qualité. Je fais des économies et je gagne du temps car je fais beaucoup moins souvent les courses. Le mieux, ce sont les fiches recette grâce auxquelles mon fils apprend à cuisiner. Le tout lors d'un super moment ensemble en cuisine !"
                            name="Ismael E."
                        />
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
