import React from "react";

// Assets
import lunch from "../../../assets/bs/customize-lunch.png";
import bol from "../../../assets/bs/customize-bol.png";
import dessert from "../../../assets/bs/customize-dessert.png";

const Customize = () => {
	return (
		<div className="customize">
			<div className="customize__container">
				<h3 className="customize__title">Personnalisez votre boîte</h3>
				<div className="customize__content">
					<div className="customize__content--item">
						<h6 className="title">12, 24, 32...</h6>
						<p className="text">
							Ajoutez autant de repas que vous le souhaitez
						</p>
						<img
							src={lunch}
							alt="Des bons plats prets à manger"
							className="image"
						/>
					</div>
					<div className="customize__content--item">
						<h6 className="title">Allergènes et commodités alimentaires</h6>
						<p className="text">
							Explorez notre grande variété de repas, pensée pour tous!
						</p>
						<img src={bol} alt="Un bol style asiatique" className="image" />
					</div>
					<div className="customize__content--item">
						<h6 className="title">Collations sucrées et salées? Oh oui!</h6>
						<p className="text">
							Agrémentez votre boîtes de collations et accessoires santé.
						</p>
						<img
							src={dessert}
							alt="Des bons plats prets à manger"
							className="image"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Customize;
