import axios from "axios";
import { getServer } from "../configs/backend";
import { auth } from "../configs/firebase";

const getHeaders = async () => {
    let headers = { "Content-Type": "application/json" };
    if (auth.currentUser) {
        headers.authorization = `Bearer ${await auth.currentUser.getIdToken(false)}`;
    }
    return headers;
};

export const getRequest = async (path, values) => {
    const headers = await getHeaders();
    return axios({ method: "GET", url: `${getServer()}/api/v1/${path}`, params: values, headers });
};

export const postRequest = async (path, values) => {
    const headers = await getHeaders();
    return axios.post(`${getServer()}/api/v1/${path}`, values, { headers });
};

export const putRequest = async (path, values) => {
    const headers = await getHeaders();
    return axios({ method: "PUT", url: `${getServer()}/api/v1/${path}`, data: values, headers });
};
