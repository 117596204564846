import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// Custom components
import Table from "../../../shared/utils/table";
// Utils
import { parseDate, getTimestampDifferenceInDay } from "../../../../utils/parseData";
import { settings } from "../../../../settings";
// Redux
import { useDispatch } from "react-redux";
import { snackbarEnqueuedAction } from "../../../../store/reducers/snackbars";
// DB
import { getRequest } from "../../../../actions/requests";
import { getByUserId, getAll } from "../../../../actions/trialOrderRoute";
import { stripeSubscriptionsRoot } from "../../../../actions/stripeRoute";
import { useSnackbar } from "notistack";

const TrialOrders = ({ displayedUser }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    // Table
    const [sortColumn, setSortColumn] = useState({ path: "date", order: "desc" });
    const [selectedPage, setSelectedPage] = useState(0);

    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);

    const sortedData = _.orderBy(orders, [sortColumn.path], [sortColumn.order]);
    const totalCount = sortedData.length;

    const parseStatus = rawStatus => {
        switch (rawStatus) {
            case "active":
            case "trialing":
                return "Actif";
            default:
                return "Inactif";
        }
    };

    const columns = [
        {
            path: "orderNumber",
            label: "# Commande",
        },
        {
            path: "createdAt",
            key: "data",
            label: "Date",
            body: order => {
                return parseDate(order.createdAt);
            },
        },
        {
            path: "shippingStatus",
            label: "Statut",
            asyncBody: async order => {
                const transactionPayload = await getRequest(`transactions/?orderId=${order.id}`);
                const transaction = transactionPayload.data[0];

                if (transaction) {
                    const stripeSub = await getRequest(`users/stripeSub/${transaction.stripePayments.find(s => s.includes("sub_"))}`);
                    const sub = stripeSub.data;

                    if (sub.canceled_at && sub.status === "active") {
                        return "Renouvellement annulé";
                    } else {
                        return parseStatus(sub.status);
                    }
                }
            },
        },
        {
            key: "Item",
            label: "Item",
            asyncBody: async order => {
                const sub = order.items.find(i => i.isSubscription);
                if (sub) {
                    const subPayload = await getRequest(`subscriptions/${sub.id}`);
                    return subPayload.data.name;
                }
            },
        },
        {
            key: "canceled_at",
            path: "canceled_at",
            label: "Essai gratuit",
            asyncBody: async order => {
                const transactionPayload = await getRequest(`transactions/?orderId=${order.id}`);
                if (transactionPayload && transactionPayload.data.length > 0 && transactionPayload.data[0].stripePayments.length > 0) {
                    const stripeSubPayload = await getRequest(`users/stripeSub/${transactionPayload.data[0].stripePayments[0]}`);
                    if (stripeSubPayload) {
                        const stripeSub = stripeSubPayload.data;
                        const remainingTrialDays = stripeSub.trial_end && getTimestampDifferenceInDay(stripeSub.trial_end, Date.now());
                        if (Date.now() < stripeSub.trial_end * 1000 && remainingTrialDays) {
                            return `${remainingTrialDays} jour${remainingTrialDays > 1 ? "s" : ""}`;
                        } else {
                            return "Terminé";
                        }
                    }
                }
            },
        },
        {
            key: "totalRow",
            label: "Total",
            asyncBody: async order => {
                const transactionPayload = await getRequest(`transactions/?orderId=${order.id}`);
                const transaction = transactionPayload.data[0];

                if (transaction) {
                    const stripeSub = await getRequest(`users/stripeSub/${transaction.stripePayments.find(s => s.includes("sub_"))}`);
                    const sub = stripeSub.data;

                    if (sub.canceled_at || sub.status === "active") {
                        return "0.00 $";
                    } else {
                        return `${(order.totalGrand / 100).toFixed(2)} $`;
                    }
                }
            },
        },
    ];

    const handleSort = sortColumn => {
        setSortColumn(sortColumn);
    };

    const handlePageChange = (_, page) => {
        setSelectedPage(page);
    };

    const handleRowClick = order => {
        history.push(`/portail/facture-essai/${order.id}`);
    };

    const fetchTrialOrders = async () => {
        try {
            const trialOrderPayload = await getRequest(`trialOrders?user.email=${displayedUser.email}`);
            const trialOrders = trialOrderPayload.data;

            setOrders(trialOrders);
            setLoading(false);
        } catch (e) {
            enqueueSnackbar("Problème avec la requête", { variant: "error" });
            console.error(e);
            setOrders([]);
            setLoading(false);
        }

        // const trialOrders = [];

        // try {
        //     const payload = await getRequest(`trialOrders?user.email=${displayedUser.email}`);

        //     if (payload && payload.data) {
        //         for (const trialOrder of payload.data) {
        //             const transactionPayload = await getRequest(`transactions/?orderId=${trialOrder.id}`);
        //             if (transactionPayload && transactionPayload.data[0]) {
        //                 const stripeSubPayload = await getRequest(
        //                     `users/stripeSub/${transactionPayload.data[0].stripePayments.find(payment => payment.includes("sub_"))}`
        //                 );
        //                 if (stripeSubPayload && stripeSubPayload.data) {
        //                     switch (stripeSubPayload.data.status) {
        //                         case "active":
        //                         case "trialing":
        //                             trialOrders.push(trialOrder);
        //                     }
        //                 }
        //             }
        //         }
        //         setOrders(trialOrders);
        //         setLoading(false);
        //     }
        // } catch (e) {
        //     enqueueSnackbar("Problème avec la requête", { variant: "error" });
        //     console.error(e);
        //     setOrders([]);
        //     setLoading(false);
        // }
    };

    useEffect(() => {
        fetchTrialOrders(displayedUser.id);
    }, [displayedUser]);

    return (
        <Table
            columns={columns}
            sortColumn={sortColumn}
            data={sortedData}
            rowsPerPage={settings.page.rowsPerPage}
            selectedPage={selectedPage}
            totalCount={totalCount}
            loading={loading}
            sortable={true}
            paginated={false}
            rowClickable={true}
            onSort={handleSort}
            onPageChange={handlePageChange}
            onRowClick={handleRowClick}
        />
    );
};

export default TrialOrders;
