import React from "react";

// React-router-dom
import { Link } from "react-router-dom";

// Assets
import logo from "../../../assets/bs/logo-footer.png";
import linkedin from "../../../assets/bs/linkedin.png";
import facebook from "../../../assets/bs/facebook.png";
import instagram from "../../../assets/bs/instagram.png";
import youtube from "../../../assets/bs/youtube.png";

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__container">
                <div className="footer__linksContainer">
                    <div className="footer__links">
                        <Link to="/home">
                            <img src={logo} alt="" />
                        </Link>
                    </div>
                    <div className="footer__nav footer__nav--menus">
                        <p className="footer__nav--title">Menus</p>
                        <Link to="/menus">
                            <span className="link">Boîte sur-mesure</span>
                        </Link>
                        <Link to="/menus">
                            <span className="link">Boîte découverte</span>
                        </Link>
                    </div>
                    <div className="footer__nav footer__nav--concept">
                        <p className="footer__nav--title">Le concept</p>
                        <Link to="/how">
                            <span className="link">Comment ça marche?</span>
                        </Link>
                        <Link to="/commitment">
                            <span className="link">Notre engagement</span>
                        </Link>
                        <Link to="/about">
                            <span className="link">À propos</span>
                        </Link>
                        <Link to="/faq">
                            <span className="link">Foire aux questions</span>
                        </Link>
                        <Link to="">
                            <span className="link">Nous joindre</span>
                        </Link>
                    </div>
                    <div className="footer__nav footer__nav--jimmy">
                        <p className="footer__nav--title">Développés par Jimmy Sévigny</p>
                        <a target="_blank" href="https://gomatcha.ca/">
                            <span className="link">GoMatcha</span>
                        </a>
                        <a href="https://aktivation.ca/">
                            <span className="link">Aktivation</span>
                        </a>
                        <a href="https://melangecoupefaim.com/">
                            <span className="link">Mélange Coupe Faim</span>
                        </a>
                    </div>
                </div>
                <div className="footer__links--socials">
                    <button className="button button__regular button__regular--socials">
                        <img src={linkedin} alt="" />
                    </button>
                    <button className="button button__regular button__regular--socials">
                        <img src={facebook} alt="" />
                    </button>
                    <button className="button button__regular button__regular--socials">
                        <img src={instagram} alt="" />
                    </button>
                    <button className="button button__regular button__regular--socials">
                        <img src={youtube} alt="" />
                    </button>
                </div>
                <p className="copyright">
                    © 2022 <b>Boîte Santé</b>
                </p>

                <div className="footer__bar"></div>
                <div className="footer__bottom">
                    <p className="footer__bottom--politics">Politique de confidentialité</p>
                    <p className="footer__bottom--copyright">
                        © 2022 <b>Boîte Santé</b>
                    </p>
                    <p className="footer__bottom--leadhouse">
                        Propulsé par <a target="_blank" href="https://leadhouse.ca/">LEADHOUSE</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
