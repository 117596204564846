import React from "react";
// Pages
import Nav from "./home/nav";
import Hero from "./home/hero";
import Intro from "./home/intro";
import Cadeau from "./home/cadeau";
import Products from "./home/products";
import Sample from "./home/sample";
import Valeurs from "./home/valeurs";
import Socials from "./home/socials";
import Use from "./home/use";
import Annick from './home/annick';
import Vs from "./home/vs";
import Fact from "./home/fact";
import Shipping from "./home/shipping";
import Testimonials from "./home/testimonials";
import Faq from "./home/faq";
import Newsletter from "./home/newsletter";
import Footer from "./home/footer";
import FooterNav from "./home/footernav";
import Lh from "./home/lh";
// Custom Components
import { GetHelmet } from "../../utils/config";

function Home() {
    return (
        <div className="gm">
            <GetHelmet />
            <div
                className="fb-customerchat"
                attribution="setup_tool"
                page_id="101333205095205"
                theme_color="#44602c"
                logged_in_greeting="Bienvenue sur GoMatcha! Comment pouvons-nous vous aider?"
                logged_out_greeting="Bienvenue sur GoMatcha! Comment pouvons-nous vous aider?"
            ></div>
            <Nav />
            <Hero />
            <Intro />
            {/* <Cadeau /> */}
            <Products />
            <Sample />
            <Valeurs />
            <Socials />
            <Use />
            <Annick />
            <Vs />
            <Fact />
            <Shipping />
            <Testimonials />
            <Faq />
            <Newsletter />
            <Footer />
            <FooterNav />
            <Lh />
        </div>
    );
}

export default Home;
