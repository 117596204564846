import React from "react";
import { Grid } from "@material-ui/core";

import Icons from "../../../assets/gc/use-icons.png";
import Left from "../../../assets/gc/use-left.png";

function Use() {
    return (
        <div className="use">
            <Grid container>
                <Grid item sm={12} md={6}>
                    <img src={Left} className="use__left" alt="" />
                </Grid>
                <Grid item sm={12} md={6}>
                    <div className="use__container">
                        <span>
                            <span className="use__1">Comment utiliser <br/>Go Choko!</span>
                            <span className="use__2">Go Choko! est à la base une collation prête-à-manger.</span>
                            <span className="use__3">Toutefois, il est possible de l’ajouter à vos aliments préférés. Nos trois saveurs proposées viendront rehausser à coup sûr le goût de vos aliments.</span>
                            <span className="use__2">Utilisez également Go Choko! dans...</span>
                            <img src={Icons}  className="use__icons" />
                            <a href="#produits"><button className="use__cta">Achetez maintenant</button></a>
                        </span>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Use;