import draftToHtml from "draftjs-to-html";
import parse from "html-react-parser";

const locale = "fr-CA";

const localeOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
};

/**
 * Parses the date from a timestamp
 * @param {number} timestamp
 * @returns {Date} date
 */
export const parseDate = timestamp => {
    if (timestamp.hasOwnProperty("_type") && timestamp._type === "Date") {
        return new Date(timestamp._value).toLocaleDateString(locale, localeOptions);
    } else if (timestamp.hasOwnProperty("_seconds")) {
        return new Date(timestamp._seconds * 1000).toLocaleDateString(locale, localeOptions);
    } else {
        // if timestamp is in milliseconds
        if (timestamp < 9999999999) {
            timestamp *= 1000;
        }
        return new Date(timestamp).toLocaleDateString(locale, localeOptions);
    }
};

/**
 * Gets the difference between 2 timestamps in number of days
 * @param {Number} timestamp1
 * @param {Number} timestamp2
 * @returns {Number} number of days
 */
export const getTimestampDifferenceInDay = (timestamp1, timestamp2) => {
    const date1 = new Date(parseDate(timestamp1));
    const date2 = new Date(parseDate(timestamp2));
    const diffTime = Math.abs(date1 - date2);
    const diffdays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffdays;
};

/**
 * Formats the received value to match our homemade string policy
 * @param {string} value
 * @returns {string} formatted value
 */
export const formatValue = value => {
    const numeric = value.match(/\d+/g);
    const type = value.replace(/[0-9]/g, "");
    switch (type) {
        case "d":
            return `${numeric > 1 ? numeric + " Jours" : numeric + " Jour"}`;
        case "m":
            return `${numeric} Mois`;
        case "y":
            return `${numeric > 1 ? numeric + " Années" : numeric + " Année"}`;
        default:
            return "";
    }
};

/**
 * Formats a number to a price (2 number after decimal point)
 * @param {number} price
 * @returns {number} formattedPrice
 */
export const formatPrice = (price, fixed = true) => {
    return fixed ? (price / 100).toFixed(2) : price / 100;
};

export const parseDraftToHtml = node => parse(draftToHtml(JSON.parse(node)));

/*
 * convert the query object to a query string
 * */
export const stringifyQueries = (queries, queryCharacter = "?") =>
    Object.entries(queries).reduce((queryString, query, index, arr) => {
        const queryToString = (key, value, queryString) => {
            if (value.operator && typeof value.value !== "undefined")
                queryString += `${key}:${value.operator}=${typeof value.value !== "string" ? JSON.stringify(value.value) : value.value}`;
            else queryString += `${key}=${value}`;
            if (index < arr.length - 1) queryString += "&";

            return queryString;
        };

        const [key, value] = query;

        if (Array.isArray(value)) for (const v of value) queryString = queryToString(key, v, queryString);
        else queryString = queryToString(key, value, queryString);
        return queryString;
    }, queryCharacter);
