import React from "react";
import { useHistory } from "react-router-dom";

import { Grid } from "@material-ui/core";

import Bg from "../../../assets/gm/use-bg.jpg";
import Icon from "../../../assets/gm/use-icon.png";

function Use() {
    const history = useHistory();
    return (
        <div className="use">
            <Grid container>
                <Grid item sm={12} md={6}>
                    <img src={Bg} alt="" />
                </Grid>
                <Grid item sm={12} md={6}>
                    <div className="use__container">
                        <span>
                            <span className="use__1">Comment utiliser</span>
                            <span className="use__2">GoMatcha!</span>
                            <div className="use__3">
                                <span>
                                    Tout simplement <b>mélanger</b> votre sachet dans 300ml à 500ml d’eau!
                                </span>
                                <img src={Icon} alt="" />
                            </div>

                            {/* <div className="use__cta">
                                <button onClick={() => history.push("/recettes")}>Voir nos recettes</button>
                            </div> */}
                        </span>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Use;
