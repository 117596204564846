import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// Material UI Core
import Grid from "@material-ui/core/Grid";
// Custom Components
import Mcf1 from "../../../assets/mcf/sachet1.png";
import Mcf2 from "../../../assets/mcf/sachet2.png";
// Redux
import { useDispatch } from "react-redux";
import { addProduct, addSubscription } from "../../../store/reducers/checkout";
// DB
import { getRequest } from "../../../actions/requests";
import { productsRoot } from "../../../actions/productsRoute";
import { subscriptionsRoot } from "../../../actions/subscriptionsRoute";
// Utils
import { fetchProductById } from "../../../utils/apiCalls";

function Products() {
    const history = useHistory();
    const dispatch = useDispatch();

    const [product, setProduct] = useState({});

    const BASE = "JqqJ8khea3i51MPw41DH";
    const PLUS = "4ubUr0rt9v3IefoI9UGp";
    const LIBERTY = "shMsZJD9y4HPZ7wM0Dvm";

    useEffect(async () => {
        setProduct(await fetchProductById(LIBERTY));
    }, []);

    return (
        <div className="products" id="produits">
            <div className="products__container">
                <h3 className="products__title">Choisissez la formule pour vous</h3>
                <Grid container spacing={3} className="products__grid">
                    <Grid item md={12} lg={4}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                const subPayload = await getRequest(`${subscriptionsRoot}/${BASE}`);
                                const subscription = subPayload.data;
                                dispatch(addSubscription({ ...subscription, selectedQuantity: 1 }));
                                history.push(`/checkout`);
                            }}
                        >
                            <div className="products__top">
                                <span className="products__item__title">MÉLANGE COUPE FAIM 2.0</span>
                                <span className="products__item__subtitle">BASE</span>
                            </div>

                            <div className="products__middle">
                                <span className="product__price">
                                    <span className="products__price">19</span>
                                    <sup className="products__price__cents">99$</sup>
                                    <sub className="products__price__pack">/sachet +txs</sub>
                                </span>
                                <span className="products__savings">ÉCONOMISEZ 20%</span>
                                <img src={Mcf1} alt="" />
                                <span className="product__total__price">
                                    <span className="products__total__price">19</span>
                                    <sup className="products__total__price__cents">99$</sup>
                                    <sub className="products__total__price__pack">/mois +txs</sub>
                                </span>
                                <span className="product__monthly__packs">1 SACHET PAR MOIS</span>
                                <span className="product__monthly__packs__2">AVEC ABONNEMENT</span>
                            </div>

                            <div className="products__bottom">
                                <span className="products__cta">
                                    ACHETEZ
                                    <br />
                                    MAINTENANT
                                </span>
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={12} lg={4}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                const subPayload = await getRequest(`${subscriptionsRoot}/${PLUS}`);
                                const subscription = subPayload.data;
                                dispatch(addSubscription({ ...subscription, selectedQuantity: 1 }));
                                history.push(`/checkout`);
                            }}
                        >
                            <div className="products__top green">
                                <span className="products__best">MEILLEUR VENDEUR</span>
                                <span className="products__item__title">MÉLANGE COUPE FAIM 2.0</span>
                                <span className="products__item__subtitle">PLUS+</span>
                            </div>

                            <div className="products__middle green">
                                <span className="product__price">
                                    <span className="products__price">17</span>
                                    <sup className="products__price__cents">99$</sup>
                                    <sub className="products__price__pack">/sachet +txs</sub>
                                </span>
                                <span className="products__savings">ÉCONOMISEZ 28%</span>
                                <img src={Mcf2} alt="" />
                                <span className="product__total__price">
                                    <span className="products__total__price">35</span>
                                    <sup className="products__total__price__cents">98$</sup>
                                    <sub className="products__total__price__pack">/mois +txs</sub>
                                </span>
                                <span className="product__monthly__packs">2 SACHETS PAR MOIS</span>
                                <span className="product__monthly__packs__2">AVEC ABONNEMENT</span>
                            </div>

                            <div className="products__bottom">
                                <span className="products__cta">
                                    ACHETEZ
                                    <br />
                                    MAINTENANT
                                </span>
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={12} lg={4}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                dispatch(addProduct({ ...product, selectedQuantity: 1, status: "À livrer", price: 2199 }));
                                history.push(`/checkout`);
                            }}
                        >
                            <div className="products__top">
                                <span className="products__item__title">MÉLANGE COUPE FAIM 2.0</span>
                                <span className="products__item__subtitle">LIBERTÉ</span>
                            </div>

                            <div className="products__middle">
                                <span className="product__price">
                                    <span className="products__price">21</span>
                                    <sup className="products__price__cents">99$</sup>
                                    <sub className="products__price__pack">/sachet +txs</sub>
                                </span>
                                <span className="products__savings">
                                    RÉGULIER <strike>24.99$</strike>
                                </span>
                                <img src={Mcf1} alt="" />
                                <span className="product__total__price">
                                    <span className="products__total__price__cents">SANS ABONNEMENT</span>
                                </span>
                                <span className="product__monthly__packs">1 SACHET</span>
                            </div>

                            <div className="products__bottom">
                                <span className="products__cta">
                                    ACHETEZ
                                    <br />
                                    MAINTENANT
                                </span>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Products;
