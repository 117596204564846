import React from "react";
import { useHistory } from "react-router-dom";
// Redux
import { useDispatch } from "react-redux";
import { addProduct } from "../../../store/reducers/checkout";
// DB
import { getRequest } from "../../../actions/requests";
import { productsRoot } from "../../../actions/productsRoute";

import { Grid } from "@material-ui/core";
import Left from "../../../assets/gk/gokrisp-sample.png";

function Sample() {
    const history = useHistory();
    const dispatch = useDispatch();

    const SAMPLE_PACK = "t4AnYPTEMFSaHI9f6qrO";

    return (
        <div className="sample" id="sample">
            <Grid container>
                <Grid item sm={12} md={6}>
                    <img src={Left} alt="" />
                </Grid>
                <Grid item sm={12} md={6}>
                    <div className="sample__container">
                        <span>
                            <span className="sample__title">
                                Essayez le 'sample pack'
                            </span>
                            <span className="sample__text">
                              Le parfait ensemble d'essais pour vous permettre de découvrir toutes les saveurs sans aucun engagement de votre part.
                            </span>
                            <span className="sample__text__item">1 x Go Krisp! Chocolat et noix de coco</span>
                            <span className="sample__text__item">1 x Go Krisp! Explosion de baies </span>
                            <span className="sample__text__item">1 x Go Krisp! Pommes et caramel salé </span>
                            <span className="sample__text__item">1 x Go Krisp! Tiramisu </span>
                                <button
                                    className="sample__cta"
                                    onClick={async () => {
                                        const payload = await getRequest(`${productsRoot}/${SAMPLE_PACK}`, {});
                                        dispatch(addProduct({ ...payload.data, selectedQuantity: 1, status: "À livrer" }));
                                        history.push(`/checkout`);
                                    }}
                                >
                                    Essayer un “sample pack”  -  14,96$
                                </button>
                        </span>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Sample;
