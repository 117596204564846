import { useDispatch, useSelector } from "react-redux";
import * as slices from "../store/reducers";

/* Fetchs the data corresponding to the queries pass as a parameter.
 * Returns useful information regarding the slice (is there more data to fetch, is the slice loading, the last page ) */
const hookName = "useSlice";
const useSlice = ({ sliceName, id, page, pageSize } = { id: null, page: null, pageSize: 10 }) => {
    if (!sliceName) throw new Error(`${hookName} hook: sliceName must be defined`);
    const dispatch = useDispatch();
    const slice = slices[sliceName];

    if (!slice) throw new Error(`${hookName} hook: ${sliceName} does not exist as a slice name`);

    const { remove, create, update, fetchData, setLastPage } = slice.actionCreators;

    // Mandatory functions for a slice
    // --- [Action creators] ---
    if (!fetchData) throw new Error(`${hookName} hook: you must define the "fetchData" action creator in the ${sliceName} slice`);
    if (typeof fetchData !== "function") throw new Error(`${hookName} hook: "fetchData" in ${sliceName} must be a function`);

    if (!update) throw new Error(`${hookName} hook: you must define the "update" action creator in the ${sliceName} slice`);
    if (typeof update !== "function") throw new Error(`${hookName} hook: "update" in ${sliceName} must be a function`);

    if (!create) throw new Error(`${hookName} hook: you must define the "create" action creator in the ${sliceName} slice`);
    if (typeof create !== "function") throw new Error(`${hookName} hook: "create" in ${sliceName} must be a function`);

    if (!remove) throw new Error(`${hookName} hook: you must define the "remove" action creator in the ${sliceName} slice`);
    if (typeof remove !== "function") throw new Error(`${hookName} hook: "remove" in ${sliceName} must be a function`);

    // --- [Selectors] ---
    if (!slice.selectors.noMoreData) throw new Error(`${hookName} hook: you must define the "noMoreData" selector in the ${sliceName} slice`);

    if (!slice.selectors.isLoading) throw new Error(`${hookName} hook: you must define the "isLoading" selector in the ${sliceName} slice`);

    if (!slice.selectors.getLastPage) throw new Error(`${hookName} hook: you must define the "getLastPage" selector in the ${sliceName} slice`);

    if (!slice.selectors.all) throw new Error(`${hookName} hook: you must define the "all" selector in the ${sliceName} slice`);

    if (!slice.selectors.getPage) throw new Error(`${hookName} hook: you must define the "getPage" selector in the ${sliceName} slice`);

    if (!slice.selectors.getById) throw new Error(`${hookName} hook: you must define the "getById" selector in the ${sliceName} slice`);

    const noMoreData = useSelector(state => slice.selectors.noMoreData(state));
    const loading = useSelector(state => slice.selectors.isLoading(state));
    const lastPage = useSelector(state => slice.selectors.getLastPage(state));
    const all = useSelector(state => slice.selectors.all(state));

    const dataPage = useSelector(slice.selectors.getPage(page, pageSize));
    const current = useSelector(slice.selectors.getById(id));

    const fetchFn = ({ id, queries, refresh } = { id: null, queries: null, refresh: false }) => dispatch(fetchData({ id, queries, refresh }));
    const updateFn = data => dispatch(update(data));
    const createFn = data => dispatch(create(data));
    const removeFn = id => dispatch(remove(id));
    const setLastPageFn = page => dispatch(setLastPage(page));

    // return { removeFn, createFn, updateFn, fetchFn, noMoreData, loading, lastPage, setLastPage: setLastPageFn, all, dataPage, current };
    return {
        actionCreators: { removeFn, createFn, updateFn, fetchFn, setLastPage: setLastPageFn },
        selectors: { noMoreData, loading, lastPage, all, dataPage, current },
    };
};

export default useSlice;
