import React, { useState } from "react";

// Components
import ProductOrder from "./productOrder";
import Process from "./process";

// Mui
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

// React Icons
import { BsBoxSeam } from "react-icons/bs";

// Assets
import bourg from "../../../assets/bs/menus/boeuf-bourguignon.png";
import curry from "../../../assets/bs/menus/curry-vegetarien.png";
import chili from "../../../assets/bs/menus/chili-vegetarien.png";
import lasagne from "../../../assets/bs/menus/lasagne.png";
import poulet from "../../../assets/bs/menus/poulet-jerk.png";
import x from "../../../assets/bs/menu-close.png";

const ProductsOrder = ({ create, step }) => {
    const [large, setLarge] = useState(false);
    const [medium, setMedium] = useState(false);

    const handleLargeSelected = () => {
        setMedium(false);
        setLarge(true);
    };

    const handleMediumSelected = () => {
        setMedium(true);
        setLarge(false);
    };

    return create ? (
        <div className="products">
            <Process step={step} create={create} />
            {step === 1 && (
                <>
                    <div className="products__size">
                        <div className={medium ? "products__size--container selected" : "products__size--container"} onClick={handleMediumSelected}>
                            <div className="products__size--image">
                                <BsBoxSeam />
                            </div>
                            <div className="products__size--text">
                                <h6>Moyenne - 14 repas</h6>
                                <p>
                                    À partir de <b>146,99$</b>
                                </p>
                            </div>
                        </div>
                        <div className={large ? "products__size--container selected" : "products__size--container"} onClick={handleLargeSelected}>
                            <div className="products__size--image">
                                <BsBoxSeam />
                            </div>
                            <div className="products__size--text">
                                <h6>Grande - 28 repas</h6>
                                <p>
                                    À partir de <b>349,00$</b>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="products__container">
                        <ProductOrder create={create} image={bourg} price="10,55" />
                        <ProductOrder create={create} image={curry} price="10,55" />
                        <ProductOrder create={create} image={chili} price="10,55" />
                        <ProductOrder create={create} image={lasagne} price="10,55" />
                        <ProductOrder create={create} image={poulet} price="10,55" />
                    </div>
                </>
            )}
            {step === 2 && (
                <div>
                    <div className="questions">
                        <Accordion className="questions__accordion">
                            <AccordionSummary
                                expandIcon={
                                    <div>
                                        <img src={x} alt="" />
                                    </div>
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <h3 className="questions__accordion--title">Collations</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="products__container">
                                    <ProductOrder create={create} image={bourg} price="10,55" />
                                    <ProductOrder create={create} image={curry} price="10,55" />
                                    <ProductOrder create={create} image={chili} price="10,55" />
                                    <ProductOrder create={create} image={lasagne} price="10,55" />
                                    <ProductOrder create={create} image={poulet} price="10,55" />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="questions__accordion">
                            <AccordionSummary
                                expandIcon={
                                    <div>
                                        <img src={x} alt="" />
                                    </div>
                                }
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <h3 className="questions__accordion--title">Accessoires</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo
                                    lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            )}
        </div>
    ) : (
        <div className="products">
            <Process step={1} create={create} />
            <div className="products__container">
                <ProductOrder create={create} />
                <ProductOrder create={create} />
                <ProductOrder create={create} />
                <ProductOrder create={create} />
                <ProductOrder create={create} />
            </div>
        </div>
    );
};

export default ProductsOrder;
