import { ga } from "../../configs/firebase";
import ReactFP from "react-facebook-pixel";

import { getLandingPageInfo } from "../../utils/config";

const { brandAcronym } = getLandingPageInfo();

const sendEvent = (eventName, event) => {
    try {
        // ga.logEvent(eventName, event);
        window.dataLayer.push({ event: eventName, ecommerce: event });
        ReactFP.trackCustom(eventName, event);
    } catch (e) {
        console.error(e);
    }
};

export default {
    init: () => {
        // ga is already initialized
        ReactFP.init("1422379857910508");
    },

    addedToCart: (item, email) => {
        const eventName = brandAcronym + "_add_to_cart";
        const event = { item_id: item.id, item_name: item.name, email };

        sendEvent(eventName, event);
    },

    productViewed: (item, email) => {
        const eventName = brandAcronym + "_product_modal_view";
        const event = { item_id: item.id, item_name: item.name, email };

        sendEvent(eventName, event);
    },

    checkoutSuccess: (order, items) => {
        const eventName = brandAcronym + "_checkout_success";
        const event = { order_id: order.id, order_number: order.orderNumber, email: order.user.email };
        const purchaseEvent = {
            currency: "CAD",
            transaction_id: order.id,
            items: items.map(item => ({ item_id: item.id, item_name: item.name, quantity: item.quantity, item_variant: item.selectedSku })),
            tax: order.totalTaxes / 100,
            value: order.totalGrand / 100,
        };

        sendEvent(eventName, event);
        sendEvent(brandAcronym + "_purchase", purchaseEvent);
    },

    orderDetails: order => {
        const eventName = brandAcronym + "_order_details";

        const taxes = order.totalTaxes / 100;
        const avg_price_per_item =
            order.items.reduce((total, item) => {
                return total + item.price;
            }, 0) /
            order.items.length /
            100;
        const item_count = order.items.length;
        const event = {
            order_id: order.id,
            order_number: order.orderNumber,
            taxes,
            avg_price_per_item,
            item_count,
            email: order.user.email,
        };

        sendEvent(eventName, event);
    },

    subscribedTo: (sub, email) => {
        const eventName = brandAcronym + "_subscribed_to";
        const event = { subscription_id: sub.id, subscription_name: sub.name, email };

        sendEvent(eventName, event);
    },

    unsubscribedFrom: (sub, email) => {
        const eventName = brandAcronym + "_unsubscribe_from";
        const event = { subscription_id: sub.id, subscription_name: sub.name, email };

        sendEvent(eventName, event);
    },
    upsale: (item, email) => {
        const eventName = brandAcronym + "_upsale";
        const event = {
            item: { item_name: item.name, item_id: item.id, price: item.price, item_brand: brandAcronym },
            email,
        };

        sendEvent(eventName, event);
    },
};
