import React from "react";

function Fact() {
    return (
        <div className="fact">
            <div className="fact__container">
                <span className="fact__subtitle">NOUS SOMMES FIERS DE NOTRE PLATEFORME</span>
                <h3 className="fact__title">100% QUÉBÉCOISE!</h3>
                <a href="./#produits">
                    <button className="fact__cta">
                        J'ESSAIE AKTIVATON
                        <br />
                        POUR 14 JOURS GRATUITEMENT
                    </button>
                </a>
            </div>
        </div>
    );
}

export default Fact;
