import React from 'react';
import Grid from '@material-ui/core/Grid';

import Emoji1 from '../../../assets/gm/emoji1.png';
import Emoji2 from '../../../assets/gm/emoji2.png';
import Emoji3 from '../../../assets/gm/emoji3.png';
import Quote from '../../../assets/gm/icon-quote.png';

function Testimonials() {

    return (
        <div className="testimonials">
            <Grid container>
                <Grid item xs={12} md={6}>
                    <div className="testimonials__item">
                        <Grid container>
                            <Grid item xs={3}>
                                <img src={Emoji1} />
                            </Grid>
                            <Grid item xs={9}>
                                <div className="testimonials__content">
                                    <p>J'ai fait mon premier ce matin et wow la texture! 1/2 pour commencer ce matin. 🔥🔥🔥 je vais augmenter dans 1 semaine à 1 ct! C'est délicieux 😊😊😊</p>
                                    <span>Chantal D.</span>
                                    <img src={Quote} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="testimonials__item offset">
                        <Grid container>
                            <Grid item xs={3}>
                                <img src={Emoji2} />
                            </Grid>
                            <Grid item xs={9}>
                                <div className="testimonials__content">
                                    <p>Je suis une fille qui a essayé pas mal de matcha depuis 1 an (de commerces spécialisés) et j'ai reçu le Gomatcha vendredi et j'ai été totalement conquise par le tien. Il est vraiment très bon et je ressens les effets bien plus longtemps et rapidement que tout ceux que j'ai essayé jusqu'à présent - et côté qualité prix aussi il est de loin moins cher également. Merci à toi et toute ton équipe pour cette merveille .. Et bonne continuation xxx</p>
                                    <span>Stéphanie R.</span>
                                    <img src={Quote} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="testimonials__item">
                        <Grid container>
                            <Grid item xs={3}>
                                <img src={Emoji3} />
                            </Grid>
                            <Grid item xs={9}>
                                <div className="testimonials__content">
                                    <p>Pour me donner un boost en ce lundi au travail, un excellent matcha en latté glacé avec lait de soya!! 😍 Bonne semaine les activeux! 😄</p>
                                    <span>Julie de B.</span>
                                    <img src={Quote} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="testimonials__right">
                        <div>
                            <span className="testimonials__right__2">Découvrez les témoignages des consommateurs</span>
                            <a href="#produits"><button className="testimonials__cta">Acheter maintenant</button></a>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Testimonials
