import React from "react";

const OrderType = ({ create, setCreate }) => {
    return (
        <>
            {create === undefined ? (
                <div className="orderType">
                    <div
                        className="orderType__try"
                        onClick={() => {
                            setCreate(false);
                        }}
                    >
                        <div className="orderType__item orderType__item--try">
                            <h6 className="orderType__item--section">Boîte découverte</h6>
                            <h3 className="orderType__item--title">Essayer une boîte</h3>
                            <p className="orderType__item--text">
                                Et orci potenti elementum consectetur at posuere sem a.
                                <br />
                                Abonnement non requis.
                            </p>
                        </div>
                    </div>
                    <div
                        className={create ? "orderType__create selected" : "orderType__create"}
                        onClick={() => {
                            setCreate(true);
                        }}
                    >
                        <div className="orderType__item orderType__item--create">
                            <h6 className="orderType__item--section">Boîte-sur-mesure</h6>
                            <h3 className="orderType__item--title">Créez votre boîte</h3>
                            <p className="orderType__item--text">
                                Et orci potenti elementum consectetur at posuere sem a.
                                <br />
                                Abonnement non requis.
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="orderType">
                    <div
                        className={create ? "orderType__try" : "orderType__try selected"}
                        onClick={() => {
                            setCreate(false);
                        }}
                    >
                        <div className="orderType__item orderType__item--try">
                            <h6 className="orderType__item--section">Boîte découverte</h6>
                            <h3 className="orderType__item--title">Essayer une boîte</h3>
                            <p className="orderType__item--text">
                                Et orci potenti elementum consectetur at posuere sem a.
                                <br />
                                Abonnement non requis.
                            </p>
                        </div>
                    </div>
                    <div
                        className={create ? "orderType__create selected" : "orderType__create"}
                        onClick={() => {
                            setCreate(true);
                        }}
                    >
                        <div className="orderType__item orderType__item--create">
                            <h6 className="orderType__item--section">Boîte-sur-mesure</h6>
                            <h3 className="orderType__item--title">Créez votre boîte</h3>
                            <p className="orderType__item--text">
                                Et orci potenti elementum consectetur at posuere sem a.
                                <br />
                                Abonnement non requis.
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default OrderType;
