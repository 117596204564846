import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
// Material UI Core
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
// Material UI Styles
import { makeStyles } from "@material-ui/core/styles";
// Custom Components
import OrderDetailsTableWrapper from "./orderDetailsTableWrapper";
// Settings
import { settings } from "../../../settings";
// Redux
import { useSelector } from "react-redux";
import { getProducts, getSubscriptions, getCheckoutDetails } from "../../../store/reducers/checkout";
import { getAllOrders } from "../../../store/reducers/orders";
import { formatCartItems } from "./utils";
import { formatPrice } from "../../../utils/parseData";

const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: 700,
    },
    title: {
        marginTop: theme.spacing(2),
    },
    paper: {
        margin: theme.spacing(2, 2, 2, 1),
        padding: theme.spacing(3),
        [theme.breakpoints.down(600)]: {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

const OrderDetails = () => {
    // Totals
    const [federalTax, setFederalTax] = useState(0);
    const [provincialTax, setProvincialTax] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [amountSaved, setAmountSaved] = useState(0);

    const classes = useStyles();

    // Redux
    const products = useSelector(getProducts);
    const subscriptions = useSelector(getSubscriptions);
    const details = useSelector(getCheckoutDetails);
    const orders = useSelector(getAllOrders);
    const lastOrder = orders[orders.length - 1];

    useEffect(() => {
        if (orders.length > 0) {
            if (orders[0].amountSaved) setAmountSaved(orders[0].amountSaved / 100);
            setSubTotal(orders[0].totalSub / 100);
            setFederalTax(orders[0].totalTaxesFed / 100);
            setProvincialTax(orders[0].totalTaxesProv / 100);
            setTotal(orders[0].totalGrand / 100);
        }
    }, [orders]);

    const tableData = useMemo(() => formatCartItems(lastOrder, products, subscriptions), [lastOrder, products, subscriptions]);

    return (
        <main className={classes.layout}>
            <Paper className={classes.paper}>
                <Typography component="h1" variant="h4" align="center">
                    Sommaire
                </Typography>
                <List disablePadding>
                    <OrderDetailsTableWrapper data={tableData} />
                    {details.billing && (
                        <>
                            {amountSaved !== 0 && (
                                <ListItem className={classes.listItem}>
                                    <ListItemText primary="Promotion" />
                                    <Typography variant="subtitle1" className={classes.total}>
                                        {amountSaved.toFixed(2)} $
                                    </Typography>
                                </ListItem>
                            )}
                            <ListItem className={classes.listItem}>
                                <ListItemText primary="Livraison" />
                                <Typography variant="subtitle1" className={classes.total}>
                                    {formatPrice(lastOrder.totalShippingCost)} $
                                </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <ListItemText primary="Sous-Total" />
                                <Typography variant="subtitle1" className={classes.total}>
                                    {subTotal.toFixed(2)} $
                                </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <ListItemText primary={`Taxe fédérale (${settings.order.TPS_NUMBER})`} />
                                <Typography variant="subtitle1" className={classes.total}>
                                    {federalTax.toFixed(2)} $
                                </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <ListItemText primary={`Taxe provinciale (${settings.order.TVQ_NUMBER})`} />
                                <Typography variant="subtitle1" className={classes.total}>
                                    {provincialTax.toFixed(2)} $
                                </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <ListItemText primary="Total" />
                                <Typography variant="subtitle1" className={classes.total}>
                                    {total.toFixed(2)} $
                                </Typography>
                            </ListItem>
                        </>
                    )}
                </List>
            </Paper>
        </main>
    );
};

export default OrderDetails;
