import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

// Material UI Core
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// Custom Components
import LogoAKT from "../../../assets/portal/logo-aktivation.png";
import LogoGM from "../../../assets/gm/gm-logo-color.png";
import LogoGC from "../../../assets/gc/gc-login.png";
import LogoMCF from "../../../assets/mcf/js-signature-blue.png";

// Custom Components
import { getLandingPageInfo } from "../../../utils/config";
import { GetHelmet } from "../../../utils/config";

// api requests
import { getRequest } from "../../../actions/requests";

// Hooks
import { useForgotPassword } from "../../../hooks/useForgotPassword";

// validation
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loading from "../../shared/loading";

// yup validation
const requiredMessage = "Ce champ est requis";

const schema = yup.object({
    email: yup.string().email("Courriel invalide").required(requiredMessage),
});

export const ForgotPassword = () => {
    const hist = useHistory();
    const { email, setEmail } = useForgotPassword();
    const { enqueueSnackbar } = useSnackbar();
    const { brand, brandAcronym } = getLandingPageInfo();

    // States
    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(schema) });

    const onSubmit = async data => {
        try {
            setLoading(true);
            const payload = await getRequest("users/userExists", { email: data.email });

            if (payload.data) {
                setEmail(data.email);
            } else enqueueSnackbar("Courriel invalide", { variant: "error" });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const properLogo = () => {
        switch (brandAcronym) {
            case "MCF":
                return LogoMCF;
            case "GM":
                return LogoGM;
            case "AKT":
                return LogoAKT;
            case "GC":
                return LogoGC;
            default:
                return LogoGC;
        }
    };

    useEffect(() => {
        if (email) hist.push("/verificationCode");
    }, [email]);

    return (
        <>
            <Loading loading={loading} />
            <div className={`login__page ${brand}`}>
                <GetHelmet />
                <Card className="login__container">
                    <CardContent>
                        <img onClick={() => hist.push("/")} src={properLogo()} alt="Leadhouse Logo" className="login__logo" />

                        <div className="element__centered" style={{ fontFamily: "Roboto" }}>
                            <h1>Mot de passe oublié</h1>
                        </div>
                        <div className="element__centered" style={{ fontFamily: "Roboto" }}>
                            <h4 style={{ maxWidth: "75%", textAlign: "justify" }}>
                                <p>
                                    Si vous avez oublié votre mot de passe, veuillez saisir votre adresse courriel. <br />
                                </p>
                                <p>
                                    Si ce courriel est valide, vous recevrez un message avec les étapes à suivre pour réinitialiser votre mot de
                                    passe.
                                </p>
                            </h4>
                        </div>

                        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="login__actions">
                                <TextField
                                    helperText={errors.email?.message}
                                    error={!!errors.email?.message}
                                    label="Courriel"
                                    type="email"
                                    name="email"
                                    inputRef={register}
                                    fullWidth
                                    required
                                />
                            </div>
                            <div className="login__actions">
                                <Button className={`login__cta ${brand}`} type="submit" variant="contained">
                                    Réinitialisation
                                </Button>
                                <Button className={`login__cta ${brand}`} variant="contained" onClick={() => hist.push("/portail/login")}>
                                    Retour
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </>
    );
};

export default ForgotPassword;
