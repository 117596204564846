import React, { useState } from "react";

// Slick Carousel
import Slider from "react-slick";

// Mui
import { Box, Dialog, DialogContent, Tabs, Tab, withWidth } from "@material-ui/core";

// Assets
import modal from "../../../assets/bs/menus/boite-vege-modal.png";
import close from "../../../assets/bs/menu-close.png";

const Product = ({ image, title, subtitle, type }) => {
    const [open, setOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    const settings = {
        dots: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: () => (
            <div className="ft-slick__dots--custom">
                <div className="current" />
            </div>
        ),
    };

    const Slide = ({ image }) => {
        return (
            <div className="slide">
                <img src={image} alt="" className="slide__image" />
            </div>
        );
    };

    const handleClickOpen = () => {
        setOpen(!open);
        console.log("open");
    };
    const handleClickClose = () => {
        setOpen(!open);
        console.log("close");
    };

    const ProductDialog = ({ name, desc, tags, ingredients, prep, nutrition }) => {
        function TabPanel(props) {
            const { children, value, index, ...other } = props;

            return (
                <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                    {value === index && (
                        <Box sx={{ p: 3 }}>
                            <p>{children}</p>
                        </Box>
                    )}
                </div>
            );
        }

        function a11yProps(index) {
            return {
                id: `simple-tab-${index}`,
                "aria-controls": `simple-tabpanel-${index}`,
            };
        }

        const [value, setValue] = React.useState(0);

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        return (
            <Dialog fullWidth open={open} onClose={() => handleClickClose()} className="productmodal">
                <img src={close} alt="" className="productmodal__close" onClick={() => handleClickOpen()} />
                <DialogContent>
                    <Slider appendDots={dots => <ul>{dots}</ul>} infinite autoplay arrows={false} {...settings}>
                        <div>
                            <Slide image={modal} />
                        </div>
                        <div>
                            <Slide image={modal} />
                        </div>
                        <div>
                            <Slide image={modal} />
                        </div>
                    </Slider>
                    <div className="productmodal__container">
                        <h3 className="productmodal__title">Boîte végétarienne</h3>
                        <div className="productmodal__desc">Une délicieuse et réconfortante poutine style hot chicken</div>
                        <div className="productmodal__tags--container">
                            <p className="productmodal__tags">Sans gluten</p>
                            <p className="productmodal__tags">Gourmand</p>
                        </div>
                        <button className="button button__cta button__cta--green productmodal__cta">Débuter ma commande</button>
                        {windowWidth && windowWidth < 768 ? (
                            <Tabs className="productmodal__tabs" value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Ing." {...a11yProps(0)} className="productmodal__tab" />
                                <Tab label="Allerg. et prép." {...a11yProps(1)} className="productmodal__tab" />
                                <Tab label="Valeurs nut." {...a11yProps(2)} className="productmodal__tab" />
                            </Tabs>
                        ) : (
                            <Tabs className="productmodal__tabs" value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Ingrédients" {...a11yProps(0)} className="productmodal__tab" />
                                <Tab label="Allergènes et préparation" {...a11yProps(1)} className="productmodal__tab" />
                                <Tab label="Valeurs nutritionnelles" {...a11yProps(2)} className="productmodal__tab" />
                            </Tabs>
                        )}
                        <TabPanel value={value} index={0}>
                            Frites (Frite rouges, Poudre d'oignon, Paprika, Poivre noir, Piment de Cayenne), Poulet (Poitrine de poulet, Sauce
                            Sud-Ouest (Bouillon de poulet sans sodium, Pâte de tomates, Ketchup, Sucre brun, Oignon granulé, Ail, Paprika, Poivre
                            noir, Origan, Poudre de chili, Coriandre, Vinaigre de riz)), Sauce (Eau, Sauce Brune réduite en sodium), Fromage en grains
                            (Fromage mozzarella, partiellement écrémé, Fromage cheddar faible en gras (lait partiellement écrémé, substances laitières
                            modifiées, sel, présure et/ou enzyme microbienne, culture bactérienne, cellulose, chlorure de calcium), Pois verts.
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            Item Two
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            Item Three
                        </TabPanel>
                    </div>
                </DialogContent>
            </Dialog>
        );
    };

    const ProductSwitch = box => {
        switch (type) {
            case "decouverte":
                return windowWidth && windowWidth < 1280 ? (
                    <>
                        <div className="product__container product__container--box" onClick={handleClickOpen}>
                            <img src={image} alt="" className="product__image--centered" />
                            <h5 className="product__title">{title}</h5>
                            <p className="product__subtitle">{subtitle}</p>
                            <button className="button button__cta button__cta--green product__cta" onClick={handleClickOpen}>
                                En savoir plus
                            </button>
                        </div>
                        <ProductDialog />
                    </>
                ) : (
                    <div className="product__container product__container--box">
                        <img src={image} alt="" className="product__image--centered" />
                        <h5 className="product__title">{title}</h5>
                        <p className="product__subtitle">{subtitle}</p>
                        <button className="button button__cta button__cta--green product__cta" onClick={handleClickOpen}>
                            En savoir plus
                        </button>
                        <ProductDialog />
                    </div>
                );
            case "snacks":
                return windowWidth && windowWidth < 1280 ? (
                    <>
                        <div className="product__container product__container--snacks" onClick={handleClickOpen}>
                            <img src={image} alt="" className="product__image--centered" />
                            <h5 className="product__title">{title}</h5>
                            <p className="product__subtitle">{subtitle}</p>
                            <button className="button button__cta button__cta--green product__cta" onClick={handleClickOpen}>
                                En savoir plus
                            </button>
                        </div>
                        <ProductDialog />
                    </>
                ) : (
                    <div className="product__container product__container--snacks">
                        <img src={image} alt="" className="product__image--centered" />
                        <h5 className="product__title">{title}</h5>
                        <p className="product__subtitle">{subtitle}</p>
                        <button className="button button__cta button__cta--green product__cta" onClick={handleClickOpen}>
                            En savoir plus
                        </button>
                        <ProductDialog />
                    </div>
                );
            default:
                if (windowWidth && windowWidth < 1280)
                    return (
                        <>
                            <div
                                onClick={handleClickOpen}
                                className="product__containerBackground"
                                style={{
                                    backgroundImage: "url(" + image + ")",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    objectFit: "cover",
                                    backgroundSize: "cover",
                                }}
                            >
                                <h5 className="title">{title}</h5>
                                <p className="subtitle">{subtitle}</p>
                                <button className="button button__cta button__cta--green cta" onClick={handleClickOpen}>
                                    En savoir plus
                                </button>
                            </div>
                            <ProductDialog />
                        </>
                    );
                else {
                    return (
                        <div
                            className="product__containerBackground"
                            style={{
                                backgroundImage: "url(" + image + ")",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                objectFit: "cover",
                                backgroundSize: "cover",
                            }}
                        >
                            <h5 className="title">{title}</h5>
                            <p className="subtitle">{subtitle}</p>
                            <button className="button button__cta button__cta--green cta" onClick={handleClickOpen}>
                                En savoir plus
                            </button>
                            <ProductDialog />
                        </div>
                    );
                }
        }
    };

    return (
        <div className="product">
            <ProductSwitch box={type} />
        </div>
    );
};

export default Product;
