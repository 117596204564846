import React, { useState } from "react";

// Custom Components
import DesktopMenu from "./desktopMenu";
import MobileMenu from "./mobileMenu";
import { settings } from "../../../settings";
import { getDrawerLogo } from "../../../utils/config";

function MainLayout({ children }) {
    const [width, setWidth] = useState(window.innerWidth);
    const logo = getDrawerLogo();

    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return (
        <div className="layout">
            {/* Display a different drawer based on screen width */}
            {width >= settings.page.maxScreenWidth ? <DesktopMenu logo={logo} /> : <MobileMenu />}
            <main className="main__content">{children}</main>
        </div>
    );
}

export default MainLayout;
