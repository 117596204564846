import React from "react";

// React-router-dom
import { Link } from "react-router-dom";

// React-icons
import { BiPlay } from "react-icons/bi";

// Assets
import cuisiner from "../../../assets/bs/cuisiner.png";

const Engagement = () => {
	return (
		<div className="engagementBanner">
			<div className="engagementBanner__container">
				<div className="engagementBanner__left">
					<p className="engagementBanner__left--section">Notre Engagement</p>
					<h3 className="engagementBanner__left--title">
						Des plats rigoureusement élaborés par notre nutritionniste
					</h3>
					<div className="engagementBanner__left--buttons">
						<Link to="commitment" className="button button__cta button__cta--orange">
							En savoir plus
						</Link>
						<button className="button button__play">
							<BiPlay />
						</button>
					</div>
				</div>
				<div className="engagementBanner__right">
					<img src={cuisiner} alt="" className="engagementBanner__right--image" />
				</div>
			</div>
		</div>
	);
};

export default Engagement;
