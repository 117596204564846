import React from "react";

function Newsletter() {
    return (
        <div className="newsletter">
            <span className="newsletter__title">Restez à l'affût des promotions GoMatcha!</span>
            <span className="newsletter__text">Inscrivez-vous à notre liste d'envoi et profitez de nos nombreuses promotions!</span>
            <div className="klaviyo-form-V8JtFD"></div>
        </div>
    );
}

export default Newsletter;
