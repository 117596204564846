import { useState } from "react";
import { singletonHook } from "react-singleton-hook";

const initState = {
    email: "",
    // eslint-disable-next-line
    setEmail: () => {
        //
    },
};

const useForgotPasswordImpl = () => {
    // States
    const [email, setEmail] = useState("");
    return { email, setEmail };
};

export const useForgotPassword = singletonHook(initState, useForgotPasswordImpl);
