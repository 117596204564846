import React from "react";
import { useHistory } from "react-router-dom";
// Material UI
import { Grid } from "@material-ui/core";
// asset
import Box from '../../../assets/gc/cadeau.png';
// Custom Components
import { fetchProductById } from "../../../utils/apiCalls";
// Redux
import { useDispatch } from "react-redux";
import { addProduct } from "../../../store/reducers/checkout";

function Cadeau() {
    const history = useHistory();
    // Redux
    const dispatch = useDispatch();
    // Products
    const BOITE_CADEAU = "rt7JNST4tzH8WUWiB6Jh";

    return (
        <div className="cadeau" id="Cadeau">
            <Grid container>
                <Grid item xs={12} md={8}>
                    <div className="cadeau__left">
                        <div>
                            <span className="cadeau__left__1">PROMO DU BLACK FRIDAY</span>
                            <span className="cadeau__left__2">COFFRET CADEAU</span>
                            <span className="cadeau__left__3">GO CHOKO!</span>

                            <span className="cadeau__price__container">
                                <span className="cadeau__price">72</span>
                                <sup className="cadeau__price__cents">99$</sup>
                                <sub className="cadeau__price__pack2">
                                    <span>(VALEUR DE 96$)</span>
                                    <span>ÉCONOMISEZ 24$</span>
                                    <span>500 COFFRETS DISPONIBLES</span>
                                </sub>
                            </span>

                            <span className="cadeau__left__4">24 SACHETS MIXTES DE GO CHOKO!</span>
                            <span className="cadeau__left__5">(6 DE CHAQUE SAVEUR)</span>

                            <div className="cadeau__cta">
                                <button onClick={async () => {
                                        const product = await fetchProductById(BOITE_CADEAU);
                                        dispatch(addProduct({ ...product, selectedQuantity: 1, status: "À livrer" }));
                                        history.push(`/checkout`);
                                    }}>Acheter maintenant</button>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div className="cadeau__right">
                        <img src={Box} />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Cadeau;
