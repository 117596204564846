import React from "react";

// React-router-dom
import { Link } from "react-router-dom";

// Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Assets
import meal from "../../../assets/bs/menus/chili.png";

const Header = () => {
    const settings = {
        dots: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: () => (
            <div className="ft-slick__dots--custom">
                <div className="current" />
            </div>
        ),
    };

    const Slide = ({ image, title, subtitle }) => {
        return (
            <div className="slide">
                <img src={image} alt="" className="slide__image" />
                {/* {new ? <>oui</> : <>non</>} */}
                <h5 className="slide__title">{title}</h5>
                <p className="slide__subtitle">{subtitle}</p>
            </div>
        );
    };

    return (
        <div className="header">
            <div className="header__container">
                <h1 className="header__title">
                    Des menus sains
                    <br /> et équilibrés
                </h1>
                <Link to="/order" className="button button__cta button__cta--orange">Débuter ma commande</Link>
                <div className="header__carousel">
                    <Slider appendDots={dots => <ul>{dots}</ul>} autoplay infinite {...settings}>
                        <div>
                            <Slide image={meal} title="Chili végétarien1" subtitle="Sans lactose, Gourmand" />
                        </div>
                        <div>
                            <Slide image={meal} title="Chili végétarien2" subtitle="Sans lactose, Gourmand" />
                        </div>
                        <div>
                            <Slide image={meal} title="Chili végétarien3" subtitle="Sans lactose, Gourmand" />
                        </div>
                    </Slider>
                </div>
            </div>
            <div className="footer__bar"></div>
        </div>
    );
};

export default Header;
