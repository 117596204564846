import React from "react";
import { useHistory } from "react-router-dom";

// custom components
import Breadcrumbs from "../../../shared/Breadcrumbs";

// hooks
import useAlgoliaSearch from "../../../../hooks/useAlgoliaSearch";

const Searchbar = () => {
    const [hits, loading, Searchfield] = useAlgoliaSearch("Products");
    const h = useHistory();

    const handleSearchChange = (_, value, reason) => {
        if (reason !== "select-option") return;
        h.push(`/portail/boutique/${value.id}`);
    };

    return (
        <div className="searchbar">
            <Breadcrumbs basepath="portail" />
            <div className="search">
                <h1>Notre Boutique</h1>
                <Searchfield
                    loading={loading}
                    autocompleteOptions={hits.map(h => ({ label: h.name, img: h.imageURLs ? h.imageURLs[0] : "", id: h.objectID }))}
                    onChange={handleSearchChange}
                />
            </div>
        </div>
    );
};

export default Searchbar;
