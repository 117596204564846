import React from "react";

// Components
import Nav from "./shared/nav";
import Header from "./faq/header";
import Engagement from "./shared/engagement";
import Questions from "./faq/questions";
import Newsletter from "./shared/newsletter";
import Footer from "./shared/footer";

const faq = () => {
    return (
        <div className="bs">
            <div className="faq">
                <Nav />
                <Header />
                <Questions />
                <Engagement />
                <Newsletter />
                <Footer />
            </div>
        </div>
    );
};

export default faq;
