import React from "react";
import Fb from "../../../assets/shared/fb.png";
import Ig from "../../../assets/shared/ig.png";

function Socials() {
    return (
        <div className="socials">
            <div className="socials__container">
                <h3 className="socials__title">
                    SUIVEZ-NOUS SUR LES MÉDIAS SOCIAUX{" "}
                    <span className="socials__icons">
                        <a href="https://www.facebook.com/Aktivation-107573801050278" target="_blank">
                            <img src={Fb} alt="" />
                        </a>
                        <a href="https://www.instagram.com/Aktivation.fit/" target="_blank">
                            <img src={Ig} alt="" />
                        </a>
                    </span>
                </h3>
            </div>
        </div>
    );
}

export default Socials;
