import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Mui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";


const Corpo = ({ open, onClose }) => {
    const history = useHistory();

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="selection-dialog-title" className="corpopop">
            <DialogTitle id="selection-dialog-title" className="corpopop__title">
                Corporatif
            </DialogTitle>
            <DialogContent>
                <p className="corpopop__text">Vous êtes une entreprise qui a à cœur la santé de ses employés? L’équipe d’AKTIVATION rêve de faire partie de votre quotidien. Voici ce qui vous est possible de faire:</p>
                <span className="corpopop__point">- Offrir un des forfaits AKTIVATION à vos employés (Jusqu’à 50% de rabais)</span>
                <span className="corpopop__point">- Engager l’un de nos entraîneurs pour un événement (Présentiel ou en ligne)</span>
                <span className="corpopop__point">- Engager Jimmy comme conférencier en motivation, empowerment, santé</span>
                <p className="corpopop__text">Sachez que nous serions heureux de discuter avec vous de votre projet afin de le concrétiser. Jimmy a donné plus de 1100 conférences et événements à travers le canada et l’équipe AKTIVATION est LA RÉFÉRENCE en termes d’entraînement en ligne du côté francophone.</p>
                <button className="corpopop__cta" onClick={() => history.push("/contact")}>Contactez-nous</button>
            </DialogContent>
        </Dialog>
    );
};

export default Corpo;
