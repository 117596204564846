import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

// Material UI Core
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// Custom Components
import { UserContext } from "../../../../reducer/userContext";
import { getLandingPageInfo } from "../../../../utils/config";
import { settings } from "../../../../settings";

// Assets
import profileIcon from "../../../../assets/shared/nav_profile.svg";
import shopIcon from "../../../../assets/shared/nav_shop.svg";
import workoutIcon from "../../../../assets/shared/nav_workout.svg";
import Favorite from "../../../../assets/shared/nav_favorite.svg";
import { getRequest } from "../../../../actions/requests";

// Redux
import { useDispatch } from "react-redux";
import { snackbarEnqueuedAction, generateKey } from "../../../../store/reducers/snackbars";
import { parseDate } from "../../../../utils/parseData";

/**
 * Displays the user's part of the navigation bar. Does not need any special role.
 */
const TopNav = ({ isMobile = false }) => {
    const dispatch = useDispatch();

    // notifications functions
    const pushNotification = (...args) => dispatch(snackbarEnqueuedAction(...args));

    const { user } = useContext(UserContext);

    const { brand } = getLandingPageInfo();
    const [hasOldAktSub, setHasOldAktSub] = useState(false);

    const baseUrl = "/portail";
    const videos = {
        path: `${baseUrl}${"/videos"}`,
        text: <span>Aktivation</span>,
    };
    const favs = { path: `${baseUrl}${"/favoris"}`, text: "favoris" };
    const recipe = { path: `${baseUrl}${"/recettes"}`, text: "Recettes" };
    const profile = { path: `${baseUrl}${"/profil"}`, text: "Profil" };

    const checkOldAktSub = async () => {
        try {
            // If user had previous AKT subs
            if (user && user.subscriptions && user.subscriptions.some(s => settings.videoAccess.includes(s.id))) {
                throw new Error();
            }

            const oldAktSubPayload = await getRequest(`${settings.urls.aktSubscribedUsers}/${user.email.toLowerCase()}`);
            const oldAktSubUser = oldAktSubPayload.data;

            // Check if oldAktSubUser is valid
            if (!_.isEmpty(oldAktSubUser) && !oldAktSubUser.used) {
                if (parseDate(Date.now()) < parseDate(oldAktSubUser.nextCycle)) {
                    return setHasOldAktSub(true);
                }
            }

            throw new Error();
        } catch (e) {
            console.error(e);
        }
    };

    const displayOldAktSubPopup = () => {
        if (hasOldAktSub && !user.hasVideoAccess) {
            pushNotification({
                message: settings.aktExistingSub,
                options: {
                    key: generateKey(),
                    variant: "success",
                    popup: {
                        title: "Accès vidéo",
                        showImage: false,
                        redirectUrl: "https://aktivation.ca#produits",
                        buttonText: "Activer un forfait",
                    },
                },
            });
        }
    };

    const VideoContent = () => (
        <div className="nav__link__item">
            <ListItem button className="nav__listItem">
                <ListItemIcon>
                    <img src={workoutIcon} alt="" />
                </ListItemIcon>
                {isMobile && <ListItemText className="nav__item__text" primary={videos.text} />}
            </ListItem>
            {!isMobile && <span className="nav__item__text">{videos.text}</span>}
        </div>
    );

    const VideoPath = () => {
        if (user.hasVideoAccess) {
            return (
                <Link to={videos.path} onClick={() => displayOldAktSubPopup()}>
                    <VideoContent />
                </Link>
            );
        } else {
            if (!hasOldAktSub) {
                return (
                    <a href="https://aktivation.ca/" onClick={() => displayOldAktSubPopup()}>
                        <VideoContent />
                    </a>
                );
            } else {
                return (
                    <span onClick={() => displayOldAktSubPopup()}>
                        <VideoContent />
                    </span>
                );
            }
        }
    };

    useEffect(() => {
        checkOldAktSub();
    }, []);

    return (
        <List className="nav__link__container">
            <VideoPath />

            {typeof user.favoriteVideos !== "undefined" && (
                <Link to={favs.path}>
                    <div className="nav__link__item">
                        <ListItem button className="nav__listItem">
                            <ListItemIcon>
                                <img src={Favorite} />
                            </ListItemIcon>
                            {isMobile && <ListItemText className="nav__item__text" primary={favs.text} />}
                        </ListItem>
                        {!isMobile && <span className="nav__item__text">{favs.text}</span>}
                    </div>
                </Link>
            )}
            {/* <Link to="/portail/boutique"> */}
                <a href="https://www.jimmysevigny.com/boutique/aktivation/">
                <div className="nav__link__item">
                    <ListItem button className="nav__listItem">
                        <ListItemIcon>
                            <img src={shopIcon} alt="" />
                        </ListItemIcon>
                        {isMobile && <ListItemText className="nav__item__text" primary="Boutique" />}
                    </ListItem>
                    {!isMobile && <span className="nav__item__text">Boutique</span>}
                </div>
                </a>
            {/* </Link> */}
            {/* <Link to={recipe.path}>
                <div className="nav__link__item">
                    <ListItem button className="nav__listItem">
                        <ListItemIcon>
                            <img src={recipeIcon} alt="" />
                        </ListItemIcon>
                        {isMobile && <ListItemText className="nav__item__text" primary={recipe.text} />}
                    </ListItem>
                    {!isMobile && <span className="nav__item__text">{recipe.text}</span>}
                </div>
            </Link> */}
            <Link to={profile.path}>
                <div className="nav__link__item">
                    <ListItem button className="nav__listItem">
                        <ListItemIcon>
                            <img src={profileIcon} alt="" />
                        </ListItemIcon>
                        {isMobile && <ListItemText className="nav__item__text" primary={profile.text} />}
                    </ListItem>
                    {!isMobile && <span className="nav__item__text">{profile.text}</span>}
                </div>
            </Link>
        </List>
    );
};

export default TopNav;
