import React from "react";

const process = ({ step, create }) => {
    return create ? (
        <div className="process process__create">
            <div className={step == 1 ? "process__item selected" : "process__item"}>
                <p className="process__item--step">1</p>
                <p className="process__item--text">Sélectionner une boîte</p>
            </div>
            <div className={step == 2 ? "process__item selected" : "process__item"}>
                <p className="process__item--step">2</p>
                <p className="process__item--text">Collations & accessoires</p>
            </div>
            <div className={step == 3 ? "process__item selected" : "process__item"}>
                <p className="process__item--step">3</p>
                <p className="process__item--text">Choisir un forfait</p>
            </div>
            <div className={step == 4 ? "process__item selected" : "process__item"}>
                <p className="process__item--step">4</p>
                <p className="process__item--text">Finaliser la commande</p>
            </div>
        </div>
    ) : (
        <div className="process process__try">
            <div className={step == 1 ? "process__item selected" : "process__item"}>
                <p className="process__item--step">1</p>
                <p className="process__item--text">Sélectionner une boîte</p>
            </div>
            <div className={step == 2 ? "process__item selected" : "process__item"}>
                <p className="process__item--step">2</p>
                <p className="process__item--text">Choisir un forfait</p>
            </div>
            <div className={step == 3 ? "process__item selected" : "process__item"}>
                <p className="process__item--step">3</p>
                <p className="process__item--text">Finaliser la commande</p>
            </div>
        </div>
    );
};

export default process;
