import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import Grid from '@material-ui/core/Grid';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import BG from '../../../assets/gk/gokrisp-faq.jpg';

function Faq() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div className="faq" id="faq">
            <div className="faq__container">
                <Grid container>

                    <Grid item sm={12} md={7}>

                        <h3 className="faq__title">La réponse à vos questions</h3>

                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Qu'est-ce que Go Krisp?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Il s’agit d'un carré protéiné prêt à manger à base de PVT (Protéines végétales texturées)  Ces collations ont été développées en collaboration avec des nutritionnistes et des chercheurs.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Que contient Go Krisp?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>1 carré contient 15 grammes de protéines et jusqu'à 12 grammes de fibres. </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>De quelle façon sont livrés les produits?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>En colis standard par Postes Canada</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Le renouvellement est-il obligatoire ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Aucunement. Vous pouvez annuler en tout temps.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Comment modifier mes informations personnelles, renouvellement?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Il suffit de vous connecter via votre profil afin de modifier facilement toutes vos informations.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Quelle est la durée de conservation du Go Krisp?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>18 mois</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Est-ce que les carrés ont un goût de protéines en poudre?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Non car il n'en contient pas</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Est-ce que les enfants peuvent consommer des Go Krisp?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Oui! Toutefois, assurez-vous que votre enfant n’est pas allergique à l’un ou l’autre des ingrédients présents dans les carrés.</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Est-ce que ce produit est certifié sans arachides?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Oui</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Est-ce que le produit contient du gluten ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Non</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Est-ce que ce produit est considéré Vegan?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Oui</Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Est-ce que Go Krisp contient du sucre ajouté?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Oui. Entre 3 à 5 grammes par carré. Cela fait de Go krisp, collation protéinée avec un très bas taux de sucres ajoutés versus son poids (54 grammes)</Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item sm={12} md={1}></Grid>

                    <Grid item md={4}>
                        <img src={BG} className="faq__bg" />
                    </Grid>

                </Grid>
            </div>
        </div>
    );
}

export default Faq;
