import React from "react";
import { FaShippingFast } from 'react-icons/fa';
import Image from "../../../assets/gk/gokrisp-shipping.png";
import Logo from "../../../assets/gk/logo-gokrisp.png";

function Shipping() {
    return (
        <div className="shipping">
            <div className="shipping__container">
                <img className="shipping__pack" src={Image} />
                <img className="shipping__logo__fake" src={Logo} />
                <h3 className="shipping__title">Livraison gratuite! <FaShippingFast /></h3>
                <img className="shipping__logo" src={Logo} />
            </div>
        </div>
    );
}

export default Shipping;
