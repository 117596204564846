import { formatPrice } from "./parseData";

export const getItemPrice = (item, orderItem, fixed = true) => {
    if (typeof orderItem?.price === "number") return formatPrice(orderItem.price, fixed);
    if (typeof item.price === "number") return formatPrice(item.price, fixed);
    if (typeof item.price !== "undefined") return formatPrice(item.price.amount, fixed);
    if (item.rate) return formatPrice(item.rate.amount, fixed);
};

export const isTaxable = product => {
    return product.hasProvincialTax || product.hasFederalTax;
};
