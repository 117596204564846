import React, { useCallback, useState, useRef, forwardRef } from "react";

// Mui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// Mui icons
import CloseIcon from "@material-ui/icons/Close";

const Transition = forwardRef((props, ref) => {
    return <Slide ref={ref} direction={props.direction} {...props} />;
});

export const Modal = ({ title, contentText, buttons = [], children, transitionDirection, ...dialogProps }) => {
    if (transitionDirection) {
        dialogProps.TransitionComponent = props => <Transition {...props} direction={dialogProps.transitionDirection} />;
    }
    return (
        <Dialog {...dialogProps}>
            {dialogProps.fullScreen ? (
                <AppBar color="secondary" style={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton onClick={dialogProps.onClose}>
                            <CloseIcon />
                        </IconButton>
                        {title && <h3>{title}</h3>}
                    </Toolbar>
                </AppBar>
            ) : (
                title && <DialogTitle>{title}</DialogTitle>
            )}
            <DialogContent>
                {contentText && <DialogContentText>{contentText}</DialogContentText>}
                {children}
            </DialogContent>
            {buttons.length > 0 && (
                <DialogActions>
                    {buttons.map(({ text, ...rest }) => (
                        <Button
                            key={text}
                            {...rest}
                            onClick={() => {
                                if (typeof rest.onClick === "function") return rest.onClick();
                                dialogProps.onClose();
                            }}
                        >
                            {text}
                        </Button>
                    ))}
                </DialogActions>
            )}
        </Dialog>
    );
};

const useModal = () => {
    const [_open, setOpen] = useState(false);

    const _title = useRef("");
    const _contentText = useRef("");
    const _buttons = useRef([]);
    const _transition = useRef(null);
    const _fullScreen = useRef(false);
    const _preventClose = useRef(false);

    const present = config => {
        setOpen(true);
        if (!config) return;

        _title.current = config.title;
        _contentText.current = config.contentText;
        _buttons.current = config.buttons;
        _transition.current = config.transitionDirection;
        _fullScreen.current = config.fullScreen;
        _preventClose.current = config.preventClose;
    };

    const dismiss = callback => {
        setOpen(false);
        if (typeof callback === "function") callback();
    };

    const component = useCallback(
        ({ children, buttons = [], onClose }) => (
            <Modal
                fullScreen={_fullScreen.current}
                open={_open}
                onClose={() => {
                    if (typeof onClose === "function") onClose();
                    if (!_preventClose.current) dismiss();
                }}
                title={_title.current}
                contentText={_contentText.current}
                buttons={buttons.length ? buttons : _buttons.current}
                transitionDirection={_transition.current}
            >
                {children}
            </Modal>
        ),
        [_open]
    );

    return [component, present, dismiss];
};

export default useModal;
