import React from "react";
// Material UI
import { Grid } from "@material-ui/core";
// Assets
import Background from "../../../assets/akt/what-bg.png";

function What() {
    return (
        <div className="what">
            <Grid container>
                <Grid item xs={12} lg={4}>
                    <div className="what__container">
                        <span className="what__1">AKTIVATION</span>
                        <span className="what__2">C'EST:</span>

                        <span className="what__points">- Plus de 100 entraînements</span>
                        <span className="what__points">- Des vidéos de qualités supérieures</span>
                        <span className="what__points">- 3 à 4 nouveaux programmes par année garanti!</span>
                        <span className="what__points">- Des recettes</span>
                        <span className="what__points">- Des plans alimentaires</span>
                        <span className="what__points">- Des planifications d'entraînement</span>
                        <a href="./#produits">
                            <button className="what__cta">JE M'INSCRIS</button>
                        </a>
                    </div>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <img src={Background} alt="" />
                </Grid>
            </Grid>
        </div>
    );
}

export default What;
