import React from 'react'

const Header = () => {
  return (
		<div className="header">
			<div className="header__container">
        <p className="header__section">Comment ça marche</p>
				<h1 className="header__title">Notre concept est<br /> simple comme tout</h1>
			</div>
		</div>
  )
}

export default Header