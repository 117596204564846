import React from "react";

const Promo = () => {
    return (
        <div className="promo">
            <p className="promo__text">
                Bénéficiez de <b>25% de rabais</b> sur votre première commande avec le code : <b>WELCOME</b>
            </p>
        </div>
    );
};

export default Promo;
