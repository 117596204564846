import React from "react";

// Assets
import jimmy from "../../../assets/bs/team-jimmy.png";
import stephanie from "../../../assets/bs/team-stephanie.png";
import boite from "../../../assets/bs/logo-icon.png";

const Team = () => {
    return (
        <div className="team">
            <img src={boite} alt="" />
            <h3 className="team__title">Notre équipe</h3>
            <div className="team__content">
                <div className="team__content--item">
                    <img src={jimmy} alt="" />
                    <h3 className="name">Jimmy Sévigny</h3>
                    <p className="position">Entraîneur / B. Science de l'activité physique</p>
                    <p className="text">
                        Autrefois obèse morbide, Jimmy a frôlé la mort de près. En effet, c'est à l'âge de 19 ans alors qu'il pesait 452 lb qu'il a
                        compris que nos habitudes de vie étaient responsables de notre vitalité et du même coup, de notre bonheur personnel.
                        Aujourd'hui, avec tout près de 300 lb en moins, un baccalauréat en activité physique en poche et son désir d'aider les gens à
                        poursuivre leur chemin vers la santé, il est plus que fier de vous présenter sa nouvelle gamme de plats santé!
                    </p>
                </div>
                <div className="team__content--item">
                    <img src={stephanie} alt="" />
                    <h3 className="name">Stéphanie Ferland</h3>
                    <p className="position">Nutritionniste</p>
                    <p className="text">
                        Travaillant avec Jimmy et son équipe depuis maintenant 5 ans, Stéphanie a collaboré à la réalisation de plusieurs ouvrages en
                        lien avec la santé dont notamment des livres de cuisine, des plan alimentaires spécifiques ainsi que plusieurs recettes.
                        Poursuivant religieusement ses formations continues en alimentation, elle se tient au courant des nouvelles tendances et des
                        derniers concepts alimentaires afin de bien vous guider au travers des plats que nous vous proposons en s'assurant que chacun
                        d'entre eux soit bien balancés.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Team;
