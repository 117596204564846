import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Material UI Core
import Grid from "@material-ui/core/Grid";
// Assets
import Citron from "../../../assets/gm/citron.png";
import Framboise from "../../../assets/gm/framboise.png";
import Saveurs from "../../../assets/gm/saveurs.png";
import Bleuet from "../../../assets/gm/bleuet.png";
import Chai from "../../../assets/gm/chai.png";
import Peche from "../../../assets/gm/peche.png";
import Nature from "../../../assets/gm/original.png";
import Five from "../../../assets/gm/five.png";
import NV from "../../../assets/gm/NF_GoMatcha.png";
import NV_OG from "../../../assets/gm/Nutritionfactmatcha.jpg";

import Daiquiri from "../../../assets/gm/daiquiri.png";
import Pina from "../../../assets/gm/pina.png";
import Lemonade from "../../../assets/gm/lemonade.png";
import Watermelon from "../../../assets/gm/watermelon.png";
import Mojito from "../../../assets/gm/mojito.png";
import Pie from "../../../assets/gm/pie.png";

import Vanille from "../../../assets/gm/vanille.png";
import Bonbon from "../../../assets/gm/bonbon.png";
import Erable from "../../../assets/gm/erable.png";

import Fraisekiwi from "../../../assets/gm/fraise-kiwi.png";
import Fruitpassion from "../../../assets/gm/fruit-passion.png";
import Litchi from "../../../assets/gm/litchi.png";
import Mandarine from "../../../assets/gm/mandarine.png";
import Mangue from "../../../assets/gm/mangue.png";

import Promo from "../../../assets/gm/summer-promo.jpg";
import PromoMobile from "../../../assets/gm/summer-promo.jpg";

import Promo2 from "../../../assets/gm/mini-erable.jpg";
import PromoMobile2 from "../../../assets/gm/mini-erable.jpg";

// Custom components
import ProductTypeDialog from "./productSelectionDialog";
import { fetchProductById } from "../../../utils/apiCalls";
import useModal from "../../../hooks/useModal";
import Skus from "../../portal/boutique/Product/Skus";
// Redux
import { useDispatch } from "react-redux";
import { addProduct } from "../../../store/reducers/checkout";
import { addProduct as addProductAction } from "../../../store/reducers/checkout";

function Products() {
    // Products
    const CITRON_GINGEMBRE = "kAgldtpnT8mxWkEkGhZi";
    const FRAMBOISE = "JtSYMeJ9Zxw152ZDoJRB";
    const BLEUET_CANNEBERGE = "X6Tjd9BgvLQpSSYE1nGt";
    const CHAI = "Cp8Q2YsKgjBBAEJQAuWz";
    const MELANGE = "bgK9NUY1REaNOs5jc3aQ";
    const ORIGINAL = "aTs1VgPZlOipr9DQz5VX";
    const PECHE = "Z6JDbUpTZzDtFEVYiAg4";
    const FIVEBOITES = "G6ifNgXnArVGHXC0S6P9";

    const DAIQUIRI = "RyZnLd9H0WrVKjruscHc";
    const PINA = "o6OJE7ajMFEzFMF3fgtz";
    const LEMONADE = "OymgB604ETBaaySlFmZS";
    const WATERMELON = "LxW2dA9I7b3lbdHaigu0";
    const MOJITO = "JTY7mXFv4hIpxjEmAqlR";
    const PIE = "mRSBh5d6zxiMQLxPk8eH";

    const VANILLE = "vPcE321nAeQcZU0UOWhS";
    const BONBON = "gx7ZtPTDwJy1yOaoHTJs";

    const ERABLE = "Le3IWeixdoCZjyFYPoI5";

    const FRAISE_KIWI = "I0qXmtld0cFzlSuFpIxC";
    const FRUIT_PASSION = "hRV3g4WDj9WmlXL47a7d";
    const LITCHI = "syLnMTPm8OYZf0PqYZDj";
    const MANDARINE = "ZWhyArWBd5obgXMStPjG";
    const MANGUE = "1VehJyM1D1F4TfEhalZ3";

    // Subs
    const SUB_CITRON_GINGEMBRE_1 = "dm4ORaAqvNweQELGEXQ6";
    const SUB_CITRON_GINGEMBRE_2 = "QMspR1U3G6z1YqZBaQL6";
    const SUB_FRAMBOISE_1 = "WBVdoqgDzKz5MTAcslhI";
    const SUB_FRAMBOISE_2 = "u4BXADInKQxhmi6qAwiv";
    const SUB_BLEUET_1 = "RU5DVz9z1CCpjubAhuRw";
    const SUB_BLEUET_2 = "3HRcwyesYRdiPWXsQMs8";
    const SUB_CHAI_1 = "P3hCDnU6UyljCRqDNZwZ";
    const SUB_CHAI_2 = "KgycEsSvseOIF3jsx7bN";
    const SUB_ORIGINAL_1 = "kXoeCiythQgC11uSK5gJ";
    const SUB_ORIGINAL_2 = "vKjxxcRHmo9aNGCZbFYI";
    const SUB_PECHE_1 = "isvzRuXVM0oNdRonGGNn";
    const SUB_PECHE_2 = "APCXQbD25laf6zPXoZKu";
    const SUB_MELANGE_1 = "2gnCpInHEH3M6UjOtslx";
    const SUB_MELANGE_2 = "HuGzO8na4JEPh85O7rBk";

    const SUB_DAIQUIRI_1 = "g57ChSqokxtWdKZxJFyh";
    const SUB_DAIQUIRI_2 = "WxAeoICxHb7mMGWCvFCQ";
    const SUB_PINA_1 = "mkuoZzW2dr1D8fcVY73J";
    const SUB_PINA_2 = "QXVJsj1r7y8vHmQMXO8J";
    const SUB_LEMONADE_1 = "AxYZQQVzyW3qUqGHGTiw";
    const SUB_LEMONADE_2 = "5qYGo0yfVzSpWEs3FpqE";
    const SUB_WATERMELON_1 = "iFsCCfHrsXIVN2IqyVA2";
    const SUB_WATERMELON_2 = "8FVTTf38ePhJ3BP5zhC4";
    const SUB_MOJITO_1 = "8Ow50k7QfPxWf5HEsWRf";
    const SUB_MOJITO_2 = "Qauu1eLqiKXo6Nv6afSU";
    const SUB_PIE_1 = "QcbzCn0keGsMo5dMgZd6";
    const SUB_PIE_2 = "uVhKPgjolPrz8x3aFf39";

    const SUB_VANILLE_1 = "zaQW8ECxbdiQKgSrto9J";
    const SUB_VANILLE_2 = "q2DlUafk0miZhmKiq0rb";
    const SUB_BONBON_1 = "szPyoMSiHe4qjUDvj71w";
    const SUB_BONBON_2 = "lUNcrHo6eSqWCaiQRzfT";

    // states
    const [openDialog, setOpenDialog] = useState(false);
    const [items, setItems] = useState({});

    const [selectionProduct, setSelectionProduct] = useState(null);

    const handleOpenDialog = () => setOpenDialog(true);

    const handleCloseDialog = () => setOpenDialog(false);

    const history = useHistory();
    // Redux
    const dispatch = useDispatch();
    // Products
    const BOITE_CADEAU = "hYPkuXExL29iUUkMSvxN";
    const COFFRET_ERABLE = "04oQ4SAy8ybp00EqtyTe";
    const MINI_ERABLE = "dYDjife4wdBTPTm1gnid";
    const SUMMER_PROMO = "Izggyu9sWFvdQPu84fhC";

    useEffect(async () => {
        const product = await fetchProductById(SUMMER_PROMO);
        setSelectionProduct(product);
    }, []);

    const addProduct = product => {
        const status = product.isPhysical ? "À livrer" : "Expédié";
        dispatch(addProductAction({ ...product, selectedQuantity: 1, status }));
    };

    const onContinueSummerPromo = async () => {
        const product = await fetchProductById(SUMMER_PROMO);
        addProduct({ ...product, selectedQuantity: 1, status: "À livrer" });
        history.push(`/checkout`);
    };

    return (
        <>
            {/* FEATURED ITEM */}
            {selectionProduct && (
                <Grid item xs={12} className="promo__desktop" style={{ cursor: "pointer" }} onClick={onContinueSummerPromo}>
                    <img src={Promo} alt="" />
                </Grid>
            )}

            {selectionProduct && (
                <Grid item xs={12} className="promo__mobile" style={{ cursor: "pointer" }} onClick={onContinueSummerPromo}>
                    <img src={PromoMobile} alt="" />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </Grid>
            )}

            {/* FEATURED ITEM 2 */}

            {/* <Grid item xs={12} className="promo__desktop" style={{ cursor: "pointer" }} onClick={async () => {
                                        const product = await fetchProductById(MINI_ERABLE);
                                        dispatch(addProduct({ ...product, selectedQuantity: 1, status: "À livrer" }));
                                        history.push(`/checkout`);
                                    }}>
            <img src={Promo2} alt="" />
        </Grid>

        <Grid item xs={12} className="promo__mobile" style={{ cursor: "pointer" }} onClick={async () => {
                                        const product = await fetchProductById(MINI_ERABLE);
                                        dispatch(addProduct({ ...product, selectedQuantity: 1, status: "À livrer" }));
                                        history.push(`/checkout`);
                                    }}>
            <img src={PromoMobile2} alt="" />
            <br />
            <br />
            <br />
            <br />
            <br />
        </Grid> */}

            <div className="products" id="produits">
                <div className="products__container">
                    <h3 className="products__title">produits</h3>
                    <Grid container>
                        <Grid item sm={12} md={6} lg={4}>
                            <div
                                className="products__item clickable__tag"
                                onClick={() => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: FRAISE_KIWI,
                                            isProduct: true,
                                        },
                                    });
                                }}
                            >
                                <div className="products__item__info">
                                    <span className="products__item__name">fraise & kiwi</span>
                                    <span className="products__item__disclaimer">À partir de</span>
                                    <span className="products__item__price">29,99$</span>
                                </div>
                                <div className="products__item__image">
                                    <img src={Fraisekiwi} alt="" />
                                </div>
                                <div className="products__item__cta">
                                    <button>Achetez maintenant</button>
                                </div>
                            </div>
                        </Grid>

                        <Grid item sm={12} md={6} lg={4}>
                            <div
                                className="products__item clickable__tag"
                                onClick={() => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: FRUIT_PASSION,
                                            isProduct: true,
                                        },
                                    });
                                }}
                            >
                                <div className="products__item__info">
                                    <span className="products__item__name">fruit de la passion</span>
                                    <span className="products__item__disclaimer">À partir de</span>
                                    <span className="products__item__price">29,99$</span>
                                </div>
                                <div className="products__item__image">
                                    <img src={Fruitpassion} alt="" />
                                </div>
                                <div className="products__item__cta">
                                    <button>Achetez maintenant</button>
                                </div>
                            </div>
                        </Grid>

                        <Grid item sm={12} md={6} lg={4}>
                            <div
                                className="products__item clickable__tag"
                                onClick={() => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: LITCHI,
                                            isProduct: true,
                                        },
                                    });
                                }}
                            >
                                <div className="products__item__info">
                                    <span className="products__item__name">litchi</span>
                                    <span className="products__item__disclaimer">À partir de</span>
                                    <span className="products__item__price">29,99$</span>
                                </div>
                                <div className="products__item__image">
                                    <img src={Litchi} alt="" />
                                </div>
                                <div className="products__item__cta">
                                    <button>Achetez maintenant</button>
                                </div>
                            </div>
                        </Grid>

                        <Grid item sm={12} md={6} lg={4}>
                            <div
                                className="products__item clickable__tag"
                                onClick={() => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: MANDARINE,
                                            isProduct: true,
                                        },
                                    });
                                }}
                            >
                                <div className="products__item__info">
                                    <span className="products__item__name">mandarine & baies</span>
                                    <span className="products__item__disclaimer">À partir de</span>
                                    <span className="products__item__price">29,99$</span>
                                </div>
                                <div className="products__item__image">
                                    <img src={Mandarine} alt="" />
                                </div>
                                <div className="products__item__cta">
                                    <button>Achetez maintenant</button>
                                </div>
                            </div>
                        </Grid>

                        <Grid item sm={12} md={6} lg={4}>
                            <div
                                className="products__item clickable__tag"
                                onClick={() => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: MANGUE,
                                            isProduct: true,
                                        },
                                    });
                                }}
                            >
                                <div className="products__item__info">
                                    <span className="products__item__name">mangue</span>
                                    <span className="products__item__disclaimer">À partir de</span>
                                    <span className="products__item__price">29,99$</span>
                                </div>
                                <div className="products__item__image">
                                    <img src={Mangue} alt="" />
                                </div>
                                <div className="products__item__cta">
                                    <button>Achetez maintenant</button>
                                </div>
                            </div>
                        </Grid>

                        {/* <Grid item sm={12} md={6} lg={4}>
                    <div
                        className="products__item clickable__tag"
                        onClick={() => {
                            handleOpenDialog();
                            setItems({
                                product: {
                                    id: VANILLE,
                                    isProduct: true,
                                },
                                sub1: {
                                    id: SUB_VANILLE_1,
                                    isProduct: false,
                                },
                                sub2: {
                                    id: SUB_VANILLE_2,
                                    isProduct: false,
                                },
                            });
                        }}
                    >
                        <div className="products__item__info">
                            <span className="products__item__name">vanille festive</span>
                            <span className="products__item__disclaimer">À partir de</span>
                            <span className="products__item__price">24,99$</span>
                        </div>
                        <div className="products__item__image">
                            <img src={Vanille} alt="" />
                        </div>
                        <div className="products__item__cta">
                            <button>Achetez maintenant</button>
                        </div>
                    </div>
                </Grid> */}

                        {/* <Grid item sm={12} md={6} lg={4}>
                    <div
                        className="products__item clickable__tag"
                        onClick={() => {
                            handleOpenDialog();
                            setItems({
                                product: {
                                    id: BONBON,
                                    isProduct: true,
                                },
                                sub1: {
                                    id: SUB_BONBON_1,
                                    isProduct: false,
                                },
                                sub2: {
                                    id: SUB_BONBON_2,
                                    isProduct: false,
                                },
                            });
                        }}
                    >
                        <div className="products__item__info">
                            <span className="products__item__name">canne de bonbons</span>
                            <span className="products__item__disclaimer">À partir de</span>
                            <span className="products__item__price">24,99$</span>
                        </div>
                        <div className="products__item__image">
                            <img src={Bonbon} alt="" />
                        </div>
                        <div className="products__item__cta">
                            <button>Achetez maintenant</button>
                        </div>
                    </div>
                </Grid> */}

                        {/* <Grid item sm={12} md={6} lg={4}>
                        <div
                            className="products__item clickable__tag"
                            onClick={() => {
                                handleOpenDialog();
                                setItems({
                                    product: {
                                        id: PIE,
                                        isProduct: true,
                                    },
                                    sub1: {
                                        id: SUB_PIE_1,
                                        isProduct: false,
                                    },
                                    sub2: {
                                        id: SUB_PIE_2,
                                        isProduct: false,
                                    },
                                });
                            }}
                        >
                            <div className="products__item__info">
                                <span className="products__item__name">tarte aux pommes</span>
                                <span className="products__item__disclaimer">À partir de</span>
                                <span className="products__item__price">24,99$</span>
                            </div>
                            <div className="products__item__image">
                                <img src={Pie} alt="" />
                            </div>
                            <div className="products__item__cta">
                                <button>Achetez maintenant</button>
                            </div>
                        </div>
                    </Grid> */}

                        {/* <Grid item sm={12} md={6} lg={4}>
                        <div
                            className="products__item clickable__tag"
                            onClick={() => {
                                handleOpenDialog();
                                setItems({
                                    product: {
                                        id: LEMONADE,
                                        isProduct: true,
                                    },
                                    sub1: {
                                        id: SUB_LEMONADE_1,
                                        isProduct: false,
                                    },
                                    sub2: {
                                        id: SUB_LEMONADE_2,
                                        isProduct: false,
                                    },
                                });
                            }}
                        >
                            <div className="products__item__info">
                                <span className="products__item__name">limonade rose</span>
                                <span className="products__item__disclaimer">À partir de</span>
                                <span className="products__item__price">24,99$</span>
                            </div>
                            <div className="products__item__image">
                                <img src={Lemonade} alt="" />
                            </div>
                            <div className="products__item__cta">
                                <button>Achetez maintenant</button>
                            </div>
                        </div>
                    </Grid> */}

                        {/* <Grid item sm={12} md={6} lg={4}>
                        <div
                            className="products__item clickable__tag"
                            onClick={() => {
                                handleOpenDialog();
                                setItems({
                                    product: {
                                        id: DAIQUIRI,
                                        isProduct: true,
                                    },
                                    sub1: {
                                        id: SUB_DAIQUIRI_1,
                                        isProduct: false,
                                    },
                                    sub2: {
                                        id: SUB_DAIQUIRI_2,
                                        isProduct: false,
                                    },
                                });
                            }}
                        >
                            <div className="products__item__info">
                                <span className="products__item__name">daiquiri aux fraises</span>
                                <span className="products__item__disclaimer">À partir de</span>
                                <span className="products__item__price">24,99$</span>
                            </div>
                            <div className="products__item__image">
                                <img src={Daiquiri} alt="" />
                            </div>
                            <div className="products__item__cta">
                                <button>Achetez maintenant</button>
                            </div>
                        </div>
                    </Grid> */}

                        {/* <Grid item sm={12} md={6} lg={4}>
                        <div
                            className="products__item clickable__tag"
                            onClick={() => {
                                handleOpenDialog();
                                setItems({
                                    product: {
                                        id: MOJITO,
                                        isProduct: true,
                                    },
                                    sub1: {
                                        id: SUB_MOJITO_1,
                                        isProduct: false,
                                    },
                                    sub2: {
                                        id: SUB_MOJITO_2,
                                        isProduct: false,
                                    },
                                });
                            }}
                        >
                            <div className="products__item__info">
                                <span className="products__item__name">mojito</span>
                                <span className="products__item__disclaimer">À partir de</span>
                                <span className="products__item__price">24,99$</span>
                            </div>
                            <div className="products__item__image">
                                <img src={Mojito} alt="" />
                            </div>
                            <div className="products__item__cta">
                                <button>Achetez maintenant</button>
                            </div>
                        </div>
                    </Grid> */}

                        {/* <Grid item sm={12} md={6} lg={4}>
                        <div
                            className="products__item clickable__tag"
                            onClick={() => {
                                handleOpenDialog();
                                setItems({
                                    product: {
                                        id: PINA,
                                        isProduct: true,
                                    },
                                    sub1: {
                                        id: SUB_PINA_1,
                                        isProduct: false,
                                    },
                                    sub2: {
                                        id: SUB_PINA_2,
                                        isProduct: false,
                                    },
                                });
                            }}
                        >
                            <div className="products__item__info">
                                <span className="products__item__name">pina colada</span>
                                <span className="products__item__disclaimer">À partir de</span>
                                <span className="products__item__price">24,99$</span>
                            </div>
                            <div className="products__item__image">
                                <img src={Pina} alt="" />
                            </div>
                            <div className="products__item__cta">
                                <button>Achetez maintenant</button>
                            </div>
                        </div>
                    </Grid> */}

                        <Grid item sm={12} md={6} lg={4}>
                            <div
                                className="products__item clickable__tag"
                                onClick={() => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: WATERMELON,
                                            isProduct: true,
                                        },
                                        sub1: {
                                            id: SUB_WATERMELON_1,
                                            isProduct: false,
                                        },
                                        sub2: {
                                            id: SUB_WATERMELON_2,
                                            isProduct: false,
                                        },
                                    });
                                }}
                            >
                                <div className="products__item__info">
                                    <span className="products__item__name">melon d'eau</span>
                                    <span className="products__item__disclaimer">À partir de</span>
                                    <span className="products__item__price">24,99$</span>
                                </div>
                                <div className="products__item__image">
                                    <img src={Watermelon} alt="" />
                                </div>
                                <div className="products__item__cta">
                                    <button>Achetez maintenant</button>
                                </div>
                            </div>
                        </Grid>

                        <Grid item sm={12} md={6} lg={4}>
                            <div
                                className="products__item clickable__tag"
                                onClick={() => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: CITRON_GINGEMBRE,
                                            isProduct: true,
                                        },
                                        sub1: {
                                            id: SUB_CITRON_GINGEMBRE_1,
                                            isProduct: false,
                                        },
                                        sub2: {
                                            id: SUB_CITRON_GINGEMBRE_2,
                                            isProduct: false,
                                        },
                                    });
                                }}
                            >
                                <div className="products__item__info">
                                    <span className="products__item__name">citron & gingembre</span>
                                    <span className="products__item__disclaimer">À partir de</span>
                                    <span className="products__item__price">24,99$</span>
                                </div>
                                <div className="products__item__image">
                                    <img src={Citron} alt="" />
                                </div>
                                <div className="products__item__cta">
                                    <button>Achetez maintenant</button>
                                </div>
                            </div>
                        </Grid>

                        <Grid item sm={12} md={6} lg={4}>
                            <div
                                className="products__item clickable__tag"
                                onClick={() => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: FRAMBOISE,
                                            isProduct: true,
                                        },
                                        sub1: {
                                            id: SUB_FRAMBOISE_1,
                                            isProduct: false,
                                        },
                                        sub2: {
                                            id: SUB_FRAMBOISE_2,
                                            isProduct: false,
                                        },
                                    });
                                }}
                            >
                                <div className="products__item__info">
                                    <span className="products__item__name">framboise</span>
                                    <span className="products__item__disclaimer">À partir de</span>
                                    <span className="products__item__price">24,99$</span>
                                </div>
                                <div className="products__item__image">
                                    <img src={Framboise} alt="" />
                                </div>
                                <div className="products__item__cta">
                                    <button>Achetez maintenant</button>
                                </div>
                            </div>
                        </Grid>

                        <Grid item sm={12} md={6} lg={4}>
                            <div
                                className="products__item clickable__tag"
                                onClick={() => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: BLEUET_CANNEBERGE,
                                            isProduct: true,
                                        },
                                        sub1: {
                                            id: SUB_BLEUET_1,
                                            isProduct: false,
                                        },
                                        sub2: {
                                            id: SUB_BLEUET_2,
                                            isProduct: false,
                                        },
                                    });
                                }}
                            >
                                <div className="products__item__info">
                                    <span className="products__item__name">bleuet & canneberge</span>
                                    <span className="products__item__disclaimer">À partir de</span>
                                    <span className="products__item__price">24,99$</span>
                                </div>
                                <div className="products__item__image">
                                    <img src={Bleuet} alt="" />
                                </div>
                                <div className="products__item__cta">
                                    <button>Achetez maintenant</button>
                                </div>
                            </div>
                        </Grid>

                        <Grid item sm={12} md={6} lg={4}>
                            <div
                                className="products__item clickable__tag"
                                onClick={() => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: CHAI,
                                            isProduct: true,
                                        },
                                        sub1: {
                                            id: SUB_CHAI_1,
                                            isProduct: false,
                                        },
                                        sub2: {
                                            id: SUB_CHAI_2,
                                            isProduct: false,
                                        },
                                    });
                                }}
                            >
                                <div className="products__item__info">
                                    <span className="products__item__name">chai</span>
                                    <span className="products__item__disclaimer">À partir de</span>
                                    <span className="products__item__price">24,99$</span>
                                </div>
                                <div className="products__item__image">
                                    <img src={Chai} alt="" />
                                </div>
                                <div className="products__item__cta">
                                    <button>Achetez maintenant</button>
                                </div>
                            </div>
                        </Grid>

                        <Grid item sm={12} md={6} lg={4}>
                            <div
                                className="products__item clickable__tag"
                                onClick={() => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: PECHE,
                                            isProduct: true,
                                        },
                                        sub1: {
                                            id: SUB_PECHE_1,
                                            isProduct: false,
                                        },
                                        sub2: {
                                            id: SUB_PECHE_2,
                                            isProduct: false,
                                        },
                                    });
                                }}
                            >
                                <div className="products__item__info">
                                    <span className="products__item__name">pêche</span>
                                    <span className="products__item__disclaimer">À partir de</span>
                                    <span className="products__item__price">24,99$</span>
                                </div>
                                <div className="products__item__image">
                                    <img src={Peche} alt="" />
                                </div>
                                <div className="products__item__cta">
                                    <button>Achetez maintenant</button>
                                </div>
                            </div>
                        </Grid>

                        <Grid item sm={12} md={6} lg={4}>
                            <div
                                className="products__item clickable__tag"
                                onClick={() => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: MELANGE,
                                            isProduct: true,
                                        },
                                        sub1: {
                                            id: SUB_MELANGE_1,
                                            isProduct: false,
                                        },
                                        sub2: {
                                            id: SUB_MELANGE_2,
                                            isProduct: false,
                                        },
                                        images: [Saveurs, NV],
                                    });
                                }}
                            >
                                <div className="products__item__info">
                                    <span className="products__item__name">variées</span>
                                    <span className="products__item__disclaimer">À partir de</span>
                                    <span className="products__item__price">25,49$</span>
                                </div>
                                <div className="products__item__image">
                                    <img src={Saveurs} alt="" />
                                </div>
                                <div className="products__item__cta">
                                    <button>Achetez maintenant</button>
                                </div>
                            </div>
                        </Grid>

                        <Grid item sm={12} md={6} lg={4}>
                            <div
                                className="products__item clickable__tag"
                                onClick={() => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: FIVEBOITES,
                                            isProduct: true,
                                        },
                                        images: [Five, NV],
                                    });
                                }}
                            >
                                <div className="products__item__info">
                                    <span className="products__item__name">aromatisé 5 boîtes</span>
                                    <span className="products__item__disclaimer">À partir de</span>
                                    <span className="products__item__price">109,95$</span>
                                </div>
                                <div className="products__item__image">
                                    <img src={Five} alt="" />
                                </div>
                                <div className="products__item__cta">
                                    <button>Achetez maintenant</button>
                                </div>
                            </div>
                        </Grid>

                        <Grid item sm={12} md={6} lg={4}>
                            <div
                                className="products__item clickable__tag"
                                onClick={() => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: ORIGINAL,
                                            isProduct: true,
                                        },
                                        sub1: {
                                            id: SUB_ORIGINAL_1,
                                            isProduct: false,
                                        },
                                        sub2: {
                                            id: SUB_ORIGINAL_2,
                                            isProduct: false,
                                        },
                                    });
                                }}
                            >
                                <div className="products__item__info">
                                    <span className="products__item__name">original</span>
                                    <span className="products__item__disclaimer">À partir de</span>
                                    <span className="products__item__price">21,24$</span>
                                </div>
                                <div className="products__item__image">
                                    <img src={Nature} alt="" />
                                </div>
                                <div className="products__item__cta">
                                    <button>Achetez maintenant</button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <ProductTypeDialog open={openDialog} onClose={handleCloseDialog} items={items} />
            </div>
        </>
    );
}

export default Products;
