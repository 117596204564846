import { useState, useEffect } from "react";
import { getRequest } from "../actions/requests";

// for now, only calls to our backend
const useFetch = (url, external = false) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchFn = external ? fetch : getRequest;

    useEffect(() => {
        refetch();
    }, []);

    const refetch = () => {
        setLoading(true);
        fetchFn(url)
            .then(payload => {
                setData(payload.data);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return [data, loading, error, refetch];
};

export default useFetch;
