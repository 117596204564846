import React from "react";

// Components
import Nav from "./shared/nav";
import Header from "./commitment/header";
import Discover from "./shared/discover";
import Making from "./commitment/making";
import Footprint from "./commitment/footprint";
import Testimonials from "./shared/testimonials";
import Partners from "./commitment/partners";
import Banner from "./shared/banner";
import Newsletter from "./shared/newsletter";
import Footer from "./shared/footer";

const commitment = () => {
    return (
        <div className="bs">
            <div className="commitment">
                <Nav />
                <Header />
                <Discover />
                <Making />
                <Footprint />
                <Testimonials />
                <Partners />
                <Banner />
                <Newsletter />
                <Footer />
            </div>
        </div>
    );
};

export default commitment;
