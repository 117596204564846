import React from "react";

const Newsletter = () => {
	return (
		<div className="newsletter">
			<div className="newsletter__container">
				<p className="newsletter__section">Infolettre</p>
				<h4 className="newsletter__title">
					Abonnez-vous à notre infolettre et obtenez 10% de rabais immédiat
				</h4>
				<div className="klaviyo-form-Y9qA9R"></div>
			</div>
		</div>
	);
};

export default Newsletter;
