import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import parse from "html-react-parser";
// Material UI Core
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// Custom Components
import placeholderImage from "../../../assets/shared/placeholder.jpg";
import { GetPortalHelmet, GetNavbar, getLandingPageInfo } from "../../../utils/config";
// DB
import { getRequest } from "../../../actions/requests";
import { rootRecipesRoute } from "../../../actions/recipesRoute";

/**
 * Displays the recipe in a bigger and cleaner format
 * @returns JSX component
 */
const Recipe = () => {
    const { id } = useParams();
    const { brand } = getLandingPageInfo();

    const [recipe, setRecipe] = useState(null);
    const [ingredients, setIngredients] = useState([]);
    const [imageURLs, setImageURLs] = useState([]);
    const [descriptionMarkup, setDescriptionMarkup] = useState([]);
    const [steps, setSteps] = useState([]);

    const fetchRecipe = () => {
        getRequest(`${rootRecipesRoute}/${id}`)
            .then(recipePayload => {
                setRecipe(recipePayload.data);
            })
            .catch(err => console.error(err));
    };

    const parseDescription = () => {
        const description = JSON.parse(recipe.description);
        const descriptionBlocks = [];
        description.blocks.map(element => {
            if (element.type === "unordered-list-item" || element.type === "ordered-list-item") {
                element.type = "unstyled";
                setSteps(prev => [...prev, { blocks: [{ ...element }], entityMap: [] }]);
            } else {
                descriptionBlocks.push({ ...element });
            }
        });
        setDescriptionMarkup({ ...descriptionMarkup, blocks: descriptionBlocks, entityMap: [] });
    };

    const parseIngredients = () => {
        const rawIngredients = JSON.parse(recipe.ingredients);
        rawIngredients.blocks.map(element => {
            element.type = "unstyled";
            setIngredients(prev => [...prev, { blocks: [{ ...element }], entityMap: [] }]);
        });
    };

    useEffect(() => {
        fetchRecipe(id);
    }, []);

    useEffect(() => {
        if (recipe) {
            setImageURLs(recipe.imageURLs);
            parseIngredients();
            parseDescription();
        }
    }, [recipe]);

    return (
        <>
            <GetPortalHelmet title={`Recette${recipe && " - " + recipe.title}`} />
            {recipe && (
                <div className={`recipe ${brand}`}>
                    <div className="recipe__image" style={{ backgroundImage: `url(${imageURLs ? imageURLs[0] : placeholderImage})` }}>
                        <h3 className="recipe__title">{recipe.title}</h3>
                    </div>
                    <Grid container className="recipe__info">
                        <Grid item xs={12} md={6} className="recipe__section__grid recipe__ingredients">
                            <h3 className={`recipe__section__title ${brand}`}>Ingrédients</h3>
                            {ingredients &&
                                ingredients.map((ingredient, index) => (
                                    <Grid item key={index}>
                                        <FormControlLabel control={<Checkbox color="primary" />} label={parse(draftToHtml(ingredient))} />
                                    </Grid>
                                ))}
                        </Grid>
                        <Grid item xs={12} md={6} className="recipe__section__grid recipe__prep">
                            {descriptionMarkup && descriptionMarkup.blocks && descriptionMarkup.blocks.length > 0 && (
                                <h3 className={`recipe__section__title ${brand}`}>Description</h3>
                            )}
                            {descriptionMarkup && <div>{parse(draftToHtml(descriptionMarkup))}</div>}
                            {steps && steps.length > 0 && <h3 className={`recipe__section__title ${brand}`}>Préparation</h3>}
                            {steps &&
                                steps.map((step, i) => {
                                    const counter = i + 1;
                                    const stepElement = parse(draftToHtml(step));
                                    return (
                                        <div key={i} className="recipe__step">
                                            <span className={`recipe__step__number ${brand}`}>{counter}</span>
                                            <span className="recipe__step__text">{stepElement}</span>
                                        </div>
                                    );
                                })}
                        </Grid>
                    </Grid>
                    <div className={`portal__recipe__quickinfo ${brand}`}>
                        <span>
                            <b>Temps de Préparation:</b> {recipe.prepTime} mins
                        </span>
                        {recipe.cookingTime > 0 ? (
                            <span>
                                <b>Temps de Cuisson:</b> {recipe.cookingTime} mins
                            </span>
                        ) : (
                            ""
                        )}
                        <span>
                            <b>Portions:</b> {recipe.portion}
                        </span>
                        <span>
                            <b>Calories:</b> {recipe.calories}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

export default Recipe;
