import React, { forwardRef } from "react";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "./Address.module.scss";
import { postalCodeRegex } from "../../../utils/regex";

const provinces = [
    { value: "québec", label: "Québec" },
    { value: "ontario", label: "Ontario" },
    { value: "alberta", label: "Alberta" },
    { value: "british columbia", label: "Colombie-Britannique" },
    { value: "manitoba", label: "Manitoba" },
    { value: "new brunswick", label: "Nouveau-Brunswick" },
    { value: "newfoundland and labrador", label: "Terre-Neuve et Labrador" },
    { value: "nova scotia", label: "Nouvelle-Écosse" },
    { value: "prince edward island", label: "Île du Prince-Édouard" },
    { value: "saskatchewan", label: "Saskatchewan" },
    { value: "nunavut", label: "Nunavut" },
    { value: "northwest territories", label: "Territoire du Nord-Ouest" },
    { value: "yukon", label: "Yukon" },
];

const requiredMessage = "Ce champs est requis";
const numberMessage = "Ce champs doit être un chiffre";
const schema = yup.object({
    streetNumber: yup.number().typeError(numberMessage).required(requiredMessage),
    street: yup.string().required(requiredMessage),
    appartment: yup
        .number()
        .typeError(numberMessage)
        .transform((value, original) => {
            if (!original) return undefined;
            return value;
        })
        .notRequired(),
    city: yup.string().required(requiredMessage),
    province: yup
        .string()
        .oneOf(provinces.map(({ value }) => value))
        .required(requiredMessage),
    country: yup.string().required(requiredMessage),
    postalCode: yup.string().matches(postalCodeRegex, "Veuillez corriger le format (A1B 2C3)").required(requiredMessage),
});

const Address = ({ defaultValues, onAccept }, ref) => {
    const { control, errors, handleSubmit, setValue, getValues } = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema) });

    const onSubmit = data => {
        onAccept(data);
    };

    return (
        <div className={styles.container}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="streetNumber"
                    control={control}
                    render={props => (
                        <TextField
                            {...props}
                            error={errors.streetNumber}
                            helperText={errors.streetNumber?.message}
                            className={styles.street__number}
                            label="Numéro civique"
                        />
                    )}
                />
                <Controller
                    name="street"
                    control={control}
                    render={props => (
                        <TextField
                            {...props}
                            error={errors.street}
                            helperText={errors.street?.message}
                            className={styles.street}
                            label="Nom de rue"
                        />
                    )}
                />
                <Controller
                    name="appartment"
                    control={control}
                    render={props => (
                        <TextField
                            {...props}
                            error={errors.appartment}
                            helperText={errors.appartment?.message}
                            className={styles.app__number}
                            label="Numéro d'appartement"
                        />
                    )}
                />
                <Controller
                    name="city"
                    control={control}
                    render={props => (
                        <TextField {...props} error={errors.city} helperText={errors.city?.message} className={styles.city} label="Ville" />
                    )}
                />
                <Controller
                    name="province"
                    control={control}
                    render={props => (
                        <TextField
                            {...props}
                            error={errors.province}
                            helperText={errors.province?.message}
                            className={styles.province}
                            label="Province"
                            select
                        >
                            {provinces.map(({ value, label }) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />
                <Controller
                    name="country"
                    control={control}
                    render={props => (
                        <TextField {...props} error={errors.country} helperText={errors.country?.message} className={styles.country} label="Pays" />
                    )}
                />
                <Controller
                    name="postalCode"
                    control={control}
                    render={props => (
                        <TextField
                            {...props}
                            error={errors.postalCode}
                            helperText={errors.postalCode?.message}
                            className={styles.postal__code}
                            label="Code Postal"
                            onBlur={() => {
                                let postalCode = getValues("postalCode");
                                postalCode = postalCode.toUpperCase();

                                if (postalCode[3] !== " ") {
                                    let localPostalCode = postalCode;

                                    localPostalCode = localPostalCode.slice(0, 3) + " " + localPostalCode.slice(3);
                                    setValue("postalCode", localPostalCode);
                                }
                            }}
                        />
                    )}
                />
                <button ref={ref} hidden type="submit"></button>
            </form>
        </div>
    );
};

export default forwardRef(Address);
