import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
// Material UI Core
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// Custom Components
import { UserContext } from "../../../../reducer/userContext";
import { getStaticURL } from "../../../../utils/config";
import { settings } from "../../../../settings";
import { logout as logoutFunction } from "../../../../utils/auth";
import { getLandingPageInfo } from "../../../../utils/config";
// Assets
import profileIcon from "../../../../assets/shared/nav_profile.svg";
import recipeIcon from "../../../../assets/shared/nav_recipe.svg";
import shopIcon from "../../../../assets/shared/nav_shop.svg";
import workoutIcon from "../../../../assets/shared/nav_workout.svg";
import Favorite from "../../../../assets/shared/nav_favorite.svg";
import homeIcon from "../../../../assets/shared/nav_home.svg";
import signoutIcon from "../../../../assets/shared/nav_signout.svg";
// Redux
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../../store/reducers/users";

/**
 * Displays the user's part of the navigation bar. Does not need any special role.
 */
const MobileNav = ({ isMobile = false }) => {
    const { user } = useContext(UserContext);
    const { brand } = getLandingPageInfo();

    const history = useHistory();
    const dispatch = useDispatch();

    const handleLogoutSuccess = () => {
        // dispatch(actionCreators.signOutUser());
        return history.push("/");
    };

    const baseUrl = "/portail";
    const videos = {
        path: `${baseUrl}${"/videos"}`,
        text: <span>Aktivation</span>,
    };
    const favs = { path: `${baseUrl}${"/favoris"}`, text: "favoris" };
    const recipe = { path: `${baseUrl}${"/recettes"}`, text: "Recettes" };
    const profile = { path: `${baseUrl}${"/profil"}`, text: "Profil" };
    const home = { path: `/`, text: "Retour site" };
    const logout = { path: "", text: "Déconnexion" };

    const VideoContent = () => {
        if (brand === "Aktivation") {
            return (
                <div className="nav__link__item">
                    <ListItem button className="nav__listItem">
                        <ListItemIcon>
                            <img src={workoutIcon} alt="" />
                        </ListItemIcon>
                        <ListItemText className="nav__item__text" primary={videos.text} />
                    </ListItem>
                </div>
            );
        } else return <></>;
    };

    return (
        <List className="nav__link__container">
            <Link to={home.path}>
                <div className="nav__link__item">
                    <ListItem button className="nav__listItem">
                        <ListItemIcon>
                            <img src={homeIcon} alt="" />
                        </ListItemIcon>
                        <ListItemText className="nav__item__text" primary={home.text} />
                    </ListItem>
                </div>
            </Link>
            {user.hasVideoAccess ? (
                <Link to={videos.path}>
                    <VideoContent />
                </Link>
            ) : (
                <a href={settings.staticURLs.akt}>
                    <VideoContent />
                </a>
            )}

            {user.favoriteVideos && (
                <Link to={favs.path}>
                    <div className="nav__link__item">
                        <ListItem button className="nav__listItem">
                            <ListItemIcon>
                                <img src={Favorite} />
                            </ListItemIcon>
                            <ListItemText className="nav__item__text" primary={favs.text} />
                        </ListItem>
                    </div>
                </Link>
            )}

            <a href="https://www.jimmysevigny.com/boutique/aktivation/" target="_blank">
                <div className="nav__link__item">
                    <ListItem button className="nav__listItem">
                        <ListItemIcon>
                            <img src={shopIcon} alt="" />
                        </ListItemIcon>
                        <ListItemText className="nav__item__text" primary="Boutique" />
                    </ListItem>
                </div>
            </a>

            {/* <Link to={recipe.path}>
                <div className="nav__link__item">
                    <ListItem button className="nav__listItem">
                        <ListItemIcon>
                            <img src={recipeIcon} alt="" />
                        </ListItemIcon>
                        <ListItemText className="nav__item__text" primary={recipe.text} />
                    </ListItem>
                </div>
            </Link> */}

            <Link to={profile.path}>
                <div className="nav__link__item">
                    <ListItem button className="nav__listItem">
                        <ListItemIcon>
                            <img src={profileIcon} alt="" />
                        </ListItemIcon>
                        <ListItemText className="nav__item__text" primary={profile.text} />
                    </ListItem>
                </div>
            </Link>
            <ListItem button className="nav__listItem" onClick={() => logoutFunction(handleLogoutSuccess())}>
                <ListItemIcon>
                    <img src={signoutIcon} alt="" />
                </ListItemIcon>
                <ListItemText className="nav__item__text" primary={logout.text} />
            </ListItem>
        </List>
    );
};

export default MobileNav;
