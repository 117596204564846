import React from 'react';

function FooterNav() {
    return (
        <div className="footernav"> 
            <div className="footernav__container">
                <a href="./ambassadeurs"><span className='clickable__tag'>Ambassadeurs</span></a>
                <a href="./#faq"><span>Vous avez des questions?</span></a>
                <a href="./contact"><span>Contactez-nous</span></a>
            </div>
        </div>
    );
}

export default FooterNav;
