import React from "react";

// MUI
import { Grid } from "@material-ui/core";

// Asset
import gkMixte from "../../../assets/gk/gokrisp-mixte.png";
import gkPoint from "../../../assets/gk/gokrisp-point.png";

function Advantages() {
    return (
        <div className="advantages">
            <Grid container className="advantages__container">
                <Grid item md={6} xs={12}>
                    <div className="advantages__title">
                        <h6 className="advantages__title--1">Les avantages</h6>
                        <h4 className="advantages__title--2">GoKrisp!</h4>
                    </div>
                    <div className="advantages__content">
                        <div className="advantages__content--1">
                            <span><img src={gkPoint} alt="" />Jusqu'à 15 de grammes de protéines</span>
                        </div>
                        <div className="advantages__content--2">
                            <span><img src={gkPoint} alt="" />12 grammes de fibres</span>
                        </div>
                        <div className="advantages__content--3">
                            <span><img src={gkPoint} alt="" />Moins de 5 grammes de sucres ajoutés</span>
                        </div>
                        <div className="advantages__content--4">
                            <span><img src={gkPoint} alt="" />Vegan et sans arachides</span>
                        </div>
                    </div>
                </Grid>
                <Grid item md={6} xs={12}>
                    <img src={gkMixte} alt="" className="advantages__image" />
                </Grid>
            </Grid>
        </div>
    );
}

export default Advantages;
