import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// Material UI Core
import Grid from "@material-ui/core/Grid";
// Assets
import Mixte from "../../../assets/gc/gc-mixte.png";
import Noir from "../../../assets/gc/gc-noir-solo.png";
import Caramel from "../../../assets/gc/gc-caramel-solo.png";
import Moka from "../../../assets/gc/gc-moka-solo.png";
import Framboise from "../../../assets/gc/gc-framboise-solo.png";
import Promo from "../../../assets/gc/gc_promo_stvalentin.jpg";
import PromoMobile from "../../../assets/gc/gc_promo_stvalentin.jpg";
// Custom components
import ProductTypeDialog from "./productSelectionDialog";
import { fetchProductById } from "../../../utils/apiCalls";
// Redux
import { useDispatch } from "react-redux";
import { addProduct } from "../../../store/reducers/checkout";

function Products() {
    const all = {
        caramel: { product: "tu7jUdSFxDL0970UF5wF", sub1: "TLiMuwyRR7dSPugquXhX", sub2: "f1e0K97J1FkN4W8YZGpi" },
        mixte: { product: "Gb7ALZ32SvCWobQMNKUt", sub1: "3cOoHn0kxYQrCfSMNQIV", sub2: "EbXIXp6F4sPlCbegSyZZ" },
        moka: { product: "fl0h5izsVZdeycqn1NAK", sub1: "sdfCzOeh5AMqZTHcSyHF", sub2: "w7BguOKkKrK1rkKMl72a" },
        noir: { product: "J4d51ZiF4wnuR0x1DMyM", sub1: "Cg3QHJJ60XZynRsmKati", sub2: "hoxsff0PzMMMzuiX087n" },
        framboise: { product: "XidveOgHXJEM21bZou0Q", sub1: "ss1lIuQKBV4GcM1SQGLl", sub2: "imhzzzw1nNtlOzyUavRb" },
    };

    // states
    const [openDialog, setOpenDialog] = useState(false);
    const [items, setItems] = useState({});

    const handleOpenDialog = () => setOpenDialog(true);

    const handleCloseDialog = () => setOpenDialog(false);

    const history = useHistory();
    // Redux
    const dispatch = useDispatch();
    // Products
    const BOITE_CADEAU = "ZzfrcDaFwVKjy5Xo0qQv";

    return (
        <>
        {/* FEATURED ITEM */}

        {/* <Grid item xs={12} className="promo__desktop" style={{ cursor: "pointer" }} onClick={async () => {
                                        const product = await fetchProductById(BOITE_CADEAU);
                                        dispatch(addProduct({ ...product, selectedQuantity: 1, status: "À livrer" }));
                                        history.push(`/checkout`);
                                    }}>
            <img src={Promo} alt="" />
        </Grid>

        <Grid item xs={12} className="promo__mobile" style={{ cursor: "pointer" }} onClick={async () => {
                                        const product = await fetchProductById(BOITE_CADEAU);
                                        dispatch(addProduct({ ...product, selectedQuantity: 1, status: "À livrer" }));
                                        history.push(`/checkout`);
                                    }}>
            <img src={PromoMobile} alt="" />
            <br />
            <br />
            <br />
            <br />
            <br />
        </Grid> */}

        <div className="products" id="produits">
            <div className="products__container">
                <h3 className="products__title">Choisissez votre saveur</h3>
                <span className="products__subtitle">Livraison gratuite à la grandeur du Canada!</span>
                <Grid container>

                    <Grid item xs={12} md={6}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                handleOpenDialog();
                                setItems({
                                    product: {
                                        id: all.mixte.product,
                                        isProduct: true,
                                    },
                                    sub1: {
                                        id: all.mixte.sub1,
                                        isProduct: false,
                                    },
                                    sub2: {
                                        id: all.mixte.sub2,
                                        isProduct: false,
                                    },
                                });
                            }}
                        >
                            <div className="products__top">
                                <img src={Mixte} />
                                <div className="products__price__container">
                                    <span className="products__price__reg">rég: 3.99$ chq. +tx</span>
                                    <div className="products__price__bubble">
                                        <span className="products__price__text">prix promotionnel</span>
                                        <span className="products__price">
                                            3.49$ chq. +tx<sup>*</sup>
                                        </span>
                                    </div>
                                    <span className="products__shipping">livraison gratuite!</span>
                                </div>
                            </div>
                            <div className="products__bottom">
                                <button className="products__cta1">sachets mixte</button>
                                {/* <button className="products__cta2">acheter maintenant</button> */}
                                <button className="products__cta2">actuellement indisponible</button>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                handleOpenDialog();
                                setItems({
                                    product: {
                                        id: all.framboise.product,
                                        isProduct: true,
                                    },
                                    sub1: {
                                        id: all.framboise.sub1,
                                        isProduct: false,
                                    },
                                    sub2: {
                                        id: all.framboise.sub2,
                                        isProduct: false,
                                    },
                                });
                            }}
                        >
                            <div className="products__top">
                                <div style={{position: "relative", textAlign: "center"}}>
                                <span className="framboise__new">NOUVEAU</span>
                                <img src={Framboise}/>
                                </div>
                                <div className="products__price__container">
                                    <span className="products__price__reg">rég: 3.99$ chq. +tx</span>
                                    <div className="products__price__bubble">
                                        <span className="products__price__text">prix promotionnel</span>
                                        <span className="products__price">
                                            3.49$ chq. +tx<sup>*</sup>
                                        </span>
                                    </div>
                                    <span className="products__shipping">livraison gratuite!</span>
                                </div>
                            </div>
                            <div className="products__bottom">
                                <button className="products__cta1">choko-framboise</button>
                                {/* <button className="products__cta2">acheter maintenant</button> */}
                                <button className="products__cta2">actuellement indisponible</button>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                handleOpenDialog();
                                setItems({
                                    product: {
                                        id: all.noir.product,
                                        isProduct: true,
                                    },
                                    sub1: {
                                        id: all.noir.sub1,
                                        isProduct: false,
                                    },
                                    sub2: {
                                        id: all.noir.sub2,
                                        isProduct: false,
                                    },
                                });
                            }}
                        >
                            <div className="products__top">
                                <img src={Noir} />
                                <div className="products__price__container">
                                    <span className="products__price__reg">rég: 3.99$ chq. +tx</span>
                                    <div className="products__price__bubble">
                                        <span className="products__price__text">prix promotionnel</span>
                                        <span className="products__price">
                                            3.49$ chq. +tx<sup>*</sup>
                                        </span>
                                    </div>
                                    <span className="products__shipping">livraison gratuite!</span>
                                </div>
                            </div>
                            <div className="products__bottom">
                                <button className="products__cta1">choko-noir</button>
                                {/* <button className="products__cta2">acheter maintenant</button> */}
                                <button className="products__cta2">actuellement indisponible</button>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                handleOpenDialog();
                                setItems({
                                    product: {
                                        id: all.moka.product,
                                        isProduct: true,
                                    },
                                    sub1: {
                                        id: all.moka.sub1,
                                        isProduct: false,
                                    },
                                    sub2: {
                                        id: all.moka.sub2,
                                        isProduct: false,
                                    },
                                });
                            }}
                        >
                            <div className="products__top">
                                <img src={Moka} />
                                <div className="products__price__container">
                                    <span className="products__price__reg">rég: 3.99$ chq. +tx</span>
                                    <div className="products__price__bubble">
                                        <span className="products__price__text">prix promotionnel</span>
                                        <span className="products__price">
                                            3.49$ chq. +tx<sup>*</sup>
                                        </span>
                                    </div>
                                    <span className="products__shipping">livraison gratuite!</span>
                                </div>
                            </div>
                            <div className="products__bottom">
                                <button className="products__cta1">choko-moka</button>
                                {/* <button className="products__cta2">acheter maintenant</button> */}
                                <button className="products__cta2">actuellement indisponible</button>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div
                            className="products__item clickable__tag"
                            onClick={async () => {
                                handleOpenDialog();
                                setItems({
                                    product: {
                                        id: all.caramel.product,
                                        isProduct: true,
                                    },
                                    sub1: {
                                        id: all.caramel.sub1,
                                        isProduct: false,
                                    },
                                    sub2: {
                                        id: all.caramel.sub2,
                                        isProduct: false,
                                    },
                                });
                            }}
                        >
                            <div className="products__top">
                                <img src={Caramel} />
                                <div className="products__price__container">
                                    <span className="products__price__reg">rég: 3.99$ chq. +tx</span>
                                    <div className="products__price__bubble">
                                        <span className="products__price__text">prix promotionnel</span>
                                        <span className="products__price">
                                            3.49$ chq. +tx<sup>*</sup>
                                        </span>
                                    </div>
                                    <span className="products__shipping">livraison gratuite!</span>
                                </div>
                            </div>
                            <div className="products__bottom">
                                <button className="products__cta1">choko-caramel</button>
                                {/* <button className="products__cta2">acheter maintenant</button> */}
                                <button className="products__cta2">actuellement indisponible</button>
                            </div>
                        </div>
                    </Grid>

            
                </Grid>
            </div>
            <ProductTypeDialog open={openDialog} onClose={handleCloseDialog} items={items} />
        </div>
        </>
    );
}

export default Products;
