import React from "react";

// Assets
import making1 from "../../../assets/bs/making1.png";
import making2 from "../../../assets/bs/making2.png";
import making3 from "../../../assets/bs/making3.png";
import making4 from "../../../assets/bs/making4.png";

const Making = () => {

	const Item = ({ step, text, image }) => {
		return (
			<div className="item">
				<h4 className="item__step">{step}.</h4>
				<p className="item__text">{text}</p>
				<img src={image} alt="" className="item__image" />
			</div>
		);
	};

	return (
		<div className="making">
			<h3 className="making__title">Processus de fabrication</h3>
			<div className="making__content">
				<Item
					step={1}
					text="Notre nutritionniste imagine et approuve de nouvelles recettes, toujours plus santé"
					image={making1}
				/>
				<Item
					step={2}
					text="Notre équipe de cuisiniers se met aux fourneaux pour préparer votre boîte. "
					image={making2}
				/>
				<Item
					step={3}
					text="L'équipe logistique s'assure que tout arrive bien frais chez vous!"
					image={making3}
				/>
				<Item
					step={4}
					text="Le service clientèle est présent pour vous répondre si vous avez des questions!"
					image={making4}
				/>
			</div>
		</div>
	);
};

export default Making;
