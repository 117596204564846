import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// Material UI Core
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
// Custom Components
import { GetHelmet, GetNavbar } from "../../utils/config";
import Recipe from "./utils/recipeWrapper";
import { getLandingPageInfo } from "../../utils/config";
// Utils
import { getRequest } from "../../actions/requests";

const Recipes = () => {
    const history = useHistory();
    const { brand } = getLandingPageInfo();

    // States
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filteredRecipes, setFilteredRecipes] = useState([]);

    const handleCategoryChange = category => {
        if (selectedCategories.includes(category)) {
            setSelectedCategories(selectedCategories.filter(cat => cat !== category));
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    const fetchRecipeCategories = async () => {
        const payload = await getRequest("strings/Recipes-Categories");
        setCategories(payload.data.list);
    };

    const fetchRecipesByBrand = async () => {
        const recipesPayload = await getRequest(`recipes`);
        const filteredLocalRecipes = recipesPayload.data.filter(r => r.brands.some(b => b.name === brand));

        setFilteredRecipes(filteredLocalRecipes);
    };

    useEffect(() => {
        fetchRecipeCategories();
        fetchRecipesByBrand();
    }, []);

    useEffect(() => {
        if (selectedCategories.length !== 0) {
            setFilteredRecipes([...filteredRecipes.filter(recipe => recipe.categories.some(cat => selectedCategories.includes(cat)))]);
        } else {
            setFilteredRecipes([...filteredRecipes]);
        }
    }, [selectedCategories]);

    return (
        <>
            <GetHelmet />
            <GetNavbar />
            <div className="recipes__header">
                <h3>Recettes</h3>
            </div>
            <Grid container className="recipes__filters">
                <Grid item xs={12}>
                    <ul>
                        {categories.map((catName, i) => {
                            return (
                                <li key={i}>
                                    <Chip
                                        label={catName}
                                        color={selectedCategories.includes(catName) ? "primary" : "default"}
                                        className="recipes__filter"
                                        clickable
                                        onClick={() => handleCategoryChange(catName)}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </Grid>
            </Grid>
            <Grid container>
                {filteredRecipes.map((recipe, i) => (
                    <>
                        <Grid key={i} item xs={12} sm={6} md={4} lg={3} xl={3}>
                            <div onClick={() => history.push(`/recette/${recipe.id}`)} className="recipes__wrapper">
                                <Recipe recipe={recipe} brand={brand} />
                            </div>
                        </Grid>
                    </>
                ))}
            </Grid>
        </>
    );
};

export default Recipes;
