import React from "react";
import { Grid } from "@material-ui/core";

import HD from "../../../assets/akt/use-hd.png";
import L24 from "../../../assets/akt/use-24.png";
import DVD from "../../../assets/akt/use-dvd.png";
import Devices from "../../../assets/akt/use-devices.png";

function Use() {
    return (
        <div className="use">
            <div className="use__right">
                <div className="use__right__container">
                    <h3 className="use__title">APPORTEZ AKTIVATION PARTOUT!</h3>
                    <div className="use__items">
                        <Grid container className="use__items__mobile__center">
                            <Grid item sm={12} md={3}>
                                <div className="use__item">
                                    <img src={HD} alt="" />
                                    <span>VIDÉO DE QUALITÉ SUPÉRIEURE</span>
                                </div>
                            </Grid>

                            <Grid item sm={12} md={3}>
                                <div className="use__item">
                                    <img src={L24} alt="" />
                                    <span>ACCÈS 24/7</span>
                                </div>
                            </Grid>

                            <Grid item sm={12} md={3}>
                                <div className="use__item">
                                    <img src={DVD} alt="" />
                                    <span>AUCUN DVD REQUIS</span>
                                </div>
                            </Grid>

                            <Grid item sm={12} md={3}>
                                <div className="use__item">
                                    <img src={Devices} alt="" />
                                    <span>ENTRAÎNEZ-VOUS À TOUT MOMENT</span>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <p className="use__text">
                        UNE PLATEFORME D'ENTRAÎNEMENT 100% QUÉBÉCOISE AVEC LES MEILLEURS ENTRAÎNEMENTS QUI SOIT! CONNEXION INTERNET NÉCESSAIRE
                    </p>
                    <a href="./#produits">
                        <button className="use__cta">COMMENCEZ VOTRE ESSAI GRATUIT DE 14 JOURS</button>
                    </a>
                </div>
            </div>
            {/* </Grid>
            </Grid> */}
        </div>
    );
}

export default Use;
