import React from "react";

import Bg from "../../../assets/gm/vs.png";

function Vs() {
    return (
        <div className="vs">
            <div className="vs__container">
                <h3 className="vs__title">
                    <b>matcha</b> vs <b>café</b>
                </h3>
                <img src={Bg} alt="" />
            </div>
        </div>
    );
}

export default Vs;
