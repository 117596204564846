import React from "react";

// React-router-dom
import { Link } from "react-router-dom";

// Assets
import meal from "../../../assets/bs/riz-carotte-feve.png";

const Discover = () => {
    return (
        <div className="discover">
            <div className="discover__container">
                <div className="discover__left">
                    <img src={meal} alt="Une assiète de saumon avec riz, carotte et fève" className="discover__left--image" />
                </div>
                <div className="discover__right">
                    <h3 className="discover__right--title">Santé, équilibré et incroyablement bon</h3>
                    <p className="discover__right--text">
                        Ce tout nouveau concept développé par Jimmy Sévigny et son équipe de professionnels de la santé vous garantira des plats
                        goûteux, conçus avec des aliments haut de gamme et de plus, qui sont balancés afin de vous offrir un maximum d'énergie et de
                        vitalité tout au long de la journée.
                    </p>
                    <Link to="/menus" className="button button__cta button__cta--green">Découvrez nos menus</Link>
                </div>
            </div>
        </div>
    );
};

export default Discover;
