import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import Pdf from "react-to-pdf";
// Material UI Core
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
// Material UI Icons
import GetAppIcon from "@material-ui/icons/GetApp";
// DB
import { brandsRoot } from "../../../actions/brandsRoute";
import { marketsRoot } from "../../../actions/marketRoute";
import { getRequest } from "../../../actions/requests";
// Custom Components
import { GetPortalHelmet } from "../../../utils/config";
import { UserContext } from "../../../reducer/userContext";
import { getBrandLogo } from "../../../utils/config";
import { parseDate } from "../../../utils/parseData";
import TrialBillTableWrapper from "./trialBillTableWrapper";
import { settings } from "../../../settings";

const TrialBill = () => {
    // Params from app.js
    const { id } = useParams();
    const { user } = useContext(UserContext);

    const [brand, setBrand] = useState({});
    const [market, setMarket] = useState({});
    const [order, setOrder] = useState({});
    // table
    const [loading, setLoading] = useState(true);

    const ref = React.createRef();

    const fetchBrand = name => {
        getRequest(`${brandsRoot}/${name}`, {})
            .then(res => {
                if (res.data) {
                    setBrand(res.data);
                }
            })
            .catch(err => {
                if (err.response.data) console.error(err.response.data);
                else console.error(err);
            });
    };

    const fetchMarket = marketId => {
        setLoading(true);
        getRequest(`${marketsRoot}/${marketId}`, {})
            .then(res => {
                if (res.data) {
                    setMarket(res.data);
                }
            })
            .catch(err => {
                if (err.response.data) console.error(err.response.data);
                else console.error(err);
            })
            .finally(() => setLoading(false));
    };

    const fetchOrder = orderId => {
        setLoading(true);
        getRequest(`trialOrders/${orderId}`, {})
            .then(res => {
                setOrder(res.data);
                setLoading(false);
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchOrder(id);
    }, [id]);

    useEffect(() => {
        if (order.hasOwnProperty("brand") && order.hasOwnProperty("market")) {
            fetchBrand(order.brand.name);
            fetchMarket(order.market);
        }
    }, [order]);

    return (
        <>
            <GetPortalHelmet title={`Facture #${order.hasOwnProperty("orderNumber") && order.orderNumber}`} />
            <div ref={ref} className="bill__container">
                <Grid container spacing={2}>
                    <Grid container item xs={6}>
                        <Grid item xs={12} style={{ paddingBottom: 10 }}>
                            <img src={getBrandLogo()} alt="" />
                            <div>Division de Jimmy Sevigny Inc.</div>
                        </Grid>
                        {!_.isEmpty(brand) && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        {brand.coordinates.appartment && brand.coordinates.appartment + "-"}
                                        {brand.coordinates.streetNumber} {brand.coordinates.street}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        {brand.coordinates.city}, {brand.coordinates.province}, {brand.coordinates.country}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">{brand.coordinates.postalCode}</Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid container item xs={6}>
                        <Grid container item xs={12} justify="flex-end">
                            <Grid container item xs={12} justify="flex-end">
                                <Typography variant="h5">#{order.orderNumber}</Typography>
                            </Grid>
                            <Grid container item xs={12} justify="flex-end">
                                {`${user && user.firstName} ${user && user.lastName}`}
                            </Grid>

                            {!_.isEmpty(order.shippingCoordinates) ? (
                                <>
                                    <Grid container item xs={12} justify="flex-end">
                                        {order.shippingCoordinates.appartment && `${order.shippingCoordinates.appartment}-`}
                                        {`${order.shippingCoordinates.streetNumber} ${order.shippingCoordinates.street}`}
                                    </Grid>
                                    <Grid container item xs={12} justify="flex-end">
                                        {`${order.shippingCoordinates.city}, ${order.shippingCoordinates.province}, ${order.shippingCoordinates.country}`}
                                    </Grid>
                                    <Grid container item xs={12} justify="flex-end">
                                        {order.shippingCoordinates.postalCode}
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    {user.coordinates && (
                                        <>
                                            <Grid container item xs={12} justify="flex-end">
                                                {user.coordinates.appartment && `${user.coordinates.appartment}-`}
                                                {`${user.coordinates.streetNumber} ${user.coordinates.street}`}
                                            </Grid>
                                            <Grid container item xs={12} justify="flex-end">
                                                {`${user.coordinates.city}, ${user.coordinates.province}, ${user.coordinates.country}`}
                                            </Grid>
                                            <Grid container item xs={12} justify="flex-end">
                                                {user.coordinates.postalCode}
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justify="flex-end">
                        <Typography variant="h6">
                            <strong>Date facture:</strong> {order.createdAt && parseDate(order.createdAt)}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <TrialBillTableWrapper
                                data={order.items}
                                loading={loading}
                                market={market}
                                brand={brand}
                                order={order}
                                setLoading={setLoading}
                            />
                            <Grid container>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Coût livraison
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Sous-total
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Taxe fédérale ({settings.order.TPS_NUMBER})
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Taxe provinciale ({settings.order.TVQ_NUMBER})
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                            Total
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{ textAlign: "right" }}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            GRATUITE
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            0.00 $
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            0.00 $
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            0.00 $
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                            0.00 $
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <Grid container item justify="flex-end">
                <Pdf targetRef={ref} filename={`commande-${order.orderNumber}.pdf`}>
                    {({ toPdf }) => (
                        <div className={clsx("vertical__centered", "submit__fab")}>
                            <Fab className="btn-left-margin" color="primary" variant="extended" onClick={toPdf}>
                                <GetAppIcon className="btnIcon" />
                                Télécharger
                            </Fab>
                        </div>
                    )}
                </Pdf>
            </Grid>
        </>
    );
};
export default TrialBill;
