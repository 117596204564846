import React from "react";

// Assets
import { getSplashScreenImage } from "../../utils/config";

export const Loading = ({ loading }) => {
    if (loading)
        return (
            <div
                className="loading__container parent"
                style={{ backgroundColor: "black", opacity: 0.5, position: "absolute", zIndex: 99999, minHeight: "100%", minWidth: "100%" }}
            >
                <div className="child">
                    <img className="nofilterimg" src={getSplashScreenImage()} alt="loading" style={{ maxHeight: 350 }} />
                </div>
            </div>
        );
    else return <></>;
};

export default Loading;
