import React from "react";
import Slider from 'react-slick';

import Intro1 from "../../../assets/gm/intro-1.png";
import Intro2 from "../../../assets/gm/intro-2.png";
import Intro3 from "../../../assets/gm/intro-3.png";
import Intro4 from "../../../assets/gm/intro-4.png";
import Intro5 from "../../../assets/gm/intro-5.png";
import Intro6 from "../../../assets/gm/intro-6.png";

function Intro() {

    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        dots: false,
        responsive: [
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 2,
              }
            },
            {
                breakpoint: 799,
                settings: {
                  slidesToShow: 1,
                }
              }
          ]
    };


    return (
        <div className="intro">
            <div className="intro__container">
                <Slider {...settings}>
                    <div className="intro__item">
                        <div className="intro__top">
                            <img src={Intro1} alt="" />
                        </div>
                        <div className="intro__bottom">
                            <span>Une tasse contient jusqu'à 137x plus d’antioxydants que du thé vert à quantité égale</span>
                        </div>
                    </div>

                    <div className="intro__item">
                        <div className="intro__top">
                            <img src={Intro2} alt="" />
                        </div>
                        <div className="intro__bottom">
                            <span>Pourrait vous aider à optimiser votre consommation de gras à l'effort</span>
                        </div>
                    </div>

                    <div className="intro__item">
                        <div className="intro__top">
                            <img src={Intro3} alt="" />
                        </div>
                        <div className="intro__bottom">
                            <span>Pourrait vous aider à optimiser votre perte de poids et à la conserver</span>
                        </div>
                    </div>

                    <div className="intro__item">
                        <div className="intro__top">
                            <img src={Intro4} alt="" />
                        </div>
                        <div className="intro__bottom">
                            <span>​Pourrait vous aider à augmenter votre concentration</span>
                        </div>
                    </div>

                    <div className="intro__item">
                        <div className="intro__top">
                            <img src={Intro5} alt="" />
                        </div>
                        <div className="intro__bottom">
                            <span>​Pourrait augmenter votre métabolisme pendant 24 heures</span>
                        </div>
                    </div>

                    <div className="intro__item">
                        <div className="intro__top">
                            <img src={Intro6} alt="" />
                        </div>
                        <div className="intro__bottom">
                            <span>Donne de l’énergie sans crash comme le café peut le faire!</span>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default Intro;
