import React from "react";

// custom  components
import Skus from "../../portal/boutique/Product/Skus";

const SkuModal = ({ Modal, product, onContinue, imgSrc, selectedSku, onSkuSelected, onImgChange }) => {
    if (!selectedSku && typeof product.selectedSku === "string") selectedSku = product.skus.find(sku => sku.sku.value === product.selectedSku);
    const productOutOfStock = parseInt(product.quantity) <= 0;
    const skuOutOfStock = parseInt(selectedSku?.stock.value) <= 0;

    return (
        <Modal
            buttons={[
                {
                    text: "continuer",
                    variant: "contained",
                    color: "primary",
                    onClick: onContinue,
                    disabled: !selectedSku || skuOutOfStock,
                },
            ]}
        >
            <div style={{ overflow: "hidden", minHeight: 250 }}>
                {imgSrc && (
                    <div className="element__centered">
                        <img src={imgSrc} style={{ maxWidth: 150 }} alt="Produit" />
                    </div>
                )}
                {product && (
                    <>
                        <Skus
                            skus={product.skus}
                            imgProp={product.skuImgs.associatedProp}
                            onSkuSelected={onSkuSelected}
                            onImgChange={onImgChange}
                            defaultImageSrc={product.imageURLs[0]}
                        />
                    </>
                )}
                {(productOutOfStock || skuOutOfStock) && (
                    <h2 style={{ color: "red", fontFamily: "Inter", textAlign: "center" }}>
                        <em>Rupture d'inventaire</em>
                    </h2>
                )}
            </div>
        </Modal>
    );
};

export default SkuModal;
