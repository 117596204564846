import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// Custom components
import Table from "../../../shared/utils/table";
import { settings } from "../../../../settings";
// DB
import { getRequest } from "../../../../actions/requests";
import { rootOrders } from "../../../../actions/ordersRoute";
// Utils
import { formatDate } from "../../../../utils/date";
import { deserialize } from "../../../../utils/serialization";

const Orders = ({ displayedUser }) => {
    const history = useHistory();
    // Table
    const [sortColumn, setSortColumn] = useState({ path: "date", order: "desc" });
    const [selectedPage, setSelectedPage] = useState(0);

    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);

    const sortedData = _.orderBy(orders, [sortColumn.path], [sortColumn.order]);
    const totalCount = sortedData.length;

    const columns = [
        {
            path: "orderNumber",
            label: "# Commande",
        },
        {
            key: "date",
            label: "Date",
            body: order => formatDate(deserialize(order.createdAt)),
        },
        ,
        { path: "shippingStatus", label: "Statut" },
        {
            key: "Item",
            path: "items[0].name",
            label: "Item",
            body: order => {
                let prods = 0;
                let subs = 0;
                order.items.forEach(i => {
                    if (i.isSubscription) subs += parseInt(i.selectedQuantity);
                    else prods += parseInt(i.selectedQuantity);
                });
                const productsDisplay = `${prods} Produit${prods > 1 ? "s" : ""}`;
                const subscriptionsDisplay = ` ${subs} Abonnement${subs > 1 ? "s" : ""}`;
                return `${prods > 0 ? productsDisplay : ""}${prods > 0 && subs > 0 ? ", " : ""}${subs > 0 ? subscriptionsDisplay : ""}`;
            },
        },
        {
            key: "totalGrand",
            label: "Total",
            asyncBody: async order => {
                const transactionPayload = await getRequest(`transactions/?orderId=${order.id}`);
                const transaction = transactionPayload.data[0];

                if (transaction) {
                    return `${(order.totalGrand / 100).toFixed(2)} $`;
                } else {
                    return "0.00 $";
                }
            },
        },
    ];

    const handleSort = sortColumn => {
        setSortColumn(sortColumn);
    };

    const handlePageChange = (_, page) => {
        setSelectedPage(page);
    };

    const handleRowClick = order => {
        history.push(`/portail/facture/${order.id}`);
    };

    const fetchOrders = async () => {
        try {
            const ordersPayload = await getRequest(`${rootOrders}/?user.id=${displayedUser.id}`);
            setOrders(ordersPayload.data.filter(o => o.shippingStatus));
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, [displayedUser]);

    return (
        <Table
            columns={columns}
            sortColumn={sortColumn}
            data={sortedData}
            rowsPerPage={settings.page.rowsPerPage}
            selectedPage={selectedPage}
            totalCount={totalCount}
            loading={loading}
            sortable={true}
            paginated={false}
            onSort={handleSort}
            onPageChange={handlePageChange}
            onRowClick={handleRowClick}
        />
    );
};

export default Orders;
