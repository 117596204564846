import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// Material UI Core
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
// Custom Components
import Recipe from "../../shared/utils/recipeWrapper";
import { getLandingPageInfo, GetPortalHelmet, GetNavbar } from "../../../utils/config";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { loadRecipeCategories, getAllCategories, loadRecipes, getAllRecipes } from "../../../store/reducers/recipes";

const Recipes = () => {
    const history = useHistory();
    const { brand } = getLandingPageInfo();
    // Redux
    const dispatch = useDispatch();
    const categories = useSelector(getAllCategories);
    const recipes = useSelector(getAllRecipes);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [filteredRecipes, setFilteredRecipes] = useState([]);

    const handleCategoryChange = category => {
        if (selectedCategories.includes(category)) {
            setSelectedCategories(selectedCategories.filter(cat => cat !== category));
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    useEffect(() => {
        dispatch(loadRecipeCategories());
        dispatch(loadRecipes());
    }, []);

    useEffect(() => {
        if (selectedCategories.length !== 0) {
            setFilteredRecipes([...recipes.filter(recipe => recipe.categories.some(cat => selectedCategories.includes(cat)))]);
        } else {
            setFilteredRecipes([...recipes]);
        }
    }, [selectedCategories]);

    useEffect(() => {
        if (categories && selectedCategories.length === 0) {
        }
        setFilteredRecipes(recipes);
    }, [recipes]);

    return (
        <>
            <GetPortalHelmet title="Recettes" />
            <div className="recipes__header">
                <h3>Recettes</h3>
            </div>
            <Grid container className="recipes__filters">
                <Grid item xs={12}>
                    <ul>
                        {categories.map((catName, i) => {
                            return (
                                <li key={i}>
                                    <Chip
                                        label={catName}
                                        color={selectedCategories.includes(catName) ? "primary" : "default"}
                                        className="recipes__filter"
                                        clickable
                                        onClick={() => handleCategoryChange(catName)}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </Grid>
            </Grid>
            <Grid container>
                {filteredRecipes.map((recipe, i) => (
                    <>
                        <Grid key={i} item xs={12} sm={6} md={4} lg={3} xl={3}>
                            <div onClick={() => history.push(`/portail/recette/${recipe.id}`)} className="recipes__wrapper">
                                <Recipe recipe={recipe} brand={brand} />
                            </div>
                        </Grid>
                    </>
                ))}
            </Grid>
        </>
    );
};

export default Recipes;
