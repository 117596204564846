import React, { useEffect, useState, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";

// Material UI Core
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

// Material UI Styles
import { makeStyles } from "@material-ui/core/styles";
import Upsell from "../utils/upsell";

// Custom Components
import CartTableWrapper from "./cartTableWrapper";

import { CheckoutContext } from "./Checkout";
import { getLandingPageInfo, getUpsellId } from "../../../utils/config";
import { fetchProductById } from "../../../utils/apiCalls";

// Settings
import { settings } from "../../../settings";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { addSubscription, changeItemQuantity, getCheckoutDetails, updateCheckoutData } from "../../../store/reducers/checkout";
import { addProduct, getProducts, getSubscriptions, deleteProductById, deleteSubscriptionById } from "../../../store/reducers/checkout";
import { deleteOrder, getAllOrders, updatePromocode, getPromocode, setPromocode, getIsPromocodeValidated } from "../../../store/reducers/orders";
import { loadBrands } from "../../../store/reducers/brands";
import { snackbarEnqueuedAction } from "../../../store/reducers/snackbars";
import { getRequest } from "../../../actions/requests";

// utils
import { formatCartItems } from "./utils";
import { alphabeticalSort } from "../../../utils/array";
import { formatPrice } from "../../../utils/parseData";

const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: 700,
    },
    title: {
        marginTop: theme.spacing(2),
    },
    paper: {
        margin: theme.spacing(2, 2, 2, 1),
        padding: theme.spacing(3),
        [theme.breakpoints.down(600)]: {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

export default function Cart() {
    const hist = useHistory();
    const classes = useStyles();
    // Context
    const { showSkuModal, setItemToModify, setUpsellAccepted } = useContext(CheckoutContext);

    // Redux
    const dispatch = useDispatch();
    const products = useSelector(getProducts);
    const subscriptions = useSelector(getSubscriptions);
    const orders = useSelector(getAllOrders);
    const lastOrder = orders[orders.length - 1];
    const details = useSelector(getCheckoutDetails);
    const promocode = useSelector(state => getPromocode(state));
    const promocodeValidated = useSelector(state => getIsPromocodeValidated(state));
    const handlePromocodeChange = e => dispatch(setPromocode(e.target.value.toLowerCase()));
    const { brand, market } = getLandingPageInfo();

    // States
    const [upsells, setUpsells] = useState([]);
    // Upsells that have been rejected or added to cart
    const maxUpsell = settings.order.MAX_UPSELL;
    // Totals
    const [amountSaved, setAmountSaved] = useState(0);

    const [promoCodeDescription, setPromoCodeDescription] = useState("");
    const [excludedUpsells, setExcludedUpsells] = useState([]);

    /**
     * Deletes the item from the cart
     * @param {Object} item
     */
    const handleDeleteItem = item => {
        if (typeof item.limitQuantity === "number") dispatch(deleteProductById(item.id));
        else dispatch(deleteSubscriptionById(item.id));

        // if no item delete order
        const lastOrder = orders[orders.length - 1];
        if (lastOrder?.items.length === 0) dispatch(deleteOrder(lastOrder.id, true));
    };

    /**
     * Updates the item's quantity
     * @param {Object} item
     * @param {number} quantity
     */
    const handleQuantityChange = (item, quantity) => {
        if (item.limitQuantity) {
            dispatch(changeItemQuantity(item, parseInt(quantity)));
            return;
        }

        dispatch(
            snackbarEnqueuedAction({
                message:
                    "Ooopppss! Si vous désirez avoir un abonnement à un produit de plus, vous devez d'abord compléter cette transaction-ci et recommencez de nouveau 🙂",
                options: {
                    variant: "error",
                    popup: {
                        title: "Oh no!",
                    },
                },
            })
        );
    };

    /**
     * Fetches the upsells based on the items in the cart
     * @param {Array<Object>} items
     */
    const fetchUpsells = async items => {
        // If landing page is AKT no upsells
        if (brand !== "Aktivation") {
            let upsellIds = [];
            if (items.length > 0) {
                // Hardcoded upsell
                getUpsellId().forEach(u => upsellIds.push(u));

                if (upsells.length < maxUpsell) {
                    // Related products
                    for (const item of items) {
                        if (item.relatedItem) {
                            if (item.relatedItem.length > 0) {
                                const productPayload = await getRequest(`products/price/${item.relatedItem}`, { market, brand });
                                const relatedProductPrice = productPayload.data;

                                upsellIds.push({ id: item.relatedItem, price: relatedProductPrice, text: "" });
                            }
                        }
                    }
                }

                let excludedUpsellIds = excludedUpsells;

                upsellIds.forEach(async upsell => {
                    const product = await fetchProductById(typeof upsell === "string" ? upsell : upsell.id);

                    if (product) {
                        if (!excludedUpsells.includes(upsell.id) && upsells < maxUpsell) {
                            excludedUpsellIds.push(upsell.id);
                            setUpsells(prev => [...prev, { ...product, price: upsell.price }]);
                        }
                        setExcludedUpsells(prev => [...prev, excludedUpsellIds]);
                    }
                });
            }
        }
    };

    /**
     * Removes the upsells from the cart
     * @param {Object} item
     */
    const removeFromUpsells = item => {
        setUpsells(prev => prev.filter(ups => ups !== item));
    };

    const addPromocodeToOrder = async () => {
        // Modify order
        if (lastOrder) dispatch(updatePromocode(lastOrder.id, { ...lastOrder, promoCode: promocode }));
    };

    const displayAddedItems = async () => {
        const lastOrder = orders[orders.length - 1];
        const addedItems = lastOrder.items.filter(i => i.isAddedItem);
        for (const addedItem of addedItems) {
            if (addedItem.isSubscription) {
                if (!subscriptions.find(s => s.id === addedItem.id)) {
                    const payload = await getRequest(`Subscriptions/${addedItem.id}`);
                    if (payload) {
                        const sub = payload.data;
                        dispatch(addSubscription({ ...sub, selectedQuantity: 1, status: "À livrer", price: addedItem.price, isAddOnTrial: true }));
                    }
                }
            } else {
                if (!products.find(p => p.id === addedItem.id)) {
                    const productPayload = await getRequest(`Products/${addedItem.id}`);
                    if (productPayload) {
                        const product = productPayload.data;
                        dispatch(addProduct({ ...product, selectedQuantity: 1, status: "À livrer", price: addedItem.price, isAddOnTrial: true }));
                    }
                }
            }
        }
    };

    const fetchPromoCodeDescription = async () => {
        // We need to escape the % character
        const encodedPromoCode = orders[orders.length - 1].promoCode.toLowerCase().replace("%", "%25");
        const promoCodePayload = await getRequest(`promocodes/description/${encodedPromoCode}`);
        if (promoCodePayload.data) {
            setPromoCodeDescription(promoCodePayload.data);
        }
    };

    const handleModifyItem = item => {
        showSkuModal({
            title: "Sélection de la variation du produit",
            contentText: "Plusieurs choix s'offrent à vous. Sélectionnez une des options suivantes",
        });
        setItemToModify(item);
    };

    /**
     * Modifies the order item's selectedSku
     */
    useEffect(() => {
        // if (orders[0] && selectedProduct && products.length > 0 && products.some(i => i.id === selectedProduct.id)) {
        //     // Modify order
        //     postRequest(`orders/modifyItem/${orders[0].id}/${orders[0].items.find(i => i.id === selectedProduct.id).id}`, {
        //         selectedSku: selectedSku.sku.value,
        //     });
        // }
    }, [orders, products]);

    useEffect(() => {
        setPromoCodeDescription("");
        if (orders.length > 0) {
            // added items
            displayAddedItems();
            // Totals
            if (orders[orders.length - 1].amountSaved) setAmountSaved(orders[orders.length - 1].amountSaved / 100);
            if (orders[orders.length - 1].promoCode) {
                // Promocode description
                fetchPromoCodeDescription();
            }
        }
    }, [orders, products, subscriptions]);

    // Loads promoCodes and brands once
    useEffect(() => {
        dispatch(loadBrands());
    }, []);

    useEffect(() => {
        fetchUpsells([...products, ...subscriptions]);
    }, []);

    const cartTableData = useMemo(() => formatCartItems(lastOrder, products, subscriptions), [products, subscriptions, lastOrder]);

    return (
        <main className={classes.layout}>
            <Paper className={classes.paper}>
                <Typography component="h1" variant="h4" align="center">
                    Résumé
                </Typography>
                <List disablePadding>
                    <CartTableWrapper
                        data={cartTableData.sort(alphabeticalSort("name"))}
                        onDeleteItem={handleDeleteItem}
                        onModifyItem={handleModifyItem}
                        onQuantityChange={handleQuantityChange}
                    />

                    {window.location.pathname.includes("/boutique/checkout") && !window.location.pathname.includes("confirmation") && (
                        <Button
                            style={{ marginTop: 20 }}
                            color="secondary"
                            fullWidth
                            variant="outlined"
                            onClick={() => hist.push("/portail/boutique")}
                        >
                            Continuez vos achats
                        </Button>
                    )}

                    {orders.length !== 0 && upsells.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{ marginTop: 30, fontWeight: "bold" }} align="center">
                                Offre{upsells.length === 1 ? "" : "s"} exclusive{upsells.length === 1 ? "" : "s"}
                            </Typography>
                        </Grid>
                    )}
                    {orders.length !== 0 &&
                        upsells.length > 0 &&
                        upsells.map((upsell, i) => {
                            return (
                                <Upsell
                                    key={i}
                                    item={upsell}
                                    onAccept={() => {
                                        dispatch(addProduct({ ...upsell, selectedQuantity: 1, isUpsell: true }));
                                        setUpsellAccepted(true);
                                        removeFromUpsells(upsell);
                                    }}
                                    onRefuse={() => removeFromUpsells(upsell)}
                                    textAccept="OUI, J'ACHÈTE"
                                    textRefuse="NON, je ne veux pas de ce produit exclusif"
                                    showDescription={false}
                                />
                            );
                        })}
                    <Grid item xs={12} style={{ paddingTop: 10 }}>
                        {orders.length !== 0 && (
                            <TextField
                                className="checkout__promocode"
                                fullWidth
                                type="text"
                                variant="outlined"
                                value={promocode}
                                disabled={promocodeValidated}
                                name="promoCode"
                                onChange={handlePromocodeChange}
                                label="Code promo"
                                onKeyPress={e => {
                                    if (e.key === "Enter") addPromocodeToOrder();
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <Button
                                            style={{ marginRight: 10 }}
                                            variant="contained"
                                            disabled={promocodeValidated}
                                            className={`checkout__next ${brand}`}
                                            onClick={() => addPromocodeToOrder()}
                                        >
                                            Appliquer
                                        </Button>
                                    ),
                                }}
                            />
                        )}
                    </Grid>
                    {promoCodeDescription && (
                        <Typography variant="subtitle2" className={classes.total}>
                            {promoCodeDescription}
                        </Typography>
                    )}

                    {orders.length > 0 && <Divider style={{ marginTop: 10 }} />}
                    {details.billing && orders.length > 0 && window.location.pathname.includes("/checkout/paiement") && (
                        <>
                            {amountSaved !== 0 && (
                                <ListItem className={classes.listItem}>
                                    <ListItemText primary="Promotion" />
                                    <Typography variant="subtitle1" className={classes.total}>
                                        {(lastOrder.amountSaved / 100).toFixed(2)} $
                                    </Typography>
                                </ListItem>
                            )}
                            <ListItem className={classes.listItem}>
                                <ListItemText primary="Livraison" />
                                <Typography variant="subtitle1" className={classes.total}>
                                    {formatPrice(lastOrder.totalShippingCost)} $
                                </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <ListItemText primary="Sous-Total" />
                                <Typography variant="subtitle1" className={classes.total}>
                                    {(lastOrder.totalSub / 100).toFixed(2)} $
                                </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <ListItemText primary={`Taxe fédérale (${settings.order.TPS_NUMBER})`} />
                                <Typography variant="subtitle1" className={classes.total}>
                                    {(lastOrder.totalTaxesFed / 100).toFixed(2)} $
                                </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <ListItemText primary={`Taxe provinciale (${settings.order.TVQ_NUMBER})`} />
                                <Typography variant="subtitle1" className={classes.total}>
                                    {(lastOrder.totalTaxesProv / 100).toFixed(2)} $
                                </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <ListItemText primary="Total" />
                                <Typography variant="subtitle1" className={classes.total}>
                                    {(lastOrder.totalGrand / 100).toFixed(2)} $
                                </Typography>
                            </ListItem>
                        </>
                    )}
                </List>
            </Paper>
        </main>
    );
}
