import React from "react";

// Assets
import values1 from "../../../assets/bs/propos-values1.png";
import values2 from "../../../assets/bs/propos-values2.png";
import values3 from "../../../assets/bs/propos-values3.png";

const Values = () => {
    return (
        <div className="values">
            <div className="values__container">
                <h3 className="values__title">Nos valeurs</h3>
                <div className="values__content">
                    <div className="values__content--item">
                        <h4 className="title">Valeur 1</h4>
                        <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing euismod varius.</p>
                        <img src={values1} alt="" />
                    </div>
                    <div className="values__content--item">
                        <h4 className="title">Valeur 2</h4>
                        <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum tincidunt facilisis.</p>
                        <img src={values2} alt="" />
                    </div>
                    <div className="values__content--item">
                        <h4 className="title">Valeur 3</h4>
                        <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa consequat.</p>
                        <img src={values3} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Values;
