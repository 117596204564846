import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

// Mui
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

// hook form
import { useForm, FormProvider } from "react-hook-form";

import { CheckoutContext } from "./Checkout.jsx";
import Address from "./Address";

import { getLandingPageInfo } from "../../../utils/config";

const Shipping = () => {
    // redux
    const history = useHistory();
    const formMethods = useForm();

    // states
    const { goToNextPage, gotToPreviousStep } = useContext(CheckoutContext);
    const { brand } = getLandingPageInfo();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);

    const handleBack = () => {
        gotToPreviousStep(history);
    };

    const onSubmit = (data, e) => {
        setData(data);
        setOpen(true);
    };

    const ShippingPopup = ({ isOpen }) => {
        if (data) {
            return (
                <Dialog open={isOpen} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Confirmer votre adresse d'expédition</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <h4 style={{ fontWeight: "bold", textTransform: "uppercase" }}>
                                <div>
                                    {data.shipping.appartment ? `${data.shipping.appartment}-` : ""}
                                    {data.shipping.streetNumber} {data.shipping.street}
                                </div>
                                <div>
                                    {data.shipping.city}, {data.shipping.province}, {data.shipping.postalCode}
                                </div>
                                <div>{data.shipping.country}</div>
                            </h4>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Modifier</Button>
                        <Button
                            onClick={() => {
                                setOpen(false);
                                goToNextPage(history.push, history.location.pathname, data);
                            }}
                        >
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        } else return "";
    };

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <Typography variant="h6" gutterBottom>
                    {"Informations d'expédition"}
                </Typography>
                <Grid container spacing={3}>
                    {/* <Grid item xs={12} sm={6}>
                        <TextField
                            autocomplete="given-name"
                            error={formMethods.errors.firstName ? true : false}
                            id="firstName"
                            name="shipping.firstName"
                            label="Prénom"
                            InputLabelProps={{ shrink: true }}
                            inputRef={formMethods.register({
                                required: "Ce champ est requis",
                            })}
                            fullWidth
                            helperText={formMethods.errors.firstName?.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autocomplete="family-name"
                            error={formMethods.errors.lastName ? true : false}
                            id="lastName"
                            name="shipping.lastName"
                            label="Nom"
                            InputLabelProps={{ shrink: true }}
                            inputRef={formMethods.register({
                                required: "Ce champ est requis",
                            })}
                            fullWidth
                            helperText={formMethods.errors.lastName?.message}
                        />
                    </Grid> */}
                    <Address baseObjectName="shipping" />

                    <Grid item xs={12} align="right">
                        <Button onClick={handleBack}>Revenir</Button>
                        <Button type="submit" variant="contained" className={`checkout__next ${brand}`}>
                            Suivant
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <ShippingPopup isOpen={open} />
        </FormProvider>
    );
};

export default Shipping;
