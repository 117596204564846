import React from "react";
import { Helmet } from "react-helmet";
import ReactPixel from "react-facebook-pixel";
// Custom Components
import McfNav from "../components/mcf/home/nav";
import GmNav from "../components/gm/home/nav";
import AktNav from "../components/akt/home/nav";
import GcNav from "../components/gc/home/nav";
import GkNav from "../components/gk/home/nav";
// Settings
import { settings } from "../settings";
// Images
import GmFav from "../assets/gm/favicon.png";
import GcFav from "../assets/gc/favicon.png";
import McfFav from "../assets/mcf/favicon.png";
import gmMarkerIcon from "../assets/gm/marker.png";
import mcfMarkerIcon from "../assets/mcf/marker.png";
import gcMarkerIcon from "../assets/gc/marker.png";
import aktMarkerIcon from "../assets/portal/aktivation-favicon.png";
import aktFav from "../assets/portal/aktivation-favicon.png";
import gkFav from "../assets/gk/gk-favicon.png";
import gcLoading from "../assets/gc/gc_loading.gif";
import gmLoading from "../assets/gm/gm_loading.gif";
import mcfLoading from "../assets/mcf/js_loading.gif";
import aktLoading from "../assets/portal/aktivation_loading.gif";
import gkLoading from "../assets/gk/gk_loading.gif";
import gkMarkerIcon from "../assets/gk/gk-marker.png";
import gmLogo from "../assets/gm/logo-color.png";
import gcLogo from "../assets/gc/logo-color.png";
import mcfLogo from "../assets/mcf/logo-color.png";
import gkLogo from "../assets/gk/logo-gokrisp.png";
import aktLogo from "../assets/portal/logo-aktivation.png";
import gmDrawerLogo from "../assets/shared/gm_drawer_logo.png";
import gcDrawerLogo from "../assets/shared/gc_drawer_logo.png";
import mcfDrawerLogo from "../assets/shared/mcf_drawer_logo.png";
import aktDrawerLogo from "../assets/shared/akt_drawer_logo.png";
import gkDrawerLogo from "../assets/shared/gk_drawer_logo.png";

// const hostName = window.location.hostname.substr(0, window.location.hostname.indexOf("."));
const hostName = window.location.hostname;
const mcf = settings.landingPages.mcf;
const gm = settings.landingPages.gm;
const akt = settings.landingPages.akt;
const gc = settings.landingPages.gc;
const gk = settings.landingPages.gk;

const staticURLs = settings.staticURLs;

const gkProducts = {
  upsells: [
      {
          id: "PsVzdTab2dRctfblOLpw",
          price: 1399,
          text: "Essayez tous nos saveurs ",
      },
  ],
};
const gcProducts = {
    upsells: [
        {
            id: "YDwCLZrBpKrlZGjERjMn",
            price: 1296,
            text: "Essayez tous nos saveurs ",
        },
    ],
};
const aktProducts = {
    upsells: [
        {
            id: "KgtM8ycmRV0ylRnUMZ69",
            price: 1899,
            text: "Économisez 24% en plus d’obtenir la livraison gratuite ",
        },
    ],
};
const mcfPacket = {
    upsells: [
        {
            id: "FkGJhfzNBTJbasNaK2F9",
            price: 1499,
            text: "Une offre spéciale pour vous ",
        },
    ],
};
const gmOriginal = {
    upsells: [
        {
            id: "yiKdcdi3NxFp0Zyt0Ke5",
            price: 1194,
            text: "Essayez tous nos saveurs ",
        },
    ],
};

/**
 * Gets the brand and the market based on the hostname
 * @returns {object} brand and market
 */
export const getLandingPageInfo = () => {
    const market = "Canada";
    const info = {
        mcf: { brand: "Mélange coupe faim", brandAcronym: "MCF", market },
        gm: { brand: "Go Matcha", brandAcronym: "GM", market },
        akt: { brand: "Aktivation", brandAcronym: "AKT", market },
        gc: { brand: "Go Choko", brandAcronym: "GC", market },
        gk: { brand: "Go Krisp", brandAcronym: "GK", market }
    };

    let infoValue = "";
    switch (hostName) {
        case mcf:
            infoValue = "mcf";
            break;
        case gm:
            infoValue = "gm";
            break;
        case akt:
            infoValue = "akt";
            break;
        case gc:
            infoValue = "gc";
            break;
        case gk:
            infoValue = "gk";
            break;
        default:
            infoValue = "gk";
            break;
    }
    return info[infoValue];
};

/**
 * Gets the navbar based on the hostname
 * @returns {Component} the navbar component
 */
export const GetNavbar = () => {
    switch (hostName) {
        case mcf:
            return (
                <div className="mcf">
                    <McfNav />
                </div>
            );
        case gm:
            return (
                <div className="gm">
                    <GmNav />
                </div>
            );
        case akt:
            return (
                <div className="akt">
                    <AktNav />
                </div>
            );
        case gc:
            return (
                <div className="gc">
                    <GcNav />
                </div>
            );
        case gk:
            return (
                <div className="gk">
                    <GkNav />
                </div>
            );
        default:
            return (
                <div className="gk">
                    <AktNav />
                </div>
            );
    }
};

/**
 * Gets the landing page name based on the hostname
 * @returns {string} landing page name
 */
export const getLandingPageName = () => {
    switch (hostName) {
        case mcf:
            return "Mélange coupe faim";
        case gm:
            return "Go matcha";
        case akt:
            return "Aktivation";
        case gc:
            return "Go choko";
        case gk:
            return "Go krisp";
        default:
            return "localhost";
    }
};

/**
 * Gets the helmet based on the hostname
 * @returns {Component} the header component
 */
export const GetHelmet = () => {
    const options = {
        autoConfig: true,
        debug: false,
    };

    switch (hostName) {
        case mcf:
            return (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Mélange Coupe Faim</title>
                    <meta name="description" content="Plus de 100 000 clients satisfaits!" />
                    <link rel="icon" type="image/png" href={McfFav} />
                    {ReactPixel.init("1422379857910508", options)}
                    {ReactPixel.pageView()}
                </Helmet>
            );
        case gm:
            return (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>GoMatcha!</title>
                    <meta name="description" content="Libère la puissance du matcha!" />
                    <link rel="icon" type="image/png" href={GmFav} />
                    {ReactPixel.init("1422379857910508", options)}
                    {ReactPixel.pageView()}
                </Helmet>
            );
        case akt:
            return (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Aktivation!</title>
                    <meta name="description" content="La plateforme d'entraînement 100% québécoise!" />
                    <link rel="icon" type="image/png" href={aktFav} />
                    {ReactPixel.init("1422379857910508", options)}
                    {ReactPixel.pageView()}
                </Helmet>
            );
        case gc:
            return (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Go Choko!</title>
                    <meta name="description" content="La Révolution du chocolat!" />
                    <link rel="icon" type="image/png" href={GcFav} />
                    {ReactPixel.init("1422379857910508", options)}
                    {ReactPixel.pageView()}
                </Helmet>
            );
        case gk:
            return (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Go! Krisp</title>
                    <meta name="description" content="Énergie et plaisir au Carré" />
                    {/* TODO */}
                    <link rel="icon" type="image/png" href={gkFav} />
                    {ReactPixel.init("1422379857910508", options)}
                    {ReactPixel.pageView()}
                </Helmet>
            );
        default:
            return (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Go! Krisp</title>
                    <meta name="description" content="Énergie et plaisir au Carré" />
                    <link rel="icon" type="image/png" href={gkFav} />
                    {ReactPixel.init("1422379857910508", options)}
                    {ReactPixel.pageView()}
                </Helmet>
            );
    }
};

/**
 * Gets the portal's helmet component
 * @param {String} title
 * @returns {Component} the header component
 */
export const GetPortalHelmet = ({ title = "Portail" }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content="Votre entraînement du Québec" />
            <link rel="icon" type="image/png" href={aktFav} />
        </Helmet>
    );
};

/**
 * Gets the marker's icon based on the hostname
 * @returns {string} the marker's icon path
 */
export const getMarkerIcon = () => {
    switch (hostName) {
        case mcf:
            return mcfMarkerIcon;
        case gm:
            return gmMarkerIcon;
        case akt:
            return aktMarkerIcon;
        case gc:
            return gcMarkerIcon;
        case gk:
          return gkMarkerIcon;
        default:
            return aktMarkerIcon;
    }
};

/**
 * Gets the splashscreen image based on the hostname
 * @returns {String} image path
 */
export const getSplashScreenImage = () => {
    switch (hostName) {
        case mcf:
            return mcfLoading;
        case gm:
            return gmLoading;
        case akt:
            return aktLoading;
        case gc:
            return gcLoading;
        case gk:
            return gkLoading;
        default:
            return gkLoading;
    }
};

/**
 * Gets the brand's logo based on the hostname
 */
export const getBrandLogo = () => {
    switch (hostName) {
        case mcf:
            return mcfLogo;
        case gm:
            return gmLogo;
        case akt:
            return aktLogo;
        case gc:
            return gcLogo;
        case gk:
            return gkLogo;
        default:
            return gkLogo;
    }
};

/**
 * Gets the upsell id based on the hostname
 * @returns {String} upsellId
 */
export const getUpsellId = () => {
    switch (hostName) {
        case mcf:
            return mcfPacket.upsells;
        case gm:
            return gmOriginal.upsells;
        case akt:
            return aktProducts.upsells;
        case gc:
            return gcProducts.upsells;
        case gk:
          return gkProducts.upsells;
        default:
            return aktProducts.upsells;
    }
};

/**
 * Gets the drawer logo based on the hostname
 * @returns {String} logoPath
 */
export const getDrawerLogo = () => {
    switch (hostName) {
        case mcf:
            return mcfDrawerLogo;
        case gm:
            return gmDrawerLogo;
        case akt:
            return aktDrawerLogo;
        case gc:
            return gcDrawerLogo;
        case gk:
            return gkDrawerLogo;
        default:
            return gkDrawerLogo;
    }
};

/**
 * Gets the brand static url based on the hostname
 * @returns {string} staticURL
 */
export const getStaticURL = () => {
    switch (hostName) {
        case mcf:
            return staticURLs.mcf;
        case gm:
            return staticURLs.gm;
        case akt:
            return staticURLs.akt;
        case gc:
            return staticURLs.gc;
        case gk:
            return staticURLs.gk;
        default:
            return staticURLs.gk;
    }
};
