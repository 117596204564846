import React from 'react';
import Background from '../../../assets/gm/hero.png'
import BackgroundMobile from '../../../assets/gm/hero_mobile.png';
import Gluten from '../../../assets/gm/gluten.png';
import Signature from '../../../assets/gm/js-signature-green.png';

function Hero() {
  return (
    <div className="hero">
        <img src={Background} alt="background" className="hero__background" />
        <img src={BackgroundMobile} alt="background" className="hero__background__mobile" />
        {/* <img src={Gluten} alt="gluten free" className="hero__gluten" /> */}
        <div className="hero__container">
            <div>
                <span className="hero__1">Libère <br/>la puissance <br/>du Matcha.</span>
                <div className="hero__cta">
                    <a href="#sample"><button className="hero__cta1">Essayez un 'sample pack'</button></a>
                </div>
                {/* <img src={Signature} /> */}
            </div>
        </div>
    </div>
  );
}

export default Hero;