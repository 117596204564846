// DB
import { getRequest } from "../actions/requests";
import { productsRoot } from "../actions/productsRoute";

export const fetchProductById = async id => {
    try {
        const payload = await getRequest(`${productsRoot}/${id}`, {});
        return payload.data;
    } catch (e) {
        console.error(e);
    }
};
