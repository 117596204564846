import React from "react";
import GC from '../../../assets/gc/logo-brown.png';
import Products from '../../../assets/gc/shipping-choko.png';

function Shipping() {
    return (
        <div className="shipping">
            <div className="shipping__top">
                <div className="shipping__container">
                    <img src={GC} />   
                </div>
            </div>
            <div className="shipping__bottom">
                <div className="shipping__container">
                    <span>La livraison est gratuite!</span>
                </div>
            </div>
            <img src={Products} className="shipping__products"/>
        </div>
    );
}

export default Shipping;