import React from 'react';
import Grid from '@material-ui/core/Grid';

import Emoji1 from '../../../assets/gc/emoji1.png';
import Emoji2 from '../../../assets/gc/emoji2.png';
import Emoji3 from '../../../assets/gc/emoji3.png';
import Quote from '../../../assets/gc/icon-quote.png';

function Testimonials() {

    return (
        <div className="testimonials">
            <Grid container>
                <Grid item xs={12} md={6}>
                    <div className="testimonials__item">
                        <Grid container>
                            <Grid item xs={3}>
                                <img src={Emoji1} />
                            </Grid>
                            <Grid item xs={9}>
                                <div className="testimonials__content">
                                    <p>SVP ne commander pas cette sorte là... je veux les garder pour moi... ça goûte juste le ciel en mieux😂🤑 Je vais me créer une addiction au #gochoko</p>
                                    <span>Kathia D.</span>
                                    <img src={Quote} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="testimonials__item offset">
                        <Grid container>
                            <Grid item xs={3}>
                                <img src={Emoji2} />
                            </Grid>
                            <Grid item xs={9}>
                                <div className="testimonials__content">
                                    <p>Savez-vous quoi? Je suis en amour ❤️ avec #gochoko <br/>Qui ne le serait pas? On devient vite accroc à ses petites perles de protéines croustillantes</p>
                                    <span>Lyne O.</span>
                                    <img src={Quote} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="testimonials__item">
                        <Grid container>
                            <Grid item xs={3}>
                                <img src={Emoji3} />
                            </Grid>
                            <Grid item xs={9}>
                                <div className="testimonials__content">
                                    <p>les produits sont tellement bon, on peut plus s'en passer! xoxo</p>
                                    <span>Nathalie R.</span>
                                    <img src={Quote} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="testimonials__right">
                        <div>
                            <span className="testimonials__right__1">Des milliers de clients satisfaits de</span>
                            <span className="testimonials__right__2">Go Choko!</span>
                            <span className="testimonials__right__3">Des milliers de clients satisfaits de partout au Canada font confiance à Go Choko! pour optimiser leur santé avec la meilleure collation hyoerprotéinée! </span>
                            <a href="#produits"><button className="testimonials__cta">Acheter maintenant</button></a>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Testimonials
