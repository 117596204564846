import { snackbarEnqueuedAction } from "../reducers/snackbars";
/*
 * some utility functions that will be used for redux. Mainly for error handling. Can and should be used
 * outside of redux. For exemple, pushSnackbar can be used to show some feedback to user
 * */
export const pushLimitReachedSnackbar = (productName, limitQuantity) => dispatch =>
    pushSnackbar({
        message: `Vous avez atteint la quantité maximale pour ${productName} (${limitQuantity})`,
        options: { variant: "error" },
    })(dispatch);

export const pushOutOfStockSnackbar = itemName => dispatch =>
    pushSnackbar({ message: `${itemName} est en rupture de stock`, options: { variant: "error" } })(dispatch);

export const pushSnackbar =
    ({ message, options }) =>
    dispatch =>
        dispatch(snackbarEnqueuedAction({ message, options }));

export class ProductError extends Error {
    constructor(message, product) {
        super(message);
        this.product = product;
    }
}

export const validateProduct = (product, copiesInCart) => {
    if (product.quantity <= 0) throw new ProductError("out_of_stock", product);
    if (copiesInCart >= product.limitQuantity) throw new ProductError("limit_quantity", product);
};
