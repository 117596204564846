import React, { useEffect } from "react";

import InfiniteScroller from "react-infinite-scroll-component";

// custom component
import Product from "./Product";

// styles
import style from "./Products.module.scss";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getAllProducts, fetchPageFromBoutique as fetchPage } from "../../../../store/reducers/products";

const Products = () => {
    const dispatch = useDispatch();
    const products = useSelector(getAllProducts);
    const hasMore = useSelector(state => !state.products.noMoreData);
    const { page } = useSelector(state => state.products.fetchQueries);

    const fetchMore = () => {
        dispatch(fetchPage({ page: page + 1 }));
    };

    useEffect(() => {
        dispatch(fetchPage({}, { reset: true, resetQuery: true }));
    }, []);

    if (!products.length) return <h2>Oops... Aucun résultat ☹️</h2>;

    return (
        <>
            <InfiniteScroller
                className={style.products__grid}
                dataLength={products.length}
                hasMore={hasMore}
                loader={<h2>Loading...</h2>}
                next={fetchMore}
            >
                {products.map(p => (
                    <Product key={p.id} value={p} />
                ))}
            </InfiniteScroller>
            {!hasMore && (
                <p style={{ textAlign: "center" }}>
                    <b>Il n'y a plus de données</b>
                </p>
            )}
        </>
    );
};

export default Products;
