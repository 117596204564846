import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "../apiCalls";
import { settings } from "../../settings";
import { addSlice } from "./system";
import { stringifyQueries } from "../../utils/parseData";

//  Configuration constants
const baseurl = settings.urls.workouts;
export const sliceName = "workouts";

//  These fields will be looked at by the search filter
const searchFilterFields = ["title"];

// each action in reducers object has a state and action params
const slice = createSlice({
    name: sliceName,

    initialState: {
        list: [], //  null means it hasn't been loaded
        lastFetch: null, //  do we even need this?
        currentPage: 0,
        sortField: "title",
        sortInc: true,
        searchTerm: null,
        lastPage: null,
        loading: false,
        stopRequesting: false,
    },

    reducers: {
        // NEW standard reduces
        refresh: (state, action) => {
            state.list = action.payload || [];
            state.stopRequesting = false;
            state.lastPage = null;
        },
        add: (state, action) => {
            const { payload } = action;

            let data;
            // response from post
            if (payload.result) data = payload.result;
            // reponse from get
            else if (payload) data = payload;

            state.list.push(data);
        },
        append: (state, action) => {
            const { payload } = action;

            if (!payload.length) {
                state.stopRequesting = true;
                return;
            }

            state.list = [...state.list, ...payload];
        },
        update: (state, action) => {
            const { payload } = action;

            const indexOf = state.list.findIndex(element => element.id === payload.id);
            if (indexOf > -1) state.list[indexOf] = payload;
        },
        remove: (state, action) => {
            const { id } = action.payload;

            state.list = state.list.filter(element => element.id !== id);
        },
        fetch: (state, _) => {
            state.loading = true;
        },
        receive: (state, _) => {
            state.loading = false;
        },
        setLastPage: (state, action) => {
            if (!state.lastPage) state.lastPage = action.payload;
        },

        // OLD (legacy) reducers
        //  Updates the current list
        refreshed: (state, action) => {
            state.list = action.payload || [];
            state.lastFetch = Date.now();
        },

        //  Called after successfully creating a new item
        added: (state, action) => {
            if (!Array.isArray(state.list)) state.list = [];
            state.list = [...state.list, action.payload];
        },

        //  Called after successfully updating an existing item
        updated: (state, action) => {
            const index = state.list.findIndex(x => x.id === action.payload.id);
            if (index > -1) state.list[index] = action.payload;
        },

        //  Deletes an item by ID
        //  (Can't call it "delete")
        deleteItem: (state, action) => {
            state.list = state.list.filter(x => x.id !== action.payload.id);
        },

        //  Changes the current page
        changePage: (state, action) => {
            state.currentPage = action.payload;
        },

        //  Changes the sorting field
        changeSortField: (state, action) => {
            state.sortField = action.payload;
        },

        //  Changes the sorting direction
        changeSortInc: (state, action) => {
            state.sortInc = action.payload;
        },

        //  Search
        search: (state, action) => {
            state.searchTerm = action.payload;
            state.currentPage = 0;
        },

        //  Clear search
        searchClear: (state, action) => {
            state.searchTerm = null;
            state.currentPage = 0;
        },
    },
});

export default slice.reducer;

//  Exporting actions
export const {
    refreshed,
    deleteItem,
    updated,
    added,
    changePage,
    search,
    searchClear,
    changeSortInc,
    changeSortField,
    changeSortDirection,
} = slice.actions;

const actions = slice.actions;

//  Standard action creators -------------------------------------------------------------------------------------

//  Loads all items
export const loadItems = () => dispatch => {
    dispatch(
        apiCallBegan({
            url: `${baseurl}/all`,
            onSuccess: [refreshed.type],
        })
    );
};

//  Refreshes a single item from the backend
export const getById = id => dispatch => {
    dispatch(
        apiCallBegan({
            url: `${baseurl}/getById`,
            method: "post",
            data: { id },
            onSuccess: added.type,
        })
    );
};

//  Refreshes a single item from the backend
export const deleteById = (id, hard = false) => dispatch => {
    dispatch(
        apiCallBegan({
            url: `${baseurl}/delete`,
            method: "post",
            data: { id, hard },
            onSuccess: deleteItem.type,
        })
    );
};

//  Standard selectors --------------------------------------------------------------------------------------

//  Returns all items, unsorted
export const allUnsorted = state => state[sliceName].list;

//  Returns the total count of items (excluding deleted)
export const itemCount = state => (state[sliceName].list ? state[sliceName].list.filter(x => !x.isDeleted).length : 0);

//  Returns a single item by id
export const itemById = id =>
    createSelector(
        state => state[sliceName].list,
        list => (list ? list.find(x => x.id === id) : null)
    );
// export const itemById = (state, id) => {
//     const items = state[sliceName].list;
//     if (!items) return undefined;
//     return items.filter(x => x.id === id)[0] || null;
// };

//  Filter settings selectors
export const currentPage = state => state[sliceName].currentPage;

//  Returns filter conditions
export const currentFilter = state => ({
    sortField: state[sliceName].sortField,
    sortInc: state[sliceName].sortInc,
    searchTerm: state[sliceName].searchTerm || null,
});

//  Returns the current page of the sorted dataset
export const pageContent = () =>
    createSelector([allUnsorted, currentPage, currentFilter], (allItems, currentPage, currentFilter) => {
        if (!allItems) return [];

        if (allItems.phases) allItems.phases = allItems.phases.sort((a, b) => (a.release < b.release ? -1 : 1));
        if (allItems.items) allItems.items = allItems.items.sort((a, b) => (a.title < b.title ? -1 : 1));

        const rowsPerPage = settings.page.rowsPerPage;
        const startPos = currentPage * rowsPerPage;

        return [...allItems]
            .filter(x => !x.isDeleted)
            .filter(
                x =>
                    !currentFilter.searchTerm ||
                    searchFilterFields.some(field => x[field].toLowerCase().startsWith(currentFilter.searchTerm.toLowerCase()))
            )
            .sort((a, b) =>
                a[currentFilter.sortField] < b[currentFilter.sortField] ? (currentFilter.sortInc ? 1 : -1) : currentFilter.sortInc ? -1 : 1
            )
            .slice(startPos, startPos + rowsPerPage);
    });

//  Custom(ised) selectors --------------------------------------------------------------------------------------

// --- [NEW standard action creators and selectors] ---
export const actionCreators = {
    create: userInfo => dispatch => dispatch(apiCallBegan({ url: baseurl + "/", method: "post", data: userInfo, onSuccess: actions.add.type })),
    /*
     * if no userId is provided, then we fetch all users. Otherwise, we fetch the user with the given userId
     * */
    fetchData: ({ id, queries, refresh } = { id: null, queries: null, refresh: false }) => (dispatch, getState) => {
        const method = "get";
        let onSuccess = [refresh ? actions.refresh.type : actions.append.type, addSlice(sliceName)];

        let url = baseurl + "/";

        const state = getState()[sliceName];

        if (state.stopRequesting) return;

        if (id) {
            url += id;
            onSuccess = [actions.add.type, addSlice(sliceName)];
        } else if (queries) {
            const data = state.list;
            // this case covers the situation where we already have the data.
            // We do not make the request. This is by far an unoptimal solution
            if (queries.hasOwnProperty("limit") && queries.hasOwnProperty("page")) {
                if (data.length > queries.page * queries.limit) return;
            }
            url += stringifyQueries(queries);
        }

        dispatch(apiCallBegan({ method, url, onSuccess, onStart: actions.fetch.type, onFinish: actions.receive.type }));
    },
    update: userInfo => dispatch =>
        dispatch(apiCallBegan({ url: `${baseurl}/${userInfo.id}`, method: "put", data: userInfo, onSuccess: actions.update(userInfo) })),
    remove: id => dispatch => dispatch(apiCallBegan({ url: `${baseurl}/${id}`, method: "delete", onSuccess: actions.remove(id) })),
    signIn: () => dispatch => dispatch(apiCallBegan({ url: `${baseurl}/me`, method: "get", onSuccess: actions.setCurrentUser.type })),
    signOut: () => dispatch => dispatch({ type: actions.setCurrentUser.type, payload: { signOut: true } }),
    setLastPage: page => dispatch => dispatch({ type: actions.setLastPage.type, payload: page }),
};

export const selectors = {
    all: state => state[sliceName].list,
    getCurrentUser: state => state[sliceName].currentUser,
    noMoreData: state => state[sliceName].stopRequesting,
    isLoading: state => state[sliceName].loading,
    getLastPage: state => state[sliceName].lastPage,
    getPage: (pageNum, pageSize = 10) =>
        createSelector(selectors.all, elements => {
            const start = pageNum * pageSize;
            const end = (pageNum + 1) * pageSize;
            const page = elements.slice(start, end);
            return page;
        }),
    getById: id =>
        createSelector(selectors.all, elements => {
            return elements.find(u => u.id === id);
        }),
};

export const workouts = { sliceName, reducer: slice.reducer, actions: slice.actions, actionCreators, selectors };
