import React, { useState } from "react";

import clsx from "clsx";

// Mui
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";

// Mui icons
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

// style
import style from "./Filter.module.scss";

// hooks
import useModal from "../../../../../hooks/useModal";

const Option = ({ option, checked, onChange }) => {
    return (
        <div className={style.option}>
            <span>{option}</span>
            <Checkbox color="secondary" name={option} checked={checked} onChange={onChange} />
        </div>
    );
};

const OptionWithSuboptions = ({ option, suboptions, checked, onChange }) => {
    const [open, setOpen] = useState(false);
    return (
        <div className={clsx(style.with__suboptions)}>
            <Button className={style.main__option} onClick={() => setOpen(o => !o)}>
                {option}
                {open ? <ExpandLess /> : <ExpandMore />}
            </Button>
            <Collapse in={open}>
                <div className={style.suboptions}>
                    {suboptions.map(so => {
                        const name = option + "-" + so;
                        return (
                            <div key={name} className={style.suboption}>
                                <span>{so}</span>
                                <Checkbox color="secondary" name={so} checked={checked.includes(name)} name={name} onChange={onChange} />
                            </div>
                        );
                    })}
                </div>
            </Collapse>
        </div>
    );
};

const Filter = ({ Icon, title, subcomponent, onClick, onChange, options = [] }) => {
    const [Modal, present] = useModal();

    const [checked, setChecked] = useState([]);

    const handleClick = () => {
        if (!noSubcomponent) return;
        if (typeof onClick === "function") return onClick();
        present({
            title: (
                <div>
                    <h2 className={style.title}>{title}</h2>
                </div>
            ),
            contentText: "Choisié au plus 10 choix",
        });
    };

    const handleCheckboxChange = e => {
        const { name, checked } = e.target;
        setChecked(c => {
            let newValue;
            if (!checked) {
                newValue = c.filter(checked => checked !== name);
            } else {
                newValue = [...c, name];
            }

            onChange(newValue);
            return newValue;
        });
    };

    const noSubcomponent = typeof subcomponent === "undefined";

    return (
        <div className={clsx(style.container, noSubcomponent ? "clickable__tag" : "")}>
            <section className={style.label} onClick={handleClick}>
                {Icon}
                <span className={style.title}>{title}</span>
            </section>
            {!noSubcomponent && subcomponent}
            <Modal>
                <div style={style} className={style.modal}>
                    {options.map(opt => {
                        if (Array.isArray(opt)) {
                            const [option, suboptions] = opt;
                            return (
                                <OptionWithSuboptions
                                    key={option}
                                    option={option}
                                    suboptions={suboptions}
                                    checked={checked}
                                    onChange={handleCheckboxChange}
                                />
                            );
                        }
                        return <Option key={opt} option={opt} checked={checked.includes(opt)} onChange={handleCheckboxChange} />;
                    })}
                </div>
            </Modal>
        </div>
    );
};

export default Filter;
