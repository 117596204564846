import React from "react";

const Header = () => {
	return (
		<div className="header">
			<div className="header__container">
				<p className="header__section">Notre engagement</p>
				<h1 className="header__title">
        Le meilleur dans votre assiette
				</h1>
			</div>
		</div>
	);
};

export default Header;
