import React from "react";
import { useHistory } from "react-router-dom";

import { Grid } from "@material-ui/core";

import Yogourt from "../../../assets/mcf/yogourt.png";
import Muffin from "../../../assets/mcf/muffin.png";
import Compote from "../../../assets/mcf/compote.png";
import Salade from "../../../assets/mcf/salade.png";
import Image from "../../../assets/mcf/use.png";

function Use() {
  const history = useHistory();
  return (
    <div className="use">
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className="use__left">
            <img src={Image} alt="" />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="use__right">
            <div className="use__right__container">
              <h3 className="use__title no-padding-top">Comment l'utiliser</h3>
              <span className="use__subtitle">15 à 30ml dans:</span>
              <div className="use__items">
                <Grid container className="use__items__mobile__center">
                  <Grid item sm={12} md={3}>
                    <div className="use__item">
                      <img src={Yogourt} alt="" />
                      <span>Yogourt</span>
                    </div>
                  </Grid>

                  <Grid item sm={12} md={3}>
                    <div className="use__item">
                      <img src={Muffin} alt="" />
                      <span>Recettes de pains et muffins</span>
                    </div>
                  </Grid>

                  <Grid item sm={12} md={3}>
                    <div className="use__item">
                      <img src={Compote} alt="" />
                      <span>Compote de pommes</span>
                    </div>
                  </Grid>

                  <Grid item sm={12} md={3}>
                    <div className="use__item">
                      <img src={Salade} alt="" />
                      <span>En garniture sur les salades </span>
                    </div>
                  </Grid>
                </Grid>
                <a
                  href="https://www.jimmysevigny.com/blogue/alimentation/"
                  target="_blank"
                >
                  <button className="use__cta">VOIR NOS RECETTES</button>
                </a>
              </div>
              <p className="use__disclaimer">
                *Afin d'augmenter l'efficacité du MCF 2.0, assurez-vous de vous
                hydrater adéquatement
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Use;
