import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { EditorState, convertToRaw, Editor } from "draft-js";

// Material UI Core
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";

// DB
import { postRequest } from "../../../actions/requests";
import { createDuoSubscription } from "../../../actions/usersRoute";

// redux
import { useDispatch, useSelector } from "react-redux";
import { addOrder, clearOrders, getLoading, updateOrder } from "../../../store/reducers/orders";
import { getCheckoutDetails, getDuoEmail, getSubscribedAktUser } from "../../../store/reducers/checkout";
import { snackbarEnqueuedAction } from "../../../store/reducers/snackbars";
import useSlice from "../../../hooks/useSlice";

// stripe
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

// Custom Components
import { CheckoutContext } from "./Checkout";
import { UserContext } from "../../../reducer/userContext";
import AccountDialog from "./accountDialog";

// Utils
import { settings } from "../../../settings";
import { parseDate, getTimestampDifferenceInDay } from "../../../utils/parseData";
import { generateOrder } from "../../../utils/order";

import useWithTriggerProduct from "../../../hooks/useWithTriggerProduct";

const ErrorMessage = ({ children }) => <div className="checkout__form__error">{children}</div>;

const formatAddress = coordinates => {
    const c = { ...coordinates };

    if (c.appartment !== "") {
        c.streetNumber = `${c.appartment} - ${c.streetNumber}`;
        delete c.appartment;
    }
    return Object.values(c)
        .filter(c => c !== "")
        .join(", ");
};

const parseFreeTrialTimeframe = timeframe => {
    if (timeframe.includes("d", timeframe.length - 1)) return timeframe.replace("d", " jours");
    if (timeframe.includes("w", timeframe.length - 1)) return timeframe.replace("w", " semaines");
};

const itemInSubHasFreeTrial = subs => {
    for (const item of subs) {
        if (item.freeTrial) return true;
    }

    return false;
};

const NOTE_MAX_LENGTH = 160;
const PaymentNew = () => {
    const history = useHistory();
    // Redux
    const details = useSelector(getCheckoutDetails);
    const orderSlice = useSlice({ sliceName: "orders" });
    const orders = orderSlice.selectors.all;
    const orderLoading = useSelector(getLoading);
    const duoEmail = useSelector(getDuoEmail);
    const aktSubscribedUser = useSelector(getSubscribedAktUser);
    const lastOrder = orders[orders.length - 1];

    useWithTriggerProduct(orders[orders.length - 1]);

    // Notes
    const [newNote, setNewNote] = useState(EditorState.createEmpty());

    // states
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const [isGuestCheckout, setIsGuestCheckout] = useState(false);
    const [errors, setErrors] = useState(null);
    const [recurrenceAccepted, setRecurrenceAccepted] = useState(false);
    const [freeTrialAccepted, setFreeTrialAccepted] = useState(false);
    const {
        promoCode,
        products,
        subscriptions,
        brand,
        brandAcronym,
        market,
        setStripePaymentMethod,
        setStripeCustomerId,
        goToNextPage,
        gotToPreviousStep,
        isDuo,
        duoUser,
    } = useContext(CheckoutContext);
    const { user } = useContext(UserContext);
    const [activeSubMessage, setActiveSubMessage] = useState("");

    // Context
    const { upsellAccepted } = useContext(CheckoutContext);

    // TODO: remove when actual loading system has been implemented
    const [loading, setLoading] = useState(false);

    // Modal
    const [isAccountOpen, setIsAccountOpen] = useState(true);

    const handleStripeError = error => {
        if (error.code) {
            switch (error.code) {
                case "incomplete_number":
                    setErrors({ message: "Numéro de carte incomplet" });
                    break;
                case "invalid_number":
                    setErrors({ message: "Numéro de carte invalide" });
                    break;
                case "incomplete_expiry":
                    setErrors({ message: "Date d'expiration invalide" });
                    break;
                case "incomplete_cvc":
                    setErrors({ message: "Code de sécurité invalide" });
                    break;
                case "incomplete_zip":
                    setErrors({ message: "Code postal invalide" });
                    break;
                default:
                    setErrors(error);
                    break;
            }
        }
    };

    const handleBack = () => {
        // dispatch(deleteOrder(orders[orders.length - 1].id, true));
        dispatch(clearOrders());
        gotToPreviousStep(history);
    };

    const handleAccountClose = () => {
        if (!user) setIsAccountOpen(true);
        if (isGuestCheckout) setIsAccountOpen(false);
    };

    // Duo user stuff
    const handlePostTransaction = transaction => {
        // Is duo sub
        if (isDuo) {
            // User exists
            if (duoEmail && _.isEmpty(duoUser)) {
                postRequest(`${createDuoSubscription}/${duoEmail}`, {
                    stripePayment: transaction.stripePayments[0],
                    subId: transaction.order.items.find(i => i.isSubscription).id,
                    masterId: user.id,
                    slaveId: duoUser?.id ?? null,
                });

                // New user
            } else if (!_.isEmpty(duoUser)) {
                postRequest(`${createDuoSubscription}/${duoEmail}`, {
                    firstName: duoUser.firstName,
                    lastName: duoUser.lastName,
                    password: duoUser.password,
                    source: duoUser.source,
                    stripePayment: transaction.stripePayments[0],
                    subId: transaction.order.items.find(i => i.isSubscription).id,
                    masterId: user.id,
                    slaveId: duoUser?.id ?? null,
                });
            }
        }
    };

    /**
     * Checks if the current user has an active akt sub that can be extended
     */
    const CheckIfActiveAktSub = () => {
        if (!_.isEmpty(aktSubscribedUser) && !aktSubscribedUser.used) {
            if (parseDate(Date.now()) < parseDate(aktSubscribedUser.nextCycle)) {
                const days = getTimestampDifferenceInDay(Date.now(), aktSubscribedUser.nextCycle);
                setActiveSubMessage(
                    `Vous avez un abonnement actif dans l'ancien système pour encore ${days} jour${
                        days > 1 ? "s" : ""
                    }. Le paiement se prendra à ce moment, si le renouvellement n'est pas désactivé (onglet abonnement de mon profil)`
                );
            }
        }
    };

    const handleSubmit = async e => {
        try {
            e.preventDefault();

            let createTransactionPayload = {};
            const note = JSON.stringify(convertToRaw(newNote.getCurrentContent()));

            if (!orders || orders.length === 0 || !orders[orders.length - 1].totalGrand) {
                return dispatch(
                    snackbarEnqueuedAction({
                        message: "Commande invalide",
                        options: {
                            variant: "error",
                        },
                    })
                );
            }

            // Free order
            if (orders[orders.length - 1].totalGrand === 0) {
                createTransactionPayload = await postRequest(`${settings.urls.transactions}/${orders[orders.length - 1].id}`, {
                    stripeCustomerId: null,
                    stripePaymentMethod: null,
                    note,
                });
            } else {
                const card = elements.getElement(CardElement);

                if (!stripe || !elements) return;

                setLoading(true);

                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: "card",
                    card,
                });

                if (error) {
                    handleStripeError(error);
                    setLoading(false);
                } else {
                    setStripePaymentMethod(paymentMethod.id);
                    setErrors(null);

                    createTransactionPayload = await postRequest(`${settings.urls.transactions}/${orders[orders.length - 1].id}`, {
                        stripeCustomerId: orders[orders.length - 1].customerId,
                        stripePaymentMethod: paymentMethod.id,
                        note,
                    });
                }
            }

            setLoading(false);
            if (createTransactionPayload.data.result.transactionStatus === "Completed") {
                setStripeCustomerId(createTransactionPayload.data.result.stripeCustomerId);
                handlePostTransaction(createTransactionPayload.data.result);
                goToNextPage(history.push, history.location.pathname);
            }
        } catch (e) {
            setLoading(false);
            if (e.response) {
                dispatch(
                    snackbarEnqueuedAction({
                        message: e.response.data.errorMessage,
                        options: {
                            variant: "error",
                        },
                    })
                );
            } else console.error(e);
        }
    };

    useEffect(() => {
        if (!_.isEmpty(aktSubscribedUser)) setFreeTrialAccepted(true);
    }, [aktSubscribedUser]);

    useEffect(() => {
        if (!subscriptions.length > 0) {
            setRecurrenceAccepted(true);
        } else {
            CheckIfActiveAktSub();
        }
    }, [subscriptions]);

    useEffect(() => {
        if (!orderLoading && loading) {
            setLoading(false);
        }
    }, [orderLoading]);

    // Gets the order
    useEffect(async () => {
        try {
            if (user) {
                setIsAccountOpen(false);
            }

            const order = generateOrder(subscriptions, products, details, user, promoCode, brand, brandAcronym, market);
            if (!lastOrder) dispatch(addOrder(order));
            else dispatch(updateOrder(lastOrder.id, order));
        } catch (e) {
            if (e.response) {
                dispatch(
                    snackbarEnqueuedAction({
                        message: e.response.data.errorMessage,
                        options: {
                            variant: "error",
                        },
                    })
                );
                handleBack();
            } else console.error(e);
        }
    }, [user]);

    // In the case of an upsell we need to update the order
    useEffect(() => {
        if (products.length !== 0 && orders.length !== 0 && upsellAccepted) {
            const order = generateOrder(subscriptions, products, details, user, promoCode, brand, brandAcronym, market);

            // Modify order
            dispatch(updateOrder(orders[orders.length - 1].id, { ...order }));
        }
    }, [products]);

    // if there are not subs, set free trial condition to true in order to enable the 'payer' button.
    // if none of the subs have a free trial property then also enable the payer button
    // also enable the button if the user has already used his/her free trial
    useEffect(() => {
        if (subscriptions.length === 0) {
            setFreeTrialAccepted(true);
        } else if (subscriptions.length > 0) {
            let noneHaveFreeTrial = true;
            for (const sub of subscriptions) {
                if (sub.freeTrial) noneHaveFreeTrial = false;
            }

            if (noneHaveFreeTrial) setFreeTrialAccepted(true);
        }
        if (user && user.videoTrialUsed) setFreeTrialAccepted(true);
    }, [subscriptions, user]);

    const getLengthOfSelectedText = () => {
        const currentSelection = newNote.getSelection();
        const isCollapsed = currentSelection.isCollapsed();

        let length = 0;

        if (!isCollapsed) {
            const currentContent = newNote.getCurrentContent();
            const startKey = currentSelection.getStartKey();
            const endKey = currentSelection.getEndKey();
            const startBlock = currentContent.getBlockForKey(startKey);
            const isStartAndEndBlockAreTheSame = startKey === endKey;
            const startBlockTextLength = startBlock.getLength();
            const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
            const endSelectedTextLength = currentSelection.getEndOffset();
            const keyAfterEnd = currentContent.getKeyAfter(endKey);
            if (isStartAndEndBlockAreTheSame) {
                length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
            } else {
                let currentKey = startKey;

                while (currentKey && currentKey !== keyAfterEnd) {
                    if (currentKey === startKey) {
                        length += startSelectedTextLength + 1;
                    } else if (currentKey === endKey) {
                        length += endSelectedTextLength;
                    } else {
                        length += currentContent.getBlockForKey(currentKey).getLength() + 1;
                    }

                    currentKey = currentContent.getKeyAfter(currentKey);
                }
            }
        }

        return length;
    };

    const handleBeforeInput = () => {
        const currentContent = newNote.getCurrentContent();
        const currentContentLength = currentContent.getPlainText("").length;
        const selectedTextLength = getLengthOfSelectedText();

        if (currentContentLength - selectedTextLength > NOTE_MAX_LENGTH - 1) {
            return "handled";
        }
    };

    const handlePastedText = pastedText => {
        const currentContent = newNote.getCurrentContent();
        const currentContentLength = currentContent.getPlainText("").length;
        const selectedTextLength = getLengthOfSelectedText();

        if (currentContentLength + pastedText.length - selectedTextLength > NOTE_MAX_LENGTH) {
            return "handled";
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {orders[orders.length - 1] && orders[orders.length - 1].totalGrand !== 0 && (
                <Typography variant="h6" gutterBottom>
                    Méthode de paiement
                </Typography>
            )}
            <Grid container spacing={3}>
                {orders[orders.length - 1] && orders[orders.length - 1].totalGrand !== 0 && (
                    <>
                        <Grid item xs={12}>
                            <CardElement
                                options={{
                                    style: {
                                        base: {
                                            fontSize: "16px",
                                            color: "#424770",
                                            "::placeholder": {
                                                color: "#aab7c4",
                                            },
                                        },
                                        invalid: {
                                            color: "#9e2146",
                                        },
                                    },
                                }}
                            />
                            {errors && <ErrorMessage>{errors.message}</ErrorMessage>}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={recurrenceAccepted}
                                        onChange={e => setRecurrenceAccepted(e.target.checked)}
                                        name="checkedB"
                                        color="primary"
                                        style={!subscriptions.length > 0 ? { display: "none" } : {}}
                                    />
                                }
                                label={
                                    !subscriptions.length > 0
                                        ? ""
                                        : "Je consens à être facturé de manière récurrente en fonction de l'abonnement choisi si le renouvellement est toujours actif (onglet abonnement de mon profil)"
                                }
                            />
                        </Grid>
                    </>
                )}
                {user && !user.videoTrialUsed && itemInSubHasFreeTrial(subscriptions) && _.isEmpty(aktSubscribedUser) && (
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={freeTrialAccepted}
                                    onChange={e => setFreeTrialAccepted(e.target.checked)}
                                    name="freeTrialAccepted"
                                    color="primary"
                                    style={!subscriptions.length > 0 ? { display: "none" } : {}}
                                />
                            }
                            label={
                                subscriptions.length > 0 &&
                                `Je consens à être facturé suite à mon essai gratuit, soit dans ${parseFreeTrialTimeframe(
                                    subscriptions[0].freeTrial.timeframe
                                )}, si je ne l'ai pas désactivé (onglet abonnement de mon profil)`
                            }
                        />
                    </Grid>
                )}
                {activeSubMessage && (
                    <Grid item>
                        <Typography variant="body2" gutterBottom style={{ fontWeight: "bold", color: "red" }}>
                            {activeSubMessage}
                        </Typography>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Grid item>
                        <Typography variant="h6">Détails d'expédition</Typography>
                    </Grid>
                    <Grid item>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" gutterBottom>
                                {`${details.firstName} ${details.lastName}`}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {formatAddress(details.isBillingShippingSame ? details.billing : details.shipping)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <div className="new__note__container">
                        <Editor
                            editorState={!_.isEmpty(newNote) && newNote}
                            onChange={setNewNote}
                            placeholder="Entrer une note"
                            handleBeforeInput={handleBeforeInput}
                            handlePastedText={handlePastedText}
                        />
                    </div>
                    <span style={{ fontFamily: "Roboto", color: "grey", fontSize: "0.8rem" }}>Limite de 160 charactères</span>
                </Grid>

                <Grid item xs={12} align="right">
                    <Button onClick={handleBack}>Revenir</Button>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={
                            !stripe ||
                            !elements ||
                            !recurrenceAccepted ||
                            !freeTrialAccepted ||
                            products.concat(subscriptions).length === 0 ||
                            loading
                        }
                    >
                        Payer {loading ? <CircularProgress size={10} /> : ""}
                    </Button>
                </Grid>
            </Grid>
            <AccountDialog
                open={isAccountOpen}
                setOpen={setIsAccountOpen}
                isGuestCheckout={isGuestCheckout}
                setIsGuestCheckout={setIsGuestCheckout}
                onClose={handleAccountClose}
            />
        </form>
    );
};

export default PaymentNew;
