import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "../apiCalls";
import { settings } from "../../settings";

// each action in reducers object has a state and action params
const slice = createSlice({
    name: "markets",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
    },
    reducers: {
        received: (market, action) => {
            market.list = action.payload;
            market.loading = false;
            market.lastFetch = Date.now();
        },
        requested: (market, action) => {
            market.loading = true;
        },
        requestedFailed: (market, action) => {
            market.loading = false;
        },
    },
});

const { requested, received, requestedFailed } = slice.actions;
export default slice.reducer;

// Action creators
export const loadMarkets = () => (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: `${settings.urls.markets}/all`,
            onStart: requested.type,
            onSuccess: received.type,
            onError: requestedFailed.type,
        })
    );
};

// Selectors
export const getAllMarkets = createSelector(
    state => state.markets.list,
    state => state,
    (list, state) => list
);

export const getMarketByName = marketName =>
    createSelector(
        state => state.markets.list,
        state => state,
        (list, state) => list.find(market => market.name === marketName)
    );

export const getLoading = createSelector(
    state => state.markets.loading,
    loading => loading
);
