import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, FormControlLabel, Checkbox } from "@material-ui/core";

const ConfirmDialog = forwardRef((props, ref) => {
    const [isOpen, setOpen] = useState(false);
    const [title, setTitle] = useState("Alerte");
    const [text, setText] = useState("");
    const [checkbox, setCheckbox] = useState(null);
    const [image, setImage] = useState(null);

    const [buttons, setButtons] = useState([
        {
            class: "lh__button__danger",
            text: "Oui",
        },
        {
            variant: "outlined",
            class: "primary",
            text: "No",
        },
    ]);

    const onClose = () => setOpen(false);
    useImperativeHandle(ref, () => ({
        show(config) {
            if (config.title) setTitle(config.title);
            if (config.text) setText(config.text);
            if (config.buttons) setButtons(config.buttons);
            if (config.checkbox) setCheckbox(config.checkbox);
            if (config.image) setImage(config.image);

            setOpen(true);
        },

        hide() {
            onClose();
        },
    }));

    return (
        <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid spacing={1} container justify="center" alignItems="center">
                        {image && (
                            <Grid xs={12} md={6} item>
                                <img src={image} width="auto" alt="notification" style={{ maxHeight: 200, maxWidth: 250 }} />
                            </Grid>
                        )}
                        <Grid md={image ? 6 : 12} item>
                            {text}
                        </Grid>
                    </Grid>
                </DialogContentText>
                {props.children}
            </DialogContent>
            <DialogActions>
                {checkbox && (
                    <FormControlLabel
                        control={<Checkbox checked={checkbox.checked} onChange={() => setCheckbox({ ...checkbox, checked: !checkbox.checked })} />}
                        label={checkbox.label}
                    />
                )}

                {buttons.map((button, index) => (
                    <Button
                        key={`btn_${index}`}
                        variant={button.variant || null}
                        color={button.color}
                        className={button.class}
                        onClick={() => {
                            if (button.action) button.action.call(null, checkbox ? checkbox.checked : null);
                            setOpen(false);
                        }}
                    >
                        {button.text}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
});

export default ConfirmDialog;
