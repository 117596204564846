import React from "react";
// Pages
import Hero from "./home/hero";
import Nav from "./home/nav";
import Intro from "./home/intro";
import Clients from "./home/clients";
import Cta from "./home/cta";
import Ingrediants from "./home/ingrediants";
import Products from "./home/products";
import Shipping from "./home/shipping";
import Use from "./home/use";
import Socials from "./home/socials";
import Testimonials from "./home/testimonials";
import Newsletter from "./home/newsletter";
import Faq from "./home/faq";
import Subfooter from "./home/subfooter";
import Footer from "./home/footer";
import FooterNav from "./home/footernav";
import Lh from "./home/lh";
// Custom Components
import { GetHelmet } from "../../utils/config";

function Home() {
    return (
        <div className="mcf">
            <GetHelmet />
            <div
                className="fb-customerchat"
                attribution="setup_tool"
                page_id="449353528453102"
                theme_color="#005363"
                logged_in_greeting="Bienvenue sur Mélange Coupe Faim! Comment puis-je vous aider?"
                logged_out_greeting="Bienvenue sur Mélange Coupe Faim! Comment puis-je vous aider?"
            ></div>
            <Nav />
            <Hero />
            <Intro />
            <Clients />
            <Cta />
            <Ingrediants />
            <Socials />
            <Products />
            <Shipping />
            <Use />
            <Testimonials />
            <Newsletter />
            <Faq />
            <Subfooter />
            <Footer />
            <FooterNav />
            <Lh />
        </div>
    );
}

export default Home;
