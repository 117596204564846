import React, { useState, useEffect } from "react";
import MuiPhoneInput from "material-ui-phone-number";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
// Material UI Core
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// Custom Components
import { usersRoot } from "../../../../actions/usersRoute";
import { useDispatch } from "react-redux";
import { apiCallBegan } from "../../../../store/apiCalls";
// Firebase
import { auth } from "../../../../configs/firebase";
// validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postalCodeRegex } from "../../../../utils/regex";

const requiredMessage = "Ce champ est requis";

const infoSchema = yup.object({
    firstName: yup.string().required(requiredMessage),
    lastName: yup.string().required(requiredMessage),
    email: yup.string().required(requiredMessage),
    phone: yup
        .string()
        .min(17, "Veuillez corriger le format +1 (555) 555-5555")
        .max(17, "Veuillez corriger le format +1 (555) 555-5555")
        .required(requiredMessage),
    streetNumber: yup.string().required(requiredMessage),
    street: yup.string().required(requiredMessage),
    city: yup.string().required(requiredMessage),
    province: yup.string().required(requiredMessage),
    postalCode: yup.string().matches(postalCodeRegex, "Veuillez corriger le format (A1B 2C3)").required(requiredMessage),
    country: yup.string().required(requiredMessage),
    appartment: yup.string(),
});

const passwordSchema = yup.object({
    password: yup.string().min(6, "Doit contenir au minimum 6 caractères").required(requiredMessage),
    passwordConfirm: yup
        .string()
        .oneOf([yup.ref("password"), null], "Les mots de passe doivent être identiques")
        .min(6, "Doit contenir au minimum 6 caractères")
        .required(requiredMessage),
});

const infoDefaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    streetNumber: "",
    street: "",
    city: "",
    province: "québec",
    postalCode: "",
    country: "",
    appartment: "",
};

const passwordDefaultValues = {
    passwordConfirm: "",
    streetNumber: "",
};

const Info = ({ currentUser }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { register, handleSubmit, errors, setValue, watch, control, getValues } = useForm({
        resolver: yupResolver(infoSchema),
        defaultValues: infoDefaultValues,
    });
    const {
        register: register2,
        handleSubmit: handleSubmit2,
        errors: errors2,
    } = useForm({ resolver: yupResolver(passwordSchema), defaultValues: passwordDefaultValues });

    const phone = watch("phone");

    const onInfoSubmit = data => {
        let user = {
            ...currentUser,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            coordinates: {
                streetNumber: data.streetNumber,
                appartment: data.appartment,
                street: data.street,
                city: data.city,
                province: data.province,
                country: data.country,
                postalCode: data.postalCode,
            },
        };
        if (data.phone.length > 2) {
            // Removes the formatted phone characters
            user.phone = data.phone.replace(/[- )(]/g, "");
        }

        dispatch(apiCallBegan({ url: `${usersRoot}/${currentUser.id}`, method: "put", data: user }));
    };

    const onPasswordSubmit = async data => {
        try {
            await auth.currentUser.updatePassword(data.password);
            enqueueSnackbar("Le mot de passe a été changé", { variant: "success" });
            setValue("password", "");
            setValue("passwordConfirm", "");
        } catch (e) {
            switch (e.code) {
                case "auth/invalid-password":
                    console.error("Password must be at least 6 character long");
                    enqueueSnackbar("Le mot de passe doit contenir au minimum 6 caractères", { variant: "error" });
                    break;
                case "auth/requires-recent-login":
                    console.error("Recent login required");
                    enqueueSnackbar("Vous devez vous être authentifié récemment", { variant: "error" });
                    auth.signOut();
                    history.push("/portail/login");
                    break;
                default:
                    console.error(e);
                    break;
            }
        }
    };

    useEffect(() => {
        if (currentUser) {
            setValue("firstName", currentUser.firstName ? currentUser.firstName : "");
            setValue("lastName", currentUser.lastName ? currentUser.lastName : "");
            setValue("email", currentUser.email ? currentUser.email : "");
            setValue("phone", currentUser.phone ? currentUser.phone : "");
            if (currentUser.coordinates) {
                setValue("streetNumber", currentUser.coordinates.streetNumber ? currentUser.coordinates.streetNumber : "");
                setValue("appartment", currentUser.coordinates.appartment ? currentUser.coordinates.appartment : "");
                setValue("street", currentUser.coordinates.street ? currentUser.coordinates.street : "");
                setValue("city", currentUser.coordinates.city ? currentUser.coordinates.city : "");
                setValue("province", currentUser.coordinates.province ? currentUser.coordinates.province : "");
                setValue("country", currentUser.coordinates.country ? currentUser.coordinates.country : "");
                setValue("postalCode", currentUser.coordinates.postalCode ? currentUser.coordinates.postalCode : "");
            }
        }
    }, [currentUser]);

    return (
        <span className="profile__info__container">
            <form name="infoForm" noValidate autoComplete="off" onSubmit={handleSubmit(onInfoSubmit)}>
                <Grid item container xs={12}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth
                            label="Prénom"
                            name="firstName"
                            error={!!errors.firstName}
                            helperText={!!errors && !!errors.firstName && errors.firstName.message}
                            inputRef={register}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth
                            type="text"
                            label="Nom de famille"
                            name="lastName"
                            error={!!errors.lastName}
                            helperText={!!errors && !!errors.lastName && errors.lastName.message}
                            inputRef={register}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth
                            inputProps={{
                                readOnly: true,
                            }}
                            type="email"
                            label="Courriel"
                            name="email"
                            error={!!errors.email}
                            helperText={!!errors && !!errors.email && errors.email.message}
                            inputRef={register}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <MuiPhoneInput
                            fullWidth
                            defaultCountry={"ca"}
                            onlyCountries={["ca"]}
                            disableAreaCodes
                            label="Téléphone"
                            name="phone"
                            value={phone}
                            error={!!errors.phone}
                            helperText={!!errors && !!errors.phone && errors.phone.message}
                            inputRef={register}
                        />
                    </Grid>
                </Grid>

                <Grid item container xs={12} className="profile__address">
                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth
                            name="streetNumber"
                            label="Numéro civique"
                            error={!!errors.streetNumber}
                            helperText={!!errors && !!errors.streetNumber && errors.streetNumber.message}
                            inputRef={register}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth
                            name="street"
                            label="Nom de rue"
                            error={!!errors.street}
                            helperText={!!errors && !!errors.street && errors.street.message}
                            inputRef={register}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth
                            name="appartment"
                            label="Numero d'appartement"
                            error={!!errors.appartment}
                            helperText={!!errors && !!errors.appartment && errors.appartment.message}
                            inputRef={register}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Controller
                            name="province"
                            control={control}
                            render={({ field }) => (
                                <FormControl
                                    fullWidth
                                    error={!!errors.province}
                                    helperText={!!errors && !!errors.province && errors.province.message}
                                >
                                    <InputLabel id="province-label" name="province-label">
                                        Province
                                    </InputLabel>
                                    <Select labelId="province-label" id="province" defaultValue="québec" {...field}>
                                        <MenuItem value="québec">Qc</MenuItem>
                                        <MenuItem value="ontario">Ont.</MenuItem>
                                        <MenuItem value="alberta">Alb.</MenuItem>
                                        <MenuItem value="british columbia">C.-B.</MenuItem>
                                        <MenuItem value="manitoba">Man.</MenuItem>
                                        <MenuItem value="new brunswick">N.-B.</MenuItem>
                                        <MenuItem value="newfoundland and labrador">T.-N.-L.</MenuItem>
                                        <MenuItem value="northwest territories">T.N.-O.</MenuItem>
                                        <MenuItem value="nova scotia">N.-É.</MenuItem>
                                        <MenuItem value="nunavut">Nt</MenuItem>
                                        <MenuItem value="prince edward island">Î.-P.-É.</MenuItem>
                                        <MenuItem value="saskatchewan">Sask.</MenuItem>
                                        <MenuItem value="yukon">Yn</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth
                            name="city"
                            label="Ville"
                            error={!!errors.city}
                            helperText={!!errors && !!errors.city && errors.city.message}
                            inputRef={register}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth
                            name="country"
                            label="Pays"
                            error={!!errors.country}
                            helperText={!!errors && !!errors.country && errors.country.message}
                            inputRef={register}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth
                            error={!!errors.postalCode}
                            helperText={!!errors && !!errors.postalCode && errors.postalCode.message}
                            name="postalCode"
                            label="Code postal"
                            inputRef={register}
                            onBlur={() => {
                                let postalCode = getValues("postalCode");
                                postalCode = postalCode.toUpperCase();

                                if (postalCode[3] !== " ") {
                                    let localPostalCode = postalCode;

                                    localPostalCode = localPostalCode.slice(0, 3) + " " + localPostalCode.slice(3);
                                    setValue("postalCode", localPostalCode);
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Button align="right" color="primary" variant="contained" type="submit">
                    Sauvegarder
                </Button>
            </form>

            <form name="passwordForm" className="passwordForm" noValidate autoComplete="off" onSubmit={handleSubmit2(onPasswordSubmit)}>
                <Grid item container xs={12}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth
                            id="password"
                            type="password"
                            name="password"
                            label="Nouveau mot de passe"
                            error={!!errors2.password}
                            helperText={!!errors2.password && errors2.password.message}
                            inputRef={register2}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth
                            id="passwordConfirm"
                            name="passwordConfirm"
                            type="password"
                            label="Répéter mot de passe"
                            error={!!errors2.passwordConfirm}
                            helperText={!!errors2.passwordConfirm && errors2.passwordConfirm.message}
                            inputRef={register2}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" align="right" color="primary" variant="contained">
                            Sauvegarder
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </span>
    );
};

export default Info;
