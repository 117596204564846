import React from 'react';
import Grid from '@material-ui/core/Grid';
import Signature from '../../../assets/gc/jimmy-gold.png';
import Packs from '../../../assets/gc/hero-packs.png';

function Hero() {
  return (
    <div className="hero">
        <Grid container>
            <Grid item xs={12} md={7}>
                <div className="hero__left">
                    <div className="hero__left__background">
                        <img src={Packs} />
                        <div className="hero__left__light" />
                        <div className="hero__left__dark" />
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={5}>
                <div className="hero__right">
                    <div>
                        <span className="hero__right1">Jusqu'à <span className="hero__right__beige">17g</span></span>
                        <span className="hero__right2">de proteines</span>
                        <span className="hero__right3">par sachet</span>
                        <a href="#sample"><button className="hero__cta">Essayez un ‘sample pack’</button></a>
                        <img src={Signature} className="hero__signature" />
                    </div>
                </div>
            </Grid>
        </Grid>
    </div>
  );
}

export default Hero;