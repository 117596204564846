import React from "react";
import { getLandingPageInfo } from "../../../utils/config";

/**
 * Displays a splash with a spinner
 */
const SplashScreen = ({ image }) => {
    const { brand } = getLandingPageInfo();

    return (
        <div className={`lh-splash-screen ${brand}`}>
            <div className="center">
                <div className="logo">
                    <img width="256" src={image} alt="logo" />
                </div>
                {/* <div className="spinner-wrapper">
                    <div className="spinner">
                        <div className="inner">
                            <div className="gap" />
                            <div className="left">
                                <div className="half-circle" />
                            </div>
                            <div className="right">
                                <div className="half-circle" />
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default React.memo(SplashScreen);
