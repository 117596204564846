import React from 'react';

function FooterNav() {
    return (
        <div className="footernav"> 
            <div className="footernav__container">
                <span>
                  <a href="./ambassadeurs"><span>Ambassadeurs</span></a>
                  <a href="./#faq"><span>Vous avez des questions?</span></a>
                  <a href="./contact"><span>Contactez-nous</span></a>
                </span>
                <span>
                  <a href="https://leadhouse.ca"><span><span className="footernav__text">Créé par</span> <span className='footernav__highlight'>LEADHOUSE</span></span></a>
                </span>
            </div>
        </div>
    );
}

export default FooterNav;
