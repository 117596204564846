import React from 'react';
import sig from '../../../assets/mcf/js-signature.png';

const Summer = () => {
    return (  
        <div className="summer">
            <div>
                <h2>***MESSAGE IMPORTANT***</h2>
                <p>Les beaux jours sont arrivés.  Comme le chocolat et la chaleur ne font pas bon ménage dans le transport, GoChoko! se doit de prendre une pause de ses ventes en ligne jusqu’à cet automne.  <b>Pour les clients avec abonnement, ceux-ci seront automatiquement mis en pause</b>, vous serez avisé via infolettre lors de la reprise.</p>
                {/* <p>Toutefois, nos produits Go Choko sont vendus dans plusieurs points de vente; cliquez ICI pour voir la liste.</p> */}
                <p>Toutefois, nos produits Go Choko sont vendus dans plusieurs points de vente.</p>
                <p>Merci infiniment de votre confiance! En mon nom et celui de mon équipe je vous souhaite un bel été.</p>
                <img src={sig} />
            </div>
        </div>
    );
}
 
export default Summer;