import React from "react";

// React-router-dom
import { Link } from "react-router-dom";

// Assets
import box from "../../../assets/bs/order-box.png";
import plates from "../../../assets/bs/order-plates.png";

const ProductCta = ({ decouverte }) => {
    // STYLING IN PRODUCT.SCSS DUE TO SIMILARITY
    return decouverte ? (
        <div className="productCta">
            <div className="productCta__container productCta__container--green">
                <div className="productCta__left">
                    <h6 className="productCta__section productCta__section--green">boîte découverte</h6>
                    <h4 className="productCta__title">Essayez nos boîtes pré-faites, vous serez conquis</h4>
                    <div className="productCta__text">
                        <p className="productCta__text--price">
                            À partir de <b>124,99$</b>
                        </p>
                        <p className="productCta__text--subscription">*Abonnement non requis</p>
                    </div>
                    <Link to="/order" className="button button__cta button__cta--green">Débuter ma commande</Link>
                </div>
                <div className="productCta__right">
                    <img src={box} alt="" className="productCta__image" />
                </div>
            </div>
        </div>
    ) : (
        <div className="productCta">
            <div className="productCta__container productCta__container--orange">
                <div className="productCta__left">
                    <h6 className="productCta__section productCta__section--orange">boîte sur-mesure</h6>
                    <h4 className="productCta__title">Créez votre boîte et recevez la une ou cent fois*</h4>
                    <div className="productCta__text">
                        <p className="productCta__text--price">
                            À partir de <b>149,99$</b>
                        </p>
                        <p className="productCta__text--subscription">*Abonnement non requis</p>
                    </div>
                    <Link to="/order" className="button button__cta button__cta--orange">Débuter ma commande</Link>
                </div>
                <div className="productCta__right">
                    <img src={plates} alt="" className="productCta__image" />
                </div>
            </div>
        </div>
    );
};

export default ProductCta;
