import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "../apiCalls";
import { settings } from "../../settings";
import { stringifyQueries } from "../../utils/parseData";
import { addSlice } from "./system";
import { auth } from "../../configs/firebase";

// snackbars
import { snackbarEnqueuedAction } from "./snackbars";

export const sliceName = "users";
const baseurl = settings.urls.users;

// each action in reducers object has a state and action params
const slice = createSlice({
    name: sliceName,
    initialState: {
        list: [],
        lastFetch: null,
        lastPage: null,
        stopRequesting: false,
        loading: true,
        currentUser: null,
    },
    reducers: {
        // NEW (standard) reducers
        refresh: (state, action) => {
            state.list = action.payload || [];
            state.stopRequesting = false;
            state.lastPage = null;
        },
        add: (state, action) => {
            const { payload } = action;

            let data;
            // response from post
            if (payload.result) data = payload.result;
            // response from get
            else if (payload) data = payload;

            state.list.push(data);
        },
        append: (state, action) => {
            const { payload } = action;

            if (!payload.length) {
                state.stopRequesting = true;
                return;
            }

            state.list = [...state.list, ...payload];
        },
        update: (state, action) => {
            const { payload } = action;

            const indexOf = state.list.findIndex(u => u.id === payload.id);
            if (indexOf > -1) state.list[indexOf] = payload;
        },
        remove: (state, action) => {
            const { id } = action.payload;

            state.list = state.list.filter(u => u.id !== id);
        },
        fetch: (state, _) => {
            state.loading = true;
        },
        receive: (state, _) => {
            state.loading = false;
        },
        setLastPage: (state, action) => {
            if (!state.lastPage) state.lastPage = action.payload;
        },
        setCurrentUser: (state, action) => {
            const { payload } = action;

            // data from fetching the user
            if (payload.data) state.currentUser = payload.data;
            else if (payload.result) state.currentUser = payload.result;
            // signout the current user. "payload.sigOut" is a boolean
            else if (payload.signOut) state.currentUser = null;
        },
    },
});

export default slice.reducer;

const actions = slice.actions;

export const signIn =
    (showSnackbar = true) =>
    dispatch =>
        dispatch(
            apiCallBegan({
                url: `${baseurl}/me`,
                method: "get",
                onStart: actions.fetch.type,
                onFinish: actions.receive.type,
                onSuccess: actions.setCurrentUser.type,
                showErrorSnackbar: showSnackbar,
            })
        );

export const signOut = () => dispatch => {
    dispatch({ type: actions.setCurrentUser.type, payload: { signOut: true } });
    dispatch({ type: actions.receive.type, payload: {} });
};

export const updateCurrentUser = user => dispatch => {
    dispatch({ type: actions.setCurrentUser.type, payload: { data: user } });
};

export const actionCreators = {
    create:
        ({ password, ...data }) =>
        async dispatch => {
            try {
                const authUser = await auth.createUserWithEmailAndPassword(data.email, password);

                data.id = authUser.user.uid;

                dispatch(apiCallBegan({ url: baseurl + "/", method: "post", data, onSuccess: [actions.add.type, actions.setCurrentUser.type] }));
                return true;
            } catch (e) {
                console.error(e);
                if (e.code === "auth/email-already-in-use")
                    dispatch(snackbarEnqueuedAction({ options: { variant: "error" }, message: "Ce courriel n'est pas disponible" }));
                return false;
            }
        },
    fetchData:
        ({ id, queries, refresh } = { id: null, queries: null, refresh: false }) =>
        (dispatch, getState) => {
            const method = "get";
            let onSuccess = [refresh ? actions.refresh.type : actions.append.type, addSlice(sliceName)];

            let url = baseurl + "/";

            const state = getState()[sliceName];

            if (state.stopRequesting) return;

            if (id) {
                url += id;
                onSuccess = [actions.add.type, addSlice(sliceName)];
            } else if (queries) {
                const data = state.list;
                // this case covers the situation where we already have the data.
                // We do not make the request. This is by far an unoptimal solution
                if (queries.hasOwnProperty("limit") && queries.hasOwnProperty("page")) {
                    if (data.length > queries.page * queries.limit) return;
                }
                url += stringifyQueries(queries);
            }

            dispatch(apiCallBegan({ method, url, onSuccess, onStart: actions.fetch.type, onFinish: actions.receive.type }));
        },
    update:
        ({ updateCurrentUser = false, showSuccessSnackbar = true, ...data }) =>
        dispatch => {
            const onSuccess = [actions.update(data)];

            if (updateCurrentUser) onSuccess.push(actions.setCurrentUser({ data }));
            dispatch(apiCallBegan({ url: `${baseurl}/${data.id}`, method: "put", data, showSuccessSnackbar, onSuccess }));
        },
    remove: id => dispatch => dispatch(apiCallBegan({ url: `${baseurl}/${id}`, method: "delete", onSuccess: actions.remove(id) })),
    setLastPage: page => dispatch => dispatch({ type: actions.setLastPage.type, payload: page }),
};

export const selectors = {
    all: state => state[sliceName].list,
    noMoreData: state => state[sliceName].stopRequesting,
    isLoading: state => state[sliceName].loading,
    getLastPage: state => state[sliceName].lastPage,
    getPage: (pageNum = 0, pageSize = 10) =>
        createSelector(selectors.all, elements => {
            const start = pageNum * pageSize;
            const end = (pageNum + 1) * pageSize;
            const page = elements.slice(start, end);
            return page;
        }),
    getById: id =>
        createSelector(selectors.all, elements => {
            return elements.find(u => u.id === id);
        }),
    getCurrentUser: state => state[sliceName].currentUser,
};
export const users = { sliceName, reducer: slice.reducer, actions: slice.actions, actionCreators, selectors };
