// DB
import { auth } from "../configs/firebase";

export const createAccount = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
};

/**
 * Logs the user to the portal
 * @param {String} email
 * @param {String} password
 * @param {Function} onSuccess
 * @param {Function} onError
 */
export const login = (email, password, onSuccess, onError) => {
    auth.signInWithEmailAndPassword(email.toLowerCase().trim(), password.trim())
        .then(payload => {
            typeof onSuccess === "function" && onSuccess(payload);
        })
        .catch(err => {
            typeof onError === "function" && onError(err);
        });
};

/**
 * Sends an email to the user with instructions on how to change his password
 * @param {String} email
 * @param {Function} setIsResetPassword
 * optional
 * @param {Function} onSuccess
 * optional
 */
export const resetPassword = (email, setIsResetPassword, onSuccess) => {
    typeof setIsResetPassword === "function" && setIsResetPassword(true);
    auth.sendPasswordResetEmail(email.toLowerCase().trim())
        .then(payload => {
            onSuccess(payload);
            // showSnackbar("success", "Courriel envoyé");
        })
        .catch(err => {
            if (err.code === "auth/invalid-email") {
                console.error("Courriel invalide");
                // showSnackbar("error", "Courriel invalid");
            } else if (err.code === "auth/user-not-found") {
                console.error("Aucun utilisateur avec ce courriel");
                // showSnackbar("error", "Aucun utilisateur avec ce courriel");
            }
        });
};

/**
 * Logs out the user
 * @param {Function} onSuccess
 */
export const logout = onSuccess => {
    auth.signOut();
    typeof onSuccess === "function" && onSuccess();
};
