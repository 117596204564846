import React from 'react';
import Products from "../../../assets/gk/gokrisp-header.png";
import Background from "../../../assets/gk/gokrisp-headerBg.png"

function Hero() {
  return (
    <div className="hero">
      <img src={Products} alt="products" className="hero__background" />
      <div className='hero__content'>
        <div className="hero__content--text">
          <h2 className='hero__1'>Énergie et plaisir <br /><b>au Carré²</b></h2>
          <h4 className="hero__2">Une fois que vous y aurez goûté; vous ne pourrez <br />plus vous en passer!</h4>
        </div>
        <a href="#produits"><button className='products__cta1 hero__cta'>Acheter</button></a>
      </div>
    </div>
  );
}

export default Hero;