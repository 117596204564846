import React from "react";
import Grid from '@material-ui/core/Grid';

import Smiley from '../../../assets/gc/benefits-smiley.jpg';

function Benefits() {
    return (
        <div className="benefits">
            <Grid container>
                <Grid item sm={12} md={6}>
                    <img src={Smiley} />
                </Grid>
                <Grid item sm={12} md={6}>
                    <div className="benefits__container">
                        <span>
                            <span className="benefits__1">Les avantages</span>
                            <span className="benefits__2">Go Choko!</span>
                            <br />
                            <br />
                            <br />
                            <span className="benefits__item">La collation parfaite “on-the-go”</span>
                            <span className="benefits__item">Le meilleur ratio protéines/glucides sur le marché</span>
                            <span className="benefits__item">Manger du chocolat sans se culpabiliser</span>
                            <span className="benefits__item">Se trimballe partout!</span>
                        </span>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Benefits;