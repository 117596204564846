import React from "react";

function Cta() {
  return (
    <div className="cta">
      <h3 className="cta__title no-margin-top">Commandez votre MCF 2.0!</h3>
      <span className="cta__text1">
        La livraison est <b>GRATUITE</b> pendant une durée limitée...{" "}
      </span>
      <span className="cta__text2">
        <b>Profitez-en aujourd'hui</b>
      </span>
      <a href="#produits">
        <button className="cta__cta">CHOISIR MA FORMULE</button>
      </a>
    </div>
  );
}

export default Cta;
