export const deserialize = value => {
    if (
        typeof value === "undefined" ||
        value === null ||
        typeof value !== "object" ||
        (!value.hasOwnProperty("_type") && !value.hasOwnProperty("_seconds"))
    )
        return value;

    if (value._type) return new Date(value._value);
    if (value._seconds) return new Date(value._seconds * 1000);

    return value;
};

export const recursiveDeserilize = x => {
    if (Array.isArray(x)) {
        // Array
        x = x.map(x => recursiveDeserilize(x));
    } else if (typeof x === "object" && x !== null) {
        // Object
        if (x.hasOwnProperty("_type") || x.hasOwnProperty("_seconds")) {
            x = deserialize(x);
        } else {
            for (var key in x) {
                if (x.hasOwnProperty(key)) x[key] = recursiveDeserilize(x[key]);
            }
        }
    }
    return x;
};

export const serialize = value => {
    let serialized_value = value;
    if (value === null) return null;

    //  Convert functions into string
    if (value instanceof Function) {
        serialized_value = {
            _type: "Function",
            _value: value.toString(),
        };
    }

    //  Convert dates into serialized timestamps
    if (value instanceof Date) {
        serialized_value = {
            _type: "Date",
            _value: value,
        };
    }

    //  Convert Firebase timestamp into custom timestamp
    if (typeof value === "object" && value.hasOwnProperty("_seconds") && value.hasOwnProperty("_nanoseconds")) {
        serialized_value = {
            _type: "Date",
            _value: value._seconds * 1000,
        };
    }

    //  Convert Moment objects into serialized timestamps
    //  You'll get back a Javascript date from the selector
    if (value && value._isAMomentObject) {
        serialized_value = {
            _type: "Date",
            _value: value._d.getTime(),
        };
    }

    return serialized_value;
};

export const recursiveSerialize = x => {
    if (Array.isArray(x)) {
        // Array
        x = x.map(x => recursiveSerialize(x));
    } else if (typeof x === "object" && x !== null) {
        // Object
        const serializedX = serialize(x);
        if (serializedX === x) {
            for (var key in x) {
                if (x.hasOwnProperty(key)) x[key] = recursiveSerialize(x[key]);
            }
        } else {
            return serializedX;
        }
    }
    return x;
};
