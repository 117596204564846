import React from "react";
import { useHistory } from "react-router-dom";
// Material UI
import { Grid } from "@material-ui/core";
// Assets
import Gift from "../../../assets/gm/cadeau4.jpg";
// Custom Components
import { fetchProductById } from "../../../utils/apiCalls";
// Redux
import { useDispatch } from "react-redux";
import { addProduct } from "../../../store/reducers/checkout";

function Cadeau() {
    const history = useHistory();
    // Redux
    const dispatch = useDispatch();
    // Products
    const BOITE_CADEAU = "QFPM415bp3EJSEbZc1eT";

    return (
        <div className="cadeau" id="Cadeau">
            <Grid container>
                <Grid item xs={12} lg={5}>
                    <div className="cadeau__container">
                        <span>
                            <span className="cadeau__2">BLACK</span>
                            <span className="cadeau__1">FRIDAY</span>

                            <span className="cadeau__3">ÉCONOMISEZ 65$ (VALEUR de 164$)</span>
                            <span className="cadeau__4">SEULEMENT 500 COFFRETS DISPONIBLES</span>

                            <span className="cadeau__price__container">
                                <span className="cadeau__price">99</span>
                                <sup className="cadeau__price__cents">00$</sup>
                                <sub className="cadeau__price__pack2">LIVRAISON INCLUSE</sub>
                            </span>

                            <span className="cadeau__includes">5 BOÎTES DE GOMATCHA!</span>
                            <span className="cadeau__includes">18 SACHETS</span>
                            <span className="cadeau__includes">1 GOURDE GOMATCHA!</span>
                            <span className="cadeau__includes">1 BOÎTE CADEAU</span>

                            <div className="cadeau__cta">
                                <button
                                    onClick={async () => {
                                        const product = await fetchProductById(BOITE_CADEAU);
                                        dispatch(addProduct({ ...product, selectedQuantity: 1, status: "À livrer" }));
                                        history.push(`/checkout`);
                                    }}
                                >
                                    ACHETEZ MAINTENANT
                                </button>
                            </div>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <img src={Gift} alt="" />
                </Grid>
            </Grid>
        </div>
    );
}

export default Cadeau;
