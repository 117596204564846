import React from "react";
import Logo from "../../../assets/mcf/logo-white.png";
import Footer1 from "../../../assets/gc/footer-choko.png";
import Footer2 from "../../../assets/gc/footer-matcha.png";
import Footer3 from "../../../assets/gc/footer-mcf.png";

function Footer() {
  return (
    <div className="footer">
      <div className="footer__container">
        <img src={Logo} alt="" />
        <span className="no-margin-top">
          <b>Un parcours hors du commun...</b> <br />
          <br /> Un soir de janvier, alors qu’il n’était âgé que de 19 ans, son
          cœur lui fit clairement comprendre qu’il n’était plus apte à supporter
          le poids de son corps qui atteignait maintenant la limite extrême de
          452 livres. Aujourd'hui Jimmy met en place des séminaires, des
          voyages, des entraînements et crée des produits avec son équipe afin
          d'apporter des solutions pour activer le changement!
        </span>
      </div>
      <br />
      <br />
      <div className="footer__container1">
        <a href="https://gochoko.com">
          <img src={Footer1} alt="" />
        </a>
        <a href="https://gomatcha.ca/" target="_blank">
          <img src={Footer2} alt="" />
        </a>
        <a href="https://melangecoupefaim.com/" target="_blank">
          <img src={Footer3} alt="" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
