import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";

// draft js
import draftToHtml from "draftjs-to-html";
import parse from "html-react-parser";
import { nanoid } from "nanoid";

// Mui
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SkipNextIcon from "@material-ui/icons/SkipNext";

// Basic redux
import { useDispatch } from "react-redux";

// Redux action & selectors
import useSlice from "../../../hooks/useSlice";

// external fetching
import { getRequest, postRequest } from "../../../actions/requests";
import { getById as getProductByIdRoute } from "../../../actions/productsRoute";

// Custom Components
import { getSplashScreenImage } from "../../../utils/config";
import SplashScreen from "../../shared/utils/splashScreen";
import VideoPlayer from "../../shared/videoPlayer";
import PhaseItem from "./phaseItem";
import VideoThumbnail from "./videoThumbnail";

// Assets
import pdfPlaceholder from "../../../assets/shared/pdfPlaceholder.jpg";

// utils
import { generatePlaylist, downloadFile, getItemsFromPhase, getItemsOutsidePhase, onToggleFavorite } from "./utils";
import { UserContext } from "../../../reducer/userContext";
import { parseDraftToHtml } from "../../../utils/parseData";

const Video = () => {
    const { user } = useContext(UserContext);
    const { id: workoutId } = useParams();

    const workoutSlice = useSlice({ sliceName: "workouts", id: workoutId });
    const userSlice = useSlice({ sliceName: "users" });

    const dispatch = useDispatch();
    const workout = workoutSlice.selectors.current;

    // initial state
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [trainers, setTrainers] = useState([]);
    const [individualFiles, setIndividualFiles] = useState([]);
    const [currentItem, setCurrentItem] = useState(null);
    const [player, setPlayer] = useState(null);
    const [playlist, setPlaylist] = useState(null);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [hideSkip, setHideSkip] = useState(false);

    const getWorkoutDifficulty = difficulty => {
        switch (difficulty) {
            case 0:
                return "Facile";
            case 1:
                return "Modéré";
            case 2:
                return "Avancé";
            case 3:
                return "Tous les niveaux";
        }
    };

    const getAllTrainersId = () => {
        let trainerIds = [];
        if (workout.items) {
            workout.items.forEach(item => {
                // if (item.trainers) {
                //     trainerIds = trainerIds.concat(item.trainers);
                // }
                if (item.trainers) {
                    item.trainers.forEach(trainer => {
                        if (!trainerIds.includes(trainer.id)) trainerIds.push(trainer.id);
                    });
                }
            });
        }

        return trainerIds;
    };

    const onClick = index => {
        setCurrentItem(() => ({ ...workout.items[index] }));
        setPlaylist(generatePlaylist(workout.items[index]));
        setHideSkip(false);
        window.scrollTo(0, 0);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplaySpeed: 2000,
        arrows: true,
        adaptiveHeight: true,
    };

    useEffect(() => {
        if (!workout) workoutSlice.actionCreators.fetchFn({ id: workoutId });
        if (workout) {
            // get products info
            if (workout.products) {
                const productPromises = [];
                workout.products.forEach(id => {
                    const response = postRequest(getProductByIdRoute, { id });
                    productPromises.push(response);
                });

                Promise.all(productPromises).then(values => {
                    values.forEach(v => setRelatedProducts(p => [...p, v.data]));
                });
            }

            // get trainers info
            const trainerIds = getAllTrainersId();
            const trainerPromises = [];
            trainerIds.forEach(id => {
                const response = getRequest(`trainers/${id}`);
                trainerPromises.push(response);
            });

            Promise.all(trainerPromises).then(values => {
                values.forEach(v => setTrainers(p => [...p, v.data]));
            });

            setIndividualFiles(getItemsOutsidePhase(workout.items));
            setCurrentItem(() => ({
                title: workout.title,
                difficulty: workout.difficulty,
                duration: workout.duration,
                url: workout.teaser,
                isTeaser: true,
            }));
            setPlaylist(
                generatePlaylist(
                    { title: workout.title, difficulty: workout.difficulty, duration: workout.duration, url: workout.teaser },
                    workout.title,
                    workout.description
                )
            );
        }
    }, [workout]);

    const onSkip = () => {
        if (!!currentItem.fundamental || currentItem.fundament !== "") {
            setHideSkip(true);
            if (playlist.playlist[1]) setCurrentVideo(playlist.playlist[1].file);
        }
    };

    // set the current video to the first video of the playlist
    useEffect(() => {
        if (playlist) {
            setCurrentVideo(playlist.playlist[0].file);
        }
    }, [playlist]);

    if (!workout) return <SplashScreen image={getSplashScreenImage()} />;

    return (
        <>
            {/* video player container */}
            <div className="workout__container">
                {currentItem && (
                    <div className="workout__current__video">
                        {currentVideo && (
                            <VideoPlayer jwpTag={nanoid()} player={player} setPlayer={setPlayer} onSkip={onSkip} video={currentVideo}>
                                {currentItem.fundamental && !hideSkip && (
                                    <Button
                                        variant="contained"
                                        className="workout__skip__button"
                                        style={{ visibility: `visible` }}
                                        onClick={() => {
                                            onSkip();
                                        }}
                                    >
                                        SKIP
                                        <SkipNextIcon />
                                    </Button>
                                )}
                            </VideoPlayer>
                        )}
                        {currentItem.isTeaser ? (
                            ""
                        ) : (
                            <div className="workout__current__video__info">
                                <h2 className="workout__current__video__title">
                                    {currentItem.title} - {currentItem.durationHour > 0 ? currentItem.durationHour + "hr" : ""}
                                    {currentItem.durationMinute}mins
                                </h2>
                                <span className="workout__current__video__difficulty">
                                    Difficulté: {getWorkoutDifficulty(currentItem.difficulty)}
                                </span>
                                <span className="workout__current__video__type">{currentItem.videoType}</span>
                                <p className="workout__current__video__description">{parseDraftToHtml(currentItem.description)}</p>
                                {currentItem.equipment && currentItem.equipment.length > 0 && (
                                    <div className="workout__current__video__equipment__container">
                                        <span>Équipement:</span>
                                        <ul>{currentItem.equipment && currentItem.equipment.map((e, index) => <li key={index}>{e}</li>)}</ul>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div className="workout__margin__container">
                    <h3 className="workout__main__title">{workout.title}</h3>
                    <div className="workout__info__container">
                        <span className="workout__info__text">
                            <b>Entraînements:</b> <br />
                            {workout.trainings}
                        </span>
                        {workout.duration > 0 && (
                            <span className="workout__info__text">
                                <b>Durée du programme:</b> <br />
                                {workout.duration} semaines
                            </span>
                        )}
                        <span className="workout__info__text">
                            <b>Difficulté:</b> <br />
                            {getWorkoutDifficulty(workout.difficulty)}
                        </span>
                    </div>
                    <div className="workout__description">{parse(draftToHtml(JSON.parse(workout.description)))}</div>
                    {workout.objectives && (
                        <div className="workout__objectives__container">
                            <span className="workout__objectives__title">Objectifs :</span>
                            {workout.objectives.map((objective, index) => (
                                <span key={index} className="workout__objectives__text">
                                    {objective}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {/* Individual videos and files */}
            {individualFiles.length !== 0 && (
                <div className="workout__individual__files__container workout__margin__container">
                    <Grid container spacing={1}>
                        {individualFiles.map((item, index) => {
                            return (
                                item.hasOwnProperty("difficulty") && (
                                    <Grid item key={index}>
                                        <VideoThumbnail
                                            onClick={() => onClick(item.index)}
                                            workoutId={workoutId}
                                            item={item}
                                            initialValue={(() => {
                                                if (!user.favoriteVideos) return false;
                                                if (!user.favoriteVideos[workoutId]) return false;
                                                return user.favoriteVideos[workoutId].findIndex(v => v === item.id) > -1;
                                            })()}
                                            onToggleFavorite={() => {
                                                const { isFavorite, favs } = onToggleFavorite(user, workoutId, item);
                                                userSlice.actionCreators.updateFn({
                                                    ...user,
                                                    favoriteVideos: favs,
                                                    updateCurrentUser: true,
                                                    showSuccessSnackbar: false,
                                                });
                                                return isFavorite;
                                            }}
                                        />
                                    </Grid>
                                )
                            );
                        })}
                    </Grid>
                    <Grid container spacing={1}>
                        {individualFiles.map((item, index) => (
                            <Grid item key={index}>
                                {!item.hasOwnProperty("difficulty") && (
                                    <>
                                        {/* <p className="workout__phase__title workout__individual__file__name">{item.title}</p> */}
                                        <img src={pdfPlaceholder} onClick={() => downloadFile(item.url)} alt="file to download" />
                                        <span className="workout__item__title">{item.title}</span>
                                    </>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </div>
            )}

            {/* phases */}
            <div className="workout__margin__container">
                {workout.phases && (
                    <div className="workout__phases">
                        <h3 className="workout__title">Vidéos</h3>
                        {workout.phases &&
                            workout.phases.map((phase, index) => (
                                <PhaseItem
                                    phase={phase}
                                    items={getItemsFromPhase(phase.id, workout.items)}
                                    onClick={index => onClick(index)}
                                    workoutId={workoutId}
                                    key={index}
                                />
                            ))}
                    </div>
                )}

                {/* Trainers */}
                {trainers && trainers.length !== 0 && (
                    <div className="workout__trainers__container">
                        <h3 className="workout__title">Entraîneurs</h3>
                        <Slider {...settings} className="coach__slider">
                            {trainers.map(trainer => (
                                <div key={trainer.id} className="workout__trainer">
                                    <img className="workout__trainer__profile" src={trainer.imageURL} alt="trainer" />
                                    <div className="workout__trainer__details">
                                        <p className="workout__phase__title workout__trainer__name">{trainer.firstName + " " + trainer.lastName}</p>
                                        <p className="workout__trainer__description">{trainer.description}</p>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                )}

                {/* Products */}
                {relatedProducts && relatedProducts.length !== 0 && (
                    <div className="workout__related__products">
                        <h3 className="workout__title">Produits</h3>
                        <Grid container>
                            {relatedProducts.map(product => (
                                <Grid key={product.id} item xs={12} sm={6} md={4} className="workout__phase__title">
                                    <p className="workout__det">{product.name}</p>
                                    <img src={product.imageURLs[0]} alt="related product" />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                )}

                {/* Partners */}
                {workout.partnerLogos && workout.partnerLogos.length !== 0 && (
                    <div className="workout__partners__container">
                        <h3 className="workout__title">Partenaires</h3>
                        <Grid container spacing={1}>
                            {workout.partnerLogos &&
                                workout.partnerLogos.map((logo, index) => (
                                    <Grid item x={6} sm={4} md={2} className="workout__partners__logo" key={index}>
                                        <img src={logo} />
                                    </Grid>
                                ))}
                        </Grid>
                    </div>
                )}
            </div>
        </>
    );
};

export default Video;
