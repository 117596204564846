import React from 'react'
import Grid from '@material-ui/core/Grid';
import Background from '../../../assets/akt/compatible-bg.jpg';
import Cast from '../../../assets/akt/compatible-cast.png';
import Stores from '../../../assets/akt/appstores.png';

function Compatible() {
    return (
        <div className="compatible">
            <div className="compatible__container">
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <div className="compatible__left">
                            <img src={Background} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="compatible__right">
                            <h3 className="compatible__title">COMPATIBLE À 100% AVEC TOUS LES PRODUITS APPLE ET ANDROID!</h3>
                            <img src={Cast} />
                            <span>Applications:</span>
                            <img src={Stores} />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Compatible
