import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Saveurs from '../../../assets/gm/sample.png';
import Original from '../../../assets/gm/original.png';

import NVSaveurs from '../../../assets/gm/NVSaveurs.jpg';
import NVOriginal from '../../../assets/gm/NVOriginal.jpg';

import Gluten from '../../../assets/gm/gluten.png';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Valeurs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className="valeurs">
        <h3 className="valeurs__title">Valeurs Nutritives</h3>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          className="valeurs__tabs"
        >
          <Tab label="Go Matcha! Aromatisé" {...a11yProps(0)} />
          <Tab label="Go Matcha! Original" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0} dir={theme.direction} className="valeurs__tabs">
            <Grid container>
                <Grid item sm={12} md={6} className="valeurs__left">
                    <img src={Saveurs} className="valeurs__gm" />
                </Grid>
                <Grid item sm={12} md={6} className="valeurs__right">
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6}>
                            <img src={NVSaveurs} className="valeurs__nv" />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <p><b>Ingrédients : </b>Matcha japonais biologique, Arôme naturel, Stévia Biologique</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction} className="valeurs__tabs">
            <Grid container>
                <Grid item sm={12} md={6} className="valeurs__left">
                    <img src={Original} className="valeurs__gm" />
                </Grid>
                <Grid item sm={12} md={6} className="valeurs__right">
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6}>
                            <img src={NVOriginal} className="valeurs__nv" />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <p><b>Ingrédients : </b>Thé vert biologique</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
      <div className="valeurs__gluten">
        <img src={Gluten} />
      </div>
    </div>
  );
}
