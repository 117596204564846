import React, { useContext } from "react";
import { useForm } from "react-hook-form";

// Material UI Core
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// yup
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Context
import { UserContext } from "../../../reducer/userContext";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getCheckoutDetails } from "../../../store/reducers/checkout";
import { snackbarEnqueuedAction } from "../../../store/reducers/snackbars";
import useSlice from "../../../hooks/useSlice";

// requests
import { postRequest } from "../../../actions/requests";
import { guestsRoute } from "../../../actions/guestsRoute";

// Custom Components
import { CheckoutContext } from "./Checkout";
import { getLandingPageName, getLandingPageInfo } from "../../../utils/config";
import { emailRegex } from "../../../utils/regex";

const requiredMessage = "Ce champ est requis";

const AccountDialog = ({ open, setOpen, isGuestCheckout, setIsGuestCheckout, onClose }) => {
    // redux
    const dispatch = useDispatch();
    const details = useSelector(getCheckoutDetails);
    const { brand } = getLandingPageInfo();
    const userSlice = useSlice({ sliceName: "users" });

    const orderSlice = useSlice({ sliceName: "orders" });
    const orders = orderSlice.selectors.all;

    const passwordSchema = yup.object({
        email: yup.string().matches(emailRegex, "Veuillez entrer un courriel valide").required(requiredMessage),
        emailConfirm: yup
            .string()
            .matches(emailRegex, "Veuillez entrer un courriel valide")
            .oneOf([yup.ref("email"), null], "Ce courriel ne concorde pas avec celui fourni")
            .required(requiredMessage),
        password: yup.string().min(6, "Mot de passe doit contenir au moins 6 caractères").required("Ce champ est requis"),
        passwordConfirm: yup
            .string()
            .oneOf([yup.ref("password"), null], "Mot de passes doivent concordés")
            .required(requiredMessage),
    });

    // state
    const { user } = useContext(UserContext);
    const { products, subscriptions } = useContext(CheckoutContext);

    const formOptions = {
        defaultValues: details,
    };

    if (!isGuestCheckout) {
        formOptions.resolver = yupResolver(passwordSchema);
    }

    const { register, errors, handleSubmit } = useForm(formOptions);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        let keepOpen = false;

        const { firstName, lastName, billing, email } = details;

        if (!isGuestCheckout) {
            userSlice.actionCreators.createFn({
                email: data.email,
                password: data.password,
                firstName,
                lastName,
                coordinates: billing,
                source: getLandingPageName(),
            });
        } else {
            try {
                await postRequest(guestsRoute, { email, firstName, lastName, orderId: orders[orders.length - 1].id });
            } catch (e) {
                console.error(e.response);
                if (e.response) dispatch(snackbarEnqueuedAction({ options: { variant: "error" }, message: e.response.data.errorMessage }));
            }
        }

        setOpen(keepOpen);
    };

    return (
        <Dialog fullWidth open={open} onClose={onClose}>
            <DialogTitle id="form-dialog-title">Vous {subscriptions.length === 0 ? "pouvez" : "devez"} créer votre compte</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            error={errors.email}
                            type="email"
                            inputRef={register}
                            label="Courriel"
                            name="email"
                            fullWidth
                            helperText={errors.email?.message}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={isGuestCheckout}
                            error={errors.emailConfirm}
                            type="email"
                            label="Confirmation du courriel"
                            name="emailConfirm"
                            inputRef={register}
                            helperText={errors.emailConfirm?.message}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={isGuestCheckout}
                            error={errors.password}
                            type="password"
                            label="Mot de passe"
                            name="password"
                            inputRef={register}
                            helperText={errors.password?.message}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={isGuestCheckout}
                            error={errors.passwordConfirm}
                            type="password"
                            type="password"
                            name="passwordConfirm"
                            label="Confirmation Mot de passe"
                            inputRef={register}
                            helperText={errors.passwordConfirm?.message}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {subscriptions.length === 0 && products.length > 0 && (
                            <FormControlLabel
                                className="checkout__guest__option"
                                control={
                                    <Checkbox
                                        name="checkedB"
                                        checked={isGuestCheckout}
                                        color="primary"
                                        onChange={e => setIsGuestCheckout(e.target.checked)}
                                    />
                                }
                                label="Acheter en tant qu'invité"
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} align="right">
                        <Button
                            className={`checkout__next ${brand}`}
                            variant="contained"
                            onClick={() => (window.location.href = "/")}
                            style={{ marginRight: 10 }}
                        >
                            Annuler
                        </Button>
                        <Button className={`checkout__next ${brand}`} variant="contained" onClick={handleSubmit(onSubmit)}>
                            Suivant
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default AccountDialog;
