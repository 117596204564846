import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "../apiCalls";
import { settings } from "../../settings";

// each action in reducers object has a state and action params
const slice = createSlice({
    name: "subscriptions",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
    },
    reducers: {
        received: (subscriptions, action) => {
            subscriptions.list = action.payload;
            subscriptions.loading = false;
            subscriptions.lastFetch = Date.now();
        },
        requested: (subscriptions, action) => {
            subscriptions.loading = true;
        },
        requestedFailed: (subscriptions, action) => {
            subscriptions.loading = false;
        },
        removed: (subscriptions, action) => {
            const newList = subscriptions.list.filter(subscriptions => subscriptions.id !== action.payload.id);
            subscriptions.list = newList;
        },
        added: (subscriptions, action) => {
            subscriptions.list.push(action.payload);
        },
        updated: (subscriptions, action) => {
            const { id, updatedFields } = action.payload;
            const i = subscriptions.list.findIndex(r => r.id === id);
            const tempArray = {
                ...subscriptions.list[i],
                ...updatedFields,
            };
            subscriptions.list[i] = tempArray;
        },
    },
});

const { added, updated, requested, received, requestedFailed, removed } = slice.actions;
export default slice.reducer;

// Action creators
export const loadSubscriptions = () => (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: `${settings.urls.subscriptions}/all`,
            onStart: requested.type,
            onSuccess: received.type,
            onError: requestedFailed.type,
        })
    );
};

export const loadSubscriptionById = id => (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: `${settings.urls.subscriptions}/${id}`,
            onStart: requested.type,
            onSuccess: added.type,
            onError: requestedFailed.type,
        })
    );
};

export const deleteSubscription = id =>
    apiCallBegan({
        url: `${settings.urls.subscriptions}/delete`,
        method: "post",
        data: { id },
        onSuccess: removed.type,
        onError: requestedFailed.type,
    });

export const addSubscription = props =>
    apiCallBegan({
        url: `${settings.urls.subscriptions}/create`,
        method: "post",
        data: props,
        onSuccess: added.type,
        onError: requestedFailed.type,
    });

export const updateSubscription = ({ id, updatedFields }) =>
    apiCallBegan({
        url: `${settings.urls.subscriptions}/update`,
        method: "post",
        data: { id, updatedFields },
        onSuccess: updated.type,
        onError: requestedFailed.type,
    });

// Selectors
export const getAllSubscriptions = createSelector(
    state => state.subscriptions.list,
    list => list
);

export const getSubscriptionById = id =>
    createSelector(
        state => state.subscriptions.list,
        list => list.find(sub => sub.id === id)
    );

export const getLoading = createSelector(
    state => state.subscriptions.loading,
    loading => loading
);
