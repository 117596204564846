import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";

// utils
import { getSkuProps } from "./utils";
import { findAll, uniq } from "../../../../../utils/array";
import { valuesSatisfy, findKeyValuePairs } from "../../../../../utils/object";

import style from "./Skus.module.scss";
import { Tooltip } from "@material-ui/core";

export default ({ skus, imgProp, defaultImageSrc = null, onSkuSelected, onImgChange }) => {
    const [props, setProps] = useState(
        getSkuProps(skus).reduce((acc, sku) => {
            acc[sku] = "";
            return acc;
        }, {})
    );

    const skuProps = useMemo(() => getSkuProps(skus, imgProp), [skus]);

    const onClick = (prop, value, imgSrc) => {
        const unselect = props[prop] === value;
        setProps(p => ({ ...p, [prop]: unselect ? "" : value }));

        if (typeof onImgChange !== "function") return;
        if (unselect) onImgChange(defaultImageSrc);
        else if (imgSrc) onImgChange(imgSrc);
    };

    const isPropAvailable = (prop, value) => {
        if (valuesSatisfy(props, ([_, propValue]) => propValue === "")) {
            return true;
        }

        const selectedProps = findKeyValuePairs(props, ([_, propValue]) => propValue);
        const availableSkus = findAll(skus, sku => {
            for (const [selectedProp, propValue] of selectedProps) {
                if (sku[selectedProp].value === propValue) return true;
            }
            return false;
        });

        for (const availableSku of availableSkus) {
            if (availableSku[prop].value === value) return true;
        }

        return false;
    };

    useEffect(() => {
        const allPropsSelected = Object.values(props).filter(prop => !prop).length === 0;
        if (!allPropsSelected) {
            onSkuSelected(null);
            return;
        }

        const selectedSku = skus.find(sku => {
            const arePropsSelected = Object.entries(props).reduce((acc, [prop, value]) => {
                return [...acc, sku[prop].value === value];
            }, []);
            return arePropsSelected.length === arePropsSelected.filter(v => v).length;
        });

        onSkuSelected(selectedSku);
    }, [props]);

    return (
        <div className={style.container}>
            {skuProps.map(prop => (
                <React.Fragment key={prop}>
                    {prop === imgProp && skus.every(sku => sku[imgProp].src) ? (
                        <div className={clsx(style.props, style.images)}>
                            {uniq(skus, (a, b) => a[imgProp].src === b[imgProp].src).map(sku => {
                                const { src, value } = sku[imgProp];
                                const disabled = !isPropAvailable(prop, value);
                                const selected = props[prop] === value;
                                return (
                                    <Tooltip title={sku[imgProp].value}>
                                        <img
                                            key={src}
                                            className={clsx(style.prop, selected && style.selected, disabled && style.disabled)}
                                            src={src}
                                            alt={"sku-" + value}
                                            onClick={() => !disabled && onClick(prop, value, src)}
                                        />
                                    </Tooltip>
                                );
                            })}
                        </div>
                    ) : (
                        <div className={clsx(style.props)}>
                            {uniq(skus, (a, b) => a[prop].value === b[prop].value).map(sku => {
                                const disabled = !isPropAvailable(prop, sku[prop].value);
                                const selected = props[prop] === sku[prop].value;
                                return (
                                    <span
                                        key={sku.sku.value}
                                        className={clsx(
                                            style.prop,
                                            style.text__prop,
                                            selected && style.selected,
                                            disabled && `${style.crossed} ${style.disabled}`
                                        )}
                                        onClick={() => !disabled && onClick(prop, sku[prop].value)}
                                    >
                                        {sku[prop].value}
                                    </span>
                                );
                            })}
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};
