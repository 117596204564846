import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

// Mui
import Bc from "@material-ui/core/Breadcrumbs";

// utils
import { capitalize } from "../../../utils/string";

// redux
import { useSelector } from "react-redux";

/* links = Link props. See https://v5.reactrouter.com/web/api/Link
 * @params basepath append this string to all link "to" prop
 * @params ignore List of paths to ignore
 */
const Breadcrumbs = ({ basepath }) => {
    const items = useSelector(state => [...state.products.list, ...state.subscriptions.list]);
    const l = useLocation();
    const links = useMemo(() => {
        const ignore = basepath.split("/");
        const paths = l.pathname.split("/").slice(1);

        return paths
            .filter(p => !ignore.includes(p))
            .map((p, index, self) => {
                const itemName = items.find(i => i.id === p)?.name;
                return {
                    // append each entry from pathname from beginning up to this "index"
                    to: self.slice(0, index + 1).reduce((path, current) => path + "/" + current, "/" + basepath),
                    label: capitalize(itemName ?? p),
                };
            });
    }, [l, items]);

    return (
        <Bc className="breadcrumbs">
            {links.map(link => (
                <Link key={link.to} {...link}>
                    {link.label}
                </Link>
            ))}
        </Bc>
    );
};

export default Breadcrumbs;
