import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "../apiCalls";
import { settings } from "../../settings";

// each action in reducers object has a state and action params
const slice = createSlice({
    name: "stores",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
    },
    reducers: {
        received: (stores, action) => {
            stores.list = action.payload;
            stores.loading = false;
            stores.lastFetch = Date.now();
        },
        requested: (stores, action) => {
            stores.loading = true;
        },
        requestedFailed: (stores, action) => {
            stores.loading = false;
        },
        removed: (stores, action) => {
            const newList = stores.list.filter(stores => stores.id !== action.payload.id);
            stores.list = newList;
        },
        added: (stores, action) => {
            stores.list.push(action.payload.store);
        },
        updated: (stores, action) => {
            const { id, updatedFields } = action.payload;

            const i = stores.list.findIndex(r => r.id === id);
            const tempArray = {
                ...stores.list[i],
                ...updatedFields,
            };
            stores.list[i] = tempArray;
        },
    },
});

const { added, updated, requested, received, requestedFailed, removed } = slice.actions;
export default slice.reducer;

// Action creators
export const loadStores = () => (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: `${settings.urls.stores}/all`,
            onStart: requested.type,
            onSuccess: received.type,
            onError: requestedFailed.type,
        })
    );
};

export const deleteStore = id =>
    apiCallBegan({
        url: `${settings.urls.stores}/delete`,
        method: "post",
        data: { id },
        onSuccess: removed.type,
        onError: requestedFailed.type,
    });

export const addStore = (name, coordinates, phone, brands) =>
    apiCallBegan({
        url: `${settings.urls.stores}/add`,
        method: "post",
        data: {
            name,
            coordinates,
            phone,
            brands,
        },
        onSuccess: added.type,
        onError: requestedFailed.type,
    });

export const updateStore = (id, updatedFields) =>
    apiCallBegan({
        url: `${settings.urls.stores}/update`,
        method: "post",
        data: { id, updatedFields },
        onSuccess: updated.type,
        onError: requestedFailed.type,
    });

// Selectors
export const getAllStores = createSelector(
    state => state.stores.list,
    list => list
);

export const getStoresByBrand = brand =>
    createSelector(
        state => state.stores.list.filter(s => s.brands.includes(brand)),
        stores => [...stores].sort((a, b) => (a.name < b.name ? -1 : 1))
    );

export const getLoading = createSelector(
    state => state.stores.loading,
    loading => loading
);
