import React from "react";
import { useHistory } from "react-router-dom";

import { Grid } from "@material-ui/core";

import Bg from "../../../assets/gm/annick.jpg";
import Quote from '../../../assets/gm/quote.png';

function Annick() {
    const history = useHistory();
    return (
        <div className="annick">
            <Grid container>
                <Grid item sm={12} md={6}>
                    <div className="annick__container">
                        <span>
                            <img src={Quote} className="annick__quote1"/>
                            <span className="annick__1">Go Matcha! <br /> c'est mon boost <br />quotidien de vitalité!</span>
                            <span className="annick__2">- Anick Dumontet</span><img src={Quote} className="annick__quote2"/>
                        </span>
                    </div>
                </Grid>
                <Grid item sm={12} md={6}>
                    <img src={Bg} alt="" className="annick__bg" />
                </Grid>
            </Grid>
        </div>
    );
}

export default Annick;
