import React from "react";
import { Link } from "react-router-dom";
// Material UI Core
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
// Custom Components
import TopNav from "./navigation/topNav";
import BottomNav from "./navigation/bottomNav";
import aktivation from "../../../assets/shared/aktivation-va-white.png";
import { settings } from "../../../settings";

const useStyles = makeStyles(theme => ({
    drawerPaper: {
        width: settings.page.drawerWidth,
    },
}));

/**
 * Displays the desktop drawer
 */
const DesktopMenu = ({ logo }) => {
    const classes = useStyles();
    return (
        <>
            <CssBaseline />
            <Drawer
                variant="permanent"
                className="nav__container"
                style={{ width: settings.page.drawerWidth }}
                classes={{ paper: classes.drawerPaper }}
            >
                <div className="nav__logo__container">
                    <Link to="/">
                        <img src={logo} alt="" className="nav__logo" />
                    </Link>
                </div>
                <TopNav />
                <div className="nav__toggle__container">
                    <BottomNav />
                </div>
            </Drawer>
        </>
    );
};

export default DesktopMenu;
