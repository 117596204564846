import React, { useState } from "react";
import clsx from "clsx";
// Material UI Core
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
// Material UI Icons
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DirectionsIcon from "@material-ui/icons/Directions";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        maxWidth: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    iconButtonNutColor: {
        color: "gray",
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const SearchBar = ({ setCurrentPosition }) => {
    const classes = useStyles();
    const [position, setPosition] = useState({ lat: 45.508843, lng: -73.631151 });

    let autocomplete;

    /**
     * Google API initialize
     */
    function initAutocomplete() {
        autocomplete = new window.google.maps.places.Autocomplete(document.getElementById("autocomplete"), {
            types: ["geocode"],
            componentRestrictions: { country: "ca" },
        });
        autocomplete.setFields(["geometry"]);
        autocomplete.addListener("place_changed", fetchGeoLocation);
    }

    initAutocomplete();

    /**
     * Google API autofiller
     */
    function fetchGeoLocation() {
        const place = autocomplete.getPlace();
        setPosition({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
    }

    const handleSubmit = e => {
        e.preventDefault();
        setCurrentPosition(position);
    };

    return (
        <div className="searchBar__container">
            <Paper component="form" className={classes.root} onSubmit={handleSubmit}>
                <LocationOnIcon className={clsx(classes.iconButton, classes.iconButtonNutColor)} />
                <Divider className={classes.divider} orientation="vertical" />
                <InputBase
                    id="autocomplete"
                    className={classes.input}
                    placeholder="Trouver les magasins près de vous"
                    inputProps={{ "aria-label": "Rechercher" }}
                />
                <IconButton type="submit" color="primary" className={classes.iconButton} aria-label="directions">
                    <DirectionsIcon />
                </IconButton>
            </Paper>
        </div>
    );
};

export default SearchBar;
