import { createMuiTheme } from "@material-ui/core/styles";
import scss from "./scss/parts/shared/_variables.scss";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: scss.primaryMain,
        },
        secondary: {
            main: scss.secondaryMain,
        },
    },
});
export default theme;
