import React, { useEffect, useState } from "react";
import Logo from "../../../assets/akt/logo-color.png";

const jwpTag = "jwp";
const Hero = ({ file = "https://content.jwplatform.com/videos/nPKQYcte-Atf6zIFQ.mp4" }) => {
    useEffect(() => {
        const player = window.jwplayer(jwpTag);
        if (file) player.setup({ file, cast: {}, repeat: true, controls: false });
    }, []);

    return (
        <div className="hero">
            <div id={jwpTag}></div>
            <img src={Logo} className="hero__logo" />
            <a href="./#produits">
                <button className="hero__cta">COMMENCEZ VOTRE ESSAI GRATUIT DE 14 JOURS</button>
            </a>
        </div>
    );
};

export default Hero;
