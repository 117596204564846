import React from "react";

// style
import style from "./QuantityControls.module.scss";

// hooks
import useCart from "../../../../../hooks/useCart";

const QuantityControls = ({ item, quantity }) => {
    const { changeQuantity } = useCart();

    const disabled = quantity === 0;

    const handleChangeQuantity = newQuantity => () => {
        changeQuantity(item, newQuantity);
    };

    return (
        <div className={style.container}>
            <button disabled={disabled} className={style.left} onClick={handleChangeQuantity(quantity - 1)}>
                -
            </button>
            <span className={style.quantity}>{quantity}</span>
            <button disabled={disabled} className={style.right} onClick={handleChangeQuantity(quantity + 1)}>
                +
            </button>
        </div>
    );
};

export default QuantityControls;
