import React, { useMemo } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import { nanoid } from "nanoid";

// Mui
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";

// Icons
import { IoCart } from "react-icons/io5";

// Custom components
import QuantityControls from "./QuantityControls";

// Hooks
import useCart from "../../../../hooks/useCart";

// style
import style from "./Cart.module.scss";

// utils
import { getItemPrice } from "../../../../utils/price";

const CartItem = ({ item, quantity }) => {
    const { changeQuantity } = useCart();

    const src = useMemo(() => {
        let src = item.imageURLs[0];
        if (item.selectedSku && item.skuImgs.associatedProp) {
            const sku = item.skus.find(s => s.sku.value === item.selectedSku)[item.skuImgs.associatedProp];

            if (sku?.src) src = sku.src;
        }

        return src;
    }, [item]);

    return (
        <div className={style.item}>
            <Badge style={{ cursor: "pointer" }} color="primary" badgeContent="X" onClick={() => changeQuantity(item, 0)}>
                <img src={src} alt={`cart item - ${item.name}`} />
            </Badge>
            <div className={style.info}>
                <span className={style.name}>{item.name}</span>
                <div className={style.controls}>
                    <QuantityControls item={item} quantity={quantity} />
                </div>
            </div>
            <span className={style.price}>{getItemPrice(item)}$</span>
        </div>
    );
};

const Cart = () => {
    const hist = useHistory();
    const itemsToShowLimit = 5;
    const { itemCount, items } = useCart();

    return (
        <div className={clsx(style.container, "boutique__card")}>
            <div className={clsx(style.header, "header")}>
                <IoCart />
                <span className={style.title}>Mon Panier</span>
                <Avatar className={style.count}>{itemCount}</Avatar>
            </div>
            <Divider />
            <div className={clsx(style.cart__items, "body")}>
                {!items.length ? (
                    <div className={style.no__items}>Panier vide</div>
                ) : items.length < itemsToShowLimit ? (
                    items.map(({ item, count }) => <CartItem key={nanoid()} item={item} quantity={count} />)
                ) : (
                    <div className={style.see__more}>
                        {items.slice(0, itemsToShowLimit - 1).map(({ item, count }) => (
                            <CartItem key={nanoid()} item={item} quantity={count} />
                        ))}
                        <span>...</span>
                        <Button color="secondary">voir plus</Button>
                    </div>
                )}
            </div>
            {!!items.length && (
                <Button variant="contained" color="primary" fullWidth onClick={() => hist.push("/portail/boutique/checkout")}>
                    procéder au paiement
                </Button>
            )}
        </div>
    );
};

export default Cart;
