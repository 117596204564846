import React, { useEffect, useState } from "react";

// Parse utils for Draftjs
import parse from "html-react-parser";
import draftToHtml from "draftjs-to-html";

// Mui component
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// DB
import { productsRoot } from "../../../actions/productsRoute";
import { getRequest } from "../../../actions/requests";
// Utils
import { isTaxable } from "../../../utils/price";

const parseDraftjs = node => parse(draftToHtml(JSON.parse(node)));
export default function FlashSale({ disabled, onAccept, onRefuse, item, brand, market }) {
    const [hasTax, setHasTax] = useState(false);

    useEffect(() => {
        const checkItemTaxes = async () => {
            const productPayload = await getRequest(`${productsRoot}/${item.productId}`, {});
            const product = productPayload.data;
            setHasTax(isTaxable(product, market));
        };
        checkItemTaxes();
    }, []);

    return (
        <Grid container>
            <Grid item xs={12} md={6} className="flash__image__container">
                <img src={item.imageUrl} alt="flash sale" className="flash__image" />
            </Grid>
            <Grid item xs={12} md={6}>
                <span className="flash__header">{item.header}</span>
                <span className={`flash__subheader ${brand}`}>{item.subheader}</span>
                <span className="flash__description">{parseDraftjs(item.description)}</span>

                <Grid container>
                    <Grid item xs={12} sm={5}>
                        <span className="flash__promo">{parseDraftjs(item.promoText)}</span>
                    </Grid>

                    <Grid item xs={12} sm={2} style={{ display: "flex" }}>
                        <div className="flash__seperator"></div>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <span className="flash__pricetext">{parseDraftjs(item.priceText)}</span>
                        <span className="flash__discount">
                            ${item.discountPrice / 100}
                            <span className="flash__taxes">{hasTax ? "+tx" : ""}</span>
                        </span>

                        <span className="flash__price">
                            RÉGULIER <s>${item.regPrice / 100}</s>
                        </span>
                    </Grid>
                </Grid>

                <Button disabled={disabled} variant="contained" className={`flash__cta ${brand}`} onClick={() => onAccept(item)}>
                    OUI J'ACHÈTE!
                </Button>

                <Button className="flash__refuse" onClick={() => onRefuse()}>
                    Non merci, je ne veux pas de cette offre exclusive
                </Button>
            </Grid>
        </Grid>
    );
}
