import React, { createContext, useEffect } from "react";
import useGetUser from "../general/useGetUser";

// custom components
import SplashScreen from "../components/shared/utils/splashScreen";
import { getSplashScreenImage } from "../utils/config";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
    const { user, loading } = useGetUser();

    if (!user && loading) return <SplashScreen image={getSplashScreenImage()} />;

    return (
        <UserContext.Provider
            value={{
                user,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
