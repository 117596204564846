import { getValue } from "./object";

export const alphabeticalSort =
    (key, caseSensitive = false) =>
    (a, b) => {
        let valueA;
        let valueB;

        if (key) {
            valueA = caseSensitive ? getValue(a, key) : getValue(a, key).toLowerCase();
            valueB = caseSensitive ? getValue(b, key) : getValue(b, key).toLowerCase();
        } else {
            valueA = caseSensitive ? a : a.toLowerCase();
            valueB = caseSensitive ? b : b.toLowerCase();
        }

        return valueA.localeCompare(valueB);
    };

export const findAllIndicesOf = (arr, comparator) =>
    arr.reduce((indices, current, index) => {
        if (comparator(current)) indices.push(index);
        return indices;
    }, []);

export const findAll = (arr, comparator) =>
    arr.reduce((acc, current) => {
        if (comparator(current)) acc.push(current);
        return acc;
    }, []);

export const uniqWithCount = (arr, areEqual) => {
    const result = [];

    arr.forEach(element => {
        const index = result.findIndex(({ value }) => (typeof areEqual === "function" ? areEqual(element, value) : element === value));
        if (index === -1) {
            result.push({ value: element, count: 1 });
        } else {
            result[index].count++;
        }
    });
    return result;
};

export const uniq = (arr, areEqual) => uniqWithCount(arr, areEqual).map(({ value }) => value);

// combines elements that return true for areEqual => (a,b): boolean
export const combine = (arr, areEqual) => {
    const result = [];

    arr.forEach(element => {
        const index = result.findIndex(value => (typeof areEqual === "function" ? areEqual(element, value) : element === value));
        if (index === -1) {
            result.push(element);
        } else {
            result[index] = { ...result[index], ...element };
        }
    });
    return result;
};

export const groupBy = (arr, key) =>
    arr.reduce((acc, element) => {
        const value = getValue(element, key);

        return { ...acc, [value]: Array.isArray(acc[value]) ? [...acc[value], element] : [] };
    }, {});
