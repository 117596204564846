import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// Redux
import { useDispatch } from "react-redux";
import { addProduct } from "../../../store/reducers/checkout";
// DB
import { getRequest, postRequest } from "../../../actions/requests";
import { getById, productsRoot } from "../../../actions/productsRoute";

import Sign from "../../../assets/mcf/js-signature.png";
import Gluten from "../../../assets/mcf/gluten.jpg";

function Subfooter() {
    const history = useHistory();
    const dispatch = useDispatch();

    const [product, setProduct] = useState({});

    const LIBERTY = "shMsZJD9y4HPZ7wM0Dvm";

    useEffect(() => {
        getRequest(`${productsRoot}/${LIBERTY}`)
            .then(payload => {
                setProduct(payload.data);
            })
            .catch(err => console.error(err));
    }, []);

    return (
        <div className="subfooter">
            <div className="subfooter__container">
                <div>
                    <div className="subfooter__box">
                        <h3 className="subfooter__title">Toujours pas convaincu?</h3>
                        <span className="subfooter__text">
                            Essayez le Mélange Coupe Faim 2.0 version liberté sans abonnement et découvrez ce dont tout le monde parle!
                        </span>
                        <img src={Gluten} alt="" />
                        <button
                            className="subfooter__cta"
                            onClick={() => {
                                dispatch(addProduct({ ...product, selectedQuantity: 1, status: "À livrer" }));
                                history.push(`/checkout`);
                            }}
                        >
                            ESSAYEZ UN SACHET <br />
                            SANS ABONNEMENT
                        </button>
                    </div>
                    <img src={Sign} className="subfooter__sign" alt="" />
                </div>
            </div>
        </div>
    );
}

export default Subfooter;
