import { createSlice, createAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "../apiCalls";
import { settings } from "../../settings";

// utils
import { stringifyQueries } from "../../utils/parseData";

const baseurl = settings.urls.orders;

const sliceName = "orders";

// each action in reducers object has a state and action params
const slice = createSlice({
    name: sliceName,
    initialState: {
        list: [],
        lastFetch: null,
        lastPage: null,
        loading: false,
        stopRequesting: false,
        promocode: "",
        promocodeValidated: false,
    },
    reducers: {
        // NEW standard reduces
        refresh: (state, action) => {
            state.list = action.payload || [];
            state.stopRequesting = false;
            state.lastPage = null;
        },
        add: (state, action) => {
            const { payload } = action;

            let data;
            // response from post
            if (payload.result) data = payload.result;
            // reponse from get
            else if (payload) data = payload;

            state.list.push(data);
        },
        append: (state, action) => {
            const { payload } = action;

            if (!payload.length) {
                state.stopRequesting = true;
                return;
            }

            state.list = [...state.list, ...payload];
        },
        update: (state, action) => {
            const { payload } = action;

            const indexOf = state.list.findIndex(element => element.id === payload.id);
            if (indexOf > -1) state.list[indexOf] = payload;
        },
        remove: (state, action) => {
            const { id } = action.payload;

            state.list = state.list.filter(element => element.id !== id);
        },
        fetch: (state, _) => {
            state.loading = true;
        },
        receive: (state, _) => {
            state.loading = false;
        },
        setLastPage: (state, action) => {
            if (!state.lastPage) state.lastPage = action.payload;
        },
        promocodeUpdated: (state, action) => {
            state.promocode = action.payload;
        },
        promocodeValidated: (state, action) => {
            state.promocodeValidated = action.payload && state.promocode !== "";
        },
        setted: (state, action) => {
            for (const [key, value] of Object.entries(action.payload)) {
                if (!(key in state)) throw new Error(`Order slice: ${key} is not in product state`);
                state[key] = value;
            }
        },

        // OLD Legacy reducers
        received: (orders, action) => {
            orders.list = action.payload;
            orders.loading = false;
            orders.lastFetch = Date.now();
        },
        requested: (orders, action) => {
            orders.loading = true;
        },
        requestedFailed: (orders, action) => {
            orders.loading = false;
        },
        removed: (orders, action) => {
            const newArray = orders.list.filter(orders => orders.id !== action.payload.result.id);
            orders.list = newArray;
        },
        added: (orders, action) => {
            orders.list.push(action.payload.result);
            orders.loading = false;
        },
        updated: (orders, action) => {
            const i = orders.list.findIndex(order => order.id === action.payload.result.id);
            orders.list[i] = action.payload.result;
        },
        noteAdded: (orders, action) => {
            const index = orders.list.findIndex(order => order.id === action.payload.orderId);
            if (orders.list[index].notes) {
                const tempNotes = [...orders.list[index].notes];
                tempNotes.push(action.payload.note);
                orders.list[index].notes = tempNotes;
            } else {
                let newArray = [];
                newArray.push(action.payload.note);
                orders.list[index].notes = newArray;
            }
        },
        noteUpdated: (orders, action) => {
            const orderIndex = orders.list.findIndex(order => order.id === action.payload.orderId);
            const noteIndex = orders.list[orderIndex].notes.findIndex(note => note.id === action.payload.noteId);
            orders.list[orderIndex].notes[noteIndex] = action.payload.note;
        },
        noteRemoved: (orders, action) => {
            const index = orders.list.findIndex(order => order.id === action.payload.orderId);
            const tempNotes = orders.list[index].notes.filter(note => note.noteId === action.payload.noteId);
            orders.list[index].notes = tempNotes;
        },
        ordersCleared: (orders, action) => {
            orders.list = [];
        },
    },
});

export const { added, requested, received, requestedFailed, removed, updated, noteAdded, noteUpdated, noteRemoved, ordersCleared } = slice.actions;
export default slice.reducer;
const actions = slice.actions;

// Action creators
export const loadOrders = () => (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: `${settings.urls.orders}/`,
            onStart: requested.type,
            onSuccess: received.type,
            onError: requestedFailed.type,
        })
    );
};

export const addOrder = order =>
    apiCallBegan({
        url: `${settings.urls.orders}`,
        method: "post",
        data: order,
        onStart: requested.type,
        onSuccess: added.type,
        onError: requestedFailed.type,
        showSuccessSnackbar: false,
    });

export const addOrderwithGuest = (order, source, stripeCustomerId = null) =>
    apiCallBegan({
        url: `${settings.urls.orders}/createWithGuest`,
        method: "post",
        data: {
            stripeSource: source,
            stripeCustomerId,
            ...order,
        },
        onStart: requested.type,
        onSuccess: added.type,
        onError: requestedFailed.type,
    });

export const updateOrder = (id, data) =>
    apiCallBegan({
        url: `${settings.urls.orders}/${id}`,
        method: "put",
        data,
        onSuccess: updated.type,
        onError: requestedFailed.type,
        showSuccessSnackbar: false,
    });

export const deleteOrder = (id, hard) =>
    apiCallBegan({
        url: `${settings.urls.orders}/${id}`,
        method: "delete",
        data: { hard },
        onSuccess: removed.type,
        onError: requestedFailed.type,
        showSuccessSnackbar: false,
    });

export const updateOrderStatus = (order, newStatus) =>
    apiCallBegan({
        url: `${settings.urls.orders}/updateStatus`,
        method: "post",
        data: { order, newStatus },
        onSuccess: updated.type,
        onError: requestedFailed.type,
    });

export const updateOrderItemStatus = (order, item, newStatus) =>
    apiCallBegan({
        url: `${settings.urls.orders}/updateItemStatus`,
        method: "post",
        data: { order, item, newStatus },
        onSuccess: updated.type,
        onError: requestedFailed.type,
    });

export const addNote = (orderId, content, creatorId) =>
    apiCallBegan({
        url: `${settings.urls.orders}/notes/create`,
        method: "post",
        data: { orderId, content, creatorId },
        onSuccess: noteAdded.type,
        onError: requestedFailed.type,
    });

export const deleteNote = (orderId, noteId) =>
    apiCallBegan({
        url: `${settings.urls.orders}/notes/delete`,
        method: "post",
        data: { orderId, noteId },
        onSuccess: noteRemoved.type,
        onError: requestedFailed.type,
    });

export const updateNote = (orderId, id, content, creatorId) =>
    apiCallBegan({
        url: `${settings.urls.orders}/notes/update`,
        method: "post",
        data: { orderId, id, content, creatorId },
        onSuccess: noteUpdated.type,
        onError: requestedFailed.type,
    });

export const clearOrders = () => dispatch => {
    dispatch(createAction(ordersCleared.type)());
};

export const updatePromocode = (id, data) =>
    apiCallBegan({
        url: `${settings.urls.orders}/updatePromocode/${id}`,
        method: "put",
        data,
        onSuccess: [updated.type, slice.actions.promocodeValidated(true)],
        onError: requestedFailed.type,
        showSuccessSnackbar: true,
    });

export const setPromocode = promocode => dispatch => dispatch(slice.actions.promocodeUpdated(promocode));
export const setIsPromocodeValidated = isValidated => dispatch => dispatch(slice.actions.setted({ promocodeValidated: isValidated }));

// Selectors
export const getAllOrders = createSelector(
    state => state.orders.list,
    list => list
);

export const getLoading = createSelector(
    state => state.orders.loading,
    loading => loading
);

export const getOrderById = orderId =>
    createSelector(
        state => state.orders.list,
        list => list.find(order => order.id === orderId)
    );

export const getOrderByUserId = userId =>
    createSelector(
        state => state.orders.list,
        list => list.find(order => order.user.id === userId)
    );

export const getOrderByOrderNumber = orderNumber =>
    createSelector(
        state => state.orders.list,
        list => list.find(order => order.orderNumber === orderNumber)
    );

export const getOrdersByStatus = status =>
    createSelector(
        state => state.orders.list,
        list => list.filter(order => order.status === status)
    );

export const getNotesByOrderId = orderId =>
    createSelector(
        state => state.orders.list.find(order => order.id === orderId),
        order => {
            if (order && order.notes) {
                return order.notes;
            } else {
                return;
            }
        }
    );

export const getPromocode = state => state[sliceName].promocode;

export const getIsPromocodeValidated = state => state[sliceName].promocodeValidated;

// --- [NEW standard action creators and selectors] ---
export const actionCreators = {
    create: userInfo => dispatch => dispatch(apiCallBegan({ url: baseurl + "/", method: "post", data: userInfo, onSuccess: actions.add.type })),
    /*
     * if no userId is provided, then we fetch all users. Otherwise, we fetch the user with the given userId
     * */
    fetchData:
        ({ id, queries, refresh } = { id: null, queries: null, refresh: false }) =>
        (dispatch, getState) => {
            const method = "get";
            let onSuccess = refresh ? actions.refresh.type : actions.append.type;

            let url = `baseurl/?shippingStatus:not-in=${JSON.stringify([""])}&orderBy=shippingStatus&orderBy=createdAt,desc`;

            const state = getState()[sliceName];

            if (state.stopRequesting) return;

            if (id) {
                url = `${baseurl}/${id}?shippingStatus:not-in=${JSON.stringify([""])}&orderBy=shippingStatus&orderBy=createdAt,desc`;
                onSuccess = actions.add.type;
            } else if (queries) {
                const data = state.list;
                // this case covers the situation where we already have the data.
                // We do not make the request. This is by far an unoptimal solution
                if (queries.hasOwnProperty("limit") && queries.hasOwnProperty("page")) {
                    if (data.length > queries.page * queries.limit) return;
                }
                url += stringifyQueries(queries);
            }

            dispatch(apiCallBegan({ method, url, onSuccess, onStart: actions.fetch.type, onFinish: actions.receive.type }));
        },
    update: userInfo => dispatch =>
        dispatch(
            apiCallBegan({
                url: `${baseurl}/${userInfo.id}`,
                method: "put",
                data: userInfo,
                onSuccess: actions.update(userInfo),
                showSuccessSnackbar: false,
            })
        ),
    remove: id => dispatch => dispatch(apiCallBegan({ url: `${baseurl}/${id}`, method: "delete", onSuccess: actions.remove(id) })),
    signIn: () => dispatch => dispatch(apiCallBegan({ url: `${baseurl}/me`, method: "get", onSuccess: actions.setCurrentUser.type })),
    signOut: () => dispatch => dispatch({ type: actions.setCurrentUser.type, payload: { signOut: true } }),
    setLastPage: page => dispatch => dispatch({ type: actions.setLastPage.type, payload: page }),
};

export const selectors = {
    all: state => state[sliceName].list,
    getCurrentUser: state => state[sliceName].currentUser,
    noMoreData: state => state[sliceName].stopRequesting,
    isLoading: state => state[sliceName].loading,
    getLastPage: state => state[sliceName].lastPage,
    getPage: (pageNum, pageSize = 10) =>
        createSelector(selectors.all, elements => {
            const start = pageNum * pageSize;
            const end = (pageNum + 1) * pageSize;
            const page = elements.slice(start, end);
            return page;
        }),
    getById: id =>
        createSelector(selectors.all, elements => {
            return elements.find(u => u.id === id);
        }),
};

export const orders = { sliceName, reducer: slice.reducer, actions: slice.actions, actionCreators, selectors };
