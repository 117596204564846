import React from 'react';
import Slider from 'react-slick';
import Grid from '@material-ui/core/Grid';

import Testimonials1 from '../../../assets/akt/testimonials-1.jpg';
import Testimonials2 from '../../../assets/akt/testimonials-2.jpg';
import Testimonials3 from '../../../assets/akt/testimonials-3.jpg';
import Testimonials4 from '../../../assets/akt/testimonials-4.jpg';
import Testimonials5 from '../../../assets/akt/testimonials-5.jpg';

import TestimonialsBG from '../../../assets/akt/testimonials-bg.png';

function Testimonials() {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    return (
        <div className="testimonials">
            <h3 className="testimonials__title">TÉMOIGNAGES</h3>
            <span className="testimonials__subtitle">Des vraies personnes qui ont obtenu de vrais résultats</span>

            <Slider {...settings} className="testimonials__slider">
                <div className="testimonials__item">
                    <Grid container>
                        <Grid item xs={12} md={5}>
                            <img src={Testimonials5} />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <div className="testimonials__content">
                                <span className="testimonials__text">En 4 semaines d'entraînement avec Aktivation, mon corps a changé pour le mieux! Mis-à-part les effets positifs sur mon corps, c'est incroyable de constater comment l'entraînement augmente notre bien-être psychologique.</span>
                                <br/>
                                <span className="testimonials__text">Anick Dumontet (Animatrice télé)</span>

                                <img src={TestimonialsBG} className="testimonials__bg"/>
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <div className="testimonials__item">
                    <Grid container>
                        <Grid item xs={12} md={5}>
                            <img src={Testimonials1} />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <div className="testimonials__content">
                                <span className="testimonials__text">Photos - Dec 2019, Nov 2020</span>
                                <span className="testimonials__text">187lbs - 142lbs</span>
                                <br/>
                                <span className="testimonials__text">45lbs de perdu avec Aktivation depuis le 5 Janvier 2020!</span>
                                <span className="testimonials__text">10 mois a m'entrainer avec vous autres, a me mettre en forme 🤩</span>
                                <span className="testimonials__text">Couru mon premier demi-marathon, commencer a courir le 13 Sept dernier seulement.. et la je m'entraine pour un full marathon pour juillet 2021.</span>
                                <br/>
                                <span className="testimonials__text">Fanny Chalifoux</span>

                                <img src={TestimonialsBG} className="testimonials__bg"/>
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <div className="testimonials__item">
                    <Grid container>
                        <Grid item xs={12} md={5}>
                            <img src={Testimonials2} />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <div className="testimonials__content">
                                <span className="testimonials__text">-40 LBS </span>
                                <br/>
                                <span className="testimonials__text">"Grâce à la fantastique plateforme AKTIVATION , j'ai réussi à atteindre mon objectif de poids santé et j'en suis très fière!!!"</span>
                                <span className="testimonials__text">"C'est génial d'avoir une plateforme d'exercices fait en français, ici même au Québec et avec autant de qualité"</span>
                                <br/>
                                <span className="testimonials__text">Mélanie Fréchette</span>

                                <img src={TestimonialsBG} className="testimonials__bg"/>
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <div className="testimonials__item">
                    <Grid container>
                        <Grid item xs={12} md={5}>
                            <img src={Testimonials3} />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <div className="testimonials__content">
                                <span className="testimonials__text">-12 LBS</span>
                                <br/>
                                <span className="testimonials__text">Une plateforme plus que motivante et entraînante, avec des gens de cœur d’ici au Québec, mais surtout, des gens qui nous ressemble!</span>
                                <br/>
                                <span className="testimonials__text">Mélanie Lukianiec</span>

                                <img src={TestimonialsBG} className="testimonials__bg"/>
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <div className="testimonials__item">
                    <Grid container>
                        <Grid item xs={12} md={5}>
                            <img src={Testimonials4} />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <div className="testimonials__content">
                                <span className="testimonials__text">-50 LBS</span>
                                <br/>
                                <span className="testimonials__text">"Aktivation a changé MA VIE!</span>
                                <br/>
                                <span className="testimonials__text">Marie-Elaine Sabourin</span>

                                <img src={TestimonialsBG} className="testimonials__bg"/>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Slider>

        </div>
    )
}

export default Testimonials
