export const getSkuProps = (skus, imgProp) =>
    skus
        .reduce((acc, sku) => {
            const props = Object.keys(sku);
            return [...new Set([...acc, ...props])].filter(prop => prop !== "sku" && prop !== "stock");
        }, [])
        .sort((a, b) => {
            if (!imgProp) return -1;

            if (a === imgProp) return -1;
            return a.localeCompare(b);
        });

export const getSkuImg = (skus, imgProp) =>
    skus.reduce((acc, sku) => {
        const data = { value: sku[imgProp].value, src: sku[imgProp].src };

        if (acc.find(element => data.value === element.value && data.src === element.src)) return acc;

        acc.push(data);
        return acc;
    }, []);
