import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// Material UI Core
import Grid from "@material-ui/core/Grid";
// Assets
import Chocolat from "../../../assets/gk/gokrisp-chocolat.png";
import Baies from "../../../assets/gk/gokrisp-baies.png";
import Caramel from "../../../assets/gk/gokrisp-caramel.png";
import Tiramisu from "../../../assets/gk/gokrisp-tiramisu.png";
import Promo from "../../../assets/gk/gokrisp-lancement.jpg";
import PromoMobile from "../../../assets/gk/gokrisp-lancement.jpg";
// React icons
import { TbTruckDelivery, TbArrowNarrowRight } from "react-icons/tb";
// Custom components
import ProductTypeDialog from "./productSelectionDialog";
import { fetchProductById } from "../../../utils/apiCalls";
// Redux
import { useDispatch } from "react-redux";
import { addProduct } from "../../../store/reducers/checkout";
// DB
import { getRequest } from "../../../actions/requests";
import { productsRoot } from "../../../actions/productsRoute";

function Products() {
    const BOX = "DDyL98PSnoQJrfEPobfa";

    const all = {
        choco: { product: "JHLsQLCSMGmxp218op9O", sub1: "jhLC9yxigpAx8VqIVMjJ", sub2: "zQ64iHnZtRUDfcaJsFWH" },
        baies: { product: "PSFFFO7UKEryeqrFB9jD", sub1: "18Cn6HN5GgAIoSN8WfRp", sub2: "iswki6SnMg5fZOrW2z8a" },
        caramel: { product: "FvqvQMxEY9TdzTXsUOVD", sub1: "QnE0zXRhZV2a4tkv7VAo", sub2: "aJU7u3jE2lolQ3bTVNmY" },
        tiramisu: { product: "CCGmX6WEncaZT7paYdX1", sub1: "M5vfDD9IsE3EbHrE89vP", sub2: "6sSJ0HlsYbWSw6NAE9Ji" },
        sample: { product: "t4AnYPTEMFSaHI9f6qrO" },
    };

    // states
    const [openDialog, setOpenDialog] = useState(false);
    const [items, setItems] = useState({});

    const handleOpenDialog = () => setOpenDialog(true);

    const handleCloseDialog = () => setOpenDialog(false);

    const history = useHistory();
    // Redux
    const dispatch = useDispatch();
    // Products
    const LANCEMENT = "DDyL98PSnoQJrfEPobfa";

    return (
        <>
            {/* FEATURED ITEM */}

            <Grid
                item
                xs={12}
                className="promo__desktop"
                style={{ cursor: "pointer" }}
                onClick={async () => {
                    const product = await fetchProductById(LANCEMENT);
                    dispatch(addProduct({ ...product, selectedQuantity: 1, status: "À livrer" }));
                    history.push(`/checkout`);
                }}
            >
                <img src={Promo} alt="" />
            </Grid>

            <Grid
                item
                xs={12}
                className="promo__mobile"
                style={{ cursor: "pointer" }}
                onClick={async () => {
                    const product = await fetchProductById(LANCEMENT);
                    dispatch(addProduct({ ...product, selectedQuantity: 1, status: "À livrer" }));
                    history.push(`/checkout`);
                }}
            >
                <img src={PromoMobile} alt="" />

                <br />
                <br />
                <br />
                <br />
                <br />
            </Grid>
            <div className="element__centered">
                <button
                    className="sample__cta"
                    style={{}}
                    onClick={async () => {
                        const payload = await getRequest(`${productsRoot}/${BOX}`, {});
                        dispatch(addProduct({ ...payload.data, selectedQuantity: 1, status: "À livrer" }));
                        history.push(`/checkout`);
                    }}
                >
                    ACHÈTE L'OFFRE DE LANCEMENT ICI!
                </button>
            </div>

            <div className="products" id="produits">
                <div className="products__container">
                    <span className="products__title--1">Laquelle sera votre préférée?</span>
                    <span className="products__title--2">Choisissez votre saveur</span>
                    <span className="products__subtitle">
                        Livraison gratuite à la grandeur du Canada! <TbTruckDelivery />
                    </span>
                    <Grid container spacing={3}>
                        <Grid className="products__container--item" item xs={12} md={6}>
                            <div
                                className="products__item products__item--1 clickable__tag"
                                onClick={async () => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: all.choco.product,
                                            isProduct: true,
                                        },
                                        sub1: {
                                            id: all.choco.sub1,
                                            isProduct: false,
                                        },
                                        sub2: {
                                            id: all.choco.sub2,
                                            isProduct: false,
                                        },
                                    });
                                }}
                            >
                                <div className="products__left">
                                    <img className="products__image" src={Chocolat} />
                                    <span className="products__ingredients">
                                        Voir les ingrédients <TbArrowNarrowRight />
                                    </span>
                                </div>
                                <div className="products__right">
                                    <div className="products__name--container">
                                        <h6 className="products__name products__name--black">Chocolat & Noix de coco</h6>
                                    </div>
                                    <p className="products__info products__info--black">
                                        Une saveur parfaite qui combine le chocolat et la noix de coco pour permettre à vos papilles de s'évader sous
                                        le soleil.
                                    </p>
                                    <button className="products__cta1">Acheter</button>
                                    {/* <button className="products__cta2">acheter maintenant</button> */}
                                </div>
                            </div>
                        </Grid>

                        <Grid className="products__container--item" item xs={12} md={6}>
                            <div
                                className="products__item products__item--2 clickable__tag"
                                onClick={async () => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: all.baies.product,
                                            isProduct: true,
                                        },
                                        sub1: {
                                            id: all.baies.sub1,
                                            isProduct: false,
                                        },
                                        sub2: {
                                            id: all.baies.sub2,
                                            isProduct: false,
                                        },
                                    });
                                }}
                            >
                                <div className="products__left">
                                    <img className="products__image" src={Baies} />
                                    <span className="products__ingredients">
                                        Voir les ingrédients <TbArrowNarrowRight />
                                    </span>
                                </div>
                                <div className="products__right">
                                    <div className="products__name--container">
                                        <h6 className="products__name products__name--white">Explosion de Baies</h6>
                                    </div>
                                    <p className="products__info products__info--white">
                                        Une douce et délicate saveur de fruits qui fera exploser le bonheur de vos sens à la première bouchée.
                                    </p>
                                    <button className="products__cta1">Acheter</button>
                                    {/* <button className="products__cta2">acheter maintenant</button> */}
                                </div>
                            </div>
                        </Grid>

                        <Grid className="products__container--item" item xs={12} md={6}>
                            <div
                                className="products__item products__item--3 clickable__tag"
                                onClick={async () => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: all.caramel.product,
                                            isProduct: true,
                                        },
                                        sub1: {
                                            id: all.caramel.sub1,
                                            isProduct: false,
                                        },
                                        sub2: {
                                            id: all.caramel.sub2,
                                            isProduct: false,
                                        },
                                    });
                                }}
                            >
                                <div className="products__left">
                                    <img className="products__image" src={Caramel} />
                                    <span className="products__ingredients">
                                        Voir les ingrédients <TbArrowNarrowRight />
                                    </span>
                                </div>
                                <div className="products__right">
                                    <div className="products__name--container">
                                        <h6 className="products__name products__name--black">Pommes & Caramel Salé</h6>
                                    </div>
                                    <p className="products__info products__info--black">
                                        Une saveur unique et réconfortante de pommes et caramel salé qui saura combler votre dent sucrée.
                                    </p>
                                    <button className="products__cta1">Acheter</button>
                                    {/* <button className="products__cta2">acheter maintenant</button> */}
                                </div>
                            </div>
                        </Grid>

                        <Grid className="products__container--item" item xs={12} md={6}>
                            <div
                                className="products__item products__item--4 clickable__tag"
                                onClick={async () => {
                                    handleOpenDialog();
                                    setItems({
                                        product: {
                                            id: all.tiramisu.product,
                                            isProduct: true,
                                        },
                                        sub1: {
                                            id: all.tiramisu.sub1,
                                            isProduct: false,
                                        },
                                        sub2: {
                                            id: all.tiramisu.sub2,
                                            isProduct: false,
                                        },
                                    });
                                }}
                            >
                                <div className="products__left">
                                    <img className="products__image" src={Tiramisu} />
                                    <span className="products__ingredients">
                                        Voir les ingrédients <TbArrowNarrowRight />
                                    </span>
                                </div>
                                <div className="products__right">
                                    <div className="products__name--container">
                                        <h6 className="products__name products__name--white">Tiramisu</h6>
                                    </div>
                                    <p className="products__info products__info--white">
                                        Une saveur qui vous donnera l'impression de déguster ce dessert onctueux et crémeux avec des notes de café.
                                    </p>
                                    <button className="products__cta1">Acheter</button>
                                    {/* <button className="products__cta2">acheter maintenant</button> */}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <ProductTypeDialog open={openDialog} onClose={handleCloseDialog} items={items} />
            </div>
        </>
    );
}

export default Products;
