import React from "react";

// assets
import Oops from "../../assets/shared/oops.png";

// custom components
import Dialog from "../../components/shared/utils/confirmDialog";

// redux stuff
import { useSelector, useDispatch } from "react-redux";
import { snackbarRemovedAction as snackbarRemoved, snackbarEnqueuedAction, snackbarClosedAction, getStack } from "../../store/reducers/snackbars";

import { useSnackbar } from "notistack";

let displayed = [];

export default function Notifier() {
    const dispatch = useDispatch();

    // popup
    const popup = React.useRef(null);

    // the stack of notifications
    const stack = useSelector(state => getStack(state));
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = id => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = id => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    React.useEffect(() => {
        stack.forEach(({ key, message, options = {}, dismissed = false }) => {
            const isPopup = options.popup !== null && options.popup !== undefined;
            // dismiss notification from notistack
            if (dismissed) {
                return closeSnackbar(key);
            }

            // do nothing if notification is already displayed
            if (displayed.includes(key)) return;

            // show notification as a popup instead of a snackbar
            if (isPopup) {
                const { title, showImage = true, redirectUrl, buttonText = "OK", image = Oops } = options.popup;

                popup.current.show({
                    title: title,
                    text: message,
                    image: showImage ? image : "",
                    buttons: [
                        {
                            text: buttonText,
                            variant: "text",
                            action: () => {
                                popup.current.hide();
                                if (redirectUrl) window.location.href = redirectUrl;
                            },
                        },
                    ],
                });

                dispatch(snackbarRemoved(key));
                removeDisplayed(key);

                return;
            }

            // display notification
            enqueueSnackbar(message, {
                key,
                ...options,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    dispatch(snackbarRemoved(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of displayed notifications
            storeDisplayed(key);
        });
    }, [stack, dispatch, closeSnackbar, enqueueSnackbar]);

    return <Dialog ref={popup} />;
}
