import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// MUI
import { Grid } from "@material-ui/core";

// Asset
import One from "../../../assets/gk/gk-testimonials-1.png";
import Two from "../../../assets/gk/gk-testimonials-2.png";
import Three from "../../../assets/gk/gk-testimonials-3.png";
import Four from "../../../assets/gk/gk-testimonials-4.png";

function Testimonials() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="testimonials">
            <Grid container>
                <Grid item xs={12} md={6}>
                    <div className="testimonials__container">
                        <span className="testimonials__text">
                            Découvrez les <br /> témoignages
                            <br /> des consommateurs
                        </span>
                        <a href="#sample">
                            <button className="testimonials__cta">Essayer un “sample pack”</button>
                        </a>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="testimonials__right">
                        <Slider {...settings} autoplay arrows={false}>
                            <div>
                                <img src={One} alt="" className="testimonials__right--image" />
                            </div>
                            <div>
                                <img src={Two} alt="" className="testimonials__right--image" />
                            </div>
                            <div>
                                <img src={Three} alt="" className="testimonials__right--image" />
                            </div>
                            <div>
                                <img src={Four} alt="" className="testimonials__right--image" />
                            </div>
                        </Slider>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Testimonials;
