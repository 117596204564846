import React, { useImperativeHandle, forwardRef, useEffect, useState, useContext } from "react";
// Material UI Core
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Skeleton from "@material-ui/lab/Skeleton";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { getCheckoutDetails } from "../../../store/reducers/checkout";
import { added as addingOrder, addOrder, addOrderwithGuest, getCustomerId, getLoading, getAllOrders } from "../../../store/reducers/orders";
import { sliceName as flashSaleSlice, loadItems as loadFlashSales, filteredByBrand, itemsById } from "../../../store/reducers/flashSales";
import { isSliceLoaded } from "../../../store/reducers/system";
import { snackbarEnqueuedAction } from "../../../store/reducers/snackbars";

// actions
import { postRequest, getRequest } from "../../../actions/requests";
import { productsRoot } from "../../../actions/productsRoute";
import { rootOrders } from "../../../actions/ordersRoute";
import { guestsRoute } from "../../../actions/guestsRoute";

// utils
import { generateOrder } from "../../../utils/order";

// Custom Components
import FlashSale from "./flashSale";
import { ConfirmationContent } from "./Confirmation";
import { CheckoutContext } from "./Checkout";
import { UserContext } from "../../../reducer/userContext";
import { settings } from "../../../settings";
import Skus from "../../portal/boutique/Product/Skus";

const FlashsalesDialog = forwardRef((props, ref) => {
    const { brandAcronym, brand, rebate, promoCode, market, stripePaymentMethod, stripeCustomerId } = useContext(CheckoutContext);
    const { user } = useContext(UserContext);

    useImperativeHandle(ref, () => ({
        show: () => {
            setOpen(true);
        },
        hide: () => {
            setOpen(false);
        },
    }));

    // states
    const orders = useSelector(getAllOrders);
    const details = useSelector(getCheckoutDetails);

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [flashCompleted, setFlashCompleted] = useState(false);
    const [flashOrders, setFlashOrders] = useState([]);
    const [currentFs, setCurrentFs] = useState(0);
    const [disablePayButton, setDisablePaybutton] = useState(false);
    const [ordersLoading, setOrdersLoading] = useState(false);
    const [selectedSku, setSelectedSku] = useState(null);
    const [skuSelected, setSkuSelected] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);

    // redux
    const dispatch = useDispatch();
    const flashSales = useSelector(filteredByBrand(brand));
    const fsReadyState = useSelector(isSliceLoaded(flashSaleSlice));

    const onClose = () => {
        setOpen(false);
    };

    const onAccept = async item => {
        setOrdersLoading(true);
        try {
            setDisablePaybutton(true);
            const product = selectedProduct ?? (await getRequest(`${productsRoot}/${item.productId}`, {})).data;

            // TODO: add this part once we can change the price of a product
            const generatedOrder = await generateOrder(
                [],
                [{ ...product, price: flashSales[currentFs].discountPrice ?? product.price }],
                details,
                user,
                "",
                brand,
                brandAcronym,
                market,
                false,
                flashSales[currentFs].id
            );
            // create order
            const createdOrder = (await postRequest(`${rootOrders}`, generatedOrder)).data.result;

            dispatch(addingOrder({ result: createdOrder }));

            // guestcheckout
            if (!user) {
                const { email, firstName, lastName } = createdOrder.user;
                await postRequest(guestsRoute, { email, firstName, lastName, orderId: createdOrder.id });
            }
            // create transaction
            const transaction = (
                await postRequest(`${settings.urls.transactions}/${createdOrder.id}`, {
                    stripeCustomerId,
                    stripePaymentMethod,
                })
            ).data.result;

            if (transaction.transactionStatus !== "Completed") {
                // TODO: handle error
                dispatch(
                    snackbarEnqueuedAction({
                        message: "Erreur durant la transaction",
                        options: {
                            variant: "error",
                        },
                    })
                );
                onClose();
            }

            // final sales
            if (currentFs === flashSales.length - 1) {
                setFlashCompleted(true);
            }
            // go to next flash sale
            else if (flashSales[currentFs + 1]) {
                setCurrentFs(c => c + 1);
                setDisablePaybutton(false);
            }
        } catch (e) {
            console.error(e.response);
            dispatch(
                snackbarEnqueuedAction({
                    message: e.response.data.errorMessage,
                    options: {
                        variant: "error",
                    },
                })
            );
            onClose();
        } finally {
            setOrdersLoading(false);
        }
    };

    const onRefuse = () => {
        // final sales
        if (currentFs === flashSales.length - 1) {
            if (flashOrders.length === 0) return onClose();
            else if (flashOrders.length > 0) {
                return setFlashCompleted(true);
            }
        }
        setCurrentFs(c => c + 1);
    };

    const updateSku = () => {
        if (selectedSku) {
            setSkuSelected(true);
            setSelectedProduct({ ...selectedProduct, selectedQuantity: 1, selectedSku: selectedSku.sku.value });
        }
    };

    const productHasCustomSku = async product => {
        let hasCustomSku = false;
        if (product.skus.length > 0 && !product.selectedSku) hasCustomSku = true;
        return hasCustomSku;
    };

    const checkFlashsaleForCustomSku = async () => {
        const product = (await getRequest(`${productsRoot}/${flashSales[currentFs].productId}`, {})).data;
        // Check if product has custom sku
        if (await productHasCustomSku(product)) {
            setSelectedImage(product.imageURLs[0]);
            setSelectedProduct(product);
        } else {
            setSelectedImage(null);
            setSelectedProduct(null);
            setSkuSelected(false);
            setSelectedSku(null);
        }
    };

    useEffect(() => {
        if (flashSales[currentFs]) checkFlashsaleForCustomSku();
    }, [flashSales[currentFs]]);

    useEffect(() => {
        if (!fsReadyState) dispatch(loadFlashSales());
    }, [fsReadyState]);

    useEffect(() => {
        if (!ordersLoading) {
            setFlashOrders(() => {
                const [_, ...rest] = orders;
                return rest;
            });
        }
        setLoading(ordersLoading);
    }, [ordersLoading]);

    // delay the flashsales by 3 seconds
    useEffect(() => {
        setTimeout(() => {
            setOpen(true);
        }, 3000);
    }, []);

    if (flashSales.length === 0) return null;

    return (
        <Dialog fullWidth open={open} onClose={onClose} className="flash__dialog" disableBackdropClick disableEscapeKeyDown>
            <DialogTitle id="form-dialog-title" className={`flash__title ${brand}`}>
                Vente Flash 🎉
            </DialogTitle>
            <DialogContent>
                {flashCompleted ? (
                    <>
                        <ConfirmationContent orders={flashOrders} loading={loading} disableReturn />
                        <Button color="primary" onClick={onClose}>
                            Fermer
                        </Button>
                    </>
                ) : (
                    flashSales.length > 0 &&
                    (loading ? (
                        <>
                            <Skeleton height={50} animation="wave" />
                            <Skeleton height={50} animation="wave" />
                            <Skeleton height={25} animation="wave" />
                            <Skeleton height={25} animation="wave" />
                            <Skeleton height={25} animation="wave" />
                        </>
                    ) : (
                        <div>
                            <p className="flash__desc">
                                Cette vente flash contient des items à prix <b>exclusif</b>, l'achat se fera en <b>1-clic</b> et vous recevrez une
                                confirmation par courriel!
                            </p>

                            {/* ------------------- Custom sku selection --------------------- */}
                            {selectedProduct && !skuSelected && (
                                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                                    <DialogContentText id="alert-dialog-description">
                                        Plusieurs choix s'offrent à vous. Sélectionnez une des options suivantes
                                    </DialogContentText>

                                    <div className="element__centered">
                                        <img src={selectedImage} style={{ maxWidth: 150 }} alt="Produit" />
                                    </div>
                                    {selectedProduct && (
                                        <Skus
                                            skus={selectedProduct.skus}
                                            imgProp={selectedProduct.skuImgs.associatedProp}
                                            onSkuSelected={sku => setSelectedSku(sku)}
                                            onImgChange={src => setSelectedImage(src)}
                                            defaultImageSrc={selectedProduct.imageURLs[0]}
                                        />
                                    )}

                                    <Button
                                        variant="contained"
                                        onClick={() => updateSku()}
                                        autoFocus
                                        disabled={!selectedSku || parseInt(selectedSku.stock.value) === 0}
                                    >
                                        Sélectionner
                                    </Button>
                                </div>
                            )}

                            <FlashSale
                                item={flashSales[currentFs]}
                                onAccept={item => onAccept(item)}
                                onRefuse={onRefuse}
                                brand={brand}
                                market={market}
                                disabled={disablePayButton || (selectedProduct && !skuSelected)}
                            />
                        </div>
                    ))
                )}
            </DialogContent>
        </Dialog>
    );
});

export default FlashsalesDialog;
