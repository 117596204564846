import React from "react";

// Assets
import footprint from "../../../assets/bs/footprint.png";

const Footprint = () => {
	return (
		<div className="footprint">
			<div className="footprint__container">
				<div className="footprint__left">
					<p className="footprint__left--section">empreinte écologique</p>
					<h3 className="footprint__left--title">
						Notre engagement pour aujourd'hui et demain
					</h3>
					<p className="footprint__left--text">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum
						integer imperdiet ut condimentum volutpat. Mauris malesuada vitae
						sed cras. Leo senectus mi sed vitae mattis. Morbi ultricies mattis
						scelerisque mi eget dolor cras morbi enim. Augue interdum nibh
						mauris integer. Molestie enim adipiscing sed rhoncus, aliquam non.
						Maecenas nunc, massa id diam feugiat sed non.
					</p>
				</div>
				<div className="footprint__right">
					<img src={footprint} alt="Une poignée de terre" className="footprint__right--image" />
				</div>
			</div>
		</div>
	);
};

export default Footprint;
