import React from "react";
import Arrow from "../../../assets/mcf/arrow.png";

function Hero() {
    return (
        <div className="hero">
            <div className="hero__container">
                <div>
                    <span className="hero__1">VENDU À PLUS DE 100 000 EXEMPLAIRES</span>
                    <span className="hero__2">
                        LE MÉLANGE <br /> COUPE-FAIM 2.0
                    </span>
                    <span className="hero__3">
                        J’ai fait le tour du <b>Canada et des États-Unis</b>, et j’ai participé à<br /> des séminaires sur les dernières tendances en
                        alimentation.{" "}
                    </span>
                    <br />
                    <span className="hero__3">
                        Suite à de{" "}
                        <b>
                            nombreux échanges avec des chercheurs et des
                            <br /> nutritionnistes
                        </b>
                        , je suis fier de vous présenter...
                        <img src={Arrow} alt="arrow design" />
                    </span>
                    <div className="hero__cta">
                        <a href="#produits">
                            <button className="hero__cta1">CHOISIR MA FORMULE</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;
