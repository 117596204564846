import React from "react";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";

// validation
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Custom Components
import { GetHelmet, GetNavbar, getLandingPageInfo } from "../../utils/config";
// Images
import BannerAKT from "../../assets/shared/affiliateBanner-akt.jpg";
import BannerMCF from "../../assets/shared/affiliateBanner-mcf.jpg";
import BannerGM from "../../assets/shared/affiliateBanner-gm2.jpg";
import BannerGC from "../../assets/shared/affiliateBanner-gc.jpg";
// action - requests
import { postRequest } from "../../actions/requests";
import { affiliateRoute } from "../../actions/contactUsRoutes";
import { useDispatch } from "react-redux";
import { apiCallBegan } from "../../store/apiCalls";

// yup validation
const requiredMessage = "Ce champ est requis";
const mustBeNumberMessage = "Veuillez inscrire un nombre valide";
const validAgeMessage = "Veuillez inscrire un age valide";

const emptyStringToNull = (value, originalValue) => {
    if (typeof originalValue === "string" && originalValue === "") return null;
    return value;
};

const affiliateValidationSchema = yup.object({
    fullname: yup.string().required(requiredMessage),
    email: yup.string().email("Courriel invalide").required(requiredMessage),
    phone: yup
        .string()
        .matches(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/, "Numéro de téléphone invalide")
        .required(requiredMessage),
    age: yup.number().typeError(mustBeNumberMessage).positive(validAgeMessage).integer(validAgeMessage).required(requiredMessage),
    address: yup.string().required(requiredMessage),
    specialDeliveryInstructions: yup.string(),
    usesInstagram: yup.boolean(),
    usesFacebook: yup.boolean(),
    usesTiktok: yup.boolean(),
    instagramLink: yup.string(),
    instagramFollowers: yup
        .number()
        .typeError(mustBeNumberMessage)
        .positive(mustBeNumberMessage)
        .integer(mustBeNumberMessage)
        .transform(emptyStringToNull)
        .nullable(),
    instagramAvgStoryViews: yup
        .number()
        .typeError(mustBeNumberMessage)
        .positive(mustBeNumberMessage)
        .integer(mustBeNumberMessage)
        .transform(emptyStringToNull)
        .nullable(),
    facebookLink: yup.string(),
    facebookFollowers: yup
        .number()
        .typeError(mustBeNumberMessage)
        .positive(mustBeNumberMessage)
        .integer(mustBeNumberMessage)
        .transform(emptyStringToNull)
        .nullable(),
    collaboratorsText: yup.string().required(requiredMessage),
    workWithUsText: yup.string().required(requiredMessage),
});

const Affiliate = () => {
    const dispatch = useDispatch();
    const hist = useHistory();
    const { brand, brandAcronym } = getLandingPageInfo();

    const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(affiliateValidationSchema) });

    const onSubmit = data => {
        dispatch(apiCallBegan({ url: affiliateRoute, method: "post", data: { ...data, brandAcronym, brand } }));
        hist.push("/");
    };

    const properBanner = () => {
        switch (brandAcronym) {
            case "MCF":
                return BannerMCF;
            case "GM":
                return BannerGM;
            case "AKT":
                return BannerAKT;
            case "GC":
                return BannerGC;
            default:
                return BannerGC;
        }
    };

    return (
        <>
            <GetHelmet />
            <GetNavbar />
            <div className="affiliate">
                <img src={properBanner()} className="affiliate__background" alt="" />
                <div className="affiliate__top">
                    <div className={`affiliate__container ${brand}`}>
                        <h3 className={`affiliate__title ${brand}`}>
                            Vous aimeriez travailler avec notre équipe et du même coup, faire connaître{" "}
                            {`${brand === "Aktivation" ? "notre plateforme" : "nos produits"}`} à votre entourage tout en récoltant des récompenses et
                            des bénéfices? Joignez-vous à notre équipe d'ambassadeurs!
                        </h3>
                        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                helperText={errors.fullname?.message}
                                error={!!errors.fullname?.message}
                                label="Nom Complet"
                                name="fullname"
                                inputRef={register}
                                fullWidth
                                required
                            />

                            <TextField
                                helperText={errors.email?.message}
                                error={!!errors.email?.message}
                                label="Courriel"
                                type="email"
                                name="email"
                                inputRef={register}
                                fullWidth
                                required
                            />

                            <TextField
                                helperText={errors.phone?.message}
                                error={!!errors.phone?.message}
                                label="Téléphone"
                                type="phone"
                                name="phone"
                                inputRef={register}
                                fullWidth
                                required
                            />

                            <TextField
                                helperText={errors.age?.message}
                                error={!!errors.age?.message}
                                label="Age"
                                type="number"
                                name="age"
                                inputRef={register}
                                fullWidth
                                required
                            />

                            <TextField
                                helperText={errors.address?.message}
                                error={!!errors.address?.message}
                                label="Adresse (pour livraison)"
                                name="address"
                                inputRef={register}
                                fullWidth
                                required
                            />

                            <TextField
                                multiline
                                label="Instructions spéciales de livraison"
                                rows={3}
                                name="specialDeliveryInstructions"
                                inputRef={register}
                                fullWidth
                            />

                            <FormLabel component="legend">Quelles sont les plateformes que vous utilisez?</FormLabel>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox inputRef={register} name="usesInstagram" />} label="Instagram" />
                                <FormControlLabel control={<Checkbox inputRef={register} name="usesFacebook" />} label="Facebook" />
                                <FormControlLabel control={<Checkbox inputRef={register} name="usesTiktok" />} label="Tik Tok" />
                            </FormGroup>

                            <TextField inputRef={register} name="instagramLink" label="Lien Instagram" fullWidth />

                            <TextField
                                helperText={errors.instagramFollowers?.message}
                                error={!!errors.instagramFollowers?.message}
                                inputRef={register}
                                name="instagramFollowers"
                                label="Nombre de fans Instagram"
                                fullWidth
                                type="number"
                            />

                            <TextField
                                helperText={errors.instagramAvgStoryViews?.message}
                                error={!!errors.instagramAvgStoryViews?.message}
                                inputRef={register}
                                name="instagramAvgStoryViews"
                                label="Vue moyennes de vos stories Instagram"
                                fullWidth
                                type="number"
                            />

                            <TextField inputRef={register} name="facebookLink" label="Lien Facebook" fullWidth />

                            <TextField
                                helperText={errors.facebookFollowers?.message}
                                error={!!errors.facebookFollowers?.message}
                                inputRef={register}
                                name="facebookFollowers"
                                label="Nombre de fans Facebook"
                                fullWidth
                                type="number"
                            />

                            <TextField
                                helperText={errors.collaboratorsText?.message}
                                error={!!errors.collaboratorsText?.message}
                                multiline
                                label="Présentement, avez-vous des partenariats/collaborations rémunérés avec des compagnies? Si oui, lesquelles ? "
                                rows={4}
                                fullWidth
                                required
                                name="collaboratorsText"
                                inputRef={register}
                                required
                            />

                            <TextField
                                helperText={errors.workWithUsText?.message}
                                error={!!errors.workWithUsText?.message}
                                multiline
                                label="De quelle façon aimeriez-vous collaborer / travailler avec nous?"
                                rows={4}
                                fullWidth
                                required
                                name="workWithUsText"
                                inputRef={register}
                                required
                            />

                            <button className={`affiliate__cta ${brand}`}>Soumettre</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Affiliate;
