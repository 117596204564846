import React, { useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useSnackbar } from "notistack";
import ReactInputVerificationCode from "react-input-verification-code";
import styled from "styled-components";
import CryptoJS from "crypto-js";

// Material UI Core
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

// Custom Components
import LogoAKT from "../../../assets/portal/logo-aktivation.png";
import LogoGM from "../../../assets/gm/gm-logo-color.png";
import LogoGC from "../../../assets/gc/gc-login.png";
import LogoMCF from "../../../assets/mcf/js-signature-blue.png";

// Custom Components
import { getLandingPageInfo } from "../../../utils/config";
import { GetHelmet } from "../../../utils/config";

// api requests
import { getRequest } from "../../../actions/requests";

// Hooks
import { useForgotPassword } from "../../../hooks/useForgotPassword";
import Loading from "../../shared/loading";

// Utils
import { settings } from "../../../settings";

export const StyledSeconds = styled.div`
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.002em;
    color: rgba(255, 255, 255, 0.4);
`;

const StyledError = styled.div`
    margin-top: 13px;
    font-size: 20px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.004em;
    color: #ef6c65;
`;

const StyledReactInputVerificationCode = styled.div`
    display: flex;
    justify-content: center;
    font-family: "Roboto";

    --ReactInputVerificationCode-itemWidth: 4vw;
    --ReactInputVerificationCode-itemHeight: 6vh;
    --ReactInputVerificationCode-itemSpacing: 2vw;

    .ReactInputVerificationCode__item {
        font-size: 16px;
        font-weight: 500;
        color: #fff;

        background: rgba(0, 0, 0, 1);
        border: 1px solid ${({ isInvalid }) => (isInvalid ? "#EF6C65" : "rgba(28, 30, 60, 0.4)")};
        border-radius: 4px;
        box-shadow: none;
    }

    .ReactInputVerificationCode__item.is-active {
        box-shadow: none;
        border: 1px solid #36c6d9;
    }
`;

export const VerifyCode = () => {
    const hist = useHistory();
    const { email, setEmail } = useForgotPassword();
    const { enqueueSnackbar } = useSnackbar();
    const { brand, brandAcronym } = getLandingPageInfo();

    // States
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState("");
    const [errorCount, setErrorCount] = useState(0);
    const [isError, setIsError] = useState(false);

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const payload = await getRequest("users/verifyCode", { email, code: CryptoJS.AES.encrypt(code, settings.encryption.secret).toString() });

            if (payload.data) {
                hist.push("/changementMotDePasse");
            }
        } catch (e) {
            if (e.response && e.response.data) {
                setCode("");
                setIsError(true);
                setErrorCount(errorCount + 1);
                enqueueSnackbar(e.response.data, { variant: "error" });
            } else console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const generateCode = async () => {
        try {
            setLoading(true);
            await getRequest("users/generateCode", { email, brand: brandAcronym });
        } catch (e) {
            if (e.response && e.response.data) {
                enqueueSnackbar(e.response.data, { variant: "error" });
            } else console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const properLogo = () => {
        switch (brandAcronym) {
            case "MCF":
                return LogoMCF;
            case "GM":
                return LogoGM;
            case "AKT":
                return LogoAKT;
            case "GC":
                return LogoGC;
            default:
                return LogoGC;
        }
    };

    useEffect(() => {
        if (code && !code.includes("·")) {
            handleSubmit();
        }
    }, [code]);

    useEffect(() => {
        if (errorCount > 3) {
            enqueueSnackbar("Trop de tentatives. Veuillez rééssayer plus tard.", { variant: "error" });
            setErrorCount(0);
            setLoading(true);

            setTimeout(() => {
                setLoading(false);
                return hist.push("/motDePasseOublie");
            }, 3000);
        }
    }, [errorCount]);

    useEffect(() => {
        if (email) {
            generateCode();
        }
    }, [email]);

    if (!email) return <Redirect to="/portail/login" />;
    else
        return (
            <>
                <Loading loading={loading} />
                <div className={`login__page ${brand}`}>
                    <GetHelmet />
                    <Card className="login__container">
                        <CardContent>
                            <img onClick={() => hist.push("/")} src={properLogo()} alt="Leadhouse Logo" className="login__logo" />

                            <div className="element__centered" style={{ fontFamily: "Roboto" }}>
                                <h1>Vérification du code</h1>
                            </div>

                            <div className="element__centered" style={{ fontFamily: "Roboto" }}>
                                <h4 style={{ maxWidth: "75%", textAlign: "justify" }}>
                                    <p>Veuillez saisir le code que vous venez de recevoir par courriel.</p>
                                </h4>
                            </div>

                            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                                <StyledReactInputVerificationCode isInvalid={isError}>
                                    <ReactInputVerificationCode
                                        autoFocus
                                        value={code}
                                        placeholder={undefined}
                                        length={6}
                                        onCompleted={data => setCode(data)}
                                    />
                                </StyledReactInputVerificationCode>
                            </div>

                            {errorCount > 0 && errorCount < 5 && (
                                <StyledError style={{ paddingBottom: 20 }}>
                                    {5 - errorCount} {5 - errorCount > 1 ? "essais" : "essai"} restant{5 - errorCount > 1 ? "s" : ""}.
                                </StyledError>
                            )}

                            <div className="login__actions">
                                <Button
                                    className={`login__cta ${brand}`}
                                    variant="contained"
                                    onClick={() => {
                                        setEmail("");
                                        hist.push("/motDePasseOublie");
                                    }}
                                >
                                    Retour
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </>
        );
};

export default VerifyCode;
