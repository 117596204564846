import { useCallback, useEffect, useRef } from "react";

const useTimeout = (cb, delay) => {
    const callback = useRef(cb);
    const timeout = useRef();

    useEffect(() => {
        callback.current = cb;
    }, [cb]);

    const set = useCallback(() => {
        timeout.current = setTimeout(() => callback.current(), delay);
    }, [delay]);

    const clear = useCallback(() => {
        timeout.current && clearTimeout(timeout.current);
    }, []);

    const reset = useCallback(() => {
        clear();
        set();
    }, [clear, set]);

    // immediately start the timeout when call this hook
    useEffect(() => {
        set();
        return clear;
    }, [delay, set, clear]);

    return { reset, clear };
};

export default useTimeout;
