import React from "react";
import Fb from "../../../assets/gk/fb.png";
import Ig from "../../../assets/gk/ig.png";
import Image from "../../../assets/gk/gokrisp-social-packs.png";

function Socials() {
    return (
        <div className="socials">
            <div className="socials__container">
                <h3 className="socials__title">SUIVEZ-NOUS SUR LES MÉDIAS SOCIAUX</h3>
                <img className="socials__packs" src={Image} />
                <span className="socials__icons">
                    <a href="https://www.facebook.com/JimmySevigny.CoachMotivateur" target="_blank">
                        <img src={Fb} alt="" />
                    </a>
                    <a href="https://www.instagram.com/jimmy_sevigny/" target="_blank">
                        <img src={Ig} alt="" />
                    </a>
                </span>
            </div>
        </div>
    );
}

export default Socials;
