import * as yup from "yup";
import { emailRegex } from "../../../utils/regex";

export const passwordSchema = yup.object({
    password: yup.string().min(6, "Mot de passe doit contenir au moins 6 caractères").required("Ce champ est requis"),
    passwordConfirm: yup
        .string()
        .oneOf([yup.ref("password"), null], "Mot de passes doivent concordés")
        .required("Ce champ est requis"),
});

export const emailSchema = yup.object({
    email: yup.string().matches(emailRegex, "Le courriel contient des caractères invalides").required("Ce champ est requis"),
});

export const appartmentSchema = yup.string().matches(/([a-z]|[A-Z]|[0-9])/);
export const billingSchema = yup.object({
    email: emailSchema,
    billing: {
        appartment: appartmentSchema,
    },
});

export const duoSchema = yup.object({
    duoEmail: yup.string().matches(emailRegex, "Le courriel contient des caractères invalides").required("Ce champ est requis"),
    duoFirstName: yup.string("Prénom invalide").required("Ce champ est requis"),
    duoLastName: yup.string("Nom de famille invalide").required("Ce champ est requis"),
    duoPassword: yup.string().min(6, "Mot de passe doit contenir au moins 6 caractères").required("Ce champ est requis"),
    duoPasswordConfirm: yup
        .string()
        .oneOf([yup.ref("duoPassword"), null], "Mot de passes doivent concordés")
        .required("Ce champ est requis"),
});
