import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { addSlice } from "./system";
import { apiCallBegan } from "../apiCalls";
import { settings } from "../../settings";
import { loadingAction } from "../utils/general";

export const sliceName = "flashSales";
const baseurl = settings.urls.flashSales;

const slice = createSlice({
    name: sliceName,

    initialState: { list: [] },

    reducers: {
        // list of flash sales fetched from DB
        refreshed: (state, action) => {
            state.list = action.payload || [];
        },

        // add a new flash sale
        added: (state, action) => {
            if (action.payload !== null) {
                state.list.push(action.payload.result);
            }
        },

        // remove a flash sale
        removed: (state, action) => {
            if (action.payload.id !== null) {
                state.list = state.list.filter(fs => fs.id !== action.payload.id);
            }
        },

        // upodate a flash sale
        updated: (state, action) => {
            if (action.payload !== null) {
                const index = state.list.findIndex(fs => fs.id === action.payload.id);

                state.list[index] = { ...action.payload, ...state.list[index] };
            }
        },
    },
});

export const { refreshed, added, removed, updated } = slice.actions;
export default slice.reducer;

// --- [Actions] ---------------------------------------------------------------------
export const loadItems = () => dispatch => {
    loadingAction({ sliceName, dispatch, url: baseurl, method: "get", onSuccess: refreshed.type });
};

export const getById = id => dispatch => {
    dispatch(apiCallBegan({ url: `${baseurl}/${id}`, method: "get", onSuccess: [updated.type, addSlice(sliceName)] }));
};

export const deleteById = (id, hard = false) => dispatch => {
    dispatch(apiCallBegan({ url: `${baseurl}/${id}`, method: "delete", data: { hard }, onSuccess: [removed.type, addSlice(sliceName)] }));
};

// --- [Selectors] -------------------------------------------------------------------
export const allItems = state => state[sliceName].list;

export const filteredByBrand = brand =>
    createSelector([allItems], allItems => {
        const filtered = [];

        for (const fs of allItems) {
            for (const b of fs.brands) {
                if (b.name === brand && b.isActive) {
                    filtered.splice(b.index, 0, fs);
                }
            }
        }

        return filtered;
    });

export const itemById = (state, id) => state[sliceName].list.find(fs => fs.id === id);
