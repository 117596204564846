import React from 'react';

function Fact() {
    return (
        <div className="fact">
            <div className="fact__container">
                <h3>Saviez-vous que pour avoir un effet sur votre corps... Vous devez en consommer minimalement 2 grammes? </h3>
                <span className="fact__1">Chaque sachet de GoMatcha contient au minimum</span>
                <span className="fact__2">3 grammes de notre matcha japonais de qualité supérieure.</span>
            </div>
        </div>
    );
}

export default Fact;
