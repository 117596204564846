import React, { useEffect, useMemo, useState } from "react";
import propTypes from "prop-types";
import _ from "lodash";
import { nanoid } from "nanoid";

// Custom components
import TableLoadingSkeleton from "./tableLoadingSkeleton";

// Material UI Core
import { TableBody as MuiTableBody } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const TableBody = ({ data, columns, rowsPerPage, selectedPage, loading, paginated, rowClickable, isDoubleClick = true, onRowClick }) => {
    const [state, setState] = useState({});

    const createState = (name, value) => {
        setState(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const paginatedData = useMemo(() => {
        if (paginated)
            return data.slice(
                selectedPage * rowsPerPage,
                selectedPage * rowsPerPage + rowsPerPage > data.length ? data.length : selectedPage * rowsPerPage + rowsPerPage
            );

        return data;
    }, [data, selectedPage, rowsPerPage]);

    const renderCell = async (currentItem, column) => {
        const name = genKey(currentItem, column);
        if (column.body) {
            createState(name, column.body(currentItem));
        } else if (column.asyncBody) {
            createState(name, await column.asyncBody(currentItem));
        } else if (column.calculatedField) {
            createState(name, column.calculatedField(currentItem));
        } else {
            createState(name, _.get(currentItem, column.path));
        }
    };

    const genKey = (item, column) => {
        if (item.uid) return item.uid + (column.path || column.key);
        return item.id + (column.path || column.key);
    };

    useEffect(() => {
        paginatedData.map(i =>
            columns.forEach(c => {
                renderCell(i, c);
            })
        );
    }, [data]);

    return (
        <MuiTableBody>
            {!loading && data.length > 0 ? (
                paginatedData.map(item => (
                    <TableRow
                        hover
                        className={rowClickable ? "clickable__tag" : ""}
                        key={item.id}
                        onDoubleClick={() => isDoubleClick && rowClickable && onRowClick(item)}
                        onClick={() => !isDoubleClick && rowClickable && onRowClick(item)}
                    >
                        {columns.map(column => (
                            <TableCell align="center" key={column.key}>
                                {state[genKey(item, column)]}
                            </TableCell>
                        ))}
                    </TableRow>
                ))
            ) : (
                <TableRow key={nanoid()}></TableRow>
            )}
            {loading && <TableLoadingSkeleton numRows={5} colSpan={8} animation="wave" />}
            {!loading && data.length === 0 && (
                <TableRow hover>
                    <TableCell colSpan={8} align="center">
                        Aucune donnée
                    </TableCell>
                </TableRow>
            )}
        </MuiTableBody>
    );
};

TableBody.propTypes = {
    data: propTypes.array.isRequired,
    columns: propTypes.array.isRequired,
    rowsPerPage: propTypes.number.isRequired,
    selectedPage: propTypes.number.isRequired,
    loading: propTypes.bool.isRequired,
    paginated: propTypes.bool.isRequired,
    rowClickable: propTypes.bool.isRequired,
    onRowClick: propTypes.func.isRequired,
};

export default TableBody;
