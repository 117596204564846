import React, { useLayoutEffect, useEffect, useContext } from "react";

import { UserContext } from "../../reducer/userContext";

// requests
import { putRequest } from "../../actions/requests";

let intervalId;

let focused = true;

// the threshold for which we increment the user data in firestore
const incPointThreshold = 60;

/*
 * Returns the options for jwplayer
 * */
const getOptions = ({ intervalId, onSkip, autostart, thumbnail, video, intervalCallback }) => ({
    cast: {},
    intl: { "fr-ca": { play: "reproduire", replay: "répéter" } },
    autostart: autostart,
    file: video,
    image: thumbnail ?? null,
    primary: "html",
    preload: "metadata",
    events: {
        onPlay: () => {
            if (!intervalCallback) return;
            intervalId = setInterval(() => {
                if (focused) intervalCallback();
            }, 1000);
        },
        onComplete: () => {
            clearInterval(intervalId);
            if (onSkip && typeof onSkip === "function") onSkip();
        },
        onPause: () => {
            clearInterval(intervalId);
        },
        onSeek: () => {
            clearInterval(intervalId);
        },
        onError: () => {
            clearInterval(intervalId);
        },
        remove: () => {
            clearInterval(intervalId);
        },
    },
});

const VideoPlayer = ({ jwpTag = "jwp", video, calculatePoints = true, thumbnail, onSkip = null, autostart = true, children }) => {
    // user is needed to be able to update their points
    const { user } = useContext(UserContext);

    useLayoutEffect(() => {
        const onFocus = () => {
            focused = true;
        };
        const onBlur = () => {
            focused = false;
        };

        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);

        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        };
    }, []);

    useEffect(() => {
        const player = window.jwplayer(jwpTag);

        if (!calculatePoints) {
            const opt = getOptions({
                video,
                thumbnail,
                onSkip,
                autostart,
                intervalId,
            });
            player.setup(opt);
            return;
        }

        let watchTime = 1;

        const opt = getOptions({
            intervalCallback: () => {
                if (watchTime % incPointThreshold === 0) {
                    putRequest("users/loyaltyPoints/" + user.id)
                        .then(res => {
                            console.log(res);
                        })
                        .catch(e => {
                            console.error(e);
                        });
                }

                watchTime++;
            },
            video,
            thumbnail,
            onSkip,
            autostart,
            intervalId,
        });

        player.setup(opt);

        return () => {
            watchTime = 0;
            player.remove();
            clearInterval(intervalId);
        };
    }, [video]);

    return (
        <div className="workout__videoplayer__container">
            <div id={jwpTag}></div>
            {children}
        </div>
    );
};

export default VideoPlayer;
