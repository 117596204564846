import React from 'react';

function Shipping() {
    return (
        <div className="shipping">
            <h3>La livraison est gratuite!</h3>
        </div>
    );
}

export default Shipping;
