import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "../apiCalls";
import { settings } from "../../settings";
import { loadingAction } from "../utils/general";
// Utils
import { stringifyQueries } from "../../utils/parseData";

const baseurl = settings.urls.products;

export const sliceName = "products";

// each action in reducers object has a state and action params
const slice = createSlice({
    name: sliceName,
    initialState: {
        fetchQueries: { limit: 10, page: 0 },
        list: [],
        loading: true,
        lastFetch: null,
        noMoreData: false,
        categories: {
            list: [],
            lastFetch: null,
        },
    },
    reducers: {
        received: (products, action) => {
            products.list = action.payload.map(p => {
                delete p.selectedQuantity;
                return p;
            });
            products.loading = false;
            products.lastFetch = Date.now();
        },
        categoriesReceived: (products, action) => {
            products.categories.list = action.payload;
            products.loading = false;
            products.categories.lastFetch = Date.now();
        },
        requested: products => {
            products.loading = true;
        },
        requestedFailed: products => {
            products.loading = false;
        },
        removed: (products, action) => {
            products.list = products.list.filter(product => product.id !== action.payload.id);
        },
        added: (products, action) => {
            const { payload } = action;

            if (Array.isArray(payload)) products.list = [...products.list, ...payload];
            else products.list.push(action.payload);

            products.loading = false;
            products.lastFetch = Date.now();
        },
        updateById: (products, action) => {
            const index = products.list.findIndex(product => product.id === action.payload.id);

            products.list[index] = {
                ...products.list[index],
                ...action.payload.updatedFields,
            };
        },
        finishedFetching: (products, action) => {
            if (action.payload?.status === 404) {
                products.noMoreData = true;
            } else if (action.payload === "filtering") {
                /* TODO: Not sure why this condition exists... */
                // products.list = [];
                products.noMoreData = true;
            }
        },
        setted: (products, action) => {
            for (const [key, value] of Object.entries(action.payload)) {
                if (!(key in products)) throw new Error(`Product slice: ${key} is not in product state`);
                products[key] = value;
            }
        },
    },
});

const { added, requested, received, requestedFailed, removed, updateById, categoriesReceived } = slice.actions;

export default slice.reducer;

// Action creators
export const loadProducts = () => dispatch =>
    dispatch(
        apiCallBegan({
            url: `${settings.urls.products}`,
            onStart: requested.type,
            onSuccess: received.type,
            onError: requestedFailed.type,
        })
    );

export const loadProductById = id => dispatch =>
    dispatch(
        apiCallBegan({
            url: `${settings.urls.products}/${id}`,
            onStart: requested.type,
            onSuccess: added.type,
            onError: requestedFailed.type,
        })
    );

export const loadById = id => (dispatch, getState) => {
    const productList = getState().products.list;
    const found = productList.findIndex(p => p.id === id) !== -1;

    if (found) return;

    dispatch(
        apiCallBegan({
            sliceName,
            url: `${settings.urls.products}/${id}`,
            onStart: requested.type,
            onSuccess: added.type,
            onError: requestedFailed.type,
        })
    );
};

export const loadProductByBrand = brand => dispatch => {
    dispatch(
        apiCallBegan({
            url: `${baseurl}/${stringifyQueries({ brands: { operator: "array-contains", value: JSON.stringify({ name: brand }) } })}`,
            onStart: requested.type,
            onSuccess: received.type,
            onError: requestedFailed.type,
        })
    );
};

export const loadProductCategories = () => dispatch =>
    dispatch(
        loadingAction({
            url: `${settings.urls.products}/categories/all`,
            onStart: requested.type,
            onSuccess: categoriesReceived.type,
            onError: requestedFailed.type,
        })
    );
export const deleteProduct = id => dispatch =>
    dispatch(
        apiCallBegan({
            url: `${settings.urls.products}/delete`,
            onStart: requested.type,
            onSuccess: removed.type,
            data: { id: id },
            method: "post",
        })
    );
export const updateProduct =
    ({ id, updatedFields }) =>
    dispatch =>
        dispatch(
            apiCallBegan({
                url: `${settings.urls.products}/update`,
                onStart: requested.type,
                onSuccess: updateById.type,
                data: { id, updatedFields },
                method: "post",
            })
        );

export const addProduct = data => dispatch =>
    dispatch(
        apiCallBegan({
            url: `${settings.urls.products}/${data.id}`,
            onStart: requested.type,
            onSuccess: added,
            data: data,
        })
    );

/*
 *
 * @param query {object} each key, value pair corresponds to a url query, e.g. ...?amount=9999 => { amount: { operator: '=', value; 9999 } }
 * @param options {options} options that dictate the behavior of the fn. Accepted values are: reset: 'once', 'always', 'never'
 * */
export const fetchPage =
    (query = {}, options = { reset: false, resetQuery: false }) =>
    (dispatch, getState) => {
        const initState = { limit: 10, page: 0 };
        const paginatingOnly = Object.keys(query).filter(key => key !== "limit" && key !== "page").length === 0;

        const { fetchQueries } = getState().products;

        if (options.resetQuery) {
            query = { ...query, ...initState };
        } else {
            for (const [key, value] of Object.entries(fetchQueries)) {
                if (query[key] === undefined) query[key] = value;
            }
        }

        dispatch(
            apiCallBegan({
                method: "get",
                url: `${settings.urls.products}/${stringifyQueries(query)}`,
                onSuccess: options.reset
                    ? [received.type, slice.actions.setted({ fetchQueries: !!options.resetQuery ? initState : query })]
                    : [
                          added.type,
                          slice.actions.setted({ fetchQueries: !!options.resetQuery ? initState : { ...query, page: fetchQueries.page + 1 } }),
                      ],
                onError: paginatingOnly ? slice.actions.finishedFetching.type : slice.actions.finishedFetching("filtering"),
            })
        );
    };

export const fetchPageFromBoutique = (query, options) => fetchPage({ ...query, inBoutique: true }, options);

export const set = payload => dispatch => dispatch({ type: slice.actions.setted.type, payload });

// Selectors
export const getAllProducts = createSelector(
    state => state.products,
    products => products.list
);

export const getAllProductCategories = createSelector(
    state => state.products.categories,
    categories => categories.list
);

export const getProductsByBrand = brand =>
    createSelector(getAllProducts, products => {
        return products.filter(p => {
            return p.brands.some(b => b.name === brand);
        });
    });

export const getProductById = id => state => state.products.list.find(product => product.id === id);

export const isLoading = state => state.products.loading;
