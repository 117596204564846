import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "../apiCalls";
import { settings } from "../../settings";

// each action in reducers object has a state and action params
const slice = createSlice({
    name: "brands",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
    },
    reducers: {
        received: (brand, action) => {
            brand.list = action.payload;
            brand.loading = false;
            brand.lastFetch = Date.now();
        },
        requested: (brand, action) => {
            brand.loading = true;
        },
        requestedFailed: (brand, action) => {
            brand.loading = false;
        },
    },
});

const { requested, received, requestedFailed } = slice.actions;
export default slice.reducer;

// Action creators
export const loadBrands = () => (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: `${settings.urls.brands}`,
            onStart: requested.type,
            onSuccess: received.type,
            onError: requestedFailed.type,
        })
    );
};

// Selectors
export const getAllBrands = createSelector(
    state => state.brands.list.filter(brand => brand.name !== "Aktivation France" && brand.name !== "Jimmy Sévigny"),
    state => state,
    (list, state) => list
);

export const getBrandsByMarket = marketName =>
    createSelector(
        state => state.brands.list,
        list => list.filter(brand => brand.market === marketName)
    );
