import React from "react";
import propTypes from "prop-types";
import clsx from "clsx";
// Material UI Core
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
// Material UI Icons
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const TableHeader = ({ columns, sortColumn, rowsPerPage, selectedPage, totalCount, paginated, sortable, onSort, onPageChange }) => {
    const raiseSort = path => {
        if (sortable) {
            const sortedColumn = { ...sortColumn };
            if (sortedColumn.path === path) {
                sortedColumn.order = sortedColumn.order === "asc" ? "desc" : "asc";
            } else {
                sortedColumn.path = path;
                sortedColumn.order = "asc";
            }
            onSort(sortedColumn);
        }
    };

    const renderSortIcon = path => {
        if (sortable && sortColumn.path === path) {
            return (
                <IconButton size="small">
                    <div>{sortColumn.order === "desc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</div>
                </IconButton>
            );
        }
        return;
    };

    const renderCell = column => {
        if (column.header) {
            return (
                <TableCell key={createKey(column)} align="center">
                    {column.header(column.header)}
                </TableCell>
            );
        } else if (column.path) {
            return (
                <TableCell key={createKey(column)} align="center" onClick={() => raiseSort(column.path)} className={sortable ? "column__sorted" : ""}>
                    {column.label} {renderSortIcon(column.path)}
                </TableCell>
            );
        } else {
            return (
                <TableCell key={createKey(column)} align="center">
                    {column.label}
                </TableCell>
            );
        }
    };

    const createKey = column => {
        if (column.path) {
            return column.path;
        } else {
            return column.key;
        }
    };

    return (
        <TableHead>
            {paginated && (
                <TableRow>
                    <TablePagination
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}
                        page={selectedPage}
                        count={totalCount}
                        onChangePage={onPageChange}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} de ${count}`}
                    />
                </TableRow>
            )}
            <TableRow className="clickable">{columns.map(column => renderCell(column))}</TableRow>
        </TableHead>
    );
};

TableHeader.propTypes = {
    columns: propTypes.array.isRequired,
    sortColumn: propTypes.object.isRequired,
    rowsPerPage: propTypes.number.isRequired,
    selectedPage: propTypes.number.isRequired,
    totalCount: propTypes.number.isRequired,
    paginated: propTypes.bool.isRequired,
    sortable: propTypes.bool.isRequired,
    onSort: propTypes.func.isRequired,
    onPageChange: propTypes.func.isRequired,
};

export default TableHeader;
