import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
// Custom Components
import { UserContext } from "../reducer/userContext";

/**
 *
 * Assigns a layout to the component and adds a document title.
 * @param {Component} component
 * @param {Component} layout
 * @param {string} title
 *
 */
export const PortalRoute = ({ component: RouteComponent, layout: LayoutComponent, accessNeeded, title, ...rest }) => {
    const { user } = useContext(UserContext);

    if (typeof title === "string") window.title = title;

    // Video access
    if (accessNeeded && accessNeeded.includes("video")) {
        if (!user || !user.hasVideoAccess) {
            return <Redirect to="/portail/login" />;
        }
    }

    return (
        <Route
            {...rest}
            render={routeProps =>
                user ? (
                    LayoutComponent ? (
                        <LayoutComponent>
                            <RouteComponent {...routeProps} />
                        </LayoutComponent>
                    ) : (
                        <RouteComponent {...routeProps} />
                    )
                ) : (
                    <Redirect to="/portail/login" />
                )
            }
        />
    );
};
