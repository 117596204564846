import React from "react";
import propTypes from "prop-types";
// Material UI Core
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

/**
 * Displays an upsell proposal box
 * @param {object} item
 * @param {func} onAccept
 * @param {func} onRefuse
 */
const Recipe = ({ recipe, brand }) => {
    return (
        <Grid className="recipes__item">
            <div>
                <img src={recipe.imageURLs[0]} alt="" className="recipes__image" />
            </div>
            <div className="recipes__bottom">
                <div>
                    <h3 className={`recipes__title ${brand}`}>{recipe.title}</h3>
                </div>
                <div className="recipes__info">
                    <span className="recipes__level">{recipe.difficulty}</span>
                    <span className="recipes__categories">
                        {recipe.categories.map(cat => (
                            <Chip key={cat} label={cat} className="recipes__chip" />
                        ))}
                    </span>
                </div>
            </div>
        </Grid>
    );
};

Recipe.propTypes = {
    recipe: propTypes.object.isRequired,
};

export default Recipe;
