import React, { useEffect, useState, useMemo } from "react";

import clsx from "clsx";

// Mui
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Slider from "@material-ui/core/Slider";

// icons
import { BsCheckCircleFill } from "react-icons/bs";
import { IoFilter } from "react-icons/io5";
import { AiFillTag } from "react-icons/ai";
import { MdCategory } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";

// custom components
import Filter from "./Filter";

// styles
import style from "./Filters.module.scss";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchPageFromBoutique as fetchPage } from "../../../../store/reducers/products";
import { getAllBrands, loadBrands } from "../../../../store/reducers/brands";

// hooks
import useFetch from "../../../../hooks/useFetch";
import useDebounce from "../../../../hooks/useDebounce";

const Filters = () => {
    const dispatch = useDispatch();
    const brands = useSelector(getAllBrands);

    const fetchProducts = (queries, options) => dispatch(fetchPage(queries, options));

    const [priceRange, setPriceRange] = useState([0, 100]);
    const [showOnlyDiscounts, setShowOnlyDiscounts] = useState(false);

    const [categories] = useFetch("strings/Products-Categories");
    const [minPriceProductData] = useFetch("products/?orderBy=price.amount,asc&limit=1");
    const [maxPriceProductData] = useFetch("products/?orderBy=price.amount,desc&limit=1");

    const minPrice = useMemo(() => {
        return minPriceProductData ? Math.round(minPriceProductData[0].price.amount / 100) : 0;
    }, [minPriceProductData]);

    const maxPrice = useMemo(() => {
        return maxPriceProductData ? Math.round(maxPriceProductData[0].price.amount / 100) : 100;
    }, [maxPriceProductData]);

    useDebounce(
        () => {
            const [min, max] = priceRange;
            if (min === minPrice && max === maxPrice) return;

            console.log(`fetch products within [${priceRange}] (price range)`);
            fetchProducts(
                {
                    "price.amount": [
                        { operator: "less-than-equals", value: max * 100 },
                        { operator: "greater-than-equals", value: min * 100 },
                    ],
                    orderBy: "price.amount",
                },
                { reset: true }
            );
        },
        500,
        [priceRange]
    );

    const filters = [
        {
            Icon: <BsCheckCircleFill color={showOnlyDiscounts ? "inherit" : "grey"} />,
            title: "En Solde",
            onClick: () => {
                setShowOnlyDiscounts(showDiscounts => {
                    const queries = showDiscounts ? {} : { discountedPrice: { operator: "not-equals", value: null }, orderBy: "discountedPrice" };
                    fetchProducts(queries, { reset: true });

                    return !showDiscounts;
                });
            },
        },
        {
            Icon: <AiFillTag />,
            title: "Marque",
            options: brands.map(brand => brand.name),
            onChange: selected => {
                const selectedBrands = brands
                    .filter(brand => selected.some(s => s === brand.name))
                    .map(brand => ({ name: brand.name, acronym: brand.acronym }));

                if (!selectedBrands.length) {
                    fetchProducts({}, { reset: true });
                    return;
                }

                fetchProducts({ brands: { operator: "array-contains-any", value: selectedBrands } }, { reset: true });
            },
        },
        {
            Icon: <MdCategory />,
            title: "Catégorie",
            options: categories?.list ? Object.entries(categories?.list).sort() : [],
            onChange: selectedCategories => {
                if (!selectedCategories.length) {
                    fetchProducts({}, { reset: true });
                    return;
                }

                fetchProducts({ fullCategory: { operator: "in", value: selectedCategories } }, { reset: true });
            },
        },
        {
            Icon: <RiMoneyDollarCircleFill />,
            title: "Prix",
            subcomponent: (
                <div style={{ width: "90%" }}>
                    <Slider
                        marks={[
                            { value: minPrice, label: `${minPrice} $` },
                            { value: maxPrice, label: `${maxPrice} $` },
                        ]}
                        valueLabelDisplay="on"
                        valueLabelFormat={value => value}
                        valueLabelDisplay="auto"
                        min={minPrice}
                        max={maxPrice}
                        value={priceRange}
                        onChange={(_, newRange) => {
                            setPriceRange(newRange);
                        }}
                    />
                </div>
            ),
        },
    ];

    useEffect(() => {
        if (!brands.length) dispatch(loadBrands());
    }, []);

    useEffect(() => {
        setPriceRange([minPrice, maxPrice]);
    }, [minPrice, maxPrice]);

    return (
        <div className={clsx(style.container, "boutique__card")}>
            <div className={clsx(style.header, "header")}>
                <IoFilter />
                <span>Filtres</span>
                <Button color="secondary">effacer</Button>
            </div>
            <Divider />
            <div className={clsx("body")}>
                {filters.map(filter => (
                    <Filter key={filter.title} {...filter} />
                ))}
            </div>
        </div>
    );
};

export default Filters;
