export const usersRoot = "users";
export const getStripeSub = "users/stripeSub";
export const cancelStripeSub = "users/cancelSub";

export const postFindById = "users/getById";
export const postRegister = "users/register";
export const postRegisterAuth = "users/registerAuth";
export const getAllUsers = "users/all";
export const getUserByEmail = "users/email";
export const postResetPassword = "users/forgotPassword";
export const postChangePassword = "users/changePassword";
export const postUpdateUser = "users/update";
export const postAddNote = "users/addNote";
export const postFindByRole = "users/findByRole";
export const postDeleteNote = "users/deleteNote";
export const postUpdateNote = "users/updateNote";
export const postGetNotes = "users/getNotes";
export const postDeleteUser = "users/delete";
export const postSearch = "users/search";
export const postAddSubscription = "users/addSubscription";
export const createDuoSubscription = "users/createDuoSubscription"
