import React, { useState, useEffect } from "react";

// Mui
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";

// Assets
import box from "../../../assets/bs/how-box.png";

const ProgressOrder = ({ create, step }) => {
    return (
        <div className="progressOrder">
            {create === undefined ? (
                <>
                    <div className="progressOrder__container">
                        <div className="progressOrder__step">
                            <div className="progressOrder__step--image">
                                <img className="progressOrder__step--image" src={box} alt="" />
                            </div>
                            <p className="progressOrder__step--text">
                                Sélectionnez un <b>type de boîte</b>
                            </p>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {create ? (
                        <div className="progressOrder__container">
                            {step === 1 && (
                                <div className="progressOrder__step">
                                    <div className="progressOrder__step--image">
                                        <img className="progressOrder__step--image" src={box} alt="" />
                                    </div>
                                    <p className="progressOrder__step--text">
                                        Sélectionnez un <b>format de boîte</b>
                                    </p>
                                </div>
                            )}
                            {step === 2 && (
                                <div className="progressOrder__step">
                                    <div className="progressOrder__step--image">
                                        <img className="progressOrder__step--image" src={box} alt="" />
                                    </div>
                                    <p className="progressOrder__step--text">
                                        <b>Ajouter des repas</b>
                                    </p>
                                    <Box sx={{ width: "100%" }}>
                                        <LinearProgress variant="determinate" value={60} />
                                        <p className="progressOrder__step--percentage">60%</p>
                                        <div className="subtotal">
                                            <p className="title">Subtotal</p>
                                            <h6 className="price">50,99$</h6>
                                        </div>
                                    </Box>
                                </div>
                            )}
                            {/* <button className="button button__cta button__cta--darkGreen">Étape suivante</button> */}
                        </div>
                    ) : (
                        <div className="progressOrder__container">
                            <div className="progressOrder__step">
                                <div className="progressOrder__step--image">
                                    <img className="progressOrder__step--image" src={box} alt="" />
                                </div>
                                <p className="progressOrder__step--text">
                                    Sélectionnez <b>une ou plusieurs boîtes</b>
                                </p>
                            </div>
                            {/* <button className="button button__cta button__cta--darkGreen">Étape suivante</button> */}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ProgressOrder;
