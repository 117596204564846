import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import Cryptr from "cryptr";
// Material UI Core
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
// React Hook Form
import { useForm } from "react-hook-form";
import { duoSchema } from "./yupSchema";
import { yupResolver } from "@hookform/resolvers/yup";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { addDuoEmail, getCheckoutDetails } from "../../../store/reducers/checkout.js";
import { snackbarEnqueuedAction, generateKey } from "../../../store/reducers/snackbars";
// DB
import { getRequest } from "../../../actions/requests";
// Custom Components
import { CheckoutContext } from "./Checkout";
import { getLandingPageName, getLandingPageInfo } from "../../../utils/config";
import { settings } from "../../../settings";
import { UserContext } from "../../../reducer/userContext";
import { emailRegex } from "../../../utils/regex";

const Duo = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // Redux
    const details = useSelector(getCheckoutDetails);

    const cryptr = new Cryptr(settings.encryption.secret);

    // states
    const { user } = useContext(UserContext);
    const { goToNextPage, setDuoUser } = useContext(CheckoutContext);
    const [email, setEmail] = useState("");
    const { brand } = getLandingPageInfo();

    // notifications functions
    const pushNotification = (...args) => dispatch(snackbarEnqueuedAction(...args));

    const DuoEmail = () => {
        const { register, errors, handleSubmit, getValues } = useForm({
            resolver: yupResolver(
                yup.object({
                    duoEmail: yup
                        .string()
                        .matches(emailRegex, "Le courriel contient des caractères invalides")
                        .notOneOf([details.email ?? user.email, null], "Ne doit pas être votre propre courriel")
                        .required("Ce champ est requis"),
                    duoEmailConfirmation: yup
                        .string()
                        .matches(emailRegex, "Le courriel contient des caractères invalides")
                        .oneOf([yup.ref("duoEmail"), null], "Les courriels doivent concordés")
                        .required("Ce champ est requis"),
                })
            ),
        });

        const handleCheckEmail = async data => {
            try {
                const payload = await getRequest(`users/subs/${data.duoEmail}`);
                const { exists, subscriptions } = payload.data;

                if (exists) {
                    if (subscriptions.some(s => settings.videoAccess.includes(s.id) && !s.expired)) {
                        pushNotification({
                            message:
                                "Le compte que vous tentez d'ajouter a déjà un abonnement d'Aktivation en vigueur. Veuillez choisir un autre utilisateur.",
                            options: {
                                key: generateKey(),
                                variant: "success",
                                popup: {
                                    title: "Erreur de compte",
                                    showImage: false,
                                },
                            },
                        });
                    } else {
                        dispatch(addDuoEmail(data.duoEmail));
                        goToNextPage(history.replace, history.location.pathname, data);
                    }
                } else {
                    setEmail(data.duoEmail);
                }
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    setEmail(data.duoEmail);
                } else console.error(err);
            }
        };

        return (
            <form onSubmit={handleSubmit(handleCheckEmail)}>
                <Grid container direction="column" spacing={3}>
                    <TextField
                        error={errors.duoEmail ? true : false}
                        id="duoEmail"
                        name="duoEmail"
                        label="Courriel du compte duo"
                        inputRef={register}
                        helperText={errors.duoEmail?.message}
                        fullWidth
                    />
                    <TextField
                        error={errors.duoEmailConfirmation ? true : false}
                        id="duoEmailConfirmation"
                        name="duoEmailConfirmation"
                        label="Confirmer compte duo"
                        inputRef={register}
                        helperText={errors.duoEmailConfirmation?.message}
                        fullWidth
                    />

                    <Typography variant="body2" gutterBottom>
                        <sup>*</sup>Le courriel duo ne pourra pas être changé par la suite
                    </Typography>
                    <Grid container item alignItems="flex-end" justify="flex-end" spacing={3}>
                        <Grid item>
                            <Button className={`checkout__next ${brand}`} variant="contained" type="submit">
                                Suivant
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        );
    };

    const DuoSubscribe = () => {
        const source = getLandingPageName();
        const { register, errors, handleSubmit } = useForm({
            resolver: yupResolver(duoSchema),
            defaultValues: { duoEmail: email },
        });

        const handleCreateCustomer = data => {
            dispatch(addDuoEmail(data.duoEmail));

            const encryptedPassword = cryptr.encrypt(data.duoPassword);

            setDuoUser({
                email: data.duoEmail,
                firstName: data.duoFirstName,
                lastName: data.duoLastName,
                password: encryptedPassword,
                source,
            });

            goToNextPage(history.replace, history.location.pathname, data);
        };

        return (
            <form onSubmit={handleSubmit(handleCreateCustomer)}>
                <TextField
                    error={errors.email ? true : false}
                    id="duoEmail"
                    name="duoEmail"
                    label="Courriel du compte duo"
                    inputRef={register}
                    InputProps={{ readOnly: true }}
                    helperText={errors.duoEmail?.message}
                    fullWidth
                />
                <TextField
                    error={errors.firstName ? true : false}
                    id="duoFirstName"
                    name="duoFirstName"
                    label="Prénom"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register}
                    fullWidth
                    helperText={errors.duoFirstName?.message}
                />
                <TextField
                    error={errors.lastName ? true : false}
                    id="duoLastName"
                    name="duoLastName"
                    label="Nom"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register}
                    fullWidth
                    helperText={errors.duoLastName?.message}
                />
                <TextField
                    error={errors.password ? true : false}
                    label="Mot de passe"
                    name="duoPassword"
                    id="duoPassword"
                    type="password"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register}
                    helperText={errors.duoPassword?.message}
                    fullWidth
                />
                <TextField
                    error={errors.passwordConfirm ? true : false}
                    label="Confirmation du mot de passe"
                    name="duoPasswordConfirm"
                    id="passwordConfirm"
                    type="password"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register}
                    helperText={errors.duoPasswordConfirm?.message}
                    fullWidth
                />
                <Grid container item alignItems="flex-end" justify="flex-end" spacing={3}>
                    <Grid item>
                        <Button className={`checkout__next ${brand}`} variant="contained" type="submit">
                            Suivant
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    };

    return (
        <div className="duo_container">
            {!email && <DuoEmail />}
            {email && <DuoSubscribe />}
        </div>
    );
};

export default Duo;
