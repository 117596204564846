import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

// utils
import { dispatchActions } from "../utils/general";

export const sliceName = "system";

// Each action in reducers object has a state and action params
const slice = createSlice({
    name: sliceName,

    initialState: {
        loading: 1, //  spinners active
        errorMessage: null, //  current error message
        slicesLoaded: [], //  which slices have finished loading
    },

    reducers: {
        //  Starts a spinner
        loadingStart: (state, action) => {
            state.loading++;
        },

        //  Ends a spinner
        loadingEnd: (state, action) => {
            if (state.loading > 0) state.loading--;
        },

        //  Clears all spinners
        loadingClear: (state, action) => {
            state.loading = 0;
        },

        //  Shows an error message
        showError: (state, action) => {
            state.errorMessage = action.payload;
        },

        //  Clears the current error message
        clearError: state => {
            state.errorMessage = null;
        },

        //  Adds a slice to the loaded ones
        addSlice: (state, action) => {
            if (state.slicesLoaded.indexOf(x => x === action.payload) === -1) state.slicesLoaded = [...state.slicesLoaded, action.payload];
        },

        //  Removes a slice from the loaded ones
        removeSlice: (state, action) => {
            state.slicesLoaded = state.slicesLoaded.filter(x => x !== action.payload);
        },
    },
});

export default slice.reducer;

//  Exporting actions
export const { loadingStart, loadingEnd, loadingClear, showError, clearError, addSlice, removeSlice } = slice.actions;

// --- [Actions] --------------------------------------------------------------------------------------------
export const loadingSlice = sliceName => dispatch => {
    const actions = [
        { type: loadingStart.type, payload: {} },
        { type: addSlice.type, payload: sliceName },
    ];

    dispatchActions(dispatch, actions);
};

//  Selectors -----------------------------------------------------------------------------------------------

//  The current state of the spinner
export const showSpinner = state => state[sliceName].loading > 0;

//  Current error
export const currentError = state => state[sliceName].errorMessage || null;

//  Currently loaded slices
export const slicesLoaded = state => state[sliceName].slicesLoaded;

//  Number of currently loaded slices (helps to trigger changes)
export const slicesCount = state => state[sliceName].slicesLoaded.length;

//  Is a certain slice / array of slices loaded?
//  Pass a string or an array of strings
export const isSliceLoaded = name =>
    createSelector([slicesLoaded], slicesLoaded => {
        if (!slicesLoaded || slicesLoaded.length === 0) return false;
        if (!Array.isArray(name)) return slicesLoaded.includes(name);
        let retval = true;
        name.forEach(n => {
            if (slicesLoaded.indexOf(n) === -1) retval = false;
        });
        return retval;
    });
