import React from "react";
// Pages
import Nav from "./home/nav";
import Hero from "./home/hero";
import Subhero from './home/subhero';
import Compatible from './home/compatible';
import Use from './home/use';
import Connect from './home/connect';
import What from './home/what';
import Socials from "./home/socials";
import Coach from './home/coach';
import Progs from './home/progs';
import Products from "./home/products";
import Testimonials from "./home/testimonials";
import Faq from "./home/faq";
import Fact from "./home/fact";
import Footer from "./home/footer";
import FooterNav from "./home/footernav";
import Lh from "./home/lh";
// Custom Components
import { GetHelmet } from "../../utils/config";

function Home() {
    return (
        <div className="akt">
            <GetHelmet />
            <div
                className="fb-customerchat"
                attribution="setup_tool"
                page_id="107573801050278"
                theme_color="#007faa"
                logged_in_greeting="Bienvenue sur Aktivation! Comment pouvons-nous vous aider?"
                logged_out_greeting="Bienvenue sur Aktivation! Comment pouvons-nous vous aider?"
            ></div>
            <Nav />
            <Hero />
            <Subhero />
            <Compatible />
            <Use />
            <Connect />
            <What />
            <Socials />
            <Coach />
            <Progs />
            <Products />
            <Testimonials />
            <Faq />
            <Fact />
            <Footer />
            <FooterNav />
            <Lh />
        </div>
    );
}

export default Home;
