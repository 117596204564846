import React from "react";
import { Grid } from "@material-ui/core";

import Left from "../../../assets/gk/gokrisp-use.jpg";

function Use() {
    return (
        <div className="use">
            <Grid container>
                <Grid item sm={12} md={6}>
                  <div className="use__left__container">
                    <img src={Left} className="use__left" alt="" />
                  </div>
                </Grid>
                <Grid item sm={12} md={6} className="use__background">
                    <div className="use__container">
                        <span>
                            <span className="use__1">La collation parfaite!</span>
                            <span className="use__2">Que ce soit au travail, lors de vos activités  ou avant/après  vos entraînements, Go Krisp! est plus que parfaite!</span>
                            <span className="use__3">Chaque barre contient jusqu'à 15 grammes de protéines, 12 grammes de fibres et moins de 5 grammes de sucres ajoutés. </span>
                            <a href="#sample"><button className="use__cta">Essayer un “sample pack”</button></a>
                        </span>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Use;