import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { createAction } from "@reduxjs/toolkit";
import { jsonWalk, deserialize } from "../middleware/serializer";

const sliceName = "snackbars";

const slice = createSlice({
    name: sliceName,
    initialState: {
        // a notification consists of an object : {message, options: {key, variant}}. See https://iamhosseindhv.com/notistack/api for more details
        stack: [],
    },

    reducers: {
        // add a notification to the queue
        snackbarEnqueued: (state, action) => {
            const key = action.payload.options && action.payload.options.key;
            state.stack.push({ key: key || generateKey(), ...action.payload });
        },

        // close all snackbars or the one with the given key
        snackbarClosed: (state, action) => {
            state.stack.forEach(n => {
                if (action.payload.dismissAll || action.payload.key === n.key) n.dismissed = true;
            });
        },

        // remove the notification with the given key from the queue
        snackbarRemoved: (state, action) => {
            state.stack = state.stack.filter(n => n.key !== action.payload);
        },
    },
});

export const { snackbarEnqueued, snackbarClosed, snackbarRemoved } = slice.actions;
export default slice.reducer;

// --- [Actions] ---------------------------------------------------------------
export const snackbarEnqueuedAction = notification => dispatch => {
    dispatch({ type: snackbarEnqueued.type, payload: { ...notification } });
};

export const snackbarClosedAction = key => dispatch => {
    dispatch({ type: snackbarClosed.type, payload: { key, dismissAll: !key } });
};

export const snackbarRemovedAction = key => dispatch => {
    dispatch({ type: snackbarRemoved.type, payload: key });
};

// --- [Selector] --------------------------------------------------------------
export const getStack = state => {
    return state[sliceName].stack;
};

// --- [Utils] -----------------------------------------------------------------
export const generateKey = () => new Date().getTime() + Math.random();
