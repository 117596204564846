import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";

import KO30 from "../../../assets/akt/ko30-banniere.jpg";
import KO30Mobile from "../../../assets/akt/ko30-mobile.jpg";
import Zone2 from "../../../assets/akt/zone2-banniere.jpg";
import Zone2Mobile from "../../../assets/akt/zone2-mobile.jpg";
import Reset from "../../../assets/akt/reset-banniere.jpg";
import ResetMobile from "../../../assets/akt/reset-mobile.jpg";
import HiitMobile from "../../../assets/akt/Hiit-mobile.jpg";
import AscMobile from "../../../assets/akt/asc-mobile.jpg";
import TntMobile from "../../../assets/akt/tnt-mobile.jpg";
import AbzMobile from "../../../assets/akt/Abz-mobile.jpg";
import Indestructible from "../../../assets/akt/indestructible-mobile.png";
import Metabolik from "../../../assets/akt/metabolik_mobile.jpg";
import Danza from "../../../assets/akt/danza.jpg";
import Cadense from "../../../assets/akt/cadense.jpg";
import Zone from "../../../assets/akt/zone.jpg";
import Genesys from "../../../assets/akt/genesys.jpg";
import Connex from "../../../assets/akt/connex.jpg";
import Amp from "../../../assets/akt/amp.jpg";
import Bpm from "../../../assets/akt/bpm.jpg";
import Intensite from "../../../assets/akt/intensite.jpg";
import Yoga from "../../../assets/akt/yoga.jpg";
import Revolution from "../../../assets/akt/revolution.jpg";
import Transformation from "../../../assets/akt/transformation.jpg";
import Evolution from "../../../assets/akt/evolution.jpg";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import VideoPlayer from "../../shared/videoPlayer";

// server request
import { getRequest } from "../../../actions/requests";

// const workouts = {
//     indestructible: {
//         id: "CYHZIINEPpr6eGM8wK0T",
//     },
//     metabolik: {
//         id: "rxKVvKpv7hKU2PNhRcb2",
//     },
//     danza: {
//         id: "U25twcjE6nadCfonFmHm",
//     },
//     cadence: {
//         id: "qysg9tC8gwYN1Idm5Mjj",
//     },
//     zone: {
//         id: "lfbPU8jRxVtxaaRLjYna",
//     },
//     connex: {
//         id: "J6BapubIg2KiGZNPQqI8",
//     },
//     genesys: {
//         id: "C4whBgIdcSFRHJ1jr1kQ",
//     },
//     bpm: {
//         id: "WSr668WDLjrBxkEzxL26",
//     },
//     intensite: {
//         id: "hasvNhqbfunqVevMuxGD",
//     },
//     transformation: {
//         id: "b7PyLu7bmq7l2qJccMqM",
//     },
//     revolution: {
//         id: "VX0NaERSW2UlKJWxwXc8",
//     },
//     yoga: {
//         id: "ecFdlXptvNb1Ej6GTQQX",
//     },
//     amp: {
//         id: "v9kPKvMHIlDGiX8ZGfsg",
//     },
//     evolution: {
//         id: "VtLpMP4Ixnteav0JCg03",
//     },
// };

const VideoDialog = ({ tag, file, open, onClose, workoutInfo }) => {
    const [player, setPlayer] = useState(null);
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={"lg"} className="akt">
            <div style={{ width: "100%", height: "100%" }}>
                <VideoPlayer calculatePoints={false} player={player} setPlayer={setPlayer} jwpTag={tag} video={file} />
            </div>
            <DialogContent>
                {workoutInfo && workoutInfo.title && (
                    <div>
                        <p className="progs__teaser__title">{workoutInfo.title}</p>
                        {workoutInfo.duration > 0 && <p className="progs__teaser__duration">{`${workoutInfo.duration} semaines`}</p>}
                        <p className="progs__teaser__description">{workoutInfo.shortDescription}</p>
                        <p className="progs__teaser__training">{workoutInfo.entrainements}</p>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};

function Progs() {
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState("");
    const [workoutName, setWorkoutName] = useState("metabolik");

    const [workouts, setWorkouts] = useState({});

    const onClose = () => {
        setOpen(false);
        setFile(null);
    };

    // fetch the workout info once. If we already have the info, it's not fetched
    const onClick = async workoutName => {
        setOpen(true);
        setFile(workouts[workoutName].teaser);
        setWorkoutName(workoutName);
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await getRequest("workouts/");
                setWorkouts(
                    response.data.reduce((acc, value) => {
                        acc[value.title.toLowerCase()] = value;
                        return acc;
                    }, {})
                );
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    return (
        <div className="progs">
            <VideoDialog open={open} file={file} tag="teaser-id" onClose={onClose} workoutInfo={workouts[workoutName]} />
            <h3 className="progs__title">DÉCOUVREZ NOS PROGRAMMES</h3>
            <Grid container>
                {/* FEATURED ITEM */}

                <Grid item xs={12} className="progs__feat__desktop">
                    <img src={KO30} onClick={() => onClick("ko30")} alt="" />
                </Grid>

                <Grid item xs={12} className="progs__feat__mobile">
                    <h3 className="progs__title">Programme vedette</h3>
                    <img src={KO30Mobile} onClick={() => onClick("ko30")} alt="" />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </Grid>

                {/* GRID ITEMS */}

                <Grid item xs={12} sm={6} md={4}>
                    <img src={Zone2Mobile} onClick={() => onClick("zone 2")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={ResetMobile} onClick={() => onClick("reset")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={HiitMobile} onClick={() => onClick("hiit")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={AscMobile} onClick={() => onClick("ascension")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={TntMobile} onClick={() => onClick("tnt step")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={AbzMobile} onClick={() => onClick("abz")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={Indestructible} onClick={() => onClick("indestructible")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={Metabolik} onClick={() => onClick("métabolik")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={Danza} onClick={() => onClick("danza")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={Cadense} onClick={() => onClick("cadence")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={Zone} onClick={() => onClick("zone")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={Genesys} onClick={() => onClick("genesys")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={Connex} onClick={() => onClick("connex")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={Amp} onClick={() => onClick("amp10")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={Bpm} onClick={() => onClick("bpm")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={Intensite} onClick={() => onClick("intensité 5.25")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={Yoga} onClick={() => onClick("yoga pré & post natal")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={Revolution} onClick={() => onClick("révolution")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={Transformation} onClick={() => onClick("transformation")} alt="" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <img src={Evolution} onClick={() => onClick("évolution")} alt="" />
                </Grid>
            </Grid>
        </div>
    );
}

export default Progs;
