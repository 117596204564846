import React from "react";
import Background from "../../../assets/mcf/intro-bg-blue.png";

function Intro() {
  return (
    <div className="intro">
      <div className="intro__container">
        <img src={Background} alt="" />
        <div className="intro__content">
          <div className="intro__video">
            <iframe
              width="550"
              height="300"
              src="https://cdn.jwplayer.com/players/W75ojtpL-hDyhcbyt.html"
            ></iframe>
          </div>
          <div className="intro__info">
            <span className="intro__1">Un mélange</span>
            <span className="intro__2">2.0</span>
            <span className="intro__3">à découvrir maintenant.</span>
            <a href="#produits">
              <button className="intro__cta">ACHETER MAINTENANT</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
