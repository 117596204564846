import React, { useEffect } from "react";

// Mui components
import Grid from "@material-ui/core/Grid";

// User context
import { UserContext } from "../../../reducer/userContext";

// custom components
import VideoThumbnail from "./videoThumbnail";
import VideoPlayer from "../../shared/videoPlayer";

// redux
import useSlice from "../../../hooks/useSlice";

const FavoriteVideos = () => {
    const { user } = React.useContext(UserContext);
    const workoutSlice = useSlice({ sliceName: "workouts" });
    const workouts = workoutSlice.selectors.all;

    // state
    const [state, setState] = React.useState({ autostart: false, currentVideo: null, videos: [], player: null });

    useEffect(() => {
        if (!!user.favoriteVideos && !!workouts.length) {
            for (const workoutId in user.favoriteVideos) {
                // skip the workout ids that have no entries
                if (!user.favoriteVideos[workoutId].length) continue;

                const workout = workouts.find(w => w.id === workoutId);
                const videos = workout.items.filter(workoutItem => {
                    return user.favoriteVideos[workoutId].includes(workoutItem.id);
                });

                // set videos
                setState(s => ({
                    ...s,
                    videos: [...s.videos, ...videos],
                }));
            }
        }

        // set current video
        setState(s => ({ ...s, currentVideo: s.videos[0] }));
    }, [workouts]);

    useEffect(() => {
        if (!workouts.length) workoutSlice.actionCreators.fetchFn({ refresh: true });
    }, []);

    if (!state.currentVideo) return null;

    return (
        <>
            {state.currentVideo && (
                <VideoPlayer
                    autostart={state.autostart}
                    player={state.player}
                    setPlayer={p => setState(s => ({ ...s, player: p }))}
                    video={state.currentVideo.url}
                    thumbnail={state.currentVideo.thumbnail}
                />
            )}
            <div className="workout__phase__content favorites__videos__container">
                <Grid container spacing={1}>
                    {state.videos.map(v => {
                        return (
                            <Grid item xs={12} sm={6} md={3} key={v.id}>
                                <VideoThumbnail item={v} onClick={() => setState(s => ({ ...s, currentVideo: v, autostart: true }))} />
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        </>
    );
};

export default FavoriteVideos;
