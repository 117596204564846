import React from "react";

// Mui
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";

import { AiOutlineSearch } from "react-icons/ai";

const InputField = withStyles({
    root: {
        justifyContent: "center",
        height: "3rem",
        backgroundColor: "white",
        borderRadius: "20px",
        border: "none",
        paddingLeft: "20px",
        paddingRight: "20px",
        "& .MuiInput-underline:before": {
            display: "none",
        },
        "& .MuiInput-underline:after": {
            display: "none",
        },
        "& svg": {
            marginRight: "10px",
        },
    },
})(({ value, onChange, placeholder = "Rechercher", startAdornment, ...rest }) => (
    <TextField
        {...rest}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        InputProps={{ ...rest.InputProps, startAdornment: startAdornment ?? <AiOutlineSearch fill="#000000" /> }}
    />
));

export default ({ value, onInputChange, autocompleteOptions = [], placeholder, ...rest }) => {
    return (
        <Autocomplete
            {...rest}
            noOptionsText="Aucun résultat"
            loadingText="Chargement..."
            style={{ flexGrow: 1 }}
            options={autocompleteOptions}
            getOptionLabel={opt => opt.label}
            renderOption={opt => (
                <>
                    <Avatar src={opt.img} alt="" />
                    <span>{opt.label}</span>
                </>
            )}
            renderInput={params => <InputField {...params} value={value} onChange={onInputChange} placeholder={placeholder} />}
        />
    );
};
