import React from "react";
import Grid from "@material-ui/core/Grid";
import Image from "../../../assets/akt/subhero.png";

function Subhero() {
    return (
        <div className="subhero">
            <div className="subhero__container">
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <div className="subhero__left">
                            <h3 className="subhero__title">PASSEZ AU NIVEAU SUPÉRIEUR!</h3>
                            <p className="subhero__text">
                                AKTIVATION est la nouvelle façon de s'entraîner 100% en ligne via vos appareils intelligents (téléphone, tablette et
                                téléviseur) et votre ordinateur.{" "}
                            </p>
                            <p className="subhero__text">
                                Vous pouvez aussi connecter votre ordinateur à votre téléviseur à l'aide d'un fil HDMI ou utiliser l'appareil
                                Chromecast ou Apple TV.
                            </p>
                            <a href="./#produits">
                                <button className="subhero__cta">JE VEUX ACTIVER LE CHANGEMENT!</button>
                            </a>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="subhero__right">
                            <img src={Image} />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Subhero;
