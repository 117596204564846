import { useEffect, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";

// TODO: implement these funtions in new user slice
import { signIn, signOut, selectors } from "../store/reducers/users";
import useSlice from "../hooks/useSlice";
// Custom Components
import { auth } from "../configs/firebase";

export default function useGetUser() {
    const dispatch = useDispatch();
    const userSlice = useSlice({ sliceName: "users" });
    const loading = userSlice.selectors.loading;
    const user = useSelector(state => selectors.getCurrentUser(state));

    useEffect(() => {
        auth.onAuthStateChanged(authUser => {
            // logged in
            if (authUser) {
                dispatch(signIn(false));
            }
            // no user
            else {
                dispatch(signOut());
            }
        });
    }, []);

    return { user, loading };
}