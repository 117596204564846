import React from "react";
// Pages
import Nav from "./home/nav";
import Hero from "./home/hero";
import Features from './home/features';
import Use from './home/use';
import Products from './home/products';
import Socials from './home/socials';
import Sample from './home/sample';
import Valeurs from './home/valeurs';
import Shipping from './home/shipping';
import Testimonials from './home/testimonials';
import Advantages from './home/advantages';
import Newsletter from './home/newsletter';
import Faq from './home/faq';
import Footer from './home/footer';
import FooterNav from './home/footernav';
// Custom Components
import { GetHelmet } from "../../utils/config";

function Home() {
    return (
        <div className="gk">
            <GetHelmet />
            {/* <div
                className="fb-customerchat"
                attribution="setup_tool"
                // TODO when he gives us the GoKrisp Facebook
                page_id="100525795408489"
                theme_color="#352312"
                logged_in_greeting="Bienvenue sur Go! Krisp Comment pouvons-nous vous aider?"
                logged_out_greeting="Bienvenue sur Go! Krisp Comment pouvons-nous vous aider?"
            ></div> */}
          <Nav />
          <Hero />
          <Features />
          <Use />
          <Socials />
          <Products />
          <Sample /> 
          <Valeurs />
          <Advantages />
          <Shipping />
          <Testimonials />
          <Newsletter />
          <Faq />
          <Footer />
          <FooterNav />
        </div>
    );
}

export default Home;
