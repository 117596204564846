import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Coconut from '../../../assets/gk/gk-coconut-solo.png';
import Baies from '../../../assets/gk/gk-baies-solo.png';
import Pommes from '../../../assets/gk/gk-pommes-solo.png';
import Tiramisu from '../../../assets/gk/gk-tiramisu-solo.png';

import NVCoconut from '../../../assets/gk/nv-coconut.jpg';
import NVBaies from '../../../assets/gk/nv-baies.jpg';
import NVPommes from '../../../assets/gk/nv-pommes.jpg';
import NVTiramisu from '../../../assets/gk/nv-tiramisu.jpg';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Valeurs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className="valeurs">
        <h3 className="valeurs__title">Valeurs Nutritives</h3>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          className="valeurs__tabs"
        >
          <Tab label="CHOCOLAT & NOIX DE COCO" {...a11yProps(0)} />
          <Tab label="EXPLOSION DE BAIES" {...a11yProps(1)} />
          <Tab label="Pommes & caramel salé" {...a11yProps(2)} />
          <Tab label="Tiramisu" {...a11yProps(3)} />
        </Tabs>
        <TabPanel value={value} index={0} dir={theme.direction} className="valeurs__tabs">
            <Grid container>
                <Grid item sm={12} md={6} className="valeurs__left">
                    <img src={Coconut} className="valeurs__gk" />
                </Grid>
                <Grid item sm={12} md={6} className="valeurs__right">
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6}>
                            <img src={NVCoconut} className="valeurs__nv" />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <p><b>Ingrédients : </b>Protéine de soya, Polydextrose, Glycérine, Noix de coco séchée, Inuline, Beurre de graine de tournesol, Chocolat non sucré, Composé au chocolat aromatisé (sucre, huile de palmiste, cacao en poudre, lécithine de tournesol, sel), Riz brun, Protéine de pois, Huile de tournesol et/ou canola, Quinoa rouge croustillant, Amidon de tapioca, Eau, Arôme naturel, Sel, Protéine de riz, Agar-agar, Sucres (sirop de riz brun). <br /><br /> <b>Contient : </b>Soja</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction} className="valeurs__tabs">
            <Grid container>
                <Grid item sm={12} md={6} className="valeurs__left">
                    <img src={Baies} className="valeurs__gk" />
                </Grid>
                <Grid item sm={12} md={6} className="valeurs__right">
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6}>
                            <img src={NVBaies} className="valeurs__nv" />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <p><b>Ingrédients : </b>Protéine de soya, Polydextrose, Glycérine, Inuline, Beurre de graine de tournesol, Flocons d'avoine, Quinoa rouge croustillant, Chocolat composé blanc aromatisé (sucre, huile de palmiste, arôme naturel, lécithine de tournesol, sel), Protéine de pois, Canneberge, Amidon de tapioca, Huile de tournesol et/ou canola, Raisin, Eau, Fraise, Sucres (jus de pomme concentré), Arôme naturel, Protéine de riz, Sel, Agar-agar. <br /><br /> <b>Contient : </b>Soja</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction} className="valeurs__tabs">
            <Grid container>
                <Grid item sm={12} md={6} className="valeurs__left">
                    <img src={Pommes} className="valeurs__gk" />
                </Grid>
                <Grid item sm={12} md={6} className="valeurs__right">
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6}>
                            <img src={NVPommes} className="valeurs__nv" />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <p><b>Ingrédients : </b>Protéine de soya, Polydextrose, Glycérine, Inuline, Beurre de graine de tournesol, Chocolat composé blanc aromatisé (sucre, huile de palmiste, arôme naturel, lécithine de tournesol, sel), Quinoa rouge croustillant, Riz brun, Pomme, Protéine de pois, Graine de chia, Amidon de tapioca, Huile de tournesol et/ou canola, Caramel (sucre de canne, eau), Eau, Arôme naturel, Sel, Protéine de riz, Agar-agar, Sucres (sirop de riz brun). <br /><br /> <b>Contient : </b>Soja</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction} className="valeurs__tabs">
            <Grid container>
                <Grid item sm={12} md={6} className="valeurs__left">
                    <img src={Tiramisu} className="valeurs__gk" />
                </Grid>
                <Grid item sm={12} md={6} className="valeurs__right">
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6}>
                            <img src={NVTiramisu} className="valeurs__nv" />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <p><b>Ingrédients : </b>Protéine de soya, Polydextrose, Glycérine, Composé au chocolat aromatisé (sucre, huile de palmiste, cacao en poudre, lécithine de tournesol, sel), Inuline, Riz brun, Beurre de graine de tournesol, Chocolat non sucré, Protéine de pois, Quinoa rouge croustillant, Graine de chia, Huile de tournesol et/ou canola, Amidon de tapioca, Eau, Arôme naturel, Sel, Protéine de riz, Agar-agar, Sucres (sirop de riz brun). <br /><br /> <b>Contient : </b>Soja</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
    </div>
  );
}
