import React from "react";

// React-router-dom
import { Link } from "react-router-dom";

// Assets
import jimmy from "../../../assets/bs/jimmy-boite-sante.png";

const About = () => {
	return (
		<div className="about">
			<div className="about__container">
				<div className="about__left">
					<img src={jimmy} alt="" className="about__left--image" />
				</div>
				<div className="about__right">
					<p className="about__right--section">à propos</p>
					<h3 className="about__right--title">
						Un concept développé par Jimmy Sévigny
					</h3>
					<p className="about__right--text">
						Ce tout nouveau concept développé par Jimmy Sévigny et son équipe de
						professionnels de la santé vous garantira des plats goûteux, conçus
						avec des aliments haut de gamme et de plus, qui sont balancés afin
						de vous offrir un maximum d'énergie et de vitalité tout au long de
						la journée.
					</p>
					<Link to="/about" className="button button__cta button__cta--green">
						En savoir plus
					</Link>
				</div>
			</div>
		</div>
	);
};

export default About;
