import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import Corpo from "../home/corpo";

function FooterNav() {
    const history = useHistory();

    // states
    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    return (
        <div className="footernav"> 
            <div className="footernav__container">
                <span className='clickable__tag' onClick={() => history.push("/devenir-entraineur")}>DEVENIR COACH</span>
                <span className='clickable__tag' onClick={() => history.push("/ambassadeurs")}>AMBASSADEURS</span>
                <span className='clickable__tag' onClick={() => handleOpenDialog()}>CORPORATIF</span>
                <span className='clickable__tag' onClick={() => history.push("/contact")}>CONTACTEZ-NOUS</span>
            </div>
            <Corpo open={openDialog} onClose={handleCloseDialog} />
        </div>
    );
}

export default FooterNav;
