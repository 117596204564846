import axios from "axios";
import { auth } from "../../configs/firebase";
import * as actions from "../apiCalls";
import { getServer } from "../../configs/backend";
import { snackbarEnqueuedAction, generateKey } from "../reducers/snackbars";
import { settings } from "../../settings";
import { dispatchActions } from "../utils/general";

const api =
    ({ dispatch }) =>
    next =>
    async action => {
        if (action.type !== actions.apiCallBegan.type) return next(action);

        const { url, method, data, onStart, onSuccess, onError, onFinish, showSuccessSnackbar, showErrorSnackbar } = action.payload;

        let headers = { "Content-Type": "application/json" };
        if (auth.currentUser) {
            const idToken = await auth.currentUser.getIdToken(false);
            headers.authorization = `Bearer ${idToken}`;
        }

        // Snackbars
        let displaySuccessSnackbar = true;
        let displayErrorSnackbar = true;
        if (showSuccessSnackbar !== null && showSuccessSnackbar !== undefined) displaySuccessSnackbar = showSuccessSnackbar;
        if (showErrorSnackbar !== null && showErrorSnackbar !== undefined) displayErrorSnackbar = showErrorSnackbar;

        // global snackbar initialization
        const pushSnackbar = (...args) => dispatch(snackbarEnqueuedAction(...args));

        if (onStart) dispatch({ type: onStart });
        next(action);

        //  Spinner ON
        // dispatch(loadingStart());

        axios
            .request({
                baseURL: `${getServer() + settings.urls.baseApi}`,
                url,
                method,
                data,
                headers,
            })
            .then(response => {
                dispatch(actions.apiCallSuccess(response.data));

                if (onSuccess) dispatchActions(dispatch, onSuccess, response.data);

                if (response.data.successMessage && displaySuccessSnackbar) {
                    pushSnackbar({ message: response.data.successMessage, options: { key: generateKey(), variant: "success" } });
                }
            })
            .catch(err => {
                dispatch(actions.apiCallFailed(err.message));

                if (onError) {
                    if (err.response) dispatchActions(dispatch, onError, { status: err.response.status, message: err.message });
                    else dispatchActions(dispatch, onError, { status: err.status, message: err.message });
                }

                if (err.response) {
                    const errorData = err.response.data;
                    if (errorData.errorMessage && displayErrorSnackbar)
                        pushSnackbar({ message: errorData.errorMessage, options: { key: generateKey(), variant: "error" } });
                } else {
                    // server did not respond
                    pushSnackbar({ message: "Erreur du serveur", options: { key: generateKey(), variant: "error" } });
                }
            })
            .finally(() => {
                //  Spinner OFF
                // dispatch(loadingEnd());

                if (onFinish) dispatchActions(dispatch, onFinish);
            });
    };

export default api;
