import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import draftToHtml from "draftjs-to-html";
import parse from "html-react-parser";

// Mui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

// Redux
import { useDispatch } from "react-redux";
import { addProduct as addProductAction, addSubscription } from "../../../store/reducers/checkout";

// Firestore
import { getRequest } from "../../../actions/requests";
import { subscriptionsRoot } from "../../../actions/subscriptionsRoute";

// Utils
import { fetchProductById } from "../../../utils/apiCalls";
import { getLandingPageInfo } from "../../../utils/config";

import useModal from "../../../hooks/useModal";
import Analytics from "../../../services/analytics";
import useGetUser from "../../../general/useGetUser";

let images = [];

const ProductTypeSelectionDialog = ({ open, onClose, items }) => {
    const { user } = useGetUser();
    const [Modal, present, dismiss] = useModal();
    const history = useHistory();
    // Redux
    const dispatch = useDispatch();
    const { brand } = getLandingPageInfo();

    // states
    const [product, setProduct] = useState(null);
    const [descriptionMarkup, setDescriptionMarkup] = useState(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    if (!_.isEmpty(product) && product.imageURLs) {
        images = product.imageURLs.reduce((acc, value) => {
            acc.push(<img src={value} alt="product" className="productpop__image" style={{ width: "100%" }} />);
            return acc;
        }, []);
    }

    const addProduct = product => {
        const status = product.isPhysical ? "À livrer" : "Expédié";
        dispatch(addProductAction({ ...product, selectedQuantity: 1, status }));
    };

    const handleOnContinue = async item => {
        if (item.isProduct) {
            if (product.skus?.length > 0 && !product.selectedSku) {
                present({
                    title: "Sélection de la variation du produit",
                    contentText: "Plusieurs choix s'offrent à vous. Sélectionnez une des options suivantes",
                });
                onClose();
                return;
            }
            addProduct(product);
        } else {
            const subscriptionPayload = await getRequest(`${subscriptionsRoot}/${item.id}`);
            const subscription = subscriptionPayload.data;
            dispatch(addSubscription({ ...subscription, selectedQuantity: 1 }));
        }
        history.push(`/checkout`);
    };

    useEffect(async () => {
        if (!_.isEmpty(items)) {
            const product = await fetchProductById(items.product.id);
            setProduct(product);
            Analytics.productViewed(product, user?.email);
            setDescriptionMarkup(draftToHtml(JSON.parse(product.description)));
        }
    }, [items]);

    const properPrice = () => {
        switch (product.id) {
            // og
            case "aTs1VgPZlOipr9DQz5VX":
                return "24,99$";
            // mixte
            case "bgK9NUY1REaNOs5jc3aQ":
                return "29,99$";
            // five
            case "G6ifNgXnArVGHXC0S6P9":
                return "109,95$";
            // erable
            case "Le3IWeixdoCZjyFYPoI5":
                return "26,99$";
            // saveurs
            default:
                return "29,99$";
        }
    };

    const properPrice1Sub = () => {
        switch (product.id) {
            // og
            case "aTs1VgPZlOipr9DQz5VX":
                return "22,49$ (Économisez 10%)";
            // mixte
            case "bgK9NUY1REaNOs5jc3aQ":
                return "26,99$ (Économisez 10%)";
            // saveurs
            default:
                return "26,99$ (Économisez 3$)";
        }
    };

    const properPrice2Sub = () => {
        switch (product.id) {
            // og
            case "aTs1VgPZlOipr9DQz5VX":
                return "42.48$ (21.24$/Sachet) (Économisez 15%)";
            // mixte
            case "bgK9NUY1REaNOs5jc3aQ":
                return "50.98$ (25.49$/Boîte) (Économisez 15%)";
            // saveurs
            default:
                return "49.99$ (24.99$/Boîte) (Économisez 10$)";
        }
    };
        useEffect(() => {
            console.log(product);
        }, [product]);

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="selection-dialog-title" className="productpop">
                <DialogTitle id="selection-dialog-title" className="productpop__title">
                    {product && product.name}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} className="productpop__images__container">
                            <Slider {...settings} autoplay>
                                {images.map((item, key) => (
                                    <div key={key}>
                                        {item && item.props && item.props.src && (
                                            <img src={`${item.props.src}`} alt="product" className="productpop__image" />
                                        )}
                                    </div>
                                ))}
                            </Slider>
                        </Grid>
                        <Grid item xs={12} sm={6} className="productpop__info__container">
                            {product && <div className="productpop__desc">{descriptionMarkup && parse(descriptionMarkup)}</div>}
                            <div>
                                <Button
                                    className={`productpop__cta ${brand}`}
                                    variant="contained"
                                    onClick={() => {
                                        handleOnContinue(items.product);
                                    }}
                                >
                                    Liberté sans abonnement {product && properPrice(product.id)}
                                </Button>

                                {items.sub1 && (
                                    <Button
                                        className={`productpop__cta ${brand}`}
                                        variant="outlined"
                                        onClick={() => {
                                            handleOnContinue(items.sub1);
                                        }}
                                    >
                                        Abonnement 1 {product && product.id === "aTs1VgPZlOipr9DQz5VX" ? "sachet" : "boîte"} / mois{" "}
                                        {product && properPrice1Sub(product.id)}
                                    </Button>
                                )}

                                {items.sub2 && (
                                    <Button
                                        className={`productpop__cta ${brand}`}
                                        variant="outlined"
                                        onClick={() => {
                                            handleOnContinue(items.sub2);
                                        }}
                                    >
                                        Abonnement 2 {product && product.id === "aTs1VgPZlOipr9DQz5VX" ? "sachets" : "boîtes"} / mois{" "}
                                        {product && properPrice2Sub(product.id)}
                                    </Button>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ProductTypeSelectionDialog;
