import React from "react";

// Components
import Nav from "./shared/nav";
import Header from "./about/header";
import Mission from "./about/mission";
import Values from "./about/values";
import Team from "./about/team";
import Banner from "./shared/banner";
import Newsletter from "./shared/newsletter";
import Footer from "./shared/footer";

const about = () => {
    return (
        <div className="bs">
            <div className="about">
                <Nav />
                <Header />
                <Mission />
                <Values />
                <Team />
                <Banner />
                <Newsletter />
                <Footer />
            </div>
        </div>
    );
};

export default about;
