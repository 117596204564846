import React, { useCallback, useState } from "react";
import algoliasearch from "algoliasearch";

// Custom components
import Searchfield from "../components/shared/Searchfield";

// hooks
import useDebounce from "./useDebounce";

const algolia = algoliasearch("ZJRXYU80DX", "2ae2bf4bc82a32e8a640a70759354433");

export default function useAlgoliaSearch(searchIndex) {
    const index = algolia.initIndex(searchIndex);

    const [hits, setHits] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [fetching, setFetching] = useState(false);

    useDebounce(
        () => {
            index
                .search(searchText)
                .then(res => {
                    setHits(res.hits);
                })
                .finally(() => {
                    setFetching(false);
                });
        },
        500,
        [searchText]
    );

    const handleChange = e => {
        setFetching(true);
        const { value } = e.target;

        setSearchText(value);
    };

    const Component = useCallback(props => <Searchfield {...props} onInputChange={handleChange} />, []);

    return [hits, fetching, Component];
}
