import React from "react";
import Footer1 from "../../../assets/gk/footer-gc.png";
import Footer2 from "../../../assets/gk/footer-gm.png";
import Footer3 from "../../../assets/gk/footer-akt.png";
import Footer4 from "../../../assets/gk/footer-mcf.png";

function Footer() {
    return (
        <div className="footer">
            <h4 className="footer__title">Une gamme innovatrice complète de produits santé</h4>
            <div className="footer__container">
                <a href="https://gochoko.com"><img src={Footer1} alt="" /></a>
                <a href="https://gomatcha.ca/" target="_blank"><img src={Footer2} alt="" /></a>
                <a href="https://aktivation.ca" target="_blank"><img src={Footer3} alt="" /></a>
                <a href="https://melangecoupefaim.com/" target="_blank"><img src={Footer4} alt="" /></a>
            </div>
        </div>
    );
}

export default Footer;