import React from 'react';

function Lh() {
  return (
    <div className="lh">
        Propulsé par: <a href="https://leadhouse.ca" target="_blank">LEADHOUSE</a>
    </div>
  );
}

export default Lh;
