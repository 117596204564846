import React from 'react';
import Grid from '@material-ui/core/Grid';

import Aktivation from '../../../assets/akt/logo-grey.png';
import Signature from '../../../assets/akt/js-signature.png';

function Footer() {
    return (
        <div className="footer">
            <Grid container>
                <Grid item xs={12} lg={3}>
                    <div className="footer__logo__div">
                        <img src={Aktivation} className="footer__logo"/>
                    </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <span className="footer__title">La mission d'AKTIVATION en 3 points</span>
                    <span className="footer__feature">Faire rayonner le plus grand nombre d’entraineurs québécois possible</span>
                    <span className="footer__feature">Offrir LA MEILLEURE QUALITÉ VIDÉO sur le marché</span>
                    <span className="footer__feature">Permettre au plus de gens d’Activer le changement à leur rythme!</span>
                </Grid>

                <Grid item xs={12} lg={3}>
                    <div className="footer__logo__div">
                        <img src={Signature} className="footer__logo"/>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Footer
