import React from "react";

// Assets
import x from "../../../assets/bs/menu-close.png";

// Mui
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

const Questions = () => {
    return (
        <div className="questions">
            <Accordion className="questions__accordion">
                <AccordionSummary
                    expandIcon={
                        <div>
                            <img src={x} alt="" />
                        </div>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h3 className="questions__accordion--title">Commande en ligne</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <h6 className="questions__accordion--subtitle">Comment fonctionnent les Boîtes Forfait et les repas choisis à la carte ?</h6>
                    <p className="questions__accordion--text">
                        Super simple! Nous offrons les Boîtes forfait (12 ou 24 repas) ainsi que la possibilité de choisir à la carte. Les Boîtes
                        forfait vous donnent droit à un rabais par rapport à l'option à la carte équivalente. Les différences, mise à part le prix,
                        est que le contenu des Boîtes forfait est au choix du chef, alors qu'à la carte, vous pouvez choisir vos repas comme bon vous
                        semble. Toutefois, nous vous garantissons que vous n'aurez jamais plus que 1 repas en double. De plus, les Boîtes forfait se
                        renouvellent automatiquement. (Note : vous pouvez vous désabonner d'un Boîte forfait, en tout temps, sans condition, en autant
                        que vous le fassiez avant la date de renouvellement de la commande).
                    </p>
                    <h6 className="questions__accordion--subtitle">Comment est-ce que je commande ?</h6>
                    <p className="questions__accordion--text">
                        Vous n'avez qu'à ajouter une Boîte forfait à votre panier, ou votre choix de 12 à 24 repas. Par la suite, vous pourrez
                        également ajouter d'autres aliments comme du Mélange Coupe Faim 2.0 ou un sachet de Go Matcha!! Finalement, vous n'aurez qu'à
                        passer à votre panier, procéder à l'entrée de vos informations et au paiement. Notez qu'à votre première commande, vous devrez
                        vous créer un compte.
                    </p>
                    <h6 className="questions__accordion--subtitle">Quelles sont les méthodes de paiement acceptées ?</h6>
                    <p className="questions__accordion--text">Vous pouvez payer par carte de crédit directement sur le site, par Stripe.</p>
                    <h6 className="questions__accordion--subtitle">Comment est-ce que je me désabonne de mon Boîte Santé ?</h6>
                    <p className="questions__accordion--text">
                        Vous pouvez vous désabonner de votre Boîte forfait directement dans votre compte sous l'onglet « Abonnements ». Notez que vous
                        devez vous désabonner 24h avant le renouvellement automatique de votre Boîte forfait. Une fois la date de votre renouvellement
                        arrivée, il n'est plus possible de vous désabonner pour cette commande mais vous pourrez vous désabonner pour la suivante.
                    </p>
                    <h6 className="questions__accordion--subtitle">Est-ce que je peux choisir mes repas individuellement ?</h6>
                    <p className="questions__accordion--text">
                        Nous vous conseillons nos Boîtes forfait, mais si vous désirez choisir vos repas à la carte, vous pouvez le faire et c'est
                        très simple! Vous n'avez qu'à ajouter vos repas au panier et le tour est joué. À noter que vous devez impérativement choisir
                        les repas à la carte en multiple de 12 (12 ou 24) afin que votre commande puisse être terminées et complétée par notre équipe.
                    </p>
                    <h6 className="questions__accordion--subtitle">Est-ce qu'il y a un minimum pour commander ?</h6>
                    <p className="questions__accordion--text">
                        La commande de repas Boîte Santé minimum est d'une Boîte forfait de 12 repas, ou 12 repas à la carte.
                    </p>
                </AccordionDetails>
            </Accordion>
            <Accordion className="questions__accordion">
                <AccordionSummary
                    expandIcon={
                        <div>
                            <img src={x} alt="" />
                        </div>
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <h3 className="questions__accordion--title">Livraison</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className="questions__accordion">
                <AccordionSummary
                    expandIcon={
                        <div>
                            <img src={x} alt="" />
                        </div>
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <h3 className="questions__accordion--title">Les repas</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className="questions__accordion">
                <AccordionSummary
                    expandIcon={
                        <div>
                            <img src={x} alt="" />
                        </div>
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <h3 className="questions__accordion--title">Divers</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default Questions;
