import React from 'react'

const Mission = () => {
  return (
    <div className="mission">
      <div className="mission__container">
        <p className="mission__section">NOTRE MISSION</p>
        <h4 className="mission__title">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum integer imperdiet ut condimentum volutpat. Mauris malesuada vitae sed cras. Leo senectus mi sed vitae mattis.</h4>
      </div>
    </div>
  )
}

export default Mission