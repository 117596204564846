import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import Pdf from "react-to-pdf";

// Material UI Core
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

// Material UI Icons
import GetAppIcon from "@material-ui/icons/GetApp";

// DB
import { rootOrders } from "../../../actions/ordersRoute";
import { brandsRoot } from "../../../actions/brandsRoute";
import { marketsRoot } from "../../../actions/marketRoute";
import { getRequest } from "../../../actions/requests";

// Custom Components
import { GetPortalHelmet } from "../../../utils/config";
import { UserContext } from "../../../reducer/userContext";
import { getBrandLogo } from "../../../utils/config";
import { parseDate, formatPrice } from "../../../utils/parseData";
import BillTableWrapper from "./billTableWrapper";
import ItemsTotals from "./itemsTotals";

const Bill = () => {
    // Params from app.js
    const { id } = useParams();
    const { user } = useContext(UserContext);

    const [brand, setBrand] = useState({});
    const [market, setMarket] = useState({});
    const [order, setOrder] = useState({});
    const [refunds, setRefunds] = useState([]);
    // table
    const [loading, setLoading] = useState(true);

    const ref = React.createRef();

    const fetchBrand = name => {
        getRequest(`${brandsRoot}/${name}`, {})
            .then(res => {
                if (res.data) {
                    setBrand(res.data);
                }
            })
            .catch(err => {
                if (err.response.data) console.error(err.response.data);
                else console.error(err);
            });
    };

    const fetchMarket = marketId => {
        setLoading(true);
        getRequest(`${marketsRoot}/${marketId}`, {})
            .then(res => {
                if (res.data) {
                    setMarket(res.data);
                }
            })
            .catch(err => {
                if (err.response.data) console.error(err.response.data);
                else console.error(err);
            })
            .finally(() => setLoading(false));
    };

    const fetchOrder = orderId => {
        setLoading(true);
        getRequest(`${rootOrders}/${orderId}`, {})
            .then(res => {
                setOrder(res.data);
                setLoading(false);
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchOrder(id);
    }, [id]);

    useEffect(() => {
        if (order.hasOwnProperty("brand") && order.hasOwnProperty("market")) {
            fetchBrand(order.brand.name);
            fetchMarket(order.market);
        }
    }, [order]);

    useEffect(() => {
        (async () => {
            try {
                const orderRefunds = (await getRequest(`refunds/?orderId=${id}`)).data;

                const refunds = [];
                const products = [];
                for (const { items, createdAt } of orderRefunds) {
                    for (const item of items) {
                        if (products.length === 0 || !products.some(p => p.id === item.id))
                            products.push((await getRequest(`products/${item.id}`)).data);
                        refunds.push({ createdAt, amount: item.amount, productName: products.find(p => p.id === item.id).name });
                    }
                }
                setRefunds(refunds);
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    return (
        <>
            <GetPortalHelmet title={`Facture #${order.hasOwnProperty("orderNumber") && order.orderNumber}`} />
            <div ref={ref} className="bill__container">
                <Grid container spacing={2}>
                    <Grid container item xs={6}>
                        <Grid item xs={12} style={{ paddingBottom: 10 }}>
                            <img src={getBrandLogo()} alt="" />
                            <div>Division de Jimmy Sevigny Inc.</div>
                        </Grid>
                        {!_.isEmpty(brand) && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        {brand.coordinates.appartment && brand.coordinates.appartment + "-"}
                                        {brand.coordinates.streetNumber} {brand.coordinates.street}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        {brand.coordinates.city}, {brand.coordinates.province}, {brand.coordinates.country}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">{brand.coordinates.postalCode}</Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid container item xs={6}>
                        <Grid container item xs={12} justify="flex-end">
                            <Grid container item xs={12} justify="flex-end">
                                <Typography variant="h5">#{order.orderNumber}</Typography>
                            </Grid>
                            <Grid container item xs={12} justify="flex-end">
                                {`${user && user.firstName} ${user && user.lastName}`}
                            </Grid>

                            {!_.isEmpty(order.shippingCoordinates) ? (
                                <>
                                    <Grid container item xs={12} justify="flex-end">
                                        {order.shippingCoordinates.appartment && `${order.shippingCoordinates.appartment}-`}
                                        {`${order.shippingCoordinates.streetNumber} ${order.shippingCoordinates.street}`}
                                    </Grid>
                                    <Grid container item xs={12} justify="flex-end">
                                        {`${order.shippingCoordinates.city}, ${order.shippingCoordinates.province}, ${order.shippingCoordinates.country}`}
                                    </Grid>
                                    <Grid container item xs={12} justify="flex-end">
                                        {order.shippingCoordinates.postalCode}
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    {user.coordinates && (
                                        <>
                                            <Grid container item xs={12} justify="flex-end">
                                                {user.coordinates.appartment && `${user.coordinates.appartment}-`}
                                                {`${user.coordinates.streetNumber} ${user.coordinates.street}`}
                                            </Grid>
                                            <Grid container item xs={12} justify="flex-end">
                                                {`${user.coordinates.city}, ${user.coordinates.province}, ${user.coordinates.country}`}
                                            </Grid>
                                            <Grid container item xs={12} justify="flex-end">
                                                {user.coordinates.postalCode}
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justify="flex-end">
                        <Typography variant="h6">
                            <strong>Date facture:</strong> {order.createdAt && parseDate(order.createdAt)}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <BillTableWrapper
                                data={order.items}
                                loading={loading}
                                market={market}
                                brand={brand}
                                order={order}
                                setLoading={setLoading}
                            />
                            <Grid container>
                                <ItemsTotals order={order} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {refunds.length > 0 && (
                    <div className="refund__table">
                        <h1>Remboursement</h1>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date de création</TableCell>
                                    <TableCell>Produit</TableCell>
                                    <TableCell>Montant</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {refunds.map((r, index) => (
                                    <TableRow key={r.productName + "-" + index}>
                                        <TableCell>{parseDate(r.createdAt)}</TableCell>
                                        <TableCell>{r.productName}</TableCell>
                                        <TableCell>{formatPrice(r.amount)}$</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </div>

            <Grid container item justify="flex-end">
                <Pdf targetRef={ref} filename={`commande-${order.orderNumber}.pdf`}>
                    {({ toPdf }) => (
                        <div className={clsx("vertical__centered", "submit__fab")}>
                            <Fab className="btn-left-margin" color="primary" variant="extended" onClick={toPdf}>
                                <GetAppIcon className="btnIcon" />
                                Télécharger
                            </Fab>
                        </div>
                    )}
                </Pdf>
            </Grid>
        </>
    );
};
export default Bill;
