import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import api from "./middleware/api";

import systemReducer from "./reducers/system";
import productReducer from "./reducers/products";
import orderReducer from "./reducers/orders";
import userReducer from "./reducers/users";
import marketReducer from "./reducers/markets";
import brandReducer from "./reducers/brands";
import recipeReducer from "./reducers/recipes";
import snackbarReducer, { snackbarEnqueued, snackbarClosed, snackbarRemoved } from "./reducers/snackbars";
import storeReducer from "./reducers/stores";
import subscriptionsReducer from "./reducers/subscriptions";
import checkoutReducer from "./reducers/checkout";
import workoutsReducer from "./reducers/workouts";
import flashSalesReducer from "./reducers/flashSales";

// Run serializer on these actions. Also turn off default serialization
// checks on them
const serializeActions = [snackbarEnqueued.type, snackbarClosed.type, snackbarRemoved.type];

// TODO: add middleware if needed
export const store = configureStore({
    reducer: combineReducers({
        system: systemReducer,
        products: productReducer,
        orders: orderReducer,
        users: userReducer,
        markets: marketReducer,
        brands: brandReducer,
        recipes: recipeReducer,
        snackbars: snackbarReducer,
        stores: storeReducer,
        subscriptions: subscriptionsReducer,
        checkoutData: checkoutReducer,
        workouts: workoutsReducer,
        flashSales: flashSalesReducer,
    }),

    middleware: [...getDefaultMiddleware(), api],
});
