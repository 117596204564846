import React from "react";

import { nanoid } from "nanoid";

export const renderItemImage = item => {
    let src = item.imageURLs[0];
    if (item.selectedSku && item.skuImgs.associatedProp) {
        const sku = item.skus.find(s => s.sku.value === item.selectedSku)[item.skuImgs.associatedProp];

        if (sku?.src) src = sku.src;
    }

    return <img src={src} className="image__cart" alt="" />;
};

export const formatCartItems = (lastOrder, products, subscriptions) => {
    const applyPriceFromLastOrder = () => {
        if (!lastOrder) return [...products, ...subscriptions];

        const replaceItemPrice = item => {
            for (const i of lastOrder.items) {
                if (i.id !== item.id) continue;

                if (i.price) item["price"] = i.price;
            }
            return item;
        };

        return [...JSON.parse(JSON.stringify(products)).map(replaceItemPrice), ...JSON.parse(JSON.stringify(subscriptions)).map(replaceItemPrice)];
    };

    return applyPriceFromLastOrder().reduce((items, current) => {
        let index = -1;

        if (current.selectedSku) index = items.findIndex(item => item.selectedSku === current.selectedSku);
        else index = items.findIndex(item => item.name === current.name);
        if (index !== -1) {
            items[index].selectedQuantity++;

            return items;
        }

        return [...items, { ...current, uid: nanoid(), selectedQuantity: 1 }];
    }, []);
};
