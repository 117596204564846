import _ from "lodash";
// Utils
import { getLandingPageName } from "../utils/config";

/**
 * Generates an order
 * @param {any[]} subscriptions
 * @param {any[]} products
 * @param {object} details
 * @param {object} user
 * @param {object} rebate
 * @param {object} promoCode
 * @param {string} brand
 * @param {string} brandAcronym
 * @param {string} market
 * @returns {object} order
 */
export const generateOrder = (
    subscriptions,
    products,
    details,
    user,
    promoCode,
    brand,
    brandAcronym,
    market,
    isFree = false,
    flashsaleId = undefined
) => {
    let orderItems = products.concat(subscriptions);

    orderItems = orderItems.map(i => {
        let item = {
            id: i.id,
            isSubscription: i.shippingInfo ? false : true,
            selectedSku: i.selectedSku ?? null,
            isAddOnTrial: i.isAddOnTrial ? true : false,
        };

        if (flashsaleId) item.flashsaleId = flashsaleId;
        if (i.price >= 0) item.price = i.price;

        return item;
    });

    let order = {
        flashsaleId,
        isFree,
        source: getLandingPageName(),
        items: orderItems,
        // rebate,
        brand: {
            name: brand,
            acronym: brandAcronym,
        },
        market,
        billingCoordinates: details.billing,
        shippingCoordinates: details.shipping ? details.shipping : details.billing,
        user: {
            id: user ? user.id : details.email.toLowerCase(),
            firstName: details.firstName ? details.firstName : user ? user.firstName : "",
            lastName: details.lastName ? details.lastName : user ? user.lastName : "",
            email: details.email ? details.email.toLowerCase() : user ? user.email.toLowerCase() : "",
            phone: details.phone ? details.phone : user ? user.phone : "",
        },
    };

    if (promoCode) {
        order.promoCode = promoCode.toLowerCase();
    }

    return order;
};

export const orderItemsComparator = (a, b) => {
    if (a.selectedSku && b.selectedSku) return a.selectedSku === b.selectedSku;
    return a.id === b.id;
};
