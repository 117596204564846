/*
 * returns the value of the object at the given key.
 * The key argument can be separated by a "." to indicate
 * the the value is nested
 * */
export const getValue = (o, key) => {
    if (!key.includes(".")) return o[key];

    const keys = key.split(".");
    const [thisKey, ...rest] = keys;
    return getValue(o[thisKey], rest.join("."));
};

/*
 * return a list of [key, value] that satisfy the predicate
 * */
export const findKeyValuePairs = (o, predicate) => Object.entries(o).filter(([key, element]) => predicate([key, element]));

export const findKeys = (o, predicate) => findKeyValuePairs(o, predicate).map(([key]) => key);
export const findValues = (o, predicate) => findKeyValuePairs(o, predicate).map(([_, value]) => value);

/*
 * returns whether all values satisfy the predicate
 * */
export const valuesSatisfy = (o, predicate) => findKeys(o, predicate).length === Object.keys(o).length;
