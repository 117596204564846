import React from "react";
import { useHistory } from "react-router-dom";
// Redux
import { useDispatch } from "react-redux";
import { addProduct } from "../../../store/reducers/checkout";
// DB
import { getRequest } from "../../../actions/requests";
import { getById } from "../../../actions/productsRoute";

import { Grid } from "@material-ui/core";
import Left from "../../../assets/gm/sample.png";

function Sample() {
    const history = useHistory();
    const dispatch = useDispatch();

    const SAMPLE_PACK = "7NNDmua6rCxv8ua1RYP0";

    return (
        <div className="sample" id="sample">
            <Grid container>
                <Grid item sm={12} md={6}>
                    <img src={Left} alt="" />
                </Grid>
                <Grid item sm={12} md={6}>
                    <div className="sample__container">
                        <span>
                            <span className="sample__title">
                                Essayez notre <br />
                                <b>'sample pack'!</b>
                            </span>
                            <span className="sample__subtitle">Essayez les tous avant de commander votre saveur préférée!</span>
                            <span className="sample__text">1 x sachet Go Matcha! aux bleuets & canneberges</span>
                            <span className="sample__text">1 x sachet Go Matcha! aux framboises</span>
                            <span className="sample__text">1 x sachet Go Matcha! au chaï</span>
                            <span className="sample__text">1 x sachet Go Matcha! aux pêches </span>
                            <span className="sample__text">1 x sachet Go Matcha! au citron & gingembre </span>
                            <span className="sample__text">1 x sachet Go Matcha! au melon d'eau  </span>
                            <a href="#produits">
                                <button
                                    className="sample__cta"
                                    onClick={async () => {
                                        const payload = await getRequest(`${getById}/${SAMPLE_PACK}`, {});
                                        dispatch(addProduct({ ...payload.data, selectedQuantity: 1, status: "À livrer" }));
                                        history.push(`/checkout`);
                                    }}
                                >
                                    Essayer pour seulement 11.94$
                                </button>
                            </a>
                        </span>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Sample;
