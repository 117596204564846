import { useEffect } from "react";
import useTimeout from "./useTimeout";

// run a callback after "delay" amount of time has run (in ms).
// As opposed to useTimeout, this hook make is so each time the
// dependencies change, we reset the timer
export default function useDebounce(callback, delay, dependencies) {
    const { reset, clear } = useTimeout(callback, delay);

    useEffect(reset, [...dependencies, reset]);

    useEffect(clear, []);
}
