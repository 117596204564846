import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";

// Material UI Core
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

// Material UI Icons
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

// Custom Components
import Table from "../utils/table";
import { formatValue, getTimestampDifferenceInDay } from "../../../utils/parseData";
import { getItemPrice } from "../../../utils/price";
import { UserContext } from "../../../reducer/userContext";
import { getLandingPageInfo } from "../../../utils/config";
import { getRequest } from "../../../actions/requests";

// Redux
import { useSelector } from "react-redux";
import { getAllOrders } from "../../../store/reducers/orders";
import { getSelectedEmail } from "../../../store/reducers/checkout";

// Settings
import { settings } from "../../../settings";
import { renderItemImage } from "./utils";

const CartTableWrapper = ({ data, onDeleteItem, onModifyItem, onQuantityChange }) => {
    const { user } = useContext(UserContext);
    const { brand, market } = getLandingPageInfo();

    // Redux
    const orders = useSelector(getAllOrders);
    const selectedEmail = useSelector(getSelectedEmail);

    // States
    const [sortColumn, setSortColumn] = useState({ path: "orderNumber", order: "asc" });
    const [selectedPage, setSelectedPage] = useState(0);
    const [aktSubscribedUser, setAktSubscribedUser] = useState(null);

    useEffect(() => {
        const fetchAktSubscribedUser = async () => {
            try {
                if (selectedEmail && (!user || (user && !user.videoTrialUsed))) {
                    const payload = await getRequest(`users/aktSubscribedUsers/${selectedEmail.toLowerCase()}`);
                    setAktSubscribedUser(payload.data);
                }
            } catch (e) {
                console.error(e);
            }
        };

        fetchAktSubscribedUser();
    }, [selectedEmail]);

    const columns = [
        {
            key: "image",
            body: item => renderItemImage(item),
        },
        {
            path: "name",
            label: "Item",
            asyncBody: async item => {
                let freeTrialText = "";

                if (brand === "Aktivation" && selectedEmail) {
                    if (item.freeTrial && item.freeTrial.timeframe) {
                        if (!_.isEmpty(aktSubscribedUser)) {
                            if (!aktSubscribedUser.used && Date.now() < new Date(aktSubscribedUser.nextCycle).getTime()) {
                                const days = getTimestampDifferenceInDay(Date.now(), aktSubscribedUser.nextCycle);
                                freeTrialText = `(le forfait vous sera facturé après votre essai gratuit de ${days} jour${days > 1 ? "s" : ""})`;
                            }
                        } else freeTrialText = `(le forfait vous sera facturé après votre essai gratuit de ${formatValue(item.freeTrial.timeframe)})`;
                    }
                }

                if (brand === "Aktivation" && item.isAddOnTrial)
                    freeTrialText = `(le produit vous sera envoyé après votre essai gratuit si vous poursuivez votre abonnement)`;

                return (
                    <>
                        <span>
                            {item.name} <br />
                            {!user || (user && !user.videoTrialUsed && item.freeTrial) ? freeTrialText : ""}
                        </span>
                        <span style={{ color: "red" }}>{item.isAddOnTrial && !user.videoTrialUsed ? freeTrialText : ""}</span>
                    </>
                );
            },
        },
        {
            path: "selectedQuantity",
            label: "Qté",
            body: item => {
                if ("limitQuantity" in item && orders.length === 0) {
                    return (
                        <TextField
                            name="quantity"
                            type="number"
                            style={{ maxWidth: 50 }}
                            value={item.selectedQuantity}
                            onChange={e => onQuantityChange(item, e.target.value)}
                            inputProps={{ min: 1, max: Number.MAX_VALUE }}
                            InputProps={{
                                readOnly: "price" in item, // if item has custom price, the qty can't be changed
                            }}
                        />
                    );
                } else {
                    return item.selectedQuantity;
                }
            },
        },
        {
            path: "price",
            key: "price",
            label: "Prix",
            body: item => {
                return <span style={{ whiteSpace: "nowrap" }}>{getItemPrice(item)} $</span>;
            },
        },
        {
            path: "subTotal",
            key: "subTotal",
            label: "Sous-total",
            body: item => {
                return <span style={{ whiteSpace: "nowrap" }}>{(getItemPrice(item) * item.selectedQuantity).toFixed(2)} $</span>;
            },
        },
        {
            key: "modifyItem",
            body: item => {
                if (item.skus?.length > 0 || item.selectedSku) {
                    return (
                        <IconButton aria-label="ship-item" style={{ padding: 0 }} onClick={() => onModifyItem(item)}>
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                    );
                }
            },
        },
        {
            key: "deleteItem",
            body: item => (
                <IconButton aria-label="ship-item" style={{ padding: 0 }} onClick={() => onDeleteItem(item)}>
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            ),
        },
    ];

    const sortedData = _.orderBy(data, [sortColumn.path], [sortColumn.order]);
    const totalCount = sortedData.length;

    const handleSort = sortColumn => {
        setSortColumn(sortColumn);
    };

    const handlePageChange = (_, page) => {
        setSelectedPage(page);
    };

    return (
        <Table
            columns={columns}
            sortColumn={sortColumn}
            data={sortedData}
            rowsPerPage={settings.page.rowsPerPage}
            selectedPage={selectedPage}
            totalCount={totalCount}
            paginated={false}
            rowClickable={false}
            onSort={handleSort}
            onPageChange={handlePageChange}
        />
    );
};

export default CartTableWrapper;
