import React from "react";

// custom components
import Searchbar from "./Searchbar";
import Cart from "./Cart";
import Filters from "./Filters";

import style from "./Boutique.module.scss";

// TODO: place sidebar as sticky
const Boutique = ({ showFilters = true, children }) => {
    return (
        <div className={style.container}>
            <section className={style.content}>
                <Searchbar />
                <div className={style.main}>{children}</div>
            </section>
            <section className={style.sidebar}>
                <Cart />
                {showFilters && <Filters />}
            </section>
        </div>
    );
};

export default Boutique;
