import React from "react";
import { useHistory } from "react-router-dom";

import Slider from "react-slick";
import Sarah from "../../../assets/akt/sarah.jpg";
import Jimmy from "../../../assets/akt/jimmy.jpg";
import Joanie from "../../../assets/akt/joanie.jpg";
import Christian from "../../../assets/akt/christian.jpg";
import Julie from "../../../assets/akt/julie.jpg";
import Sophie from "../../../assets/akt/sophie.jpg";
import Gen from "../../../assets/akt/gen.jpg";
import Tam from "../../../assets/akt/tammara.jpg";

function Coach() {
    const history = useHistory();
    const settings = {
        // draggable: true,
        // swipe: true,
        // touchMove: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        // autoplay: true,
        // autoplaySpeed: 2000,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    arrows: false,
                },
            },
        ],
    };

    return (
        <div className="coach">
            <h3 className="coach__title">NOS COACHS</h3>
            <Slider {...settings} className="coach__slider">
                <div className="coach__item">
                    <img src={Gen} />
                    <h5>Gen Tardif</h5>
                </div>

                <div className="coach__item">
                    <img src={Sarah} />
                    <h5>Coach Sarah</h5>
                </div>

                <div className="coach__item">
                    <img src={Jimmy} />
                    <h5>Jimmy Sévigny</h5>
                </div>

                <div className="coach__item">
                    <img src={Joanie} />
                    <h5>Joanie Tardif</h5>
                </div>

                <div className="coach__item">
                    <img src={Tam} />
                    <h5>Tammara Thibeault</h5>
                </div>

                <div className="coach__item">
                    <img src={Christian} />
                    <h5>Christian Racicot</h5>
                </div>

                <div className="coach__item">
                    <img src={Julie} />
                    <h5>Julie Bégin</h5>
                </div>

                <div className="coach__item">
                    <img src={Sophie} />
                    <h5>Sophie Yergeau</h5>
                </div>
            </Slider>

            <button className="coach__cta" onClick={() => history.push("/devenir-entraineur")}>DEVIENS NOTRE PROCHAIN ENTRAINEUR</button>
        </div>
    );
}

export default Coach;
